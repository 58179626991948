import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

import { Parameter, Medication } from '@/timeline/types/Parameter';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import { computed, toRef, Ref } from 'vue';
import use from '@/_shared/compositionApi';
import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicationParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<Medication>;
const colour = computed(() => ((coercedValue.value)?.refused ? 'red' : 'green'));

const locationCodename = computed(() => coercedValue?.value.locationCodename);

const { instanceLocations, defaultSide } = useBodyMapLocationsHelper().forLocationCodeName(locationCodename);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseParameter, {
    hideHeading: "",
    parameter: __props.parameter
  }, {
    "second-line": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("b", null, _toDisplayString(__props.parameter.name), 1),
          _createElementVNode("p", {
            class: _normalizeClass(colour.value)
          }, _toDisplayString(coercedValue.value?.reason), 3),
          (coercedValue.value?.notes)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(translate)('common.note')) + ": " + _toDisplayString(coercedValue.value.notes), 1))
            : _createCommentVNode("", true),
          (coercedValue.value?.administeredBy)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(translate)('timeline.parameters.administeredBy')) + ": " + _toDisplayString(coercedValue.value.administeredBy), 1))
            : _createCommentVNode("", true),
          (coercedValue.value?.witnessedBy)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(translate)('timeline.parameters.witnessedBy')) + ": " + _toDisplayString(coercedValue.value.witnessedBy), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["quantity", colour.value])
        }, _toDisplayString(coercedValue.value?.quantity), 3)
      ]),
      (coercedValue.value?.locationCodename)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (coercedValue.value?.medicationRoute)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(translate)('timeline.parameters.medicationRoute')) + ": " + _toDisplayString(coercedValue.value.medicationRoute), 1))
                : _createCommentVNode("", true),
              (coercedValue.value?.locationDescription)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_unref(translate)('timeline.parameters.medicationLocation')) + ": " + _toDisplayString(coercedValue.value.locationDescription), 1))
                : _createCommentVNode("", true),
              _createVNode(BodyMapImage, {
                instanceLocations: _unref(instanceLocations),
                whiteBackground: true,
                "side-to-show": _unref(defaultSide)
              }, null, 8, ["instanceLocations", "side-to-show"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["parameter"]))
}
}

})