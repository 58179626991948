import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-name-container" }

import {
  reactive, watch, onBeforeMount, PropType, ref, onUnmounted,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { fetchClientTypes } from '@/client_types/services/clientTypesApi';
import { getClientTemplates } from '@/clients/services/clientsApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import use from '@/_shared/compositionApi';
import useUserStore from '@/_shared/store/user';

import { FormClient } from './formClientType';
import NotKnownDatePicker from './NotKnownDatePicker.vue';

type optionArray = Array<string | { text: string, value: string | number }>;

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseClientForm',
  props: {
  fields: {
    type: Array as PropType<string[]>,
    required: true,
  },
  inputWidth: {
    type: String,
    required: true,
  },
  closed: {
    type: Boolean,
    required: true,
  },
},
  emits: ['requiredComplete', 'requiredMissing', 'changed', 'unchanged'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

function translateOption(key: string) {
  return {
    text: translate(key),
    value: key.split('.').pop() as string,
  };
}

const props = __props;

const required = props.fields.filter((field: string) => field.endsWith('*'))
  .map((field: string) => field.slice(0, -1));
const fields = props.fields.map((field: string) => field.replace(/\*$/, ''));

function replaceArrayInPlace(target: optionArray, source: optionArray) {
  target.length = 0;
  target.push(...source);
}

const titleOptions = reactive([]);
const sexOptions = reactive([]);
const relationshipOptions = reactive([]);
const clientTypeOptions = reactive([]);
const templateOptions = reactive([]);
onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('clients'); // TODO: remove when pure vue
  replaceArrayInPlace(titleOptions, ['titles.mr', 'titles.mrs', 'titles.ms', 'titles.dr', 'titles.prof'].map(translate));
  replaceArrayInPlace(sexOptions, ['sex.female', 'sex.male', 'sex.indeterminate', 'sex.not_specified'].map(translateOption));
  // TODO: are the values right?
  replaceArrayInPlace(relationshipOptions, ['family', 'friend', 'community', 'social_care', 'health_care'].map(
    (option) => translateOption(`relationship.${option}`),
  ));
  if (fields.includes('clientType')) {
    const clientTypes = await fetchClientTypes(useUserStore().currentOrgId);
    if (clientTypes?.length) {
      const visibleClientTypes = clientTypes.filter((type) => type.visible);
      replaceArrayInPlace(clientTypeOptions, visibleClientTypes.map((type) => ({ text: type.name, value: type.codename })));
    }
  }
  if (fields.includes('template')) {
    const clientTemplates = await getClientTemplates(useUserStore().currentOrgUnitId);
    if (clientTemplates?.clients?.length) {
      replaceArrayInPlace(templateOptions, clientTemplates.clients.map(
        (client) => ({ text: client.name, value: client.id }),
      ));
    }
  }
});
onUnmounted(() => { window.vueUnsavedMessage = null; });
function filterOutSingleOptions(field: string) {
  // these are auto-selected so shouldn't count
  return !(
    (field === 'clientType' && clientTypeOptions.length === 1)
    || (field === 'template' && templateOptions.length === 1)
  );
}

const client = reactive({} as FormClient);
const emit = __emit;
const changed = ref(false);
watch(client, () => {
  const entered = Object.entries(client).filter((x) => x[1]).map((x) => x[0]);
  const manuallyEntered = entered.filter(filterOutSingleOptions);
  changed.value = !!manuallyEntered.length;
  const missing = Object.fromEntries(required.filter((x) => !entered.includes(x)).map((x) => [x, true]));
  if (missing.template && client.careplanReplicateClientId) delete missing.template;
  if (missing.templateName && client.firstName) {
    delete missing.templateName;
    client.lastName = 'Template';
  }
  if (missing.serviceName && client.firstName) delete missing.serviceName;
  if (missing.name && client.firstName) delete missing.name;
  if (Object.keys(missing).length > 0) {
    emit('requiredMissing');
  } else {
    emit('requiredComplete', client);
  }
});

const marginBottom = ref('0px');
switch (fields.at(-1)) {
  case 'sex':
    marginBottom.value = '6rem';
    break;
  case 'startOn':
    marginBottom.value = '6rem';
    break;
  case 'clientType':
  case 'clientTypeChangedLater':
  case 'template':
    // slightly above maximum height of select
    marginBottom.value = '11rem';
    break;
  default:
}

function nowAddYears(years: number) {
  const date = new Date();
  date.setFullYear(date.getFullYear() + years);
  return date;
}

// TODO both watch and onBeforeRouteLeave should be removed once UI is in Vue

watch([() => props.closed, changed], () => {
  if (!props.closed && changed.value) {
    window.vueUnsavedMessage = translate('common.unsaved_changes');
    return;
  }
  window.vueUnsavedMessage = null;
});

onBeforeRouteLeave(() => {
  if (window.vueUnsavedMessage) {
    const answer = window.confirm(translate('common.unsaved_changes'));
    if (!answer) return false;
  }
  window.vueUnsavedMessage = null;
  return true;
});

// TODO use me instead of the previous method once moved a way from angular
// onBeforeRouteLeave((to, from) => {
//   if (!props.closed && changed.value) {
//     const answer = window.confirm(translate('common.unsaved_changes'));
//     if (!answer) return false;
//   };
//   return true;
// });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`margin-bottom: ${marginBottom.value}`)
  }, [
    (_unref(fields).includes('title'))
      ? (_openBlock(), _createBlock(BaseSelect, {
          key: 0,
          label: _unref(translate)('client_form.title'),
          placeholder: _unref(translate)('client_form.choose_one'),
          options: titleOptions,
          modelValue: client.title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((client.title) = $event)),
          required: _unref(required).includes('title'),
          border: "",
          "input-width": "200px"
        }, null, 8, ["label", "placeholder", "options", "modelValue", "required"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_unref(fields).includes('name'))
        ? (_openBlock(), _createBlock(BaseInput, {
            key: 0,
            label: _unref(translate)('client_form.name'),
            modelValue: client.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((client.firstName) = $event)),
            required: _unref(required).includes('name'),
            border: "",
            labelWidth: "auto",
            "input-width": __props.inputWidth
          }, null, 8, ["label", "modelValue", "required", "input-width"]))
        : _createCommentVNode("", true),
      (_unref(fields).includes('firstName'))
        ? (_openBlock(), _createBlock(BaseInput, {
            key: 1,
            label: _unref(translate)('client_form.first_name'),
            modelValue: client.firstName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((client.firstName) = $event)),
            required: _unref(required).includes('firstName'),
            border: "",
            labelWidth: "auto",
            "input-width": __props.inputWidth
          }, null, 8, ["label", "modelValue", "required", "input-width"]))
        : _createCommentVNode("", true),
      (_unref(fields).includes('lastName'))
        ? (_openBlock(), _createBlock(BaseInput, {
            key: 2,
            label: _unref(translate)('client_form.surname'),
            modelValue: client.lastName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((client.lastName) = $event)),
            required: _unref(required).includes('lastName'),
            border: "",
            "input-width": __props.inputWidth
          }, null, 8, ["label", "modelValue", "required", "input-width"]))
        : _createCommentVNode("", true)
    ]),
    (_unref(fields).includes('preferredName'))
      ? (_openBlock(), _createBlock(BaseInput, {
          key: 1,
          label: _unref(translate)('client_form.preferred_name'),
          modelValue: client.preferredName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((client.preferredName) = $event)),
          required: _unref(required).includes('preferredName'),
          border: "",
          "input-width": __props.inputWidth
        }, null, 8, ["label", "modelValue", "required", "input-width"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('enquirerPhoneNumber'))
      ? (_openBlock(), _createBlock(BaseInput, {
          key: 2,
          label: _unref(translate)('client_form.enquirer_phone_number'),
          modelValue: client.enquirerPhoneNumber,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((client.enquirerPhoneNumber) = $event)),
          required: _unref(required).includes('enquirerPhoneNumber'),
          border: "",
          "input-width": __props.inputWidth
        }, null, 8, ["label", "modelValue", "required", "input-width"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('relationship'))
      ? (_openBlock(), _createBlock(BaseSelect, {
          key: 3,
          label: _unref(translate)('client_form.relationship'),
          placeholder: _unref(translate)('client_form.choose_one'),
          options: relationshipOptions,
          required: _unref(required).includes('relationship'),
          border: "",
          "input-width": __props.inputWidth
        }, null, 8, ["label", "placeholder", "options", "required", "input-width"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('birthDate'))
      ? (_openBlock(), _createBlock(NotKnownDatePicker, {
          key: 4,
          label: _unref(translate)('client_form.date_of_birth'),
          modelValue: client.birthDate,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((client.birthDate) = $event)),
          required: _unref(required).includes('birthDate'),
          maxDate: new Date()
        }, null, 8, ["label", "modelValue", "required", "maxDate"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('sex'))
      ? (_openBlock(), _createBlock(BaseSelect, {
          key: 5,
          label: _unref(translate)('client_form.sex'),
          placeholder: _unref(translate)('client_form.choose_one'),
          options: sexOptions,
          modelValue: client.sex,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((client.sex) = $event)),
          required: _unref(required).includes('sex'),
          border: "",
          "input-width": "200px"
        }, null, 8, ["label", "placeholder", "options", "modelValue", "required"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('clientType'))
      ? (_openBlock(), _createBlock(BaseSelect, {
          key: 6,
          class: "wide-select sp-client-type",
          label: _unref(translate)('client_form.care_type'),
          afterLabel: _unref(fields).includes('clientTypeChangedLater') ? _unref(translate)('client_form.changed_later') : '',
          placeholder: _unref(translate)('client_form.choose_one'),
          searchable: "",
          sort: "",
          options: clientTypeOptions,
          modelValue: client.clientType,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((client.clientType) = $event)),
          required: _unref(required).includes('clientType'),
          border: "",
          ellipsis: 71
        }, null, 8, ["label", "afterLabel", "placeholder", "options", "modelValue", "required"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('template'))
      ? (_openBlock(), _createBlock(BaseSelect, {
          key: 7,
          label: _unref(translate)('client_form.template'),
          class: "wide-select",
          placeholder: _unref(translate)('client_form.choose_one'),
          searchable: "",
          sort: "",
          options: templateOptions,
          ellipsis: 71,
          modelValue: client.careplanReplicateClientId,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((client.careplanReplicateClientId) = $event)),
          required: _unref(required).includes('template'),
          border: ""
        }, null, 8, ["label", "placeholder", "options", "modelValue", "required"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('observations'))
      ? (_openBlock(), _createBlock(BaseInput, {
          key: 8,
          textarea: "",
          label: _unref(translate)('client_form.notes'),
          modelValue: client.observations,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((client.observations) = $event)),
          border: "",
          "input-width": "560px"
        }, null, 8, ["label", "modelValue"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('templateName'))
      ? (_openBlock(), _createBlock(BaseInput, {
          key: 9,
          label: _unref(translate)('client_form.template_name'),
          required: _unref(required).includes('templateName'),
          modelValue: client.firstName,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((client.firstName) = $event)),
          border: "",
          "input-width": "560px"
        }, null, 8, ["label", "required", "modelValue"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('serviceName'))
      ? (_openBlock(), _createBlock(BaseInput, {
          key: 10,
          label: _unref(translate)('client_form.service_name'),
          required: _unref(required).includes('serviceName'),
          modelValue: client.firstName,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((client.firstName) = $event)),
          border: "",
          "input-width": "560px"
        }, null, 8, ["label", "required", "modelValue"]))
      : _createCommentVNode("", true),
    (_unref(fields).includes('startOn'))
      ? (_openBlock(), _createBlock(NotKnownDatePicker, {
          key: 11,
          label: _unref(translate)('client_form.admission_date'),
          modelValue: client.startOn,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((client.startOn) = $event)),
          required: _unref(required).includes('startOn'),
          minDate: nowAddYears(-100),
          maxDate: nowAddYears(2)
        }, null, 8, ["label", "modelValue", "required", "minDate", "maxDate"]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})