import { Alarm } from '@/timeline/types/Alarm';
import apiClient from '@/_shared/services/apiClient';
import CollectionStore from './collectionStore';

export type AlarmQueryResponse = {
  alarms: Alarm[];
}

export type AlarmNotificationCountResponse = {
  alarm: number;
  message: number;
}

class AlarmStore extends CollectionStore<Alarm, AlarmQueryResponse> {
  getQuery(query: object, params: object | undefined = {}): Promise<AlarmQueryResponse> {
    const queryUrl = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/alarms/query`;
    return apiClient.post<AlarmQueryResponse>(queryUrl, { query }, { params });
  }

  extractData(data: AlarmQueryResponse): Alarm[] {
    return data.alarms;
  }

  fetch(ids: number[]): Promise<AlarmQueryResponse> {
    return apiClient.post('/api/v2/interactions/by_ids', { ids });
  }

  fetchNotificationsCount() : Promise<AlarmNotificationCountResponse> {
    return apiClient.get<AlarmNotificationCountResponse>('/api/v2/notifications/count', { params: { organisation_unit_id: window.currentOrganisationUnit.id } })
      .then((response) => response);
  }
}

export const alarmStore: AlarmStore = new AlarmStore();
