import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "v-single-panel-header v-single-panel-header__text" }
const _hoisted_3 = { class: "v-single-panel-body" }

import { onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import useIntegrationStore from '@/_shared/store/integrations';
import { IconUrls } from '@/_shared/types/IconUrls';
import fetchCategoryIcons from '@/_shared/services/categoryIconsApi';
import IntegrationCard from './components/IntegrationCard.vue';
import {
  fetchOrgUnitsForOrganisation,
} from './services/integrationsApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationsMain',
  setup(__props) {

const { translate } = use.helpers();
const integrationStore = useIntegrationStore();
const { initialSetup } = integrationStore;
const { integrations } = storeToRefs(integrationStore);
const { featureToggles, currentOrgId } = storeToRefs(useUserStore());
const ouList = ref<OrganisationUnit[]>([]);
const categoryIcons = ref<IconUrls>({});

onBeforeMount(async () => {
  categoryIcons.value = await fetchCategoryIcons() as IconUrls;
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
  await initialSetup(featureToggles.value.gpConnect);
  const organisationUnits = await fetchOrgUnitsForOrganisation(currentOrgId.value);
  ouList.value = sortOrgUnits(organisationUnits);
});

const sortOrgUnits = (units: OrganisationUnit[]) => units.sort((u1: OrganisationUnit, u2: OrganisationUnit) => (u1!.name! < u2!.name! ? -1 : 1));

const getCategoryIcon = (categoryCodename: string | undefined | null): string => {
  if (categoryCodename && categoryIcons.value[toCamel(categoryCodename)]) {
    return categoryIcons.value[toCamel(categoryCodename)];
  }
  return 'ncp-chosen_services-filled';
};

const toCamel = (s: string) => s.replace(
  /([-_][a-z])/ig,
  ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''),
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)("integrations.page_title")), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(integrations), (integration) => {
        return (_openBlock(), _createBlock(IntegrationCard, {
          key: integration['id'],
          integration: integration,
          organisationUnits: ouList.value,
          categoryIcon: getCategoryIcon(integration.categoryCodename)
        }, null, 8, ["integration", "organisationUnits", "categoryIcon"]))
      }), 128))
    ])
  ]))
}
}

})