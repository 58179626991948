import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }
const _hoisted_2 = {
  key: 0,
  class: "v-tos-error"
}

import { onBeforeMount, PropType, ref } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import Integration from '../../types/integration';
import { nhsOdsCodeLookup } from '../../services/integrationsApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationNhsTokenModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
},
  emits: ['continueToken', 'closeTokenModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { translate } = use.helpers();
const showTokenValidationError = ref<boolean>(false);
const tokenValid = ref<boolean>(false);

const setTokenValue = () => {
  if (props.token && props.token !== '') {
    return props.token;
  }
  return props.ou.odsCode ? props.ou.odsCode : '';
};
const tokenValue = ref<string>(setTokenValue());

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const validateOdsCodeExists = async (odsCode: string) => {
  const response = await nhsOdsCodeLookup(odsCode);
  return response;
};

const validateOdsCode = async (tokenVal: string) => {
  if (tokenVal.match(/^[A-Z0-9]{5}$/)) {
    const response = await validateOdsCodeExists(tokenVal);
    if (response && response === true) {
      showTokenValidationError.value = false;
      tokenValid.value = true;
      return;
    }
  }
  tokenValid.value = false;
  showTokenValidationError.value = true;
};

const emit = __emit;
const confirmTokenIfValid = () => {
  validateOdsCode(tokenValue.value);
  if (tokenValid.value === true) {
    emit('continueToken', tokenValue.value);
    return;
  }
  showTokenValidationError.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    class: "v-integration-nhs-token-alert",
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: confirmTokenIfValid,
    onCancelAlert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeTokenModal'))),
    confirmText: _unref(translate)('alert.continue')
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(translate)('integrations.integration_token')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_text_1`)) + " " + _toDisplayString(__props.ou.name) + " " + _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_text_2`)), 1)
      ]),
      _createVNode(BaseInput, {
        border: true,
        modelValue: tokenValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tokenValue).value = $event)),
        onChange: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (validateOdsCode(tokenValue.value)), ["stop"]))
      }, null, 8, ["modelValue"]),
      (showTokenValidationError.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.invalid_ods_code`)), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["confirmText"]))
}
}

})