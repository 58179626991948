import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-known-date-picker" }

import { watch, ref } from 'vue';

import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotKnownDatePicker',
  props: {
  required: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  modelValue: {
    type: String,
  },
  minDate: {
    type: [String, Date],
    default: null,
  },
  maxDate: {
    type: [String, Date],
    default: null,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const notKnown = ref(false);
const date = ref(props.modelValue);
function emitValue() {
  emit('update:modelValue', notKnown.value ? 'not_known' : date.value);
}
watch(date, () => {
  if (date.value) notKnown.value = false;
  emitValue();
});
watch(notKnown, () => {
  if (notKnown.value) date.value = '';
  emitValue();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseDatePickerInput, {
      label: __props.label,
      modelValue: date.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
      required: __props.required,
      minDate: __props.minDate,
      maxDate: __props.maxDate
    }, null, 8, ["label", "modelValue", "required", "minDate", "maxDate"]),
    _createVNode(BaseCheckbox, {
      label: _unref(translate)('client_form.not_known'),
      checked: notKnown.value,
      "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((notKnown).value = $event))
    }, null, 8, ["label", "checked"])
  ]))
}
}

})