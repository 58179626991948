import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-interaction-state-buttons-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "v-interaction-state-buttons-wrapper"
}
const _hoisted_3 = { class: "v-save-for-later-text" }

import { computed } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';

type interactionCloseState = 'ok' | 'warning' | 'alarm' | 'planned'
// TODO this should be imported from Interaction type but it does not recognise it at all when imported


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionStateButtons',
  props: {
  isCovidTracker: {
    type: Boolean,
    default: false,
  },
  isFamilyPortal: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
},
  emits: ['saveInteraction'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

const {
  isViewOnly,
  isClosed,
  hasManualAlarm,
  cannotBeClosed,
  hasSavedSignatureParam,
  isClosedWithNoWarning,
  hasAlarm,
  hasWarning,
  currentInteraction,
} = storeToRefs(useCurrentInteractionStore());

const saveInteraction = (closeState: interactionCloseState, disabled: boolean) => {
  if (disabled) {
    // TODO - do we show any error here?
  } else {
    emit('saveInteraction', closeState);
  }
};

const saveForLater = () => {
  emit('saveInteraction', currentInteraction.value.state);
};

const stateButtonsVisible = computed(() => isClosed.value
  || props.isCovidTracker
  || props.isFamilyPortal
  || !props.isEditing);

const warningButtonsVisible = computed(() => !props.isCovidTracker);

const okButtonDisabled = computed(() => isViewOnly.value
  || hasManualAlarm.value
  || cannotBeClosedAgain.value
  || closeButtonIsDisabled.value);

const cannotBeClosedAgain = computed(() => (isViewOnly.value && !isClosed)
  || cannotBeClosed.value);

const closeButtonIsDisabled = computed(() => hasManualAlarm.value
  || hasSavedSignatureParam.value
  || props.isFamilyPortal
  || isViewOnly.value);

const closeButtonClass = computed(() => {
  let buttonClasses = '';
  if (cannotBeClosedAgain.value && !isClosedWithNoWarning.value) {
    buttonClasses += 'v-inactive-button ';
  }
  if (closeButtonIsDisabled.value) {
    buttonClasses += 'v-disabled-button ';
  }
  if (isClosedWithNoWarning.value) {
    buttonClasses += 'v-closed__ok ';
  }
  return buttonClasses;
});

const warningButtonClass = computed(() => {
  let buttonClasses = '';
  if (hasWarning.value) {
    buttonClasses += 'v-closed__warning ';
  }
  if (isViewOnly.value && !hasWarning.value) {
    buttonClasses = 'v-inactive-button ';
  }
  if (isViewOnly.value || props.isFamilyPortal) {
    buttonClasses += 'v-disabled-button ';
  }
  return buttonClasses;
});

const alarmButtonClass = computed(() => {
  let buttonClasses = '';
  if (hasAlarm.value) {
    buttonClasses += 'v-closed__alarm ';
  }
  if (isViewOnly.value && !hasAlarm.value) {
    buttonClasses = 'v-inactive-button ';
  }
  if (isViewOnly.value || props.isFamilyPortal) {
    buttonClasses += 'v-disabled-button ';
  }
  return buttonClasses;
});

return (_ctx: any,_cache: any) => {
  return (stateButtonsVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (warningButtonsVisible.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "warning-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (saveInteraction('warning', _unref(isViewOnly)))),
              class: _normalizeClass(["v-interaction-state-button__warning sp-state-button-warning", warningButtonClass.value])
            }, [
              _createVNode(BaseIcon, {
                name: "bell",
                size: "lg",
                noHoverBackground: "",
                compact: "",
                disabled: _unref(isViewOnly),
                "foreground-hover-color": "black"
              }, null, 8, ["disabled"])
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "close-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (saveInteraction('ok', okButtonDisabled.value))),
          class: _normalizeClass(["v-interaction-state-button__close sp-state-button-ok", closeButtonClass.value])
        }, [
          _createVNode(BaseIcon, {
            name: "check",
            size: "2x",
            noHoverBackground: "",
            compact: "",
            disabled: okButtonDisabled.value,
            position: "relative",
            "foreground-hover-color": "black"
          }, null, 8, ["disabled"])
        ], 2),
        (warningButtonsVisible.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              id: "alarm-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (saveInteraction('alarm', _unref(isViewOnly)))),
              class: _normalizeClass(["v-interaction-state-button__alarm sp-state-button-alarm", alarmButtonClass.value])
            }, [
              _createVNode(BaseIcon, {
                name: "bell",
                size: "lg",
                noHoverBackground: "",
                foregroundColor: "white",
                compact: "",
                disabled: _unref(isViewOnly)
              }, null, 8, ["disabled"])
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          id: "v-save-for-later-button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (saveForLater())),
          class: "v-interaction-state-button__save-for-later sp-save-for-later"
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('common.save_for_later')), 1)
        ])
      ]))
}
}

})