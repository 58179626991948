import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { getInteractionsPastVersions } from '@/timeline/services/interactionsApi';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { compareDesc } from 'date-fns';
import { onBeforeMount, ref } from 'vue';
import interactionsPastVersionsTable from '@/timeline/services/interactionsPastVersionsTable';
import { InteractionLogTableData } from '@/timeline/types/InteractionLogData';
import IInteraction from '@/timeline/types/IInteraction';
import PastVersionsNoData
  from '@/timeline/components/InteractionPastVersions/PastVersionsNoData.vue';
import {
  handleHideEmptyParameters,
  handleNourishInstanceParams,
} from '@/timeline/helper/parametersHelper';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import PastVersionsTable from './PastVersionsTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionPastVersions',
  props: {
  iconUrl: {
    type: String,
    required: true,
  },
  interactionId: {
    type: Number,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
  needsStartAt: {
    type: Boolean,
    default: false,
  },
  hideEmptyParameters: {
    type: Boolean,
    default: false,
  },
},
  emits: ['closePastVersions'],
  setup(__props) {

const props = __props;



onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('timeline'); // TODO WHEN PURE VUE remove;
  const interactions = await fetchInteractions();
  interactionsPastVersionsTableData.value = await interactionsPastVersionsTable(interactions, props.clientId, props.needsStartAt);
  loading.value = false;
});

const loading = ref(true);

const interactionsPastVersionsTableData = ref<InteractionLogTableData | null>(null);
const fetchInteractions = async () => {
  let fetchedInteractions = await getInteractionsPastVersions(props.clientId, props.interactionId);
  if (props.hideEmptyParameters) { fetchedInteractions = handleHideEmptyParameters(fetchedInteractions); }
  fetchedInteractions = handleNourishInstanceParams(fetchedInteractions);
  return fetchedInteractions.sort((a: IInteraction, b: IInteraction) => compareDesc(a.savedAt as Date, b.savedAt as Date));
};


return (_ctx: any,_cache: any) => {
  return (!loading.value)
    ? (_openBlock(), _createBlock(BaseModal, {
        key: 0,
        title: 'Past versions of this record',
        footer: false,
        iconUrl: __props.iconUrl,
        fullWidth: "",
        onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closePastVersions')))
      }, {
        content: _withCtx(() => [
          (!interactionsPastVersionsTableData.value || !interactionsPastVersionsTableData.value.entries.length)
            ? (_openBlock(), _createBlock(PastVersionsNoData, {
                key: 0,
                messageType: "table"
              }))
            : _createCommentVNode("", true),
          (interactionsPastVersionsTableData.value)
            ? (_openBlock(), _createBlock(PastVersionsTable, {
                key: 1,
                class: "v-past-versions-table",
                data: interactionsPastVersionsTableData.value
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["iconUrl"]))
    : _createCommentVNode("", true)
}
}

})