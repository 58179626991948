import apiClient from '@/_shared/services/apiClient';
import { CarePlanNeedVersions } from '@/_shared/types/carePlan';

export const getNeedsVersions = async (clientId: number) => {
  try {
    const response = await apiClient.get(`/api/v2/clients/${clientId}/careplan/need_versions`);
    return response as CarePlanNeedVersions;
  } catch (error) {
    return error;
  }
};

export const generateCarePlanReport = async (clientId: number) => {
  try {
    const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/reports`;
    const params = {
      organisationUnitId: window.currentOrganisationUnit.id,
      format: 'pdf',
      reportClass: 'ClientCareplanReport',
      params: {
        clientIds: [clientId],
      },
    };
    return await apiClient.post(url, { report: params, wait_until_ready: true });
  } catch (error) {
    return error;
  }
};

export default getNeedsVersions;
