import CollectionStore from '@/_shared/store/collectionStore';
import GenericFile from '@/generic_file/types/GenericFile';
import apiClient from '@/_shared/services/apiClient';
import { ApiRequestConfig } from '@/_shared/services/apiClientBase';
import { CoercedChoice } from '@/timeline/types/Parameter';

interface ClientDataPointsFromAPI extends Datapoints {
  [key: string]: unknown
  clientId : number;

}

interface ClientDataPoints extends Datapoints {
  id: number;
}

export interface Datapoints {

  dataPoints?: Record<string, Datapoint>;

  items?: string[];
}

export type DatapointsApiResponse = ClientDataPointsFromAPI[];
// If generic file type changes, we may consider typing here
export class Datapoint {
  value?: DataPointValue;

  changedAt?: string;

  finishAt?: string;

  interactionId?: string;
}
export type DataPointValue = string[]|string|GenericFile|CoercedChoice[]| CoercedChoice;

class DataPointsStore extends CollectionStore<ClientDataPoints, DatapointsApiResponse> {
  fetch(ids: number[]): Promise<DatapointsApiResponse> {
    const data = { ids };
    const config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>> = {};
    apiClient.addHeader(config, 'Organisation-Unit-id', `${window.currentOrganisationUnit.id}`);
    return apiClient.post<DatapointsApiResponse>('/api/v2/data_points', data, config);
  }

  protected extractData(resp: DatapointsApiResponse): ClientDataPoints[] {
    return resp.map((datapoint) => {
      const { clientId, ...dp } = datapoint;
      return { id: clientId, ...dp };
    });
  }

  clientDataPoints = (id: number) => this.byId(id).dataPoints;
}

const timeout = 10 * 60 * 1000; // 10 minutes

export const dataPointsStore: DataPointsStore = new DataPointsStore(timeout);
