type VtabBinding = {
  value?: boolean;
};

const vTab = {
  mounted(el: Element, binding: VtabBinding) {
    init(el, binding.value);
  },
  updated(el: Element, binding: VtabBinding) {
    init(el, binding.value);
  },
  unmounted(el: Element) {
    el.removeEventListener('keydown', handleEnterKey);
  },
};

const handleEnterKey = (event: Event) => {
  if ((<KeyboardEvent>event).key === 'Enter' || (<KeyboardEvent>event).key === ' ') {
    event.preventDefault();
    (<HTMLElement|undefined>event.target)?.click();
  }
};
const init = (el: Element, value:boolean|undefined) => {
  if (value === undefined || value) {
    el.setAttribute('tabindex', '0');
  }
  el.addEventListener('keydown', handleEnterKey);
};

export default vTab;
