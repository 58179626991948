import use from '@/_shared/compositionApi';
import {
  LocationQuery,
  RouteLocationNormalizedLoaded,
  useRoute,
} from 'vue-router';
import { computed } from 'vue';
import useUserStore from '@/_shared/store/user';
import { TopBarLinks, filterLinksWithRouteNames } from '@/_shared/types/baseTopBarLink';

export default function getDynamicLinks(clientId: number, forFamilyPortal: boolean, route :RouteLocationNormalizedLoaded, forMobileView = false) {
  const routePrefix = forFamilyPortal ? 'portalClient' : 'client';

  const activateTimeLine = computed(() => {
    const currentRouteName = useRoute()?.name;
    return !!(currentRouteName && timelineNestedRoute.includes(currentRouteName.toString()));
  });
  const { translate } = use.helpers();
  const { clientStore } = use.store.clients();
  const clientType = clientStore.clientType(clientId);
  const { userHasPermission, userCan } = useUserStore();
  const defaultLinks = useUserStore().getFeatureToggle('covidTracker') ? [] : getDefaultLinks();
  const state = clientStore.currentOrgUnitState(+clientId);
  // TODO / TECH DEBT the hiddenLinksByState is also in vue/src/clients/components/ClientTopBar.vue
  // we need to maintain it in both places until we turn mobile vue into VUE.  Make sure we update it in both places
  const hiddenLinksByState: { [key: string]: string[]; } = {
    pre_assessment: [`${routePrefix}.careplan`, `${routePrefix}.timeline`],
    not_admitted: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`, `${routePrefix}.timeline`],
    enrolled: [`${routePrefix}.timeline`],
    emergency_admission: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`, `${routePrefix}.assessments`, `${routePrefix}.profile`],
    enquiry: [`${routePrefix}.supportNetwork`, `${routePrefix}.careplan`],
  };

  const dynamicLinks = computed(() => {
    if (!state.value) return [];
    if (defaultLinks.length === 0) return defaultLinks;
    const linksToBeHidden = new Set<string>();
    if (isNonAssessmentType(clientType)) {
      linksToBeHidden.add(`${routePrefix}.assessments`);
    }
    if (!userHasPermission('readCareplan')) {
      linksToBeHidden.add(`${routePrefix}.careplan`);
    }
    if (window.familyPortal) {
      linksToBeHidden.add(`${routePrefix}.assessments`);
      linksToBeHidden.add(`${routePrefix}.alarms`);
      linksToBeHidden.add(`${routePrefix}.supportNetwork`);
    }
    if (!(userCan('any', 'londonCareRecord'))) {
      linksToBeHidden.add(`${routePrefix}.medicalRecords`);
    }
    if (hiddenLinksByState[state.value]) {
      hiddenLinksByState[state.value].forEach(linksToBeHidden.add, linksToBeHidden);
    }
    if (!forMobileView && useUserStore().getFeatureToggle('newInteractionCardsAndTopBar') && !linksToBeHidden.has(`${routePrefix}.timeline`)) {
      linksToBeHidden.add(`${routePrefix}.assessments`);
    }
    return filterLinksWithRouteNames(defaultLinks, Array.from(linksToBeHidden));
  });

  const timelineLinks = computed(() => {
    const linksToBeHidden = new Set<string>();
    const dropdownLinks: TopBarLinks = [
      {
        name: translate('person.timeline'),
        icon: 'nr-icon-watch',
        timelineDropdown: true,
        to: {
          name: `${routePrefix}.timeline`,
          query: { mine: 'false', ...stateQuery() },
        },
      },
      {
        name: translate('body_map.body_map'),
        icon: 'v-icon-body-map',
        timelineDropdown: true,
        to: {
          name: `${routePrefix}.timeline.body_map`,
          query: { ...stateQuery() },
        },
        disabled: isNonBodyMapAndAssessmentType(clientType),
      },
      {
        name: translate('client.events'),
        icon: 'v-icon-events',
        timelineDropdown: true,
        to: {
          name: `${routePrefix}.events`,
          query: { ...stateQuery() },
        },
      },
      {
        name: translate('client.sections.assessments'),
        icon: 'nr-icon-assessments',
        timelineDropdown: true,
        to: {
          name: `${routePrefix}.assessments`,
          query: { ...stateQuery() },
        },
        disabled: isNonBodyMapAndAssessmentType(clientType),
      },
    ];

    if (!useUserStore().getFeatureToggle('eventViewPws')) {
      linksToBeHidden.add(`${routePrefix}.events`);
    }

    if (hiddenLinksByState[state.value]) {
      hiddenLinksByState[state.value].forEach(linksToBeHidden.add, linksToBeHidden);
    }

    return filterLinksWithRouteNames(dropdownLinks, Array.from(linksToBeHidden))
      // Sort links.
      .sort((a, b) => {
        if (a.name === translate('person.timeline') || b.name === translate('person.timeline')) {
          return a.name === translate('person.timeline') ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      // Remove disabled links.
      .filter((l) => !l.disabled);
  });

  function isNonBodyMapAndAssessmentType(type: string) {
    return ['service_management', 'care_template'].includes(type);
  }

  function isNonAssessmentType(type: string) {
    return ['service_management', 'care_template', 'animal'].includes(type);
  }

  function getDefaultLinks() {
    const baseLinks: TopBarLinks = [
      {
        name: translate('person.profile'),
        icon: 'nr-icon-profile',
        to: { name: `${routePrefix}.profile`, query: { ...stateQuery() } },
      },
      {
        name: translate('client.sections.support_network'),
        icon: 'nr-icon-circle-care',
        to: { name: `${routePrefix}.supportNetwork`, query: { Archived: 'false', ...stateQuery() } },
      },
      {
        name: translate('client.sections.assessments'),
        icon: 'nr-icon-assessments',
        to: { name: `${routePrefix}.assessments`, query: { ...stateQuery() } },
        disabled: !useUserStore().getFeatureToggle('assessments'),
        timelineDropdown: useUserStore().getFeatureToggle('newInteractionCardsAndTopBar'),
      },
      {
        name: translate('body_map.body_map'),
        icon: 'v-icon-body-map',
        to: { name: `${routePrefix}.timeline.body_map`, query: { ...stateQuery() } },
        disabled: !(forMobileView && useUserStore().getFeatureToggle('newInteractionCardsAndTopBar')),
      },
      {
        name: translate('client.events'),
        icon: 'v-icon-events',
        to: { name: `${routePrefix}.events`, query: { ...stateQuery() } },
        disabled: !(forMobileView && useUserStore().getFeatureToggle('newInteractionCardsAndTopBar') && useUserStore().getFeatureToggle('eventViewPws')),
      },
      {
        name: translate('person.timeline'),
        icon: 'nr-icon-watch',
        to: { name: `${routePrefix}.timeline`, query: { mine: 'false', ...stateQuery() } },
        activeOnChildren: activateTimeLine,
        timelineDropdown: useUserStore().getFeatureToggle('newInteractionCardsAndTopBar'),
      },
      {
        name: translate('client.care_plan'),
        icon: 'nr-icon-care-plan',
        to: { name: forFamilyPortal ? `${routePrefix}.careplanReport` : `${routePrefix}.careplan`, query: { ...stateQuery() } },
      },
      {
        name: translate('client.sections.medical_records'),
        icon: 'nr-icon-new-folder',
        to: { name: `${routePrefix}.medicalRecords`, query: { ...stateQuery() } },
      },
      {
        name: translate('client.alarm', 2),
        icon: 'nr-icon-bell',
        to: { name: `${routePrefix}.alarms`, query: { ...stateQuery() } },
      },
      {
        name: translate('navigation.other.dashboard'),
        icon: 'nr-icon-profile',
        to: { name: `${routePrefix}.dashboard`, query: { ...stateQuery() } },
        disabled: !(forMobileView && !forFamilyPortal),
      },
    ];

    if (!(useUserStore().getFeatureToggle('londonCareRecord'))) {
      const medicalIndex = baseLinks.findIndex((link) => link.to.name === 'client.medicalRecords');
      if (medicalIndex >= 0) {
        baseLinks.splice(medicalIndex, 1);
      }
    }

    return baseLinks.filter(({ disabled }) => !disabled);
  }

  function stateQuery(): LocationQuery {
    if (route.query.state) return { state: route.query.state };
    return {};
  }
  const timelineNestedRoute = ['client.timeline.interaction_new',
    'client.timeline.interaction',
    'client.timeline.needinteraction',
    'client.timeline.service_selector',
    'client.timeline.interaction_new.documents',
    'client.timeline.interaction.documents',
    'client.timeline.interaction.cancel',
    'client.timeline.interaction.snooze',
  ];

  return {
    clientStore,
    defaultLinks,
    dynamicLinks,
    timelineLinks,
    route,
  };
}
