import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { storeToRefs } from 'pinia';
import { onBeforeMount, PropType, ref } from 'vue';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import useIntegrationStore from '@/_shared/store/integrations';
import { updateOrganisationUnitWithOdsCode, nhsNdsaCheck, lcrOdsCodeLookup } from '../../services/integrationsApi';
import IntegrationTosModal from './IntegrationTosModal.vue';
import Integration from '../../types/integration';
import IntegrationTokenModal from './IntegrationTokenModal.vue';
import CustomerIntegration from '../../types/customerIntegration';
import IntegrationNhsTosModal from './IntegrationNhsTosModal.vue';
import IntegrationNhsTokenModal from './IntegrationNhsTokenModal.vue';
import IntegrationNhsConfirmationModal from './IntegrationNhsConfirmationModal.vue';
import IntegrationNhsNdsaFailModal from './IntegrationNhsNdsaFailModal.vue';
import LondonCareOdsCheckFailModal from './LondonCareOdsCheckFailModal.vue';

type emits = 'closeEnableModal' | 'toggleOnExtension' | 'toggleOnNhsExtension';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationEnableModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
  tokenModalOnly: {
    type: Boolean,
    required: true,
  },
  customerIntegration: {
    type: Object as PropType<CustomerIntegration>,
    required: false,
  },
},
  emits: ['toggleOnExtension', 'toggleOnNhsExtension', 'closeEnableModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { translate } = use.helpers();
const showTosModal = ref<boolean>(props.tokenModalOnly !== true);
const showTokenModal = ref<boolean>(props.tokenModalOnly === true);
const showConfirmTokenModal = ref<boolean>(false);
const integrationsStore = useIntegrationStore();
const nhsIntegration = ref<boolean>(props.integration.integrationType === 'ods_code');
const isLondonCare = ref<boolean>(props.integration.integrationCodename === 'london_care_records');
const isGpConnect = ref<boolean>(props.integration.integrationCodename === 'gp_connect');
const showLondonCareOdsCheckFail = ref<boolean>(false);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const getToken = () => {
  if (
    props.tokenModalOnly === true
    && props.customerIntegration
    && props.customerIntegration.integrationToken
  ) {
    return props.customerIntegration.integrationToken;
  }
  return '';
};

const token = ref<string>(getToken());

const continueFromTos = () => {
  showTosModal.value = false;
  showTokenModal.value = true;
};

const showNdsaError = ref<boolean>(false);

const continueToken = async (tokenValue: string) => {
  showTokenModal.value = false;
  if (isLondonCare.value) {
    const response = await callLondonCareOdsCheck(tokenValue);
    if (response === false) {
      showLondonCareOdsCheckFail.value = true;
      return;
    }
  }
  if (isGpConnect.value) {
    const ndsaCheckResult = await callNdsaCheck(tokenValue);
    if (ndsaCheckResult === false) {
      showNdsaError.value = true;
      return;
    }
  }
  showConfirmTokenModal.value = true;
  token.value = tokenValue;
};

const { currentUser } = storeToRefs(useUserStore());

const emit = __emit;
const confirmToken = async (
  tokenValue: string,
  emitString: emits = 'toggleOnExtension',
  emitValue: string | null = null,
) => {
  if (
    props.integration.integrationCodename !== undefined
    && props.ou.id
    && currentUser.value.name
  ) {
    const customerIntegration = integrationsStore.getCustomerIntegrationForOu(
      props.integration.integrationCodename,
      props.ou.id,
    );
    if (customerIntegration === undefined) {
      await integrationsStore.addNewCustomerIntegration(props.integration, props.ou.id, tokenValue);
    }
    if (customerIntegration !== undefined) {
      await integrationsStore.updateCustomerIntegration(customerIntegration!, tokenValue);
    }
    toast(translate('ajax.notices.save_success'), ToastType.Success);
    emit(emitString, emitValue);
    token.value = tokenValue;
  }
};

const confirmNhsToken = async (tokenValue: string) => {
  showConfirmTokenModal.value = false;
  confirmToken(tokenValue, 'toggleOnNhsExtension', tokenValue);
  await updateOrganisationUnitWithOdsCode(tokenValue, props.ou.id);
};

const callNdsaCheck = async (tokenValue: string) => {
  const response = await nhsNdsaCheck(tokenValue);
  return response;
};

const callLondonCareOdsCheck = async (tokenValue: string) => {
  const response = await lcrOdsCodeLookup(tokenValue);
  return response.success;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showTosModal.value && !nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationTosModal, {
          key: 0,
          integration: __props.integration,
          onContinueTosEnableModal: continueFromTos,
          onCancelEnableModal: _cache[0] || (_cache[0] = ($event: any) => {
      showTosModal.value = false;
      _ctx.$emit('closeEnableModal');
    })
        }, null, 8, ["integration"]))
      : _createCommentVNode("", true),
    (showTokenModal.value && !nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationTokenModal, {
          key: 1,
          integration: __props.integration,
          ou: __props.ou,
          onCloseTokenModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeEnableModal'))),
          onConfirmToken: confirmToken,
          token: token.value
        }, null, 8, ["integration", "ou", "token"]))
      : _createCommentVNode("", true),
    (showTosModal.value && nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationNhsTosModal, {
          key: 2,
          integration: __props.integration,
          onContinueTosEnableModal: continueFromTos,
          onCancelEnableModal: _cache[2] || (_cache[2] = ($event: any) => {
      showTosModal.value = false;
      _ctx.$emit('closeEnableModal');
    })
        }, null, 8, ["integration"]))
      : _createCommentVNode("", true),
    (showTokenModal.value && nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationNhsTokenModal, {
          key: 3,
          integration: __props.integration,
          ou: __props.ou,
          onCloseTokenModal: _cache[3] || (_cache[3] = ($event: any) => {
      showTokenModal.value = false;
      _ctx.$emit('closeEnableModal');
    }),
          onContinueToken: continueToken,
          token: token.value
        }, null, 8, ["integration", "ou", "token"]))
      : _createCommentVNode("", true),
    (showConfirmTokenModal.value && nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationNhsConfirmationModal, {
          key: 4,
          integration: __props.integration,
          ou: __props.ou,
          onCancelEnableModal: _cache[4] || (_cache[4] = ($event: any) => {
      showConfirmTokenModal.value = false;
      _ctx.$emit('closeEnableModal');
    }),
          onConfirmToken: confirmNhsToken,
          token: token.value
        }, null, 8, ["integration", "ou", "token"]))
      : _createCommentVNode("", true),
    (showNdsaError.value && nhsIntegration.value)
      ? (_openBlock(), _createBlock(IntegrationNhsNdsaFailModal, {
          key: 5,
          onCancelEnableModal: _cache[5] || (_cache[5] = ($event: any) => {
      showNdsaError.value = false;
      _ctx.$emit('closeEnableModal');
    })
        }))
      : _createCommentVNode("", true),
    (showLondonCareOdsCheckFail.value)
      ? (_openBlock(), _createBlock(LondonCareOdsCheckFailModal, {
          key: 6,
          onCancelEnableModal: _cache[6] || (_cache[6] = ($event: any) => {
      showLondonCareOdsCheckFail.value = false;
      _ctx.$emit('closeEnableModal');
    })
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})