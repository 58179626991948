
import { defineComponent, computed } from 'vue';
import { watchDebounced } from '@vueuse/core';
import PupilSizeParameter from '@/timeline/interaction_parameters/PupilSizeParameter.vue';
import InlineMultipleChoiceParameter
  from '@/timeline/interaction_parameters/InlineMultipleChoiceParameter.vue';
import MultipleChoiceDropdownParameter
  from '@/timeline/interaction_parameters/MultipleChoiceDropdownParameter.vue';
import MultipleChoiceArrayParameter
  from '@/timeline/interaction_parameters/MultipleChoiceArrayParameter.vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import PrefillToggle from '@/timeline/components/PrefillToggle.vue';
import use from '@/_shared/compositionApi';
import NourishInstanceParameter
  from '@/timeline/interaction_parameters/NourishInstanceParameter.vue';
import EventPWSParameter from '@/events/components/EventPWSParameter.vue';
import { Parameter } from '../types/Parameter';
import CheckboxParameter from './CheckboxParameter.vue';
import CombinedDataSetParameter from './CombinedDataSetParameter.vue';
import DateParameter from './DateParameter.vue';
import DateTimeParameter from './DateTimeParameter.vue';
import DurationParameter from './DurationParameter.vue';
import FileParameter from './FileParameter.vue';
import InteractionSection from '../components/InteractionSection.vue';
import MedicationParameter from './MedicationParameter.vue';
import MultiSelectDataSetParameter from './MultiSelectDataSetParameter.vue';
import NumberParameter from './NumberParameter.vue';
import PersonPickerParameter from './PersonPickerParameter.vue';
import PictureParameter from './PictureParameter.vue';
import ReadOnlyParameter from './ReadOnlyParameter.vue';
import SingleSelectDataSetParameter from './SingleSelectDataSetParameter.vue';
import TextParameter from './TextParameter.vue';
import { isParameterFilled, isOldVersionSignatureParameter } from '../helper/parametersHelper';
import SignatureParameter from './SignatureParameter/SignatureParameter.vue';
import OldSignatureParameter from './SignatureParameter/OldSignatureParameter.vue';

const TYPE_TO_COMPONENT_MAPPING: Record<string, string> = {
  checkbox: 'CheckboxParameter',
  combined_multi_search: 'CombinedDataSetParameter',
  date: 'DateParameter',
  datetime: 'DateTimeParameter',
  duration: 'DurationParameter',
  file: 'FileParameter',
  medication: 'MedicationParameter',
  multi: 'MultiSelectDataSetParameter',
  person_picker: 'PersonPickerParameter',
  picture: 'PictureParameter',
  read_only_text: 'ReadOnlyParameter',
  single_selector_search: 'SingleSelectDataSetParameter',
};

export default defineComponent({
  components: {
    CheckboxParameter,
    CombinedDataSetParameter,
    DateParameter,
    DateTimeParameter,
    DurationParameter,
    FileParameter,
    InlineMultipleChoiceParameter,
    InteractionSection,
    MedicationParameter,
    MultiSelectDataSetParameter,
    MultipleChoiceArrayParameter,
    MultipleChoiceDropdownParameter,
    NumberParameter,
    PersonPickerParameter,
    PictureParameter,
    PrefillToggle,
    PupilSizeParameter,
    ReadOnlyParameter,
    SingleSelectDataSetParameter,
    TextParameter,
    NourishInstanceParameter,
    EventPWSParameter,
    SignatureParameter,
    OldSignatureParameter,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
    inSaveForLaterMode: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { translate } = use.helpers();
    const { currentInteraction, shouldHideEmptyParameters } = storeToRefs(useCurrentInteractionStore());

    const { calculateComputedParameters } = useCurrentInteractionStore();

    function getParameterType(parameter: Parameter) {
      if (parameter.valueType === 'number' || parameter.valueType === 'string') {
        if (!parameter.coercedChoices || parameter.coercedChoices.length === 0) {
          if (parameter.valueType === 'number') {
            return 'NumberParameter';
          }
          return 'TextParameter';
        }
        if (parameter.codename && (parameter.codename.includes('pupil-size') || parameter.codename.includes('pupil_size'))) {
          return 'PupilSizeParameter';
        }
        if (parameter.isArray) {
          return 'MultipleChoiceArrayParameter';
        }
        if (parameter.coercedChoices.length < 4) {
          return 'InlineMultipleChoiceParameter';
        }
        if (parameter.coercedChoices.length > 3 || parameter.valueType === 'number') {
          return 'MultipleChoiceDropdownParameter';
        }
      } else if (parameter.valueType === 'signature') {
        return !isOldVersionSignatureParameter(parameter) ? 'SignatureParameter' : 'OldSignatureParameter';
      } else if (parameter.valueType === 'nourish_instance') {
        if (parameter.config!.nourishInstanceType === 'skin') {
          return 'NourishInstanceParameter';
        }
        if (parameter.config!.nourishInstanceType === 'event_pws') {
          return 'EventPWSParameter';
        }
      } else {
        return TYPE_TO_COMPONENT_MAPPING[parameter.valueType];
      }
      return '';
    }

    const visibleComputedParameters = computed(() => (currentInteraction.value?.computedParameters || [])
      .filter((computedParameter) => computedParameter.id !== 'actions'));

    const hasOnlyActionsParameter = computed(() => currentInteraction.value.computedParameters.length && currentInteraction.value.computedParameters.filter((p) => p.valueType === 'actions').length
      === currentInteraction.value.computedParameters.length);

    const showComputedInfoText = computed(() => currentInteraction.value?.computable && !props.readOnly && !hasOnlyActionsParameter.value);

    // eslint-disable-next-line vue/return-in-computed-property
    const sortedParameters = computed(() => [...currentInteraction.value?.parameters.filter((param) => !param.id.toString().includes('.')) || []]);
    // TODO move it in onBeforeMount
    // fix pb of number value comparision  2.0 != 2 in multi choic question
    // check if added removed value cause a problem

    // TODO -- fix me I am terrible!!
    // function timedUpdate() {
    //   if (interaction.value.computable) {
    //     console.log('timedUpdate');
    //     // props.
    //     interaction.value.recalculateComputedParameters();
    //     // props.interaction.recalculateActions();
    //     computedParameters.value = interaction.value.computedParameters!;
    //     // interaction.value = props.interaction;
    //
    //     setTimeout(timedUpdate, 30000);
    //   }
    // }

    // timedUpdate();
    const isDataPointToSaveForLater = (parameter: Parameter) => ((!!parameter.dataPoint || parameter.config?.combinedMulti) && props.inSaveForLaterMode);
    watchDebounced(
      // per https://vuejs.org/guide/essentials/watchers.html#basic-example
      // this should be a getter but that doesn't seem to work
      currentInteraction.value.parameters,
      calculateComputedParameters,
      { debounce: 500 },
    );

    const shouldShowParameter = (parameter: Parameter) => (shouldHideEmptyParameters.value
      ? !parameter.hidden && isParameterFilled(parameter, currentInteraction.value.closedAt, true, true)
      : !parameter.hidden);

    return {
      currentInteraction,
      getParameterType,
      showComputedInfoText,
      sortedParameters,
      visibleComputedParameters,
      isDataPointToSaveForLater,
      translate,
      shouldHideEmptyParameters,
      shouldShowParameter,
    };
  },
});
