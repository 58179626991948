import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-time-dropdown",
  ref: "dropdown"
}
const _hoisted_2 = { class: "v-dropdown-menu" }
const _hoisted_3 = { class: "v-timepicker-input-container" }
const _hoisted_4 = { class: "v-timepicker-label" }
const _hoisted_5 = {
  key: 0,
  class: "v-interaction-datepicker"
}
const _hoisted_6 = { class: "v-interaction-datepicker" }

import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { getHours, getMinutes } from 'date-fns';
import vClickOutside from '@/_shared/directives/ClickOutside';
import { BaseModal, BaseDatePickerInput } from '@/_shared/components/';
import use from '@/_shared/compositionApi';
import useCurrentInteractionStore from '../store/currentInteractionStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionTimePicker',
  props: {
  customDate: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const {
  currentInteraction, isViewOnly, hasNFC, hasDataPoint, isSticky, isClosed, isNotBulkClose, timelineDate,
} = storeToRefs(useCurrentInteractionStore());

const menuDropped = ref(false);
const modalVisible = ref(false);

const newStartAt = ref<string | null>();
const newFinishAt = ref<string | null>();
const initiateTimes = () => {
  const dateNow = new Date().toISOString();
  if (props.customDate && !isClosed.value) {
    newStartAt.value = currentInteraction.value.plannedStartAt || currentInteraction.value.startAt;
    newFinishAt.value = currentInteraction.value.plannedFinishAt || currentInteraction.value.finishAt;
  } else {
    newStartAt.value = isClosed.value ? currentInteraction.value.startAt : dateNow;
    newFinishAt.value = isClosed.value ? currentInteraction.value.finishAt : dateNow;
  }
  setTimes();
};

watch(
  () => props.customDate,
  (newValue) => {
    if (!newValue) {
      initiateTimes();
    }
  },
  { immediate: true },
);

initiateTimes();
const formatTime = (dtString: string | null | undefined) => {
  if (!dtString) return '';
  const date = new Date(dtString);
  // ensures that a 0 is included for any ranges within 0-9.
  return `${getHours(date)}:${(getMinutes(date) < 10 ? '0' : '') + getMinutes(date)}`;
};

const displayTime = computed(() => {
  if (isSticky.value) return formatTime(new Date().toISOString());
  const displayStartAt = currentInteraction.value.needsStartAt ? `${formatTime(newStartAt.value)} - ` : '';
  const displayFinishAt = formatTime(newFinishAt.value);
  return displayStartAt + displayFinishAt;
});

const menuEnabled = computed(() => {
  const shouldBeDisabled = isViewOnly.value || hasNFC.value || hasDataPoint.value || currentInteraction.value.state === 'sticky';
  return !shouldBeDisabled;
});

const menuVisible = computed(() => menuEnabled.value && menuDropped.value);

const maxDate = computed(() => (!isNotBulkClose.value ? new Date() : undefined));
function setToNow() {
  setStartAt();
  setFinishAt();
  menuDropped.value = false;
}
function setToPlanned() {
  setStartAt(currentInteraction.value.plannedStartAt);
  setFinishAt(currentInteraction.value.plannedFinishAt);
  menuDropped.value = false;
}

function setTimes() {
  if (!newFinishAt.value) return;
  if (currentInteraction.value.needsStartAt && newStartAt.value) { setStartAt(newStartAt.value); }
  setFinishAt(newFinishAt.value);
  menuDropped.value = false;
  modalVisible.value = false;
}

function setStartAt(dt?: string | null) {
  if (!currentInteraction.value.needsStartAt) return;
  dt = dt || new Date().toISOString();
  newStartAt.value = dt;
  currentInteraction.value.startAt = dt;
}

function setFinishAt(dt?: string | null) {
  if (!currentInteraction.value) return;
  dt = dt || new Date().toISOString();
  newFinishAt.value = dt;
  currentInteraction.value.finishAt = dt;
  timelineDate.value = (new Date(dt));
}

function hideDropdowns() {
  menuDropped.value = false;
}

function openModal() {
  // newStartAt.value = currentInteraction.value.startAt;
  // newFinishAt.value = currentInteraction.value.finishAt;
  modalVisible.value = true;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (menuDropped.value = !menuDropped.value))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["v-interaction-time", {disabled: !menuEnabled.value}])
      }, [
        _createTextVNode(_toDisplayString(displayTime.value), 1),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "dropdown-btn" }, null, -1))
      ], 2),
      (menuVisible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("ul", _hoisted_2, [
              _createElementVNode("li", null, [
                _createElementVNode("div", {
                  class: "v-dropdown-option",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (setToNow()))
                }, _toDisplayString(_unref(translate)('timeline.time.now')), 1)
              ]),
              _createElementVNode("li", null, [
                (_unref(currentInteraction).plannedFinishAt)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "v-dropdown-option",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (setToPlanned()))
                    }, _toDisplayString(_unref(translate)('timeline.time.plannedTime')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("div", {
                  class: "v-dropdown-option",
                  onClick: openModal
                }, _toDisplayString(_unref(translate)('timeline.time.setTime')), 1)
              ])
            ])
          ], 512))
        : _createCommentVNode("", true)
    ])), [
      [_unref(vClickOutside), hideDropdowns]
    ]),
    (modalVisible.value)
      ? (_openBlock(), _createBlock(_unref(BaseModal), {
          key: 0,
          modalClass: "v-interaction-time-picker",
          title: _unref(translate)('timeline.time.selectDateTime'),
          icon: "round-icon round-icon-sm temp-icon-size-reduce nr-icon-calendar",
          confirmText: _unref(translate)('timeline.time.setTime'),
          onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (modalVisible.value = false)),
          onConfirm: setTimes,
          confirmDisabled: !_unref(currentInteraction).finishAt
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)('timeline.time.selectDateTime')) + "*", 1),
              (_unref(currentInteraction).needsStartAt)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_unref(BaseDatePickerInput), {
                      class: "v-start-at-datetime-picker",
                      border: "",
                      readOnly: false,
                      inputWidth: "150%",
                      type: "dateTime",
                      is24Hr: true,
                      "max-date": maxDate.value,
                      modelValue: newStartAt.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newStartAt).value = $event))
                    }, null, 8, ["max-date", "modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(BaseDatePickerInput), {
                  class: "v-finish-at-datetime-picker",
                  border: "",
                  readOnly: false,
                  inputWidth: "150%",
                  type: "dateTime",
                  is24Hr: true,
                  "max-date": maxDate.value,
                  modelValue: newFinishAt.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newFinishAt).value = $event))
                }, null, 8, ["max-date", "modelValue"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "confirmText", "confirmDisabled"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})