import { getAllowedContentTypes } from '@/generic_file/services/GenericFileApi';
import use from '../compositionApi';
import { toast, ToastType } from './nourishHelpers';

const { translate } = use.helpers();

function validateFile(file: File) {
  return validateNameLength(file) && validateContentType(file);
}

function validateNameLength(file: File) {
  if (file.name.length > 100) {
    const message = translate('ajax.errors.file_name_too_long');
    toast(message, ToastType.Danger);
    return false;
  }
  return true;
}

function validateContentType(file: File) {
  const allowedContentTypes = getAllowedContentTypes();
  if (allowedContentTypes?.length > 0 && !allowedContentTypes.includes(file.type)) {
    const message = translate('ajax.errors.invalid_content_type');
    toast(message, ToastType.Danger);
    return false;
  }
  return true;
}

export default validateFile;
