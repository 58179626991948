import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-timeline-navigation-dropdown-option" }

import { PropType } from 'vue';
import BaseDropdownOption from '@/_shared/components/navigation/BaseDropdownOption.vue';
import { TopBarLink } from '@/_shared/types/baseTopBarLink';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelineNavigationDropdownOption',
  props: {
  link: {
    type: Object as PropType<TopBarLink>,
    required: true,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseDropdownOption, {
      link: __props.link.name,
      to: __props.link.to,
      icon: __props.link.icon,
      text: __props.link.name,
      "icon-color": "white"
    }, null, 8, ["link", "to", "icon", "text"])
  ]))
}
}

})