import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-navigation-dropdown-menu"
}

import getDynamicLinks from '@/_shared/helpers/clientTabs';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import TimelineNavigationDropdownOption
  from '@/_shared/components/navigation/TimelineNavigationDropdownOption.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelineNavigationDropdown',
  props: {
  personId: {
    type: Number,
    required: true,
  },
  dropdownVisible: {
    type: Boolean,
    required: true,
  },
},
  emits: ['optionClicked'],
  setup(__props) {

const props = __props;


const route = useRoute();
const links = computed(() => {
  const { timelineLinks } = getDynamicLinks(props.personId, window.familyPortal, route, false);
  return timelineLinks.value;
});

return (_ctx: any,_cache: any) => {
  return (__props.dropdownVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link) => {
          return (_openBlock(), _createBlock(TimelineNavigationDropdownOption, {
            key: link.name,
            onClick: ($event: any) => (_ctx.$emit('optionClicked', link)),
            link: link
          }, null, 8, ["onClick", "link"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})