import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-checkbox-parameter-container" }

import { PropType, Ref, toRef } from 'vue';
import BaseToggle from '@/_shared/components/inputs/BaseToggle.vue';
import { Parameter } from '../types/Parameter';
import BaseParameter from './BaseParameter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxParameter',
  props: {
  parameter: {
    type: Object as PropType<Parameter>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

// const checkboxToggle = ref<boolean>(!!props.parameter.coercedValue);
//
function updateValue() {
  checkboxToggle.value = !checkboxToggle.value;
}
const checkboxToggle = toRef(props.parameter, 'coercedValue') as Ref<boolean>;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, { parameter: __props.parameter }, {
      "value-slot": _withCtx(() => [
        _createVNode(BaseToggle, {
          checked: checkboxToggle.value,
          onToggleChange: updateValue,
          direction: "row-reverse",
          disabled: __props.readOnly
        }, null, 8, ["checked", "disabled"])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})