import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

import {
  computed, onBeforeMount, PropType, ref, watch,
} from 'vue';
import subYears from 'date-fns/subYears';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import {
  GpConnectRequest, GpConnectResponse, CalendarEvent, Sections,
} from './types/gp_connect';
import { createRequestParams, updateValues } from './helpers/ApiHelper';
import { fetchGpConnectRecord } from './services/GpConnectApi';
import GpConnectTos from './components/GpConnectTos.vue';
import GpConnectError from './components/GpConnectError.vue';
import GpConnectSuccess from './components/GpConnectSuccess.vue';
import GpConnectLoading from './components/GpConnectLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GpConnectRightPanelMain',
  props: {
  section: {
    type: String as PropType<keyof typeof Sections>,
    default: 'summary',
  },
  clientId: {
    type: [Number, String],
    required: true,
  },
},
  setup(__props) {

const props = __props;

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('medical_record_integrations/gp_connect'); // TODO WHEN PURE VUE remove
});

const { clientStore } = use.store.clients();
const displayLoading = ref<boolean>(true);
const successfulRequest = ref<boolean>(false);
const errorType = ref<string>('');
const tosConfirmed = ref<boolean>(false);
const gpApiSection = computed(() => Sections[props.section].apiSection);

const showDateFilter = computed(() => !Sections[props.section].hideDate);

const clientName = computed(() => clientStore.name(+props.clientId));
const avatarPhoto = computed(() => clientStore.photo(+props.clientId));

const gpConnectRecord = ref<GpConnectResponse>();

const callApi = async (data: CalendarEvent) => {
  const params = createRequestParams(
    data.startDate,
    data.endDate,
    data.filterByDates,
    +props.clientId,
    gpApiSection.value,
  );
  getGpConnectRecord(params);
};

const getGpConnectRecord = async (requestParams: GpConnectRequest) => {
  if (tosConfirmed.value) {
    const gpcResponse = await fetchGpConnectRecord(requestParams);
    updateValues(
      gpcResponse as GpConnectResponse,
      gpConnectRecord,
      displayLoading,
      successfulRequest,
      errorType,
    );
  }
};

const wipeSessionStorage = async () => {
  window.localStorage.removeItem('v-gp-connect-tos-confirmed');
};

// When webpage is refreshed or closed, session storage containing GP connect ToS boolean is wiped
window.addEventListener('beforeunload', wipeSessionStorage);

// initial call with 3 year date window
const currentDate = new Date();
const dateThreeYearsAgo = subYears(currentDate, 3);

const setGpConnectTosConfirmed = async () => {
  const storageValue = window.localStorage.getItem('v-gp-connect-tos-confirmed');
  if (storageValue !== null) {
    tosConfirmed.value = true;
    const params = createRequestParams(
      dateThreeYearsAgo,
      currentDate,
      showDateFilter.value,
      +props.clientId,
      gpApiSection.value,
    );
    getGpConnectRecord(params);
  }
};
setGpConnectTosConfirmed();

watch(props, () => {
  // call api to get record
  const params = createRequestParams(
    dateThreeYearsAgo,
    currentDate,
    showDateFilter.value,
    +props.clientId,
    gpApiSection.value,
  );
  getGpConnectRecord(params);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { photo: avatarPhoto.value }, {
    default: _withCtx(() => [
      (__props.section !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!tosConfirmed.value)
              ? (_openBlock(), _createBlock(GpConnectTos, {
                  key: 0,
                  clientName: clientName.value,
                  onConfirmTos: setGpConnectTosConfirmed
                }, null, 8, ["clientName"]))
              : _createCommentVNode("", true),
            (tosConfirmed.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (displayLoading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(GpConnectLoading)
                      ]))
                    : _createCommentVNode("", true),
                  (!displayLoading.value && successfulRequest.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (gpConnectRecord.value)
                          ? (_openBlock(), _createBlock(GpConnectSuccess, {
                              key: 0,
                              response: gpConnectRecord.value,
                              section: __props.section,
                              showDateFilter: showDateFilter.value,
                              onCalendarEvent: callApi
                            }, null, 8, ["response", "section", "showDateFilter"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (!displayLoading.value && !successfulRequest.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (gpConnectRecord.value)
                          ? (_openBlock(), _createBlock(GpConnectError, {
                              key: 0,
                              response: gpConnectRecord.value,
                              errorType: errorType.value
                            }, null, 8, ["response", "errorType"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["photo"]))
}
}

})