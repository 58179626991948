import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-accordion" }
const _hoisted_2 = { key: 0 }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAccordion',
  props: {
  open: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  accordionStyle: {
    type: String,
    default: 'dark',
  },
},
  setup(__props) {

const toggleOpen = () => { isOpen.value = !isOpen.value; };

const iconName = computed(() => (isOpen.value ? 'chevron-up' : 'chevron-down'));

const props = __props;

const isOpen = ref(props.open);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _withModifiers(toggleOpen, ["stop"]),
      class: _normalizeClass(["v-accordion-title", __props.accordionStyle])
    }, [
      _createTextVNode(_toDisplayString(__props.title) + " ", 1),
      _createVNode(BaseIcon, {
        name: iconName.value,
        noHover: "",
        foregroundColor: "white",
        size: "xs"
      }, null, 8, ["name"])
    ], 2),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})