import apiClient from '@/_shared/services/apiClient';
import { CertificationTableRow } from '../types/certification';

const BASE_URL = 'carers/';
export const fetchCertifications = async (carerId: number, name: string) => {
  const url = `${BASE_URL}${carerId}/${name}`;
  try {
    const resp = await apiClient.get<CertificationTableRow[]>(url);
    return resp;
  } catch (error) {
    return [];
  }
};

export const updateCertification = async (
  carerId: number,
  item: CertificationTableRow,
  name: string,
) => {
  const url = `${BASE_URL}${carerId}/${name}/${item.id}`;
  try {
    await apiClient.patch<CertificationTableRow[], CertificationTableRow>(url, item);
    return true;
  } catch (error) {
    return false;
  }
};

export const createCertification = async (
  carerId: number,
  item: CertificationTableRow,
  name: string,
) => {
  const url = `${BASE_URL}${carerId}/${name}`;
  try {
    const resp:CertificationTableRow[] = await apiClient.post<CertificationTableRow[], CertificationTableRow>(url, item);
    return resp[0].id;
  } catch (error) {
    return null;
  }
};
