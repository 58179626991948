import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "v-base-action-list-entry--v-entry-wrapper" }
const _hoisted_2 = {
  class: "v-list-entry",
  style: {"cursor":"default"}
}
const _hoisted_3 = {
  key: 0,
  class: "v-list-entry-inner v-no-padding"
}
const _hoisted_4 = { class: "v-base-action-list-entry--toggle-wrapper" }
const _hoisted_5 = { class: "v-base-action-list-entry--main-col" }
const _hoisted_6 = { class: "v-base-action-list-entry--primary-text" }
const _hoisted_7 = { class: "v-base-action-list-entry--secondary-text" }
const _hoisted_8 = { class: "v-base-action-list-entry--secondary-text v-secondary-text" }
const _hoisted_9 = { class: "v-base-action-list-entry--right-col v-base-action-list-entry--buttons-wrapper" }
const _hoisted_10 = {
  key: 1,
  class: "v-list-entry-inner v-no-padding"
}

import {
  computed, ref, watch,
} from 'vue';
import { parseReactiveTextToStaticText } from '@/_shared/helpers/useClientTypesHelpers';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseToggle from '@/_shared/components/inputs/BaseToggle.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseActionsListEntry',
  props: {
  advancedEditView: {
    type: Boolean,
  },
  assignDisabled: {
    type: Boolean,
    default: false,
  },
  deleteDisabled: {
    type: Boolean,
    default: false,
  },
  entry: {
    type: Object,
    required: true,
  },
  primaryText: {
    type: String,
  },
  primaryTextAddition: {
    type: String,
  },
  secondaryText: {
    type: String,
  },
  showIcons: {
    type: Object,
    required: false,
  },
  toggleDisabled: {
    type: Boolean,
    default: false,
  },
  closeEditForId: {
    type: Number,
  },
},
  emits: [
  'cancelEditView',
  'cloneEntry',
  'deleteEntry',
  'openAssignModal',
  'openEdit',
  'toggleEntry',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();
const originalName = props.entry ? parseReactiveTextToStaticText(props.entry.name!) : '';
const isEditing = ref(false);
const entry = ref(props.entry);

const cancelEditing = () => {
  emit('cancelEditView', props.entry, originalName);
  closeEditing();
};

const closeEditing = () => {
  isEditing.value = false;
};

const cloneEntry = () => {
  emit('cloneEntry', props.entry);
};

const toggleEditing = () => {
  if (isEditing.value) {
    cancelEditing();
  } else {
    if (!props.advancedEditView) {
      isEditing.value = true;
    }
    emit('openEdit', props.entry);
  }
};

const showIcon = (iconName: string) => props.showIcons && props.showIcons.includes(iconName);

watch(props, () => {
  if (props.entry.id === props.closeEditForId) {
    closeEditing();
  }
});

const entryVisible = computed(() => props.entry.visible);

const emitDelete = (entryToDelete: Record<string, unknown>) => {
  if (!props.deleteDisabled) {
    emit('deleteEntry', entryToDelete);
  }
};

const emitAssign = (entryToAssign: Record<string, unknown>) => {
  if (!props.assignDisabled) {
    emit('openAssignModal', entryToAssign);
  }
};

const toggleTooltipText = computed(() => (props.entry.visible ? translate('client_types.toggle_tooltip.to_off') : translate('client_types.toggle_tooltip.to_on')));

const handleNewEntryView = () => {
  if (!entry.value || !entry.value.id) {
    isEditing.value = true;
  }
};
handleNewEntryView();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!isEditing.value || __props.advancedEditView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(BaseToggle, {
                checked: entryVisible.value,
                blockToggle: true,
                onToggleChange: _cache[0] || (_cache[0] = ($event: any) => (emit('toggleEntry', entry.value))),
                disabled: __props.toggleDisabled,
                tooltipText: toggleTooltipText.value
              }, null, 8, ["checked", "disabled", "tooltipText"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, [
                _createTextVNode(_toDisplayString(__props.primaryText) + " ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(__props.primaryTextAddition), 1)
              ]),
              _createElementVNode("h5", _hoisted_8, _toDisplayString(__props.secondaryText), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              (showIcon('clone'))
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    name: "nr-icon-plus",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (cloneEntry())),
                    size: "2x",
                    compact: ""
                  }))
                : _createCommentVNode("", true),
              _createVNode(BaseTooltip, {
                position: "bottom",
                tooltipText: _unref(translate)('client_types.delete_tooltip')
              }, {
                default: _withCtx(() => [
                  (showIcon('delete'))
                    ? (_openBlock(), _createBlock(BaseIcon, {
                        key: 0,
                        name: "nr-icon-trash",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (emitDelete(entry.value))),
                        size: "2x",
                        compact: "",
                        disabled: __props.deleteDisabled
                      }, null, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["tooltipText"]),
              _createVNode(BaseTooltip, {
                position: "bottom",
                tooltipText: _unref(translate)('client_types.edit_tooltip')
              }, {
                default: _withCtx(() => [
                  (showIcon('edit'))
                    ? (_openBlock(), _createBlock(BaseIcon, {
                        key: 0,
                        name: "nr-icon-pencil",
                        onClick: toggleEditing,
                        size: "2x",
                        compact: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["tooltipText"]),
              _createVNode(BaseTooltip, {
                position: "bottom",
                tooltipText: _unref(translate)('client_types.reassign_tooltip')
              }, {
                default: _withCtx(() => [
                  (showIcon('assign'))
                    ? (_openBlock(), _createBlock(BaseIcon, {
                        key: 0,
                        name: "nr-icon-people",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (emitAssign(entry.value))),
                        disabled: __props.assignDisabled,
                        size: "2x",
                        compact: ""
                      }, null, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["tooltipText"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "default", { cancelEditing: cancelEditing })
          ]))
    ])
  ]))
}
}

})