import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "plus-count"
}

import { computed, PropType } from 'vue';
import { Photo, PhotoSize } from '@/_shared/store/people';

type AvatarSize = 'xxl' | 'xl' | 'large' | 'medium' | 'small' | 'xsmall'
type AvatarBorder = 'border' | 'border-lg'


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAvatar',
  props: {
  size: {
    type: String as PropType<AvatarSize>,
    default: 'xsmall',
  },
  border: {
    type: String as PropType<AvatarBorder>,
    default: '',
  },
  photo: {
    type: Object as PropType<Photo | Photo[]>,
  },
},
  setup(__props) {

const props = __props;

const getPhotoSize = (avatarSize: AvatarSize): PhotoSize => {
  switch (avatarSize) {
    case 'xsmall': case 'small':
      return 'xsmall';
    case 'medium': case 'large':
      return 'small';
    default:
      return 'medium';
  }
};

const photoSrc = computed(() => {
  let photo;
  if (Array.isArray(props.photo)) {
    if (props.photo.length) {
      [photo] = props.photo;
    }
  } else {
    photo = props.photo;
  }
  const propsSize = getPhotoSize(props.size);
  return (photo && photo[propsSize]) ? photo[propsSize]!.url : 'default-avatar.png';
});

const plusCount = computed(() => {
  if (Array.isArray(props.photo) && props.photo.length > 1) {
    const plus = props.photo.length - 1;
    return plus > 99 ? '99+' : `+${plus}`;
  }
  return '';
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      class: _normalizeClass(`v-base-avatar ${__props.size} ${__props.border}`),
      src: photoSrc.value,
      alt: "Users avatar image"
    }, null, 10, _hoisted_1),
    (plusCount.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(plusCount.value), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})