import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-care-plan-banner" }


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanBanner',
  props: {
  title: {
    type: String,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.title), 1))
}
}

})