import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { BaseIcon } from '@/_shared/components';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionListEntryButton',
  props: {
  icon: {
    type: String,
    default: '',
  },
  selected: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  'action',
],
  setup(__props) {

const props = __props;



const isSelected = computed(() => props.selected);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseIcon), {
    name: __props.icon,
    compact: true,
    class: "v-icon v-point-button",
    "foreground-color": isSelected.value ? 'white' : 'black',
    "no-hover-background": "",
    "background-color": isSelected.value ? 'black' : 'pale-teal',
    square: "",
    size: "2x",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('action')), ["prevent"])),
    padding: "8px",
    style: {"padding-right":"8px"}
  }, null, 8, ["name", "foreground-color", "background-color"]))
}
}

})