import {
  ref, onMounted, onBeforeUnmount, computed,
} from 'vue';
import { getEmbedToken, EmbedTokenResponse } from '@/_shared/services/analyticsApi';

const MINUTES_BEFORE_EXPIRATION = 10;
const INTERVAL_TIME = 30000;

const useTokenManager = (reportId: string, groupId: string) => {
  const token = ref<EmbedTokenResponse|null>(null);
  const tokenExpiration = computed(() => token.value?.expiration);
  let intervalId: number | null = null;

  const checkTokenAndUpdate = async () => {
    const currentTime = Date.now();
    const expiration = tokenExpiration.value ? Date.parse(tokenExpiration.value) : 0;

    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    if (timeUntilExpiration <= timeToUpdate) { await updateToken(); }
  };

  const updateToken = async () => {
    const newAccessToken = await getEmbedToken(reportId, groupId) as EmbedTokenResponse;
    token.value = newAccessToken;

    const embedContainer = document.getElementById('embedContainer') as HTMLElement;
    if (embedContainer) {
      const report = window.powerbi.get(embedContainer);
      await report.setAccessToken(newAccessToken.token);
    }
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) checkTokenAndUpdate();
  };

  onMounted(() => {
    intervalId = window.setInterval(checkTokenAndUpdate, INTERVAL_TIME);
    document.addEventListener('visibilitychange', handleVisibilityChange);
  });

  onBeforeUnmount(() => {
    if (intervalId) clearInterval(intervalId);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  });

  return {
    updateToken,
    checkTokenAndUpdate,
    token,
    tokenExpiration,
  };
};

export default useTokenManager;
