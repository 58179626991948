<script setup lang="ts">

</script>

<template>
    <div class="v-new-report"> </div>
</template>

<style scoped lang="scss">
</style>
