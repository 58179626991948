import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "messages-wrapper" }

import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import BasePaging from '@/_shared/components/navigation/BasePaging.vue';

import { useRoute } from 'vue-router';
import useMessagesStore from '@/messages/store/messagesStore';
import MessageList from './components/MessageList.vue';

import '@/_shared/styles/style.scss';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessagesMain',
  setup(__props) {

const {
  currentFolder, pagination,
} = storeToRefs(useMessagesStore());

const route = useRoute();

watch(() => route.path, (path) => {
  switch (true) {
    case path.includes('/sent'): { currentFolder.value = 'sent'; break; }
    case path.includes('/archived_inbox'): { currentFolder.value = 'archived_inbox'; break; }
    case path.includes('/archived_sent'): { currentFolder.value = 'archived_sent'; break; }
    case path.endsWith('/messages'): { currentFolder.value = 'inbox'; break; }
    default:
  }
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MessageList),
    _createVNode(BasePaging, {
      totalPages: _unref(pagination).totalPages,
      modelValue: _unref(pagination).page,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pagination).page) = $event))
    }, null, 8, ["totalPages", "modelValue"])
  ]))
}
}

})