import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-multi-choice-parameter-container" }
const _hoisted_2 = { class: "v-multiple-choices" }
const _hoisted_3 = {
  key: 0,
  class: "v-multiple-choices-array"
}

import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import { Parameter } from '../types/Parameter';
import MultipleChoiceOption from './MultipleChoiceOption.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleChoiceArrayParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  index: {
    type: Number,
    default: -1,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      "second-line": true
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (__props.parameter.isArray)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.parameter.coercedChoices, (choice, choiceIndex) => {
                  return (_openBlock(), _createBlock(MultipleChoiceOption, {
                    key: `${__props.parameter.id}-${choiceIndex}`,
                    parameter: __props.parameter,
                    index: __props.index,
                    option: choice,
                    class: _normalizeClass({ first: choiceIndex === 0 }),
                    readOnly: __props.readOnly
                  }, null, 8, ["parameter", "index", "option", "class", "readOnly"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})