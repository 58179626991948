import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

import {
  computed, onBeforeMount, PropType,
} from 'vue';
import BaseCardHeader from '@/_shared/components/display/BaseCard/BaseCardHeader.vue';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import use from '@/_shared/compositionApi';
import CertificationTableContainer from '@/professional_info/CertificationTableContainer.vue';
import AvailabilityContainer from './components/AvailabilityContainer.vue';

enum Section {
  Qualifications = 'qualifications',
  Training = 'training',
  Availabilities = 'availabilities',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfessionalInfoRightPanelMain',
  props: {
  section: {
    type: [String, Array] as PropType<Section>,
    default: '',
  },
  carerId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('professional_info'); // TODO WHEN PURE VUE remove
});
const { carerStore } = use.store.carers();
const { translate } = use.helpers();

const avatarPhoto = computed(() => carerStore.photo(+props.carerId));

const getIcon = (section: string) => {
  // TODO refactor this once we have an icon module
  if (section === 'availabilities') { return 'round-icon round-icon-sm temp-icon-size-reduce nr-icon-schedule'; }
  return `header-icon icon ncp-${section}-filled`;
};

const mappedSection = computed(() => {
  let { section } = props;
  if (Array.isArray(props.section)) {
    [section] = props.section;
  }
  return section;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, {
    photo: avatarPhoto.value,
    open: Object.values(Section).includes(mappedSection.value),
    class: "hiddenOnMobile"
  }, {
    default: _withCtx(() => [
      (mappedSection.value)
        ? (_openBlock(), _createBlock(BaseCard, { key: 0 }, {
            header: _withCtx(() => [
              _createVNode(BaseCardHeader, {
                icon: getIcon(mappedSection.value),
                title: _unref(translate)(`professional_info.sections.${mappedSection.value}`)
              }, null, 8, ["icon", "title"])
            ]),
            content: _withCtx(() => [
              (mappedSection.value === 'availabilities')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(AvailabilityContainer, {
                      "carer-id": Number(__props.carerId)
                    }, null, 8, ["carer-id"])
                  ]))
                : (_openBlock(), _createBlock(CertificationTableContainer, {
                    key: 1,
                    section: mappedSection.value,
                    "carer-id": Number(__props.carerId)
                  }, null, 8, ["section", "carer-id"]))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["photo", "open"]))
}
}

})