import useUserStore from '@/_shared/store/user';
import apiClient from '@/_shared/services/apiClient';
import { promiseMemorise } from '@/_shared/services/UseUtils';

export type OrganisationRolesApiResponse = {
  organisationRoles: OrganisationRole[];
}
export type OrganisationRole = {
  id: number;
  name: string;
};

export async function fetchOrganisationRoles(orgId = useUserStore().currentOrgId) : Promise<OrganisationRolesApiResponse> {
  const params = {
    active: true,
    for_notifications: true,
  };
  try {
    return await apiClient.get<OrganisationRolesApiResponse>(`/api/v2/organisations/${orgId}/organisation_roles/`, { params });
  } catch (error) {
    return error;
  }
}

export const memorizedOrganisationRoles = promiseMemorise(fetchOrganisationRoles);
