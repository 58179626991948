import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-single-text-parameter-container" }
const _hoisted_2 = { style: {"display":"block"} }
const _hoisted_3 = ["placeholder", "disabled"]

import {
  computed,
  onMounted,
  ref, watch,
} from 'vue';
import use from '@/_shared/compositionApi';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import { Parameter } from '../types/Parameter';

const initialHeight = 48;

export default /*@__PURE__*/_defineComponent({
  __name: 'TextParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: -1,
  },
},
  setup(__props) {

const props = __props;

const localCoercedValue = computed({
  get() {
    return (props.parameter.coercedValue as string | null) || '';
  },
  set(value: string) {
    props.parameter.coercedValue = value;
  },
});

watch(localCoercedValue, async (inputText) => {
  if (!textareaParameter.value) return;
  textareaParameter.value.value = inputText;
  let defaultHeight = true;
  if (inputText) {
    defaultHeight = false;
  }
  resize(defaultHeight);
});

const textareaParameter = ref<null | HTMLTextAreaElement>(null);
function resize(defaultHeight = false) {
  if (!textareaParameter.value) return;
  if (defaultHeight) {
    textareaParameter.value.style.height = `${initialHeight}px`;
  } else if (textareaParameter.value.scrollHeight > initialHeight) {
    textareaParameter.value.style.height = `${textareaParameter.value.scrollHeight}px`;
  } else {
    textareaParameter.value.style.height = `${initialHeight}px`;
  }
}

onMounted(resize);

const { translate } = use.helpers();
const { errorMessage } = useParameterValidator(props.parameter);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true,
      errorMessage: _unref(errorMessage) || ''
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("label", _hoisted_2, [
          _withDirectives(_createElementVNode("textarea", {
            ref_key: "textareaParameter",
            ref: textareaParameter,
            class: _normalizeClass(['v-text-parameter', `sp-parameter-field-${__props.parameter.id}`]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localCoercedValue).value = $event)),
            placeholder: __props.readOnly? '' : _unref(translate)('parameter.text_placeholder'),
            disabled: __props.readOnly,
            rows: 2
          }, null, 10, _hoisted_3), [
            [_vModelText, localCoercedValue.value]
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter", "errorMessage"])
  ]))
}
}

})