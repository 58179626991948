import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  PropType, computed, onBeforeMount,
} from 'vue';
import AssignModal from '@/_shared/components/modals/AssignModal/AssignModal.vue';
import { storeToRefs } from 'pinia';
import use from '@/_shared/compositionApi';
import { Carer } from '@/_shared/store/carers';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionAssignCarers',
  props: {
  showModal: {
    type: Boolean,
    required: true,
  },
  iconUrl: {
    type: String,
  },
  selectedEntries: {
    type: Array as PropType<number[]>,
    default: () => [],
  },
},
  emits: ['updateResponsibleIds', 'closeAssignCarers'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();



const emit = __emit;

const currentInteractionStore = useCurrentInteractionStore();
const { currentInteraction } = storeToRefs(currentInteractionStore);

const { carerStore } = use.store.carers();

onBeforeMount(async () => {
  await carerStore.initialize();
});
const orgUnitId = currentInteraction.value?.organisationUnitId;

const carers = computed<Carer[]>(() => {
  const serviceId = currentInteraction.value?.serviceId;
  return carerStore.active(orgUnitId).value?.filter((carer) => carer.serviceIds?.indexOf(serviceId) !== -1);
});

const assignResponsiblePersons = (value: number[]) => {
  emit('updateResponsibleIds', value);
  closeAssignModal();
};

const closeAssignModal = () => {
  emit('closeAssignCarers');
};


return (_ctx: any,_cache: any) => {
  return (__props.showModal)
    ? (_openBlock(), _createBlock(AssignModal, {
        key: 0,
        title: _unref(currentInteraction).name,
        buttonText: _unref(translate)('common.assign'),
        selectAllText: _unref(translate)('common.everyone'),
        selectableEntries: carers.value,
        selectedEntries: __props.selectedEntries,
        onOpenConfirmAssignEntries: assignResponsiblePersons,
        primaryTextKey: "firstName",
        secondaryTextKey: "name",
        confirmText: _unref(translate)('alert.confirm'),
        filterable: false,
        searchInputPlaceholder: _unref(translate)('common.type_here_to_search'),
        onCloseModal: closeAssignModal,
        iconUrl: __props.iconUrl
      }, null, 8, ["title", "buttonText", "selectAllText", "selectableEntries", "selectedEntries", "confirmText", "searchInputPlaceholder", "iconUrl"]))
    : _createCommentVNode("", true)
}
}

})