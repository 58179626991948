import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }
const _hoisted_2 = {
  key: 0,
  class: "v-tos-error"
}

import { onBeforeMount, PropType, ref } from 'vue';
import { BaseAlert, BaseInput } from '@/_shared/components/';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import Integration from '../../types/integration';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationTokenModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
},
  emits: ['confirmToken', 'closeTokenModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { translate } = use.helpers();
const tokenValue = ref<string>(props.token);
const showTokenValidationError = ref<boolean>(false);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const emit = __emit;
const confirmTokenIfValid = () => {
  if (tokenValue.value && tokenValue.value !== '') {
    emit('confirmToken', tokenValue.value);
    return;
  }
  showTokenValidationError.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseAlert), {
    class: "v-integration-token-alert",
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: confirmTokenIfValid,
    onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeTokenModal')))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(translate)('integrations.integration_token')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_unref(translate)('integrations.enter_token')) + " " + _toDisplayString(__props.integration.name) + " " + _toDisplayString(_unref(translate)('integrations.enter_token2')) + " " + _toDisplayString(__props.ou.name), 1)
      ]),
      _createVNode(_unref(BaseInput), {
        border: true,
        modelValue: tokenValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tokenValue).value = $event))
      }, null, 8, ["modelValue"]),
      (showTokenValidationError.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(translate)('integrations.invalid_token')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})