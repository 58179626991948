import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { buttonTypes, buttonSizes } from './types/BaseButton';

interface BaseButtonProps {
    buttonType: buttonTypes;
    size: buttonSizes;
    disabled: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
    buttonType: { default: 'primary' },
    size: { default: 'medium' },
    disabled: { type: Boolean, default: false }
  },
  emits: ['clicked'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["v-button", `${_ctx.buttonType} ${_ctx.size} ${_ctx.disabled ? 'disabled' : ''}`]),
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('clicked'))),
    onKeydown: _cache[1] || (_cache[1] = ($event: any) => (emit('clicked')))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 42, _hoisted_1))
}
}

})