import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { use } from 'echarts/core';

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { computed, PropType, provide } from 'vue';
import { formatTimeAxis } from '@/_shared/services/serviceLogGraph';
import { InteractionLogGraphData } from '@/timeline/types/InteractionLogData';

const LEGEND_MAX_CHAR_LIMIT = 30;


export default /*@__PURE__*/_defineComponent({
  __name: 'LogGraph',
  props: {
  data: {
    type: Object as PropType<InteractionLogGraphData[]>,
    required: true,
  },
},
  setup(__props) {

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
]);

provide(THEME_KEY, 'light');

const props = __props;

const legendWidth = computed(() => {
  let maxLegendNameLength = Math.max(...(props.data.map((el) => el.name.length)));

  if (maxLegendNameLength > LEGEND_MAX_CHAR_LIMIT) {
    maxLegendNameLength = LEGEND_MAX_CHAR_LIMIT;
  }
  return (maxLegendNameLength * 4) + 30;
});

const option = computed(() => ({
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    orient: 'vertical',
    right: '5',
    width: legendWidth.value,
    top: 'center',
    type: 'scroll',
    textStyle: {
      width: legendWidth.value,
      overflow: 'truncate',
    },
  },
  grid: {
    left: '50px',
    right: legendWidth.value + 30,
    bottom: '30px',
    top: '30px',
    containLabel: false,
  },
  xAxis: [
    {
      type: 'time',
      splitNumber: 10,
      axisLine: {
        lineStyle: {
          color: '#dad9d6',
          width: 2,
        },
      },
      axisLabel: {
        textStyle: {
          color: '#798b93',
          align: 'center',
          fontFamily: '"FS Me", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        formatter: formatTimeAxis,
      },
      axisTick: {
        lineStyle: {
          width: 2,
        },
      },
      splitLine: {
        lineStyle: {
          color: '#dad9d6',
          type: 'dotted',
          width: 2,
        },
      },
    },
  ],
  yAxis: [
    {
      boundaryGap: [0, 0.25],
      type: 'value',
      axisLine: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          color: '#798b93',
          align: 'right',
          fontFamily: '"FS Me", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#dad9d6',
          type: 'dotted',
          width: 2,
        },
      },
    },
  ],
  series: props.data,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VChart), {
    class: "chart",
    option: option.value
  }, null, 8, ["option"]))
}
}

})