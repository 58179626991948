import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-map-filter-dropdowns-container" }

import { computed, PropType } from 'vue';
import BaseFilterDropdown from '@/_shared/components/general/BaseFilterDropdown.vue';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import { filterOption, filterStates } from '@/_shared/services/instanceFilterHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapFilter',
  props: {
  skinInstances: {
    type: Array as PropType<{ state: string, instances: SkinInstance[] }[]>,
    required: true,
  },
  currentState: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
  currentSubType: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
},
  emits: ['filterChange'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const allInstances = computed(() => {
  let instances: SkinInstance[] = [];
  props.skinInstances?.forEach((instancesAndState) => {
    instances = instances.concat(instancesAndState.instances);
  });
  return instances;
});

const stateOptions = computed(() => {
  const values = Array.from(new Set(allInstances.value.map((instance) => instance.state)));
  return filterStates(values, 'body_map.state');
});

const subTypeOptions = computed(() => {
  const values = Array.from(new Set(allInstances.value.map((instance) => instance.subType)));
  return filterOption(values, 'datasets');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseFilterDropdown, {
      class: "v-body-map-state-filter",
      currentOption: __props.currentState,
      allOptions: stateOptions.value,
      onValueChanged: _cache[0] || (_cache[0] = (option) => emit('filterChange', {filter: 'state' , value: option}))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      class: "v-body-map-sub-type-filter",
      currentOption: __props.currentSubType,
      allOptions: subTypeOptions.value,
      onValueChanged: _cache[1] || (_cache[1] = (option) => emit('filterChange', {filter: 'subType' , value: option}))
    }, null, 8, ["currentOption", "allOptions"])
  ]))
}
}

})