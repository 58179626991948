import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TeleportWrapper',
  props: {
  to: {
    type: String,
    default: 'body',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: __props.to }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, ["to"]))
}
}

})