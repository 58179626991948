import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  ref, watch, watchEffect,
} from 'vue';
import { EmbedTokenResponse, getEmbedToken } from '@/_shared/services/analyticsApi';
import useTokenManager from '@/dashboards/services/tokenManager';
import { embedReport, powerBiService, setPage } from './services/powerBiServices';


export default /*@__PURE__*/_defineComponent({
  __name: 'PowerBiEmbed',
  props: {
  workspaceId: {
    type: String,
    required: true,
  },
  reportId: {
    type: String,
    required: true,
  },
  embedUrl: {
    type: String,
    required: true,
  },
  page: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const embedContainer = ref<HTMLElement|null>(null);
const powerbi = powerBiService();

const props = __props;

const { token } = useTokenManager(props.reportId, props.workspaceId);

watchEffect(async () => {
  const response = await getEmbedToken(props.workspaceId, props.reportId);
  if (!response || Object.keys(response).length === 0) return;

  token.value = response as EmbedTokenResponse;

  embedReport(powerbi, embedContainer.value as HTMLElement, props, token.value.token);
}, { flush: 'post' });

watch(() => props.page, async () => {
  if (props.page && token.value) {
    setPage(powerbi, embedContainer.value as HTMLElement, props.page);
  } else if (!props.page && token.value) {
    embedReport(powerbi, embedContainer.value as HTMLElement, props, token.value.token);
  }
}, { immediate: true, flush: 'post' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "embedContainer",
    ref: embedContainer,
    class: "v-embed-dashboard"
  }, null, 512))
}
}

})