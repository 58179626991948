import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-log-options-wrapper" }
const _hoisted_2 = { class: "v-time-period" }
const _hoisted_3 = { class: "v-log-label" }
const _hoisted_4 = { class: "v-log-custom" }
const _hoisted_5 = { class: "v-log-label-custom v-from" }
const _hoisted_6 = { class: "v-log-label-custom v-to" }
const _hoisted_7 = { class: "v-chart-type" }
const _hoisted_8 = { class: "v-log-label" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import { computed, onBeforeMount, ref } from 'vue';
import { subDays, subMonths, subWeeks } from 'date-fns';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogOptions',
  props: {
  showGraph: {
    type: Boolean,
    required: true,
  },
  showTable: {
    type: Boolean,
    required: true,
  },
  fromDate: {
    type: [Date, String],
    required: true,
  },
  toDate: {
    type: [Date, String],
    required: true,
  },
},
  emits: ['update:fromDate', 'update:toDate', 'toggleTable', 'toggleGraph'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

onBeforeMount(async () => {
  canShowGraph.value = props.showGraph;
});

const canShowGraph = ref(true);

const toggleGraphDisabled = computed(() => !canShowGraph.value || (props.showGraph && !props.showTable));

const { translate } = use.helpers();

const setDay = () => {
  emit('update:fromDate', subDays(new Date(Date.now()), 1));
  emit('update:toDate', new Date(Date.now()));
  activeDay.value = true;
  activeWeek.value = false;
  activeMonth.value = false;
};

const setWeek = () => {
  emit('update:fromDate', subWeeks(new Date(Date.now()), 1));
  emit('update:toDate', new Date(Date.now()));
  activeDay.value = false;
  activeWeek.value = true;
  activeMonth.value = false;
};

const setMonth = () => {
  emit('update:fromDate', subMonths(new Date(Date.now()), 1));
  emit('update:toDate', new Date(Date.now()));
  activeDay.value = false;
  activeWeek.value = false;
  activeMonth.value = true;
};

const fromDate = computed({
  get: () => props.fromDate,
  set: (value) => {
    emit('update:fromDate', value);
    activeDay.value = false;
    activeWeek.value = false;
    activeMonth.value = false;
  },
});

const toDate = computed({
  get: () => props.toDate,
  set: (value) => {
    emit('update:toDate', value);
    activeDay.value = false;
    activeWeek.value = false;
    activeMonth.value = false;
  },
});

const activeDay = ref(false);
const activeWeek = ref(false);
const activeMonth = ref(true);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('log.timePeriod')) + ":", 1),
      _createElementVNode("span", {
        class: _normalizeClass(["v-time-button v-hour", activeDay.value ? 'active' : '']),
        onClick: setDay
      }, _toDisplayString(_unref(translate)('log.day')), 3),
      _createElementVNode("span", {
        class: _normalizeClass(["v-time-button v-week", activeWeek.value ? 'active' : '']),
        onClick: setWeek
      }, _toDisplayString(_unref(translate)('log.week')), 3),
      _createElementVNode("span", {
        class: _normalizeClass(["v-time-button v-month", activeMonth.value ? 'active' : '']),
        onClick: setMonth
      }, _toDisplayString(_unref(translate)('log.month')), 3),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(translate)('common.from')) + ":", 1),
        _createVNode(BaseDatePickerInput, {
          modelValue: fromDate.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((fromDate).value = $event)),
          class: "v-from-datepicker"
        }, null, 8, ["modelValue"]),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(translate)('common.to')) + ":", 1),
        _createVNode(BaseDatePickerInput, {
          modelValue: toDate.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((toDate).value = $event)),
          class: "v-to-datepicker"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(translate)('log.chartType')) + ":", 1),
      _createVNode(BaseIcon, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (!toggleGraphDisabled.value && emit('toggleGraph'))),
        active: __props.showGraph,
        disabled: toggleGraphDisabled.value,
        name: "nr ncp-log"
      }, null, 8, ["active", "disabled"]),
      _createVNode(BaseIcon, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('toggleTable'))),
        active: __props.showTable,
        disabled: !__props.showGraph && __props.showTable,
        name: "table"
      }, null, 8, ["active", "disabled"])
    ])
  ]))
}
}

})