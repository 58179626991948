import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "v-arrow-left-wrapper",
  style: {"color":"black"}
}

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseArrowLeft',
  emits: ['clicked'],
  setup(__props, { emit: __emit }) {

const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseIcon, {
      name: "nsx-font-icon--arrow-left v-arrow-left",
      "foreground-color": "black",
      "foreground-hover-color": "grey",
      "no-hover-background": "",
      compact: "",
      size: "lg",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('clicked')))
    })
  ]))
}
}

})