import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-message-create-wrapper" }
const _hoisted_2 = { class: "inner-wrapper" }
const _hoisted_3 = { class: "v-single-panel-header" }
const _hoisted_4 = { class: "v-single-panel-body body" }
const _hoisted_5 = { class: "v-message-create-container" }

import {
  BaseButton, BaseIcon, BaseInput, BaseSelect,
} from '@/_shared/components/';
import use from '@/_shared/compositionApi';
import useMessagesStore from '@/messages/store/messagesStore';
import { storeToRefs } from 'pinia';
import { toRef } from 'vue';

import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageCreate',
  setup(__props) {

const { newMessage, recipientOptions } = storeToRefs(useMessagesStore());
const { sendMessage } = useMessagesStore();

const { translate } = use.helpers();

// TODO: This is what had to be done to pass tests. Rethink
const recipientIds = toRef(newMessage.value!, 'recipientIds');
const body = toRef(newMessage.value!, 'body');
const subject = toRef(newMessage.value!, 'subject');

const router = useRouter();
const goBackToMessages = () => {
  newMessage.value = null;
  router.push({ name: 'messages' });
};
const send = async () => {
  if (newMessage.value?.senderId && await sendMessage(newMessage.value)) {
    goBackToMessages();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BaseIcon), {
          class: "v-close-message",
          transparent: "",
          onClick: goBackToMessages,
          name: "times",
          align: "left",
          size: "lg"
        }),
        _createVNode(_unref(BaseButton), {
          class: "v-send-message",
          onClicked: send
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('message.send')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(BaseSelect), {
            modelValue: recipientIds.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((recipientIds).value = $event)),
            options: _unref(recipientOptions),
            multiple: "",
            label: `${_unref(translate)('message.to')}:`,
            required: true,
            searchable: "",
            "label-width": "70px"
          }, null, 8, ["modelValue", "options", "label"]),
          _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "v-hr" }, null, -1)),
          _createVNode(_unref(BaseInput), {
            label: `${_unref(translate)('message.subject')}:`,
            modelValue: subject.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((subject).value = $event)),
            required: true,
            "label-width": "70px"
          }, null, 8, ["label", "modelValue"]),
          _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "v-hr" }, null, -1)),
          _createVNode(_unref(BaseInput), {
            textarea: "",
            placeholder: `${_unref(translate)('message.body')}...`,
            modelValue: body.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((body).value = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ])
      ])
    ])
  ]))
}
}

})