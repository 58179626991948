import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-interaction-copyright"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CopyrightText',
  props: {
  text: {
    type: String,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.text)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(props.text), 1))
    : _createCommentVNode("", true)
}
}

})