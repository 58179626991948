
import { defineComponent } from 'vue';
import { differenceInYears } from 'date-fns';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import use from '@/_shared/compositionApi';
import { GpConnectResponse } from '../types/gp_connect';

export default defineComponent({
  components: {
    BaseCard,
  },
  props: {
    response: {
      type: Object as () => GpConnectResponse,
      required: true,
    },
  },
  setup() {
    const { translate } = use.helpers();

    function getClientDate(response: GpConnectResponse) {
      if (response.personalDetails && response.personalDetails.dob) {
        const { dob } = response.personalDetails;
        const birthDate = new Date(dob.split('-').reverse().join('-'));
        const clientAge = differenceInYears(new Date(), birthDate);
        return `${dob} (${clientAge}y)`;
      }
      return null;
    }
    return {
      translate,
      getClientDate,
    };
  },
});
