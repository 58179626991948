import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-interaction-header-container sp-interaction-details" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-interaction-title" }
const _hoisted_5 = { class: "v-header-right" }
const _hoisted_6 = { class: "v-careplan-review-section" }
const _hoisted_7 = { class: "v-careplan-review-interactions-title" }
const _hoisted_8 = { class: "v-careplan-review-interactions-content" }
const _hoisted_9 = ["innerHTML"]

import { storeToRefs } from 'pinia';
import useNeedsStore from '@/_shared/store/needs';
import {
  computed, PropType, ref,
} from 'vue';
import { Need } from '@/timeline/types/Need';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import InteractionSection from '../InteractionSection.vue';
import CarePlanResponsiblePeople from './CarePlanResponsiblePeople.vue';
import CarePlanReviewItem from './CarePlanReviewItem.vue';
import LastClosedReview from './LastClosedReview.vue';
import LevelOfNeed from './LevelOfNeed.vue';
import RiskAssessment from './RiskAssessment.vue';
import PreviousReviews from './PreviousReviews.vue';
import NextReviewDue from './NextReviewDue.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReview',
  props: {
  need: {
    type: Object as PropType<Need>,
    required: true,
  },
  hidePreviousReviews: {
    type: Boolean,
    default: false,
  },
  actionText: {
    type: String,
    default: '',
  },
  canPrint: {
    type: Boolean,
    default: true,
  },
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const needsStore = useNeedsStore();
const { fetchAllReviews, fetchVersion, getProvidedServicesForNeed } = needsStore;
const {
  needVersion,
} = storeToRefs(needsStore);

const isVersionModalOpen = ref(false);

const toggleVersionModal = async (id?: number) => {
  if (id) {
    await fetchVersion({
      id: props.need.id,
      lastReviewId: id,
    });
    isVersionModalOpen.value = true;
  } else {
    isVersionModalOpen.value = false;
  }
};

const risks = computed(() => props.need.riskAssessments.filter((r) => !r.archived).concat().sort((a, b) => {
  const idA = a.id || 0;
  const idB = b.id || 0;
  return idA - idB;
}));

const showPreviousReviews = computed(() => /* add whole logic */ !props.hidePreviousReviews);

const lastClosedReview = computed(() => props.need.reviews.find((r) => r.id === props.need.lastReviewId));

const providedServices = computed(() => getProvidedServicesForNeed(props.need));

const providedServicesForNeed = computed(() => {
  const providedServiceIds = props.need.needProvidedServices.map((nps) => nps.providedServiceId);
  return providedServices.value?.filter((service) => providedServiceIds.includes(service.id));
});

const print = () => {
  window.print();
  return false;
};

const props = __props;

const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_CarePlanReview = _resolveComponent("CarePlanReview", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BaseCard, {
      headerBackground: true,
      padding: false,
      contentTopBorder: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(BaseIcon, {
                name: "ncp-careplan",
                size: "2x",
                noHover: "",
                compact: "",
                backgroundColor: "black",
                foregroundColor: "white",
                foregroundHoverColor: "white",
                class: "v-service-icon"
              }),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, _toDisplayString(__props.need.title), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (__props.canPrint)
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    name: "print",
                    onClick: print
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(NextReviewDue, {
              title: _unref(translate)('timeline.need.next_review_on'),
              value: __props.need.nextReviewOn,
              fallbackText: _unref(translate)('timeline.need.next_review_not_set'),
              actionText: __props.actionText,
              onAction: _cache[0] || (_cache[0] = ($event: any) => (emit('action')))
            }, null, 8, ["title", "value", "fallbackText", "actionText"])
          ]),
          _createVNode(CarePlanReviewItem, {
            title: _unref(translate)('timeline.need.identified_need'),
            value: __props.need.identifiedNeed,
            fallbackText: _unref(translate)('common.empty_field_data'),
            class: "v-careplan-review-section"
          }, null, 8, ["title", "value", "fallbackText"]),
          _createVNode(LevelOfNeed, {
            title: _unref(translate)('timeline.need.level_of_need'),
            levelOfNeed: __props.need.levelOfNeed,
            fallbackText: _unref(translate)('common.empty_field_data'),
            class: "v-careplan-review-section"
          }, null, 8, ["title", "levelOfNeed", "fallbackText"]),
          _createVNode(CarePlanReviewItem, {
            title: _unref(translate)('timeline.need.planned_outcomes'),
            value: __props.need.plannedOutcomes,
            fallbackText: _unref(translate)('common.empty_field_data'),
            class: "v-careplan-review-section"
          }, null, 8, ["title", "value", "fallbackText"]),
          _createVNode(CarePlanReviewItem, {
            title: _unref(translate)('timeline.need.how_to_achive_outcomes'),
            value: __props.need.howToAchiveOutcomes,
            fallbackText: _unref(translate)('common.empty_field_data'),
            class: "v-careplan-review-section"
          }, null, 8, ["title", "value", "fallbackText"]),
          _createVNode(InteractionSection, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('timeline.need.need_subscribed_service')), 1),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providedServicesForNeed.value, (service) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: service.id,
                    class: "v-careplan-review-interactions-item"
                  }, [
                    _createElementVNode("h4", null, _toDisplayString(service.name), 1),
                    _createElementVNode("div", {
                      innerHTML: service.description
                    }, null, 8, _hoisted_9)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(RiskAssessment, {
            risks: risks.value,
            class: "v-careplan-review-section"
          }, null, 8, ["risks"]),
          _createVNode(CarePlanResponsiblePeople, {
            "responsible-person-ids": __props.need.responsiblePersonIds
          }, null, 8, ["responsible-person-ids"]),
          (lastClosedReview.value)
            ? (_openBlock(), _createBlock(LastClosedReview, {
                key: 0,
                review: lastClosedReview.value,
                class: "v-careplan-review-section"
              }, null, 8, ["review"]))
            : _createCommentVNode("", true),
          _createVNode(NextReviewDue, {
            title: _unref(translate)('timeline.need.next_review_on'),
            value: __props.need.nextReviewOn,
            fallbackText: _unref(translate)('timeline.need.next_review_not_set'),
            actionText: props.actionText,
            onAction: _cache[1] || (_cache[1] = ($event: any) => (emit('action'))),
            class: "v-careplan-review-section"
          }, null, 8, ["title", "value", "fallbackText", "actionText"]),
          (showPreviousReviews.value)
            ? (_openBlock(), _createBlock(PreviousReviews, {
                key: 1,
                need: __props.need,
                reviews: __props.need.reviews,
                onFetchAllReviews: _cache[2] || (_cache[2] = () => _unref(fetchAllReviews)(__props.need.id)),
                onShowVersion: toggleVersionModal,
                class: "no-printable"
              }, null, 8, ["need", "reviews"]))
            : _createCommentVNode("", true),
          (isVersionModalOpen.value && _unref(needVersion))
            ? (_openBlock(), _createBlock(BaseModal, {
                key: 2,
                onCloseModal: toggleVersionModal,
                confirmHidden: true,
                header: false,
                fullWidth: true,
                width: 848
              }, {
                content: _withCtx(() => [
                  _createVNode(_component_CarePlanReview, {
                    need: _unref(needVersion),
                    hidePreviousReviews: true,
                    actionText: _unref(translate)('alert.close'),
                    onAction: toggleVersionModal
                  }, null, 8, ["need", "actionText"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}
}

})