import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-careplan-person" }
const _hoisted_2 = { class: "v-careplan-person-name" }

import { PropType } from 'vue';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';
import { Person } from '@/_shared/store/people';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanPerson',
  props: {
  person: {
    type: Object as PropType<Person>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseAvatar, {
      photo: __props.person.photo,
      size: "small"
    }, null, 8, ["photo"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.person.name), 1)
  ]))
}
}

})