import use from '@/_shared/compositionApi';

const { translate } = use.helpers();
export const fetchClientStatusTerm = (state: string) => {
  if (clientStateOverride()[state]) {
    return clientStateOverride()[state];
  }
  return translate(`client_state.${state}`);
};

export const fetchClientStatusTerms = (states: string[]) => states.map((state) => fetchClientStatusTerm(state));

export const fetchAllClientStates = () => {
  // TODO: when clients filter is implemented
};

const clientStateOverride = (): {[key: string]: string} => window.currentOrganisationUnit?.client_status_override || {};
