export const getProfileUrl = () => {
  const url = window.location.href;
  const urlSegments = url.split('/');
  const urlProfileSegments = urlSegments.slice(0, 7);
  return urlProfileSegments.join('/');
};

export const redirectToProfile = () => {
  window.location.assign(getProfileUrl().toString());
};
