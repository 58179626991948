import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import use from '@/_shared/compositionApi';
import { computed } from 'vue';
import { CarePlanPage } from '@/_shared/types/carePlan';
import BaseCarePlanFolder from '@/careplan/components/BaseCarePlanFolder.vue';
import carePlanConsentIcon from '@/_shared/assets/icons/careplan_consent.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanConsentFolder',
  setup(__props) {

const { translate } = use.helpers();
const carePlanTerm = computed(() => window.currentOrganisationUnit.care_plan_term);

const consentPages: CarePlanPage[] = [
  {
    title: translate('careplan.latest_consent_title', { care_plan_term: carePlanTerm.value }),
    toLink: 'latest_consent',
    icon: 'ncp-page-filled',
  },
  {
    title: translate('careplan.update_agreement_title', { care_plan_term: carePlanTerm.value }),
    toLink: 'update_consent',
    icon: 'ncp-page-filled',
  },
  {
    title: translate('careplan.agreement_history_title', { care_plan_term: carePlanTerm.value }),
    toLink: 'agreement_history',
    icon: 'ncp-page-filled',
  },
];

const carePlanConsentFolderTitle = computed(() => translate('careplan.care_plan_consent', { care_plan_term: carePlanTerm.value }));

const consentFolder = computed(() => ({
  title: carePlanConsentFolderTitle.value,
  pages: consentPages,
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCarePlanFolder, {
    "care-plan-folder": consentFolder.value,
    "description-disabled": "",
    "icon-name": _unref(carePlanConsentIcon)
  }, null, 8, ["care-plan-folder", "icon-name"]))
}
}

})