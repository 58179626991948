import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRoute, useRouter } from 'vue-router';
import use from '@/_shared/compositionApi';
import BaseSearchableList from '@/_shared/components/general/BaseSearchableList.vue';
import { computed } from 'vue';
import { SelectorItem, SelectorSection } from '@/_shared/types/baseSearchableList';
import useFilterNewEventServices from '@/events/composables/eventServices';
import BaseRightPanel from '../../_shared/components/general/BaseRightPanel.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventSelector',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { translate } = use.helpers();

const closeForm = () => {
  router.push({ name: 'client.events' });
};

const filteredNewEventServices = useFilterNewEventServices();

const sections = computed<SelectorSection[]>(() => {
  let allItems: SelectorItem[] = [];
  if (filteredNewEventServices.value) {
    allItems = filteredNewEventServices.value.map((service) => ({
      name: service.name,
      callback: () => router.push(newEventLink(service.serviceId)),
      key: service.id,
    }));
  }
  return [{
    header: translate('interaction.selector.all'),
    searchable: true,
    items: allItems,
  }];
});

const newEventLink = (serviceId: number) => ({
  path: `/clients/${route.params.clientId}/events/new`,
  query: { ...route.query, serviceId: +serviceId },
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, null, {
    default: _withCtx(() => [
      _createVNode(BaseSearchableList, {
        title: _unref(translate)('interaction.selector.selector_header'),
        sections: sections.value,
        closeCallback: closeForm
      }, null, 8, ["title", "sections"])
    ]),
    _: 1
  }))
}
}

})