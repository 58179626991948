import { createVueAppForAngular } from '../../../vue/src/_shared/vue_loader';

if (!window.createVueApp) {
  const createVueApp = createVueAppForAngular;
  window.createVueApp = createVueApp; // this is how VueApp factory is accessed by Angular
}

if (!window.vueApp) {
  const vueApp = /*await*/ createVueApp();
  // console.log(`mounting Vue onto ${document.getElementById('vue-app')}`);
  try {
    vueApp.mount('#vue-app');
    window.vueApp = vueApp;
  } catch (ex) {
    debugger;
  }
}
