export function linkify(text: string) {
  const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return text.replace(exp, '<a href=\'$1\' target=\'_blank\'>$1</a>');
}

export enum ToastType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

// TODO implement toast
export function toast(message: string, type: ToastType, duration = 20000) {
  window.helpers?.toast(message, type, duration);
}

// translations:any

// buildErrorMessage(response) {
//     if (response.data) {
//       if (response.data.message) { return response.data.message; }
//       else if (response.data.message_tag) { return this.t(response.data.message_tag); }
//     }
//     if (response.status === 403) { return this.t('ajax.errors.permission'); }
//     if (response.status === 400 || response.status === 500) { return this.t('ajax.errors.server'); }
//     return this.t('ajax.errors.not_saved');
//   }
