import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-next-review" }
const _hoisted_2 = { class: "v-next-review-item" }
const _hoisted_3 = { class: "v-next-review-item-title" }
const _hoisted_4 = {
  key: 0,
  class: "v-next-review-item-value"
}
const _hoisted_5 = {
  key: 1,
  class: "v-missing-text"
}

import { format } from 'date-fns';
import { computed, PropType } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NextReviewDue',
  props: {
  title: {
    type: String,
    required: true,
  },
  value: {
    type: String as PropType<string | null>,
    default: null,
  },
  actionText: {
    type: String,
    default: null,
  },
  fallbackText: {
    type: String,
    default: null,
  },
},
  emits: ['action'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const dateValue = computed(() => props.value && format(new Date(props.value), 'dd/MM/yyyy'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1),
      _createVNode(BaseIcon, {
        name: "nr-icon-calendar",
        size: "sm",
        noHover: "",
        compact: ""
      }),
      (dateValue.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(dateValue.value), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.fallbackText), 1)),
      _createVNode(BaseButton, {
        class: "v-next-review-item-action",
        onClicked: _cache[0] || (_cache[0] = ($event: any) => (emit('action'))),
        "button-type": "primary",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.actionText), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})