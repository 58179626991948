import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-interaction-header-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "v-interaction-title-small" }
const _hoisted_4 = { class: "v-state-snooze-wrapper" }
const _hoisted_5 = { class: "v-state-snooze" }
const _hoisted_6 = { class: "v-state-snooze-header-wrapper" }
const _hoisted_7 = { class: "v-state-snooze-header" }
const _hoisted_8 = { class: "v-state-snooze-header-text" }
const _hoisted_9 = { class: "v-state-snooze-input-area" }
const _hoisted_10 = ["id", "value"]
const _hoisted_11 = ["for"]
const _hoisted_12 = { class: "v-base-note-wrapper" }

import { Ref, ref } from 'vue';
import BaseNote from '@/_shared/components/inputs/BaseNote.vue';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import BaseArrowLeft from '@/_shared/components/general/BaseArrowLeft.vue';
import SubmitButtons from './SubmitButtons.vue';
import use from '../../_shared/compositionApi';
import BaseInput from '../../_shared/components/inputs/BaseInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StateSnooze',
  emits: ['closeStateSnooze'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { translate } = use.helpers();
const currentInteractionStore = useCurrentInteractionStore();
const { currentInteraction, iconUrl, initialInteraction } = storeToRefs(currentInteractionStore);

const cancelStateSnooze = () => {
  emit('closeStateSnooze', false);
};
const timeOptions = [
  { id: 'fifteen-mins', text: '15 mins', value: 15 },
  { id: 'thirty-mins', text: '30 mins', value: 30 },
  { id: 'fourty-five-mins', text: '45 mins', value: 45 },
  { id: 'one-hour', text: `1 ${translate('common.hour')}`, value: 60 },
  { id: 'two-hours', text: `2 ${translate('common.hours')}`, value: 120 },
];
const snoozeJustification = ref(currentInteraction.value?.notePublic || '');

const snoozeTime = ref(15);

const saveInteraction = () => {
  if (!currentInteraction.value) return;
  if (initialInteraction.value.startAt) {
    currentInteraction.value.startAt = setNewDate(initialInteraction.value.startAt, snoozeTime);
  }
  if (initialInteraction.value.finishAt) {
    currentInteraction.value.finishAt = setNewDate(initialInteraction.value.finishAt, snoozeTime);
  }
  currentInteraction.value.notePublic = snoozeJustification.value;

  // TODO: reset data points if interaction has any (DataPointParameterUntils)
  emit('closeStateSnooze', true);
};

const setNewDate = (dateString: string, minutes: Ref<number>) => new Date(new Date(dateString).getTime() + minutes.value * 60000).toString();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCard, {
    headerBackground: true,
    padding: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(BaseArrowLeft, { onClicked: cancelStateSnooze }),
        _createElementVNode("img", {
          src: _unref(iconUrl),
          class: "v-service-icon-small",
          alt: `${_unref(iconUrl)} icon`
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)("common.snooze")) + " - " + _toDisplayString(_unref(currentInteraction).name), 1)
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "nr-icon-snooze-circle v-state-snooze-icon" }, null, -1)),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(translate)("common.snooze")) + " " + _toDisplayString(_unref(translate)("common.for")) + "...", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(timeOptions, (option) => {
              return _createElementVNode("div", {
                class: "v-radio",
                key: option.id
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: option.id,
                  value: option.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((snoozeTime).value = $event)),
                  class: "v-radio-input"
                }, null, 8, _hoisted_10), [
                  [_vModelRadio, snoozeTime.value]
                ]),
                _createElementVNode("label", {
                  for: option.id,
                  class: "v-radio-label"
                }, _toDisplayString(option.text), 9, _hoisted_11)
              ])
            }), 64))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(BaseNote, {
            title: _unref(translate)('timeline.interaction.cancelJustification')
          }, {
            mainContent: _withCtx(() => [
              _createVNode(BaseInput, {
                textarea: "",
                transparent: "",
                placeholder: _unref(translate)('timeline.interaction.notePlaceholder'),
                modelValue: snoozeJustification.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((snoozeJustification).value = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ]),
            _: 1
          }, 8, ["title"])
        ])
      ]),
      _createVNode(SubmitButtons, {
        onSaveClicked: saveInteraction,
        onBackClicked: cancelStateSnooze,
        "show-back-button": true
      })
    ]),
    _: 1
  }))
}
}

})