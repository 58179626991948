import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-log-table" }

import { PropType } from 'vue';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import { InteractionLogTableData } from '@/timeline/types/InteractionLogData';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogTable',
  props: {
  data: {
    type: Object as PropType<InteractionLogTableData>,
    required: true,
  },
  darkTheme: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseTable, {
      headers: __props.data.headers,
      items: __props.data.entries,
      editable: false,
      cellCentered: true,
      darkTheme: __props.darkTheme
    }, null, 8, ["headers", "items", "darkTheme"])
  ]))
}
}

})