
import {
  defineComponent, ref, computed, PropType,
} from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { DaySchedule } from '../types/availability';
import AvailabilityEditModal from './AvailabilityEditModal.vue';

export default defineComponent({
  props: {
    day: {
      type: Object as PropType<DaySchedule>,
      required: true,
    },
  },
  emits: ['saveDay'],
  components: {
    BaseIcon,
    AvailabilityEditModal,
  },
  setup(props, context) {
    const cornerClass = computed(() => {
      const { available, flexible } = props.day;
      if (!available && flexible) { return 'v-flexible'; }
      return available ? 'v-available' : 'v-unavailable';
    });

    const showEdit = ref(false);

    const openEditModal = () => {
      showEdit.value = true;
    };

    const closeEditModal = () => {
      showEdit.value = false;
    };

    const saveDay = (day: DaySchedule) => {
      context.emit('saveDay', day);
      closeEditModal();
    };

    return {
      cornerClass,
      showEdit,
      saveDay,
      openEditModal,
      closeEditModal,
    };
  },
});
