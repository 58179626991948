import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import BaseSearchableList from '@/_shared/components/general/BaseSearchableList.vue';
import useServiceFiltering from '@/_shared/store/serviceFiltering';
import { computed } from 'vue';
import { SelectorSection, SelectorItem } from '@/_shared/types/baseSearchableList';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssessmentsSelector',
  setup(__props) {


const route = useRoute();
const router = useRouter();
const { translate } = use.helpers();
const serviceFilteringStore = useServiceFiltering();
const { filteredNonAdhocProvidedServices } = storeToRefs(serviceFilteringStore);

const sections = computed<SelectorSection[]>(() => {
  const items: SelectorItem[] = [];

  if (filteredNonAdhocProvidedServices && filteredNonAdhocProvidedServices.value) {
    items.push(...filteredNonAdhocProvidedServices.value.map((providedService) => ({
      name: providedService.name,
      callback: () => router.push(newAssessmentLink(providedService.serviceId)),
      key: providedService.id,
    })));
  }

  return [{
    header: translate('assessment.current_state'),
    searchable: true,
    items,
  }];
});

const newAssessmentLink = (serviceId: number) => ({
  name: 'client.assessments.interaction_new',
  query: { ...route.query, serviceId: +serviceId },
});

const onClose = () => {
  router.push({
    name: 'client.assessments',
    query: route.query,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { class: "v-service-selector-form" }, {
    default: _withCtx(() => [
      _createVNode(BaseSearchableList, {
        title: _unref(translate)('assessment.selector_header'),
        sections: sections.value,
        closeCallback: onClose
      }, null, 8, ["title", "sections"])
    ]),
    _: 1
  }))
}
}

})