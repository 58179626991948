import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-person-picker-parameter-container" }

import { clientsRelationsStore } from '@/_shared/store/Relation';
import { CompositeOption, OptGroup } from '@/_shared/types/baseSelect';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { computed, ComputedRef, ref } from 'vue';
import { carerStore, Carer } from '@/_shared/store/carers';
import use from '@/_shared/compositionApi';
import AdvancedMultiselect from './AdvancedMultiselect/AdvancedMultiselect.vue';
import { CoercedPersonPicker, Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonPickerParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const { clientId } = useCurrentInteractionStore().currentInteraction;

const selectorType: 'multi' | 'single' = props.parameter.isArray ? 'multi' : 'single';

const coercedValue = useCurrentInteractionStore().getCoercedValueByParameterId(Number(props.parameter.id));

const existingCarerIds = ref<string[]>([]);
const activeCarers = carerStore.active(window.currentOrganisationUnit.id as number).value;

const peopleOptions: ComputedRef<OptGroup[]> = computed((): OptGroup[] => {
  const personPickerType = props.parameter.config?.personPickerType;
  if (personPickerType === 'circle_of_care') {
    return clientsRelationsStore.getRelationsAsOptions(clientId).value as OptGroup[];
  }
  let allCarers: Carer[] = [];
  if (personPickerType === 'carers') {
    if (coercedValue.value) {
      ((coercedValue.value as CoercedPersonPicker).values as string[]).forEach((value) => existingCarerIds.value.push(value));
    }
    allCarers = carerStore.carers([...new Set([...existingCarerIds.value, ...activeCarers.map((c) => c.id)].map((x) => Number(x)))]);
  }
  if (allCarers) {
    const tempOptions: OptGroup[] = [];

    if (allCarers.length) {
      tempOptions.push({
        optGroup: translate('common.all_options'),
        options: convertCarersToOptions(allCarers),
      });
    }
    return tempOptions;
  }
  return [];
});

const valuesNotInOptions = computed(() => existingCarerIds.value.filter((id) => !activeCarers.map((c) => String(c.id)).includes(id)).map((id) => String(id)));

const convertCarersToOptions = (carers: Carer[]): CompositeOption[] => carers.map((carer: Carer) => ({
  value: `${carer.id}`,
  text: formatCarerName(carer, carer.currentOrganisationUnitState),
  photo: carer.photo,
  state: carer.currentOrganisationUnitState || 'archived',
}));

function formatCarerName(carer: Carer, state: string | undefined = undefined) {
  let formattedValue = carer.name;
  if (carer.organisationRoleName) {
    formattedValue = `${formattedValue} - ${carer.organisationRoleName}`;
  }
  if (state) {
    formattedValue = `${formattedValue} (${translate(`common.${state}`)})`;
  }
  return formattedValue;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AdvancedMultiselect, {
      parameter: __props.parameter,
      readOnly: __props.readOnly,
      selectorType: _unref(selectorType),
      options: peopleOptions.value,
      "values-not-in-options": valuesNotInOptions.value
    }, null, 8, ["parameter", "readOnly", "selectorType", "options", "values-not-in-options"])
  ]))
}
}

})