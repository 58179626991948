import apiClient from '@/_shared/services/apiClient';
import { fetchLatestCareplanAgreement } from '../../timeline/services/interactionsApi';

export const fetchServiceIdByCodename = async (codename: string): Promise<number | null> => {
  const url = '/api/v2/services/codename';
  try {
    const response = await apiClient.get(url, { params: { codename } });
    return response as number;
  } catch (errorsResponse) {
    return null;
  }
};

export const checkIfInteractionExists = async (clientId: number): Promise<boolean> => {
  try {
    const response = await fetchLatestCareplanAgreement(clientId);
    return !!response;
  } catch (errorsResponse) {
    return false;
  }
};
