import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "london-care-record-main-panel" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "lcr-banner"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src", "onload"]
const _hoisted_6 = { key: 3 }

import {
  Ref, ref, computed, onMounted, onBeforeUnmount, onBeforeMount,
} from 'vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import LondonCareRecordTos from './LondonCareRecordTos.vue';
import LondonCareRecordError from './LondonCareRecordError.vue';
import LondonCareRecordLoading from './LondonCareRecordLoading.vue';
import { tosSessionKey } from '../models';
import { fetchLondonCareRecord } from '../services/LondonCareRecordApi';
import { LondonCareRecordResponse } from '../types/london_care_record';


export default /*@__PURE__*/_defineComponent({
  __name: 'LondonCareRecordMainPanel',
  props: {
  clientId: {
    type: [Number, String],
    required: true,
  },
},
  setup(__props) {

onBeforeUnmount(() => {
  tosConfirmed.value = false;
});

onMounted(async () => {
  await checkTermsAndLoad();
  window.onresize = resizeIframe;
  resizeIframe();
});

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('medical_record_integrations/london_care_record'); // TODO WHEN PURE VUE remove
});

const props = __props;

const displayLoading = ref<boolean>(true);
const response: Ref<LondonCareRecordResponse | null> = ref(null);
const { clientStore } = use.store.clients();

const clientName = computed(() => clientStore.name(+props.clientId));
const lcrIframe: Ref<HTMLIFrameElement | null> = ref(null);

const wipeSessionStorage = async () => {
  window.localStorage.removeItem(tosSessionKey);
};

window.addEventListener('beforeunload', wipeSessionStorage);

const tosConfirmed = ref<boolean>(false);

const checkTermsAndLoad = async () => {
  const storageValue = window.localStorage.getItem(tosSessionKey);
  if (storageValue !== null) {
    tosConfirmed.value = true;
    response.value = await fetchLondonCareRecord({ clientId: +props.clientId }) as LondonCareRecordResponse;
    displayLoading.value = false;
    setTimeout(() => {
      resizeIframe();
    }, 1000);
  }
};

const resizeIframe = () => {
  if (lcrIframe.value) {
    lcrIframe.value.style.height = `${document.getElementsByClassName('page-container')[0]?.scrollHeight}px`;
    lcrIframe.value.style.width = `${document.getElementsByClassName('page-container')[0]?.scrollWidth}px`;
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!tosConfirmed.value)
      ? (_openBlock(), _createBlock(LondonCareRecordTos, {
          key: 0,
          clientName: clientName.value,
          onConfirmTos: checkTermsAndLoad
        }, null, 8, ["clientName"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!displayLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "lcr-logo" }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (displayLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createVNode(LondonCareRecordLoading, { class: "lcr-loading" })
                ])
              ]))
            : (response.value?.url)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 2,
                  ref_key: "lcrIframe",
                  ref: lcrIframe,
                  title: "London care record details",
                  src: response.value.url,
                  onload: resizeIframe()
                }, null, 8, _hoisted_5))
              : (response.value?.error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(LondonCareRecordError, {
                      class: "lcr-error",
                      errorMessage: response.value.error
                    }, null, 8, ["errorMessage"])
                  ]))
                : _createCommentVNode("", true)
        ]))
  ]))
}
}

})