import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

import {
  computed, onBeforeMount, onUnmounted, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { vBaseTooltip } from '@/_shared/directives/';
import AddClientDropdownMenu from '@/clients/components/AddClientDropdownMenu.vue';
import BaseCircleButton from '@/_shared/components/buttons/BaseCircleButton.vue';
import use from '@/_shared/compositionApi';
import useUserStore from '@/_shared/store/user';
import vClickOutside from '@/_shared/directives/ClickOutside';
import { isMobileView, resizeHandler } from '@/_shared/helpers/mobileViewHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientPlusButton',
  setup(__props) {

const { translate } = use.helpers();
const route = useRoute();
const showAddClientMenu = ref(false);

const toggleAddClientMenu = (event: Event) => {
  if ((event.target as HTMLElement).className?.includes('v-dropdown-section-title')) return;
  showAddClientMenu.value = !showAddClientMenu.value;
};

const closeAddClientMenu = () => {
  showAddClientMenu.value = false;
};

const userHasPermission = () => useUserStore().userHasPermission('manageClients');
const hideAddButtonFT = useUserStore().featureToggles.hidePlusButtonToAddResidents;
const hideButton = ref(!userHasPermission() || hideAddButtonFT);
watch(() => route.name, (name) => {
  if (name === 'clientList') hideButton.value = !userHasPermission() || hideAddButtonFT;
});

const tooltipPosition = computed(() => (isMobileView.value ? 'top' : 'bottom'));

const plusButtonLeftPosition = computed(() => (
  // page width - 55px button width - 65px right space - 56px sidebar width
  isMobileView.value ? 'auto' : 'calc(100vw - 55px - 65px - 56px)'
));

window.addEventListener('resize', resizeHandler);

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler);
});

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('clients'); // TODO: remove when pure vue
});

return (_ctx: any,_cache: any) => {
  return (!hideButton.value)
    ? _withDirectives((_openBlock(), _createBlock(BaseCircleButton, {
        key: 0,
        class: "sp-add-button",
        top: "19px",
        right: "auto",
        left: plusButtonLeftPosition.value,
        position: "absolute",
        onClick: _withModifiers(toggleAddClientMenu, ["stop"]),
        active: showAddClientMenu.value
      }, {
        default: _withCtx(() => [
          _createVNode(AddClientDropdownMenu, {
            modelValue: hideButton.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hideButton).value = $event)),
            show: showAddClientMenu.value,
            isMobileView: _unref(isMobileView)
          }, null, 8, ["modelValue", "show", "isMobileView"])
        ]),
        _: 1
      }, 8, ["left", "active"])), [
        [_unref(vBaseTooltip), {tooltipText: _unref(translate)('client.add_new'), position: tooltipPosition.value, hide: showAddClientMenu.value}],
        [_unref(vClickOutside), closeAddClientMenu]
      ])
    : _createCommentVNode("", true)
}
}

})