import apiClient from '@/_shared/services/apiClient';
import { DatasetTranslation, DatasetTranslationItem } from '@/timeline/types/DatasetTranslation';
import i18n from './i18n';

const loadedFiles = ['common-en'];

export async function loadModuleLanguageFileAsync(module: string) {
  const lang = i18n.global.locale.value;
  await loadFileIfNotLoaded(lang, module);
}

const loadFileIfNotLoaded = async (lang: string, module: string) => {
  const fileKey = `${module}-${lang}`;
  if (!loadedFiles.includes(fileKey)) {
    const currentLocaleMessages = i18n.global.getLocaleMessage(lang);
    // TODO WHEN PURE VUE tidy up the import path
    try {
      const messages = await import(`../../${module}/translations/${lang}.json`);
      i18n.global.setLocaleMessage(lang, { ...currentLocaleMessages, ...messages });
    } catch {
      console.info(`translation file for ${module} with language ${lang} does not exist`);
    } finally {
      loadedFiles.push(fileKey);
      const fallbackLang = i18n.global.fallbackLocale.value as string;
      if (lang !== fallbackLang) await loadFileIfNotLoaded(fallbackLang, module);
    }
  }
};

export async function loadTranslationsAsync(lang = i18n.global.locale.value) {
  const languageKey = `dynamic-${lang}`;
  if (!loadedFiles.includes(languageKey)) {
    try {
      const datasetTranslations = await fetchAllDatasetTranslations(lang);
      i18n.global.mergeLocaleMessage(datasetTranslations.language, { datasets: datasetTranslations.translations });
    } catch {
      console.error(`Failed to load translations for ${lang}`);
    } finally {
      loadedFiles.push(languageKey);
    }
  }
}

type DatasetTranslationApiResponse = {
  language: string;
  translations?: DatasetTranslationItem[];
}

const fetchAllDatasetTranslations = async (lang: string): Promise<DatasetTranslation> => {
  const url = '/api/v2/data_sets/translations';
  const nullObject = { language: lang, translations: {} } as DatasetTranslation;
  try {
    const response = (await apiClient.get<DatasetTranslationApiResponse>(url));
    if (!response) {
      return nullObject;
    }
    const translationKVP: Record<string, string> = {};
    response.translations?.forEach((translation) => {
      translationKVP[translation.codename] = translation.value;
    });
    return { language: response.language, translations: translationKVP };
  } catch (error) {
    return nullObject;
  }
};
