import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-signature-consent-container" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-signature-consent-title" }
const _hoisted_5 = { class: "v-header-right" }
const _hoisted_6 = {
  key: 1,
  class: "v-empty-message"
}
const _hoisted_7 = {
  key: 2,
  class: "v-empty-message"
}

import { onBeforeMount, ref, computed } from 'vue';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import use from '../../_shared/compositionApi';
import BaseCard from '../../_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';
import {
  fetchServiceIdByCodename,
  checkIfInteractionExists,
} from '../services/agreementHistoryApi';
import ServiceLog from '../../_shared/components/feature/ServiceLog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgreementHistory',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  carePlanTerm: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const { clientStore } = use.store.clients();
const serviceId = ref<number | null>(null);
const interactionExists = ref(false);
const userStore = useUserStore();
const { permissions } = storeToRefs(userStore);
const permissionToViewInteraction = ref(false);
const clientName = clientStore.firstName(props.clientId);
const loading = ref(true);

onBeforeMount(async () => {
  serviceId.value = await fetchServiceIdByCodename('consent_care_plan_signature');
  interactionExists.value = await checkIfInteractionExists(props.clientId);
  permissionToViewInteraction.value = permissions.value.readableServiceIds.includes(serviceId.value as number);
  loading.value = false;
});

const showData = computed(() => interactionExists.value && permissionToViewInteraction.value);
const showEmptyMessage = computed(() => (!interactionExists.value || !serviceId.value) && permissionToViewInteraction.value);

const print = () => {
  window.print();
  return false;
};

return (_ctx: any,_cache: any) => {
  const _directive_base_tooltip = _resolveDirective("base-tooltip")!

  return (!loading.value)
    ? (_openBlock(), _createBlock(BaseCard, {
        key: 0,
        headerBackground: true,
        padding: true
      }, _createSlots({
        content: _withCtx(() => [
          (showData.value && serviceId.value)
            ? (_openBlock(), _createBlock(ServiceLog, {
                key: 0,
                clientId: __props.clientId,
                serviceId: serviceId.value,
                showLastInteraction: true,
                darkTheme: true,
                forCarePlanConsent: true,
                noDataMessage: _unref(translate)('careplan.no_interactions', { care_plan_term: __props.carePlanTerm })
              }, null, 8, ["clientId", "serviceId", "noDataMessage"]))
            : (showEmptyMessage.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(translate)("careplan.empty_message", {
            care_plan_term: __props.carePlanTerm,
            client_name: _unref(clientName),
          })), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(translate)("careplan.no_permission")), 1))
        ]),
        _: 2
      }, [
        (showData.value)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(BaseIcon, {
                        name: "round-icon nsx-font-icon--care_plan_signature",
                        size: "2x",
                        noHover: "",
                        compact: "",
                        backgroundColor: "black",
                        foregroundColor: "white",
                        foregroundHoverColor: "white",
                        class: "v-service-icon"
                      }),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)(`careplan.agreement_history_title`, {
                  care_plan_term: __props.carePlanTerm,
                })), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _withDirectives(_createVNode(BaseIcon, {
                        name: "print",
                        onClick: print,
                        size: "sm",
                        transparent: "",
                        "foreground-hover-color": "blue",
                        "foreground-color": "black"
                      }, null, 512), [
                        [_directive_base_tooltip, { position: 'top', tooltipText: 'Print' }]
                      ])
                    ])
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024))
    : _createCommentVNode("", true)
}
}

})