import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-text" }

import { onBeforeMount, PropType } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { IntegrationType } from '@/integrations/IntegrationType';
import Integration from '../../types/integration';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationDisableModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  type: {
    type: String as PropType<IntegrationType>,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: false,
  },
},
  emits: ['confirmDisableIntegration', 'closeIntegrationDisableModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const warningMessage = () => {
  let message = `${translate('integrations.delete_warning')} ${props.integration.name} ${translate('integrations.delete_warning_2')} `;
  if (props.type === 'organisation') {
    message += `${translate('integrations.delete_warning_all')} ${props.ou?.organisationName}.`;
    return message;
  }
  message += `${props.ou?.name}.`;
  return message;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: _cache[0] || (_cache[0] = ($event: any) => (emit('confirmDisableIntegration'))),
    onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (emit('closeIntegrationDisableModal')))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(translate)("integrations.warning")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(warningMessage()), 1)
      ])
    ]),
    _: 1
  }))
}
}

})