import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, PropType } from 'vue';
import { CoercedChoice, Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterScore',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  choice: {
    type: [Number, String, null] as PropType<number | string | null>,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const getParameterValue = () => {
  const coercedChoice = props.parameter.coercedValue as CoercedChoice;
  if (coercedChoice && coercedChoice.value !== null && coercedChoice.value !== undefined) {
    const value = coercedChoice.value as number | string;
    return value === '' ? '' : parseFloat(value.toString());
  }
  return props.parameter.coercedValue as number;
};

const choiceIsPresent = props.choice !== null && props.choice !== undefined;

const score = computed(() => {
  if (choiceIsPresent) {
    return props.choice === '' ? props.choice : parseFloat(props.choice.toString());
  }
  return getParameterValue();
});

function getTotalScoreStyle() {
  const currentCoercedChoices = props.parameter.coercedValue as CoercedChoice;
  if (currentCoercedChoices && currentCoercedChoices.options) {
    return currentCoercedChoices.options.style ? currentCoercedChoices.options.style : 'default';
  }
  return '';
}

function getColorFromScore() {
  switch (score.value) {
    case (null || undefined):
      return '';
    case (0):
      return 'v-round-label-default';
    case (1):
      return 'v-round-label-minor';
    case (2):
      return 'v-round-label-warning';
    default:
      return 'v-round-label-danger';
  }
}

function isTotalScore() {
  return props.parameter.isComputed;
}

function isNotAssessment() {
  return !props.parameter.codename;
}

const additionalClasses = computed(() => {
  if (isTotalScore()) {
    return `v-total-score v-total-score-${getTotalScoreStyle()}`;
  }
  if (isNotAssessment()) {
    return 'v-round-label-default';
  }
  return getColorFromScore();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["v-parameter-score", additionalClasses.value])
  }, _toDisplayString(score.value), 3))
}
}

})