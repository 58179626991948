import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseNote from '@/_shared/components/inputs/BaseNote.vue';
import ClosedAlarmInfo from '@/timeline/components/ClosedAlarmInfo.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ClosedAlarmNote',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseNote, {
    title: "Warning Close Note",
    "icon-name": "nr-icon-close-alarm",
    iconCssStyle: "v-close-alarm-icon"
  }, {
    mainContent: _withCtx(() => [
      _createVNode(ClosedAlarmInfo)
    ]),
    _: 1
  }))
}
}

})