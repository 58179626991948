import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import { RouteAuthContext } from '@/_shared/router/types';
import InteractionsRightPanelMain from '@/timeline/InteractionsRightPanelMain.vue';

export const handoverRoutes = [
  {
    path: '/handovers',
    name: 'handovers',
    meta: { isAngular: true },
    components: {
      TopBar: BasePlaceHolderView,
    },
    children: [
      {
        path: 'interaction',
        components: {
          Action: BasePlaceHolderView,
          SideBar: BasePlaceHolderView,
        },
        children: [
          {
            path: ':id(\\d+)',
            name: 'handovers.interaction',
            component: InteractionsRightPanelMain,
            props: true,
            meta: { isAngular: false },
          },
        ],
      },
    ],
  },
];

export const allowedHandoverRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];
  if (authContext.userHasPermission('viewHandovers')) {
    allowedRoutes.push(
      'handovers',
      'handovers.interaction',
    );
  }
  return allowedRoutes;
};
