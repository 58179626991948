
import { defineComponent, PropType, ref } from 'vue';
import AvailabilityDay from '@/professional_info/components/AvailabilityDay.vue';
import use from '@/_shared/compositionApi';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import BaseTableRow from '@/_shared/types/baseTableRow';
import { WeekSchedule, DaySchedule } from '../types/availability';

export default defineComponent({
  components: { AvailabilityDay, BaseTable },
  props: {
    weekDays: {
      type: Object as PropType<WeekSchedule>,
      required: true,
    },
  },
  setup(props, context) {
    const { translate } = use.helpers();

    const days = [
      { displayName: translate('professional_info.availability.monday'), keyName: 'monday' },
      { displayName: translate('professional_info.availability.tuesday'), keyName: 'tuesday' },
      { displayName: translate('professional_info.availability.wednesday'), keyName: 'wednesday' },
      { displayName: translate('professional_info.availability.thursday'), keyName: 'thursday' },
      { displayName: translate('professional_info.availability.friday'), keyName: 'friday' },
      { displayName: translate('professional_info.availability.saturday'), keyName: 'saturday' },
      { displayName: translate('professional_info.availability.sunday'), keyName: 'sunday' },
    ];

    const localWeek = ref(props.weekDays);
    const saveDay = (day: DaySchedule, slotProps: BaseTableRow) => {
      localWeek.value[slotProps.key as keyof WeekSchedule] = day;
      context.emit('update:weekDays', localWeek);
    };
    const weeks = ref([localWeek.value as unknown as BaseTableRow]);
    return { days, weeks, saveDay };
  },
});
