import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-care-plan-review-item-photo" }
const _hoisted_2 = { class: "v-care-plan-review-item-date" }
const _hoisted_3 = { class: "v-care-plan-review-item-text" }
const _hoisted_4 = { class: "v-care-plan-review-item-date" }
const _hoisted_5 = { class: "v-care-plan-review-item-text" }
const _hoisted_6 = { class: "v-care-plan-review-item-description" }
const _hoisted_7 = { class: "v-care-plan-review-item-actions" }

import { computed, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { NeedReview } from '@/timeline/types/Need';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { formatDate, formatTime, getResponsiblePeople } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'PreviousReviewItem',
  props: {
  review: {
    type: Object as PropType<NeedReview>,
    required: true,
  },
},
  emits: ['showVersion'],
  setup(__props, { emit: __emit }) {

const router = useRouter();

const props = __props;

const reviewDate = computed(() => formatDate(props.review.finishAt));

const reviewTime = computed(() => formatTime(props.review.finishAt));

const responsiblePeople = getResponsiblePeople(props.review.responsiblePersonIds).map((p) => p.photo);

const navigateToInteraction = () => {
  router.push({
    name: 'client.timeline.needinteraction',
    params: {
      clientId: props.review.clientId,
      id: props.review.id,
      date: props.review.finishAt,
    },
  });
};

const showVersion = () => {
  emit('showVersion', props.review.id);
};

const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-care-plan-review-item", __props.review.state])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BaseAvatar, {
        size: "small",
        photo: _unref(responsiblePeople)
      }, null, 8, ["photo"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseIcon, {
        name: "nr-icon-calendar",
        size: "sm",
        compact: "",
        noHover: ""
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(reviewDate.value), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(BaseIcon, {
        name: "nr-icon-watch",
        size: "sm",
        compact: "",
        noHover: ""
      }),
      _createElementVNode("span", _hoisted_5, _toDisplayString(reviewTime.value), 1)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(__props.review.notePublic), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(BaseIcon, {
        name: "ncp-careplan",
        onClick: showVersion
      }),
      _createVNode(BaseIcon, {
        name: "nr-icon-watch",
        onClick: navigateToInteraction
      })
    ])
  ], 2))
}
}

})