import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "error" }

import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LondonCareRecordError',
  props: {
    errorMessage: {}
  },
  setup(__props: any) {

const { translate } = use.helpers();
const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCard, null, {
    header: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(translate)("london_care_record.error_message")), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(props.errorMessage), 1)
    ]),
    _: 1
  }))
}
}

})