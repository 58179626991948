import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  ref, watchEffect,
} from 'vue';
import use from '@/_shared/compositionApi';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import { CertificationTableRow } from './types/certification';
import {
  createCertification,
  fetchCertifications,
  updateCertification,
} from './services/certificationsApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'CertificationTableContainer',
  props: {
  section: {
    type: String,
    required: true,
  },
  carerId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { toast, ToastType, translate } = use.helpers();

const certifications = ref<CertificationTableRow[]>([]);
const headers = [
  {
    keyName: 'description',
    displayName: 'Description',
    type: 'string',
  },
  {
    keyName: 'date',
    displayName: 'Date',
    type: 'date',
  },
  {
    keyName: 'review',
    displayName: 'Review',
    type: 'date',
  },
  {
    keyName: 'observations',
    displayName: 'Observations',
    type: 'string',
  },
];

const fetch = async () => {
  // TODO remove below 3 lines when pure vue
  let { section } = props;
  if (Array.isArray(props.section)) {
    [section] = props.section;
  }
  let certificationsTemp = [] as CertificationTableRow[];
  if (section === 'qualifications') {
    certificationsTemp = await fetchCertifications(+props.carerId, 'qualifications');
  } else if (section === 'training') {
    certificationsTemp = await fetchCertifications(+props.carerId, 'training');
  }
  certifications.value = certificationsTemp.filter(
    (item: CertificationTableRow) => !item.archived,
  );
};

watchEffect(async () => {
  await fetch();
});

const createItem = async (item: CertificationTableRow) => {
  const resp:string|number|null|undefined = await createCertification(
    props.carerId,
    item,
    props.section,
  );
  if (resp) {
    toast(translate('ajax.notices.save_success'), ToastType.Success);
    item.id = resp;
  }
};

const updateItem = async (item: CertificationTableRow) => {
  const resp: boolean = await updateCertification(
    props.carerId,
    item,
    props.section,
  );
  filterCertifications();
  if (resp) {
    toast(translate('ajax.notices.save_success'), ToastType.Success);
  }
};

const filterCertifications = () => {
  certifications.value = certifications.value.filter(
    (item: CertificationTableRow) => !item.archived,
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseTable, {
    headers: headers,
    items: certifications.value,
    "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((certifications).value = $event)),
    editable: true,
    onCreateItem: createItem,
    onUpdateItem: updateItem
  }, null, 8, ["items"]))
}
}

})