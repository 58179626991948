import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-capture-signature-container" }
const _hoisted_2 = { class: "v-reset" }

import {
  ref, watch, reactive,
} from 'vue';
import use from '@/_shared/compositionApi';
import { useDebounceFn } from '@vueuse/core';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import VueSignaturePadWrapper
  from '@/timeline/interaction_parameters/SignatureParameter/VueSignaturePadWrapper.vue';
import vBaseTooltip from '@/_shared/directives/BaseTooltip';


export default /*@__PURE__*/_defineComponent({
  __name: 'CaptureSignature',
  props: {
  resetSignature: {
    type: Boolean,
    default: false,
  },
},
  emits: ['base64File', 'signatureReset', 'pixelCount'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { translate } = use.helpers();

const emit = __emit;

const signatureComponent = ref<typeof VueSignaturePadWrapper | null>(null);
const signatureBase64File = ref<string | null>(null);
const pixelCount = ref<number>(0);

const state = reactive({
  count: 0,
  option: {
    backgroundColor: 'rgba(0,0,0,0)',
    penColor: 'black',
  },
  disabled: false,
});

const onDrawEnd = useDebounceFn(() => {
  const canvas = signatureComponent.value?.$el?.querySelector('canvas') as HTMLCanvasElement | null;
  if (canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      countNonTransparentPixels(canvas, ctx);
      addGreyBackground(canvas);
      emit('base64File', signatureBase64File.value as string);
      emit('pixelCount', pixelCount.value);
    } else {
      console.error('Canvas context is null');
    }
  } else {
    console.error('Signature component canvas is null');
  }
}, 2000);

const addGreyBackground = (canvas: HTMLCanvasElement) => {
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvas.width;
  tempCanvas.height = canvas.height;
  const tempCtx = tempCanvas.getContext('2d');

  if (tempCtx) {
    tempCtx.fillStyle = '#FBFAF9';
    tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
    tempCtx.drawImage(canvas, 0, 0);
    signatureBase64File.value = tempCanvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
  }
};

const countNonTransparentPixels = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData?.data;
  let count = 0;
  for (let i = 0; i < data!.length; i += 4) {
    const a = data![i + 3];
    if (a !== 0) {
      count += 1;
    }
  }
  pixelCount.value = count;
};

const reset = () => {
  if (signatureComponent.value) {
    signatureComponent.value.clear();
    signatureBase64File.value = null;
    pixelCount.value = 0;
    emit('signatureReset');
    emit('base64File', null);
    emit('pixelCount', 0);
  } else {
    console.error('Signature component is null during reset');
  }
};

watch(() => props.resetSignature, (value) => {
  if (value) {
    reset();
  }
});

window.addEventListener('resize', () => {
  if (signatureComponent.value) {
    reset();
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(VueSignaturePadWrapper, {
      ref_key: "signatureComponent",
      ref: signatureComponent,
      class: "v-signature-component",
      w: "100%",
      h: "150px",
      "sig-option": state.option,
      onEnd: _unref(onDrawEnd)
    }, null, 8, ["sig-option", "onEnd"]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(BaseIcon, {
        name: "nr-icon-audit-trail",
        class: "v-reset-icon",
        size: "2x",
        onClick: reset
      }, null, 512), [
        [_unref(vBaseTooltip), {position: 'bottom', tooltipText: _unref(translate)('timeline.parameters.signature.clear_signature')}]
      ])
    ])
  ]))
}
}

})