import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-base-alert--alert-mask" }
const _hoisted_2 = { class: "v-base-alert--wrapper" }
const _hoisted_3 = { class: "v-base-alert--main" }
const _hoisted_4 = { class: "v-base-alert--icon" }
const _hoisted_5 = { class: "v-base-alert--content" }
const _hoisted_6 = { class: "v-base-alert--title" }
const _hoisted_7 = { class: "v-base-alert--body" }
const _hoisted_8 = { class: "v-base-alert--alert-footer" }

import use from '@/_shared/compositionApi';
import TeleportWrapper from '@/_shared/components/general/TeleportWrapper.vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAlert',
  props: {
  selectPlaceholder: {
    type: String,
    required: false,
  },
  valid: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    required: true,
  },
  dangerAlert: {
    type: Boolean,
    required: false,
  },
  wide: {
    type: Boolean,
    default: false,
  },
  confirmText: {
    type: String,
    required: false,
  },
},
  emits: ['cancelAlert', 'confirmAlert'],
  setup(__props) {

const { translate } = use.helpers();





const selectedValue = ref(0);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TeleportWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["v-base-alert--container", {'v-base-alert-bg-danger': __props.dangerAlert, 'container-normal': !__props.wide, 'container-wide': __props.wide}])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("i", {
                  class: _normalizeClass(`nsx-font-icon--${__props.icon}`)
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "header")
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "body")
                ])
              ]),
              _createElementVNode("i", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelAlert'))),
                class: "v-base-alert--close-btn v-base-alert--clickable nsx-font-icon--cancel"
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _renderSlot(_ctx.$slots, "footer-left", {}, () => [
                  _createElementVNode("span", {
                    class: "v-base-alert--cancel-btn v-base-alert--clickable",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancelAlert')))
                  }, _toDisplayString(_unref(translate)('alert.cancel')), 1)
                ]),
                _renderSlot(_ctx.$slots, "footer-right", {}, () => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["v-base-alert--confirm-btn v-base-alert--clickable", __props.valid ? '' : 'v-base-alert--disabled']),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (__props.valid ? _ctx.$emit('confirmAlert', selectedValue.value) : null))
                  }, _toDisplayString(__props.confirmText || _unref(translate)('alert.confirm')), 3)
                ])
              ])
            ])
          ], 2)
        ])
      ])
    ]),
    _: 3
  }))
}
}

})