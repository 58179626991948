import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-data-message" }

import use from '@/_shared/compositionApi';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CareplanNoData',
  props: {
  lastInteraction: {
    type: Boolean,
    default: false,
  },
  noDataMessage: {
    type: String,
    default: '',
  },
  canReadInteractions: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const message = computed(() => {
  if (props.lastInteraction) { return translate('log.lastInteraction'); }
  if (!props.canReadInteractions) { return translate('log.noReadAccessInteraction'); }
  if (props.noDataMessage) { return props.noDataMessage; }

  return translate('log.noInteractionData');
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(message.value), 1)
  ]))
}
}

})