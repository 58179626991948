import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

import use from '@/_shared/compositionApi';
import { Need } from '@/timeline/types/Need';
import { PropType, ref, watch } from 'vue';
import CarePlanReview from './CarePlanReview.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReviewsWrapper',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  needsEvent: {
    type: Array as PropType<Need[]>,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const needs = ref<Need[]>([]);

const props = __props;

watch(() => props.needsEvent, (event) => {
  needs.value = event;
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#careplan-for-vue" }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(needs.value, (need) => {
      return (_openBlock(), _createElementBlock("div", {
        key: need.id
      }, [
        _createVNode(CarePlanReview, {
          need: need,
          actionText: _unref(translate)('timeline.need.update_care_plan')
        }, null, 8, ["need", "actionText"])
      ]))
    }), 128))
  ]))
}
}

})