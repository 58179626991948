import { defineStore } from 'pinia';
import { Ref, ref, computed } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { AuditLog } from '@/_shared/types/auditLog';
import postAuditLogs, {
  fetchAuditTrailDetails,
  fetchAuthToken,
  TokenResponse,
} from '@/_shared/services/auditTrailApi';

const useAuditTrailStore = defineStore('auditTrail', () => {
  const auditLog: Ref<AuditLog> = ref({} as AuditLog);
  const auditLogs: Ref<AuditLog[]> = ref([] as AuditLog[]);
  const routeTo: Ref<RouteLocationNormalized> = ref({} as RouteLocationNormalized);
  const routeFrom: Ref<RouteLocationNormalized> = ref({} as RouteLocationNormalized);
  const postLogsDetails = ref<[string, string]>(['', '']);
  const tokenResponse: Ref<TokenResponse> = ref({ token: '', expiresAt: 0 });

  const {
    id: organisationUnitId,
    organisation_id: organisationId,
  } = window.currentOrganisationUnit;
  const {
    id: userId,
    is_impersonated: isImpersonated,
    impersonated_by: impersonatedBy,
  } = window.currentPerson;

  const resourceType = computed(() => (routeTo.value.name?.toString().split('.')[0] || '').charAt(0).toUpperCase() + (routeTo.value.name?.toString().split('.')[0] || '').slice(1));

  const logRouteAccess = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    customResourceType = '',
    customResourceId: number | null = null,
  ) => {
    await getPostLogsDetails();
    routeTo.value = to;
    routeFrom.value = from;
    const resourceId = to?.fullPath.split('/')[2] ? parseInt(to.fullPath.split('/')[2], 10) : null;
    const timestamp = Date.now();

    auditLog.value = {
      userId,
      organisationUnitId,
      organisationId,
      eventType: 'RESOURCE_ACCESS',
      eventDetails: {
        deviceDetails: {
          userAgent: navigator.userAgent,
        },
        toPath: to.fullPath,
        fromPath: from.fullPath,
        isImpersonated,
      },
      impersonatedBy,
      timestamp,
      env: window.environment,
      resourceType: customResourceType || resourceType.value,
      resourceId: customResourceId || resourceId,
    };
    setTimeOnPageForLastRouteLog(timestamp);
    if (auditLogs.value.length > 9) {
      await sendAuditLogs();
    }
    auditLogs.value.push(auditLog.value);
  };

  const logInteractionAccess = async (interactionId: number | null, clientId: number) => {
    if ((routeTo.value?.name as string).includes('client.timeline')) return;
    await getPostLogsDetails();
    auditLog.value = {
      userId,
      organisationUnitId,
      organisationId,
      eventType: 'RESOURCE_ACCESS',
      eventDetails: {
        deviceDetails: {
          userAgent: navigator.userAgent,
        },
        isImpersonated,
        clientId,
      },
      impersonatedBy,
      timestamp: Date.now(),
      env: window.environment,
      resourceType: 'Interaction',
      resourceId: interactionId,
    };
    auditLogs.value.push(auditLog.value);
    await logRouteAccess(routeTo.value, routeFrom.value, 'Client', clientId);
  };

  const setTimeOnPageForLastRouteLog = (timestamp: number) => {
    if (auditLogs.value.length === 0) return;
    const previousLog = auditLogs.value.slice().reverse().find((log) => log.resourceType !== 'Interaction');
    const timeDifferenceInSeconds = previousLog ? (timestamp - previousLog.timestamp) / 1000 : undefined;
    if (previousLog && timeDifferenceInSeconds) {
      previousLog.eventDetails.timeOnPage = timeDifferenceInSeconds;
    }
  };

  const getPostLogsDetails = async () => {
    if (!postLogsDetails.value[0] || !postLogsDetails.value[1]) {
      const response = await fetchAuditTrailDetails();
      if (typeof response[0] === 'string' && typeof response[1] === 'string') {
        postLogsDetails.value = response as [string, string];
      }
    }
  };

  window.addEventListener('beforeunload', async (e) => {
    e.preventDefault();
    if (auditLogs.value.length) {
      setTimeOnPageForLastRouteLog(Date.now());
      await sendAuditLogs();
    }
  });

  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'hidden' && auditLogs.value.length) {
      setTimeOnPageForLastRouteLog(Date.now());
      await sendAuditLogs();
    }
  });

  const sendAuditLogs = async () => {
    const date = new Date();
    const unixTimestampSeconds = Math.floor(date.getTime() / 1000);
    if (tokenResponse.value.expiresAt < unixTimestampSeconds || !tokenResponse.value.token) {
      tokenResponse.value = await fetchAuthToken();
    }
    if (tokenResponse.value.expiresAt && tokenResponse.value.token) {
      const response = await postAuditLogs(auditLogs.value, postLogsDetails.value, tokenResponse.value.token);
      if (response?.status === 200) auditLogs.value = [];
    }
  };

  return {
    logRouteAccess,
    logInteractionAccess,
    getPostLogsDetails,
    sendAuditLogs,
    auditLogs,
    postLogsDetails,
    routeTo,
    routeFrom,
  };
});

export default useAuditTrailStore;
