import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  getAllReports,
  getCurrentPersonReports,
  getPages,
} from '@/_shared/services/analyticsApi';
import { AnalyticsReport } from '@/_shared/types/analytics';
import { promiseMemorise } from '@/_shared/services/UseUtils';
import * as pbi from 'powerbi-client';

const useAnalyticsStore = defineStore('analytics', () => {
  const allReports = ref<AnalyticsReport[] | []>([]);
  const currentPersonReports = ref<AnalyticsReport[] | []>([]);
  const embeddedReport = ref<pbi.Report | null>(null);

  const fetchAllReports = async (returnPages = true) => {
    allReports.value = allReports.value.length ? allReports.value : await getAllReportsMemorised();
    if (returnPages) await _fetchReportPages(allReports.value);
  };

  const getAllReportsMemorised = promiseMemorise(getAllReports);

  const fetchCurrentPersonReports = async (returnPages = true) => {
    currentPersonReports.value = currentPersonReports.value.length ? currentPersonReports.value : await getCurrentPersonReportsMemorised();
    if (returnPages) await _fetchReportPages(currentPersonReports.value);
  };

  const getCurrentPersonReportsMemorised = promiseMemorise(getCurrentPersonReports);

  const _fetchReportPages = async (reportsArray: AnalyticsReport[]) => {
    const pagePromises = reportsArray
      ?.map(async (report) => getPagesMemorised(report.workspaceId, report.id)) || [];

    const allPages = await Promise.all(pagePromises);
    reportsArray?.forEach((report, index) => {
      report.pages = allPages[index];
    });
  };

  const getPagesMemorised = promiseMemorise(getPages, 5 * 60 * 1000);

  const getCategoryInfo = (categoryId: string) => currentPersonReports.value?.find((report) => report.id === categoryId);

  return {
    allReports,
    currentPersonReports,
    embeddedReport,
    fetchAllReports,
    fetchCurrentPersonReports,
    getCategoryInfo,
  };
});

export default useAnalyticsStore;
