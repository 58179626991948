import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-assign-modal--content-wrapper v-assign-modal-filterable-content" }
const _hoisted_2 = { class: "v-assign-modal--search-wrapper" }
const _hoisted_3 = { class: "v-assign-modal--search-input-field" }
const _hoisted_4 = { class: "v-assign-modal--select-all" }
const _hoisted_5 = { style: {"float":"right"} }
const _hoisted_6 = {
  key: 0,
  class: "v-assign-modal--list-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_AssignModalEntry = _resolveComponent("AssignModalEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseInput, {
          border: true,
          modelValue: _ctx.searchTerm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: _ctx.searchInputPlaceholder,
          onInput: _ctx.filterEntries
        }, null, 8, ["modelValue", "placeholder", "onInput"])
      ]),
      _createVNode(_component_BaseSelect, {
        class: "v-assign-modal--search-dropdown-field",
        modelValue: _ctx.selectedDropdownOption,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDropdownOption) = $event)),
        options: _ctx.selectDropdownOptions,
        border: "",
        required: "",
        searchable: ""
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", {
        class: "v-assign-modal--sp-select-all",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSelectAll && _ctx.toggleSelectAll(...args)))
      }, _toDisplayString(_ctx.selectAllText) + " (" + _toDisplayString(_ctx.filteredSelectableEntries ? _ctx.filteredSelectableEntries.length : 0) + ") ", 1),
      _createElementVNode("span", _hoisted_5, "Selected (" + _toDisplayString(_ctx.selectedEntriesCount) + ")", 1)
    ]),
    (_ctx.selectableEntries)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSelectableEntries, (entry) => {
            return (_openBlock(), _createBlock(_component_AssignModalEntry, {
              key: entry['id'],
              entry: entry,
              onToggleSelectEntry: _ctx.toggleSelectEntry,
              primaryText: _ctx.entryPrimaryText(entry),
              secondaryText: _ctx.entrySecondaryText(entry),
              isSelected: _ctx.entrySelected(entry)
            }, null, 8, ["entry", "onToggleSelectEntry", "primaryText", "secondaryText", "isSelected"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}