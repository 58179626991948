import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-message-entry-body" }
const _hoisted_2 = ["innerHTML"]

import { PropType } from 'vue';
import use from '@/_shared/compositionApi';
import MessageHeader from './MessageHeader.vue';
import { MessageDTO } from '../types/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageBody',
  props: {
  message: {
    type: Object as PropType<MessageDTO>,
    required: true,
  },
},
  setup(__props) {

const { linkify } = use.helpers();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MessageHeader, {
    message: __props.message,
    withBody: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "v-hr" }, null, -1)),
        _createElementVNode("span", {
          class: "regular-font v-message-body",
          innerHTML: _unref(linkify)(__props.message.body || '')
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["message"]))
}
}

})