import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import useUserStore from '@/_shared/store/user';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import Integration from '@/integrations/types/integration';
import {
  fetchCustomerIntegrations,
  fetchIntegrations,
  postNewIntegrationForOu,
  deleteOrgIntegrationApi,
  deleteOuIntegrationApi,
  unsubscribeFromCollection,
  subscribeToCollection,
  fetchAdvancedCollections,
  updateIntegrationForOu,
} from '@/integrations/services/integrationsApi';
import CustomerIntegration from '@/integrations/types/customerIntegration';

export type IntegrationsMapOuter = {
  [codename: string] : IntegrationsMapInner
}

export type IntegrationsMapInner = {
  [ouId: string] : CustomerIntegration
}

const useIntegrationStore = defineStore('integration', () => {
  const integrations = ref<Integration[]>([]);
  const customerIntegrations = ref<CustomerIntegration[]>([]);
  const integrationsMap = ref<IntegrationsMapOuter>({});
  const { currentOrgId, currentUser } = storeToRefs(useUserStore());

  async function fetchAllIntegrations() {
    const allIntegrations : Integration[] = await fetchIntegrations(currentOrgId.value);
    const allAdvancedCollections : Integration[] = await fetchAllAdvancedCollections();
    integrations.value = [allIntegrations, allAdvancedCollections].flat();
    sortIntegrations();
  }

  async function fetchAllAdvancedCollections() {
    return fetchAdvancedCollections(currentOrgId.value).then((response) => {
      if (response) {
        return (response || []);
      }
      return [];
    });
  }

  async function fetchAllCustomerIntegrations() {
    customerIntegrations.value = await fetchCustomerIntegrations(currentOrgId.value).then((response) => (response || []));
  }

  function filterIntegrations(gpConnectEnabled: boolean) {
    integrations.value = gpConnectEnabled ? integrations.value : integrations.value.filter((integration) => integration.integrationCodename !== 'gp_connect');
  }

  function sortIntegrations() {
    integrations.value.sort((i1:Integration, i2:Integration) => (i1!.name! < i2!.name! ? -1 : 1));
  }

  function createEnabledIntegrationsMap() {
    customerIntegrations.value.forEach((integration: CustomerIntegration) => {
      if (integration.integrationCodename && integration.orgUnitId) {
        if (integrationsMap.value[integration.integrationCodename] === undefined) {
          integrationsMap.value[integration.integrationCodename] = {};
        }
        integrationsMap.value[integration.integrationCodename][integration.orgUnitId] = integration;
      }
    });
  }

  async function initialSetup(gpConnectEnabled: boolean) {
    await fetchAllIntegrations();
    await fetchAllCustomerIntegrations();
    filterIntegrations(gpConnectEnabled);
    createEnabledIntegrationsMap();
  }

  async function deleteOrgIntegration(integration: Integration) {
    if (integration.integrationCodename) {
      await deleteOrgIntegrationApi(integration, currentOrgId.value);
      delete integrationsMap.value[integration.integrationCodename];
    }
  }

  async function deleteOuIntegration(integration: Integration, customerIntegration: CustomerIntegration, ouId: number) {
    if (integration.integrationCodename) {
      await deleteOuIntegrationApi(integration, customerIntegration, currentOrgId.value, ouId);
      delete integrationsMap.value[integration.integrationCodename][ouId];
    }
  }

  async function addNewCustomerIntegration(integration: Integration, ouId: number, tokenValue: string) {
    await postNewIntegrationForOu(integration, currentOrgId.value, ouId, tokenValue);
    await fetchAllCustomerIntegrations();
    if (window.Intercom) {
      window.Intercom('trackEvent', `event_organisation_unit_${integration.name}`);
    }
    createEnabledIntegrationsMap();
  }

  async function updateCustomerIntegration(customerIntegration: CustomerIntegration, tokenValue: string) {
    customerIntegration.integrationToken = tokenValue;
    await updateIntegrationForOu(customerIntegration);
  }

  async function subscribeOuToAdvancedCollection(advancedCollection: Integration, ou: OrganisationUnit) {
    advancedCollection.enabled = true;
    advancedCollection.lastSubscribedAt = new Date().toISOString();
    advancedCollection.lastSubscribedBy = currentUser.value.name;
    await subscribeToCollection(advancedCollection.advancedCollectionCodename!, ou.organisationId, ou.id);
  }

  async function unsubscribeOuFromAdvancedCollection(advancedCollection: Integration, ou: OrganisationUnit) {
    advancedCollection.enabled = false;
    await unsubscribeFromCollection(advancedCollection.advancedCollectionCodename!, ou.organisationId, ou.id);
  }

  async function unsubscribeOrgFromAdvancedCollection(advancedCollection: Integration, units: OrganisationUnit[]) {
    const collectionsToDisable : Integration[] = (integrations.value.filter((currentCollection) => currentCollection.enabled && currentCollection.advancedCollectionCodename === advancedCollection.advancedCollectionCodename));
    collectionsToDisable.forEach((collection) => { collection.enabled = false; });
    // maybe use Promise.all + prototype.map
    await Promise.all(collectionsToDisable.map(async (collection) => {
      const collectionUnit = units.filter((ou) => ou.id === collection.orgUnitId)[0];
      await unsubscribeFromCollection(advancedCollection.advancedCollectionCodename!, collectionUnit.organisationId, collectionUnit.id);
    }));
  }

  function isOrgToggleEnabled(codename: string) : boolean {
    return Object.prototype.hasOwnProperty.call(integrationsMap.value, codename) ? Object.keys(integrationsMap.value[codename]).length > 0 : false;
  }
  function isOuToggleEnabled(codename: string, ouId: number) : boolean {
    return Object.prototype.hasOwnProperty.call(integrationsMap.value, codename) && Object.prototype.hasOwnProperty.call(integrationsMap.value[codename], ouId);
  }

  function getCustomerIntegrationForOu(codename: string | undefined, ouId: number): CustomerIntegration|undefined {
    if (codename && Object.prototype.hasOwnProperty.call(integrationsMap.value, codename) && Object.prototype.hasOwnProperty.call(integrationsMap.value[codename], ouId)) {
      return integrationsMap.value[codename][ouId];
    }
    return undefined;
  }

  return {
    integrations,
    customerIntegrations,
    integrationsMap,
    fetchAllIntegrations,
    fetchAllCustomerIntegrations,
    filterIntegrations,
    createEnabledIntegrationsMap,
    initialSetup,
    deleteOrgIntegration,
    deleteOuIntegration,
    addNewCustomerIntegration,
    isOrgToggleEnabled,
    isOuToggleEnabled,
    getCustomerIntegrationForOu,
    unsubscribeOrgFromAdvancedCollection,
    unsubscribeOuFromAdvancedCollection,
    subscribeOuToAdvancedCollection,
    updateCustomerIntegration,
  };
});

export default useIntegrationStore;
