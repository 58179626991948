import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  PropType, ref,
} from 'vue';
import TeleportWrapper from '@/_shared/components/general/TeleportWrapper.vue';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import LeftPanelActionBar from '@/_shared/components/general/left_panel/LeftPanelActionBar.vue';
import { useRoute, useRouter } from 'vue-router';
import { formatISODate } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericLeftPanel',
  props: {
  filterOptions: {
    type: Array as PropType<DropdownOption[]>,
    required: false,
  },
  datePickerDisabled: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  'updatedDate', 'searchInput',
],
  setup(__props, { emit: __emit }) {

// To be used in ServiceCounts/Client/Carer etc.

const props = __props;

const emit = __emit;

const route = useRoute();
const router = useRouter();

// date
const date = ref(route.query.date ? new Date(route.query.date as string) : new Date());

const onUpdatedDate = async (newDate: Date) => {
  if (props.datePickerDisabled || new Date(newDate) === date.value) {
    return;
  }

  date.value = new Date(newDate);
  if (route.query.date) delete route.query.date;

  await router.push({
    query: {
      date: formatISODate(date.value),
      ...route.query,
    },
  });

  emit('updatedDate', date.value);
};

// search
const onSearchInput = (searchInput: string) => {
  emit('searchInput', searchInput);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TeleportWrapper, { to: "#lp-action-bar" }, {
    default: _withCtx(() => [
      _createVNode(LeftPanelActionBar, {
        "filter-options": __props.filterOptions,
        "current-date": date.value,
        "date-picker-disabled": __props.datePickerDisabled,
        onUpdatedDate: onUpdatedDate,
        onSearchTerm: onSearchInput
      }, null, 8, ["filter-options", "current-date", "date-picker-disabled"]),
      _createVNode(TeleportWrapper, { to: "#lp-list-entry" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "list-entry")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})