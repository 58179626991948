import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }
const _hoisted_2 = { class: "v-confirm-tos-container" }
const _hoisted_3 = {
  key: 0,
  class: "v-tos-error"
}

import { onBeforeMount, PropType, ref } from 'vue';
import { BaseAlert, BaseCheckbox } from '@/_shared/components/';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import Integration from '../../types/integration';
import { downloadTos } from '../../helpers/useIntegrationsHelpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationTosModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
},
  emits: ['continueTosEnableModal', 'cancelEnableModal'],
  setup(__props, { emit: __emit }) {



const { translate } = use.helpers();
const showConfirmTosError = ref<boolean>(false);
const tosCheckboxValue = ref<boolean>(false);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const tosCheckboxClicked = () => {
  tosCheckboxValue.value = !tosCheckboxValue.value;
  showConfirmTosError.value = false;
};

const emit = __emit;
const continueFromTos = () => {
  if (tosCheckboxValue.value === true) {
    emit('continueTosEnableModal');
    return;
  }
  showConfirmTosError.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseAlert), {
    class: "v-integration-tos-alert",
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: continueFromTos,
    onCancelAlert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancelEnableModal'))),
    confirmText: _unref(translate)('alert.continue')
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(translate)("integrations.integration_authorisation")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_unref(translate)('integrations.tos_text')) + " ", 1),
          _createElementVNode("u", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(downloadTos)(__props.integration)))
          }, _toDisplayString(_unref(translate)('integrations.tos')) + ".", 1)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", null, [
          _createElementVNode("b", null, [
            _createTextVNode(_toDisplayString(_unref(translate)('integrations.confirm_tos')) + " ", 1),
            _createElementVNode("u", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(downloadTos)(__props.integration)))
            }, _toDisplayString(_unref(translate)('integrations.tos')) + ".", 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(BaseCheckbox), {
          light: "",
          class: "v-confirm-tos",
          checked: tosCheckboxValue.value,
          onClick: tosCheckboxClicked
        }, null, 8, ["checked"])
      ]),
      (showConfirmTosError.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(translate)('integrations.unaccepted_terms')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["confirmText"]))
}
}

})