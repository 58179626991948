import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, watch, watchEffect } from 'vue';

type Duration = {
  hours: number,
  minutes: number,
  seconds: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDurationPicker',
  props: {
  modelValue: {
    type: Object as () => Duration,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const localValue = ref<Duration>(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  localValue.value = newValue;
});

watchEffect(() => emit('update:modelValue', localValue.value)); // todo format date


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Datepicker), {
    class: "v-duration",
    autoApply: "",
    inline: "",
    enableSeconds: "",
    timePicker: "",
    textInput: "",
    noHoursOverlay: "",
    noMinutesOverlay: "",
    noSecondsOverlay: "",
    modeHeight: "100",
    modelValue: localValue.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localValue).value = $event)),
    disabled: __props.readOnly
  }, {
    "action-select": _withCtx(() => _cache[1] || (_cache[1] = [])),
    _: 1
  }, 8, ["modelValue", "disabled"]))
}
}

})