import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';
import { CompositeOption } from '@/_shared/types/baseSelect';
import LocationModalLeftContent
  from '@/timeline/components/BodyMap/LocationDataSetModal/LocationModalLeftContent.vue';
import use from '@/_shared/compositionApi';

const useNourishInstanceParameterHelper = () => {
  const { translate } = use.helpers();
  const buildSearchTermsDictionary = () => {
    const dictionary: Record<string, RegExp> = {};
    const left = translate('body_map.left');
    const right = translate('body_map.right');
    dictionary[`(${left[0].toUpperCase()})`] = buildMatchRegExp(left);
    dictionary[`(${right[0].toUpperCase()})`] = buildMatchRegExp(right);
    return dictionary;

    function splitWord(word: string):string {
      return (word.length > 1 ? `${word[0]}(${splitWord(word.slice(1))})?` : word);
    }
    function buildMatchRegExp(word:string) {
      return new RegExp(`\\b(${splitWord(word)})\\b`);
    }
  };

  const locationModalConfig: DataSetModalConfig = {
    leftContentComponent: LocationModalLeftContent,
    modalTitle: translate('body_map.choose_wound_location'),
    modalSearchInputPlaceholder: translate('body_map.begin_typing_your_search'),
    searchTermsDictionary: buildSearchTermsDictionary(),
    sortOptionsBy: 'text' as keyof CompositeOption,
  };

  const woundModalConfig: DataSetModalConfig = {
    modalTitle: translate('body_map.wound_presentation'),
    modalSearchInputPlaceholder: translate('body_map.begin_typing_your_search'),
    sortOptionsBy: 'text' as keyof CompositeOption,
  };
  return {
    locationModalConfig,
    woundModalConfig,
  };
};

export default useNourishInstanceParameterHelper;
