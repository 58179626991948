import {
  CoercedChoice,
  CoercedMultiSelect,
  CoercedValue,
  CoercedSignature,
  Medication,
  Parameter,
} from '@/timeline/types/Parameter';
import IInteraction from '@/timeline/types/IInteraction';
import addPreferredTermsToText from '@/_shared/services/clientHelpers';
import { format, parseISO } from 'date-fns';
import use from '@/_shared/compositionApi';
import { CompositeOption } from '@/_shared/types/baseSelect';
import { ref } from 'vue';
import { fetchCarersForOrgUnit, fetchRelations } from '@/_shared/services/datasetsApi';
import {
  isPersonPickerParamType,
  formatPicturesValue,
  handleHideEmptyParameters,
  isOldVersionSignatureParameter,
} from '@/timeline/helper/parametersHelper';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';

const { translate } = use.helpers();
const clientRelations = ref<CompositeOption[]>([]);
const personPickerCarers = ref<CompositeOption[]>([]);
const paramValueType = ref<string>('');
const personPickerType = ref<string>('');
const style = 'padding: 2px 0';
const dpStyle = 'margin-top: 10px';

// TODO need refactor, duplicate code with vue/src/timeline/helper/parametersHelper.ts
const getParamName = (param: Parameter, clientId: number): string => {
  if (param.valueType === 'medication') {
    return 'Medication';
  }
  return addPreferredTermsToText(clientId as number, addUnitToParamName(param)).value;
};

const addUnitToParamName = (param: Parameter): string => {
  const unit = param.unit ? ` (${param.unit})` : '';
  return `${param.name}${unit}`;
};

const getParamEntries = (params: Parameter[]) => params.reduce((obj, item) => Object.assign(obj, getParamValue(item)), {});

const getCarersIds = (interactions: IInteraction[]) => {
  const personPickerCarerIds: number[] = [];
  let distinctCarerIds: number[] = [];
  interactions.forEach((interaction) => {
    interaction.parameters.forEach((param) => {
      if (isPersonPickerParamType(param, 'carers') && param.coercedValue) {
        const values = (param.coercedValue as CoercedMultiSelect)?.values || [];
        if (values.length) {
          personPickerCarerIds.push(...values.map(Number));
        }
      }
    });
    distinctCarerIds = [...Array.from(new Set(personPickerCarerIds))];
  });
  return distinctCarerIds;
};

const getParamValue = (param: Parameter) => {
  paramValueType.value = param.valueType;
  if (paramValueType.value === 'checkbox') {
    return param.coercedValue ? { [param.id]: 'Yes' } : { [param.id]: 'No' };
  }
  if (paramValueType.value === 'file') {
    const fileName = param.genericFile?.fileName || param.genericFile?.fileLabel || translate('interaction.no_file_attached');
    return { [param.id]: fileName };
  }
  if (param.coercedValue != null) { // accepts edge case where coercedValue = 0
    if (paramValueType.value === 'medication') {
      return getMedicationParamValue(param);
    }
    if (paramValueType.value === 'date') {
      return { [param.id]: format(parseISO(param.coercedValue as string), 'dd/MM/yyyy') };
    }
    if (paramValueType.value === 'datetime') {
      return { [param.id]: format(parseISO(param.coercedValue as string), 'dd/MM/yyyy - HH:mm') };
    }
    if (paramValueType.value === 'duration') {
      const durationToHours = new Date(param.coercedValue as number * 1000).toISOString().slice(11, 19);
      return { [param.id]: durationToHours };
    }
    if (paramValueType.value === 'number' && param.coercedChoices?.length > 0 && param.isComputed) {
      return getNumericParamValue(param);
    }
    if (['single_selector_search', 'multi', 'combined_multi_search'].includes(paramValueType.value)) {
      return getDataSetValues(param);
    }
    if (paramValueType.value === 'person_picker') {
      personPickerType.value = param.config?.personPickerType || '';
      return getDataSetValues(param);
    }
    if (paramValueType.value === 'picture') {
      return { [param.id]: formatPicturesValue(param) };
    }
    if (paramValueType.value === 'signature') {
      return getSignatureParamValue(param);
    }
    if (param.coercedChoices?.length > 0) {
      if (Array.isArray(param.coercedValue)) {
        return { [param.id]: param.coercedValue.map((cv: NonNullable<CoercedChoice>) => cv.value?.toString()).join(', ') };
      }
      return { [param.id]: (param.coercedValue as CoercedChoice)?.value };
    }
    return { [param.id]: param.coercedValue };
  }
  return { [param.id]: '' };
};

const getMedicationParamValue = (param: Parameter) => {
  const coercedValue = param.coercedValue as Medication;

  let medData = `<p class="sp-medication" style="${style}"><b>${translate('timeline.parameters.medication')}:</b> ${param.name}</p>
                      <p class="sp-medication-qty" style="${style}"><b>${translate('common.quantity')}:</b> ${coercedValue?.quantity}</p>`;
  if (coercedValue.refused) {
    medData += `<p style="${style}"><b>${translate('common.status')}:</b> ${coercedValue?.reason}</p>`;
  }
  if (coercedValue.notes) {
    medData += `<p style="${style}"><b>${translate('common.note')}:</b> ${coercedValue?.notes}</p>`;
  }
  if (coercedValue.administeredBy) {
    medData += `<p style="${style}"><b>${translate('timeline.parameters.administeredBy')}:</b> ${coercedValue?.administeredBy}</p>`;
  }
  if (coercedValue.witnessedBy) {
    medData += `<p style="${style}"><b>${translate('timeline.parameters.witnessedBy')}:</b> ${coercedValue?.witnessedBy}</p>`;
  }
  return {
    [param.valueType]: medData,
  };
};

const getNumericParamValue = (param: Parameter) => {
  let values = '';
  Object.keys(param.coercedValue as CoercedChoice[]).forEach((key) => {
    const value = param.coercedValue![key as keyof CoercedValue];
    if (value != null && Number.isInteger(value)) {
      values += `${value}, `;
    }
  });
  return {
    [param.id]: values.replace(/,\s*$/, ''),
  };
};

const getSignatureParamValue = (param: Parameter) => {
  const {
    isSigningOnBehalf, name, signingAuthority, datetimeSigned,
  } = param.coercedValue as CoercedSignature;
  const signatureValue = `${translate('timeline.parameters.signature.signedBy')} ${name} ${translate('timeline.parameters.signature.on')} ${formatDateTime(datetimeSigned)}`;
  if (!isOldVersionSignatureParameter(param)) {
    return {
      [param.id]: signatureValue,
      [`isSigningOnBehalf${param.id}`]: isSigningOnBehalf,
      [`signingAuthority${param.id}`]: signingAuthority,
    };
  }
  return { [param.id]: signatureValue };
};

const getDataSetValues = (param: Parameter) => {
  const coercedValue = param.coercedValue as CoercedMultiSelect;
  const valuesText = coercedValue.values?.length ? `<p>${assignText(coercedValue.values)}</p>` : '';
  let addedValuesText = '';
  let removedValuesText = '';
  if (coercedValue.addedValues?.length && (param.dataPoint || param.valueType === 'combined_multi_search')) {
    addedValuesText = `<p style="${dpStyle}">Added: ${assignText(coercedValue.addedValues)}</p>`;
  }
  if (coercedValue.removedValues?.length && (param.dataPoint || param.valueType === 'combined_multi_search')) {
    removedValuesText = `<p style="${dpStyle}">Removed: ${assignText(coercedValue.removedValues)}</p>`;
  }
  const answer = coercedValue.answer === 'No' ? `<p>${coercedValue.answer}</p>` : '';
  const combinedValues = `<p style="${style}">${answer + valuesText + addedValuesText + removedValuesText}</p>`;

  return {
    [param.id]: combinedValues,
  };
};

const assignText = (values: string[]) => {
  let valueNames: string[] = [];
  if (paramValueType.value === 'person_picker') {
    const valuesIds = values?.map((str) => parseInt(str, 10));
    if (valuesIds.length && personPickerType.value === 'carers') {
      valueNames = personPickerCarers.value?.filter(
        (carer) => valuesIds?.includes(+carer.value),
      ).map((carer) => carer.text);
    } else if (valuesIds.length && personPickerType.value === 'circle_of_care') {
      valueNames = clientRelations.value?.filter(
        (relation) => valuesIds?.includes(parseInt(<string>relation.value, 10)),
      ).map((relation) => relation.text);
    }
  } else if (['single_selector_search', 'multi', 'combined_multi_search'].includes(paramValueType.value)) {
    valueNames = values;
  }
  return valueNames ? valueNames.join(', ') : '';
};

const getCarersForOrgUnit = async () => {
  const carers = await fetchCarersForOrgUnit();
  personPickerCarers.value = carers?.sort((a, b) => a.text.localeCompare(b.text)) || [];
};

// TODO need refactor, use relation store instead of calling Endpoint
const getRelations = async (clientId: number) => {
  const relations: CompositeOption[] | null = await fetchRelations(clientId);
  if (relations) {
    clientRelations.value = relations;
  }
};

const getValuesForParams = async (interactions: IInteraction[], clientId: number) => {
  const carerIdsForParams = getCarersIds(interactions);
  const [paramValueTypes, personPickerTypes] = setParamValueTypes(interactions);

  if (carerIdsForParams.length) {
    await getCarersForOrgUnit();
  }
  if (paramValueTypes.includes('person_picker') && personPickerTypes.includes('circle_of_care')) {
    await getRelations(clientId);
  }
};

const setParamValueTypes = (interactions: IInteraction[]) => {
  const paramTypes: string[] = [];
  const personPickerTypes: string[] = [];
  if (interactions[0].hideEmptyParameters) { interactions = handleHideEmptyParameters(interactions, true); }
  interactions.forEach((interaction) => {
    interaction.parameters.forEach((param) => {
      paramTypes.push(param.valueType);
      if (param.valueType === 'person_picker' && param.config?.personPickerType) {
        personPickerTypes.push(param.config.personPickerType);
      }
    });
  });
  return [[...Array.from(new Set(paramTypes))], [...Array.from(new Set(personPickerTypes))]];
};

const defaultParameter = (param: Parameter, interaction: IInteraction) => (
  {
    keyName: param.valueType === 'medication' ? 'medication' : param.id as string,
    displayName: getParamName(param, interaction.clientId),
    type: 'string',
  }
);

const buildParameterHeaders = (interaction: IInteraction) => {
  const { parameters, computedParameters } = interaction;
  const params = [...(parameters || []), ...(computedParameters || [])];
  return params.flatMap((param: Parameter) => {
    if (param.valueType === 'signature' && !isOldVersionSignatureParameter(param)) {
      return [
        {
          keyName: `isSigningOnBehalf${param.id}`,
          displayName: translate('timeline.parameters.signature.areYouSigningOnBehalfOf'),
          type: 'string',
        },
        defaultParameter(param, interaction),
        {
          keyName: `signingAuthority${param.id}`,
          displayName: translate('timeline.parameters.signature.describeAuthority'),
          type: 'string',
        },
      ];
    }
    return defaultParameter(param, interaction);
  });
};

export {
  getParamEntries,
  getParamName,
  getValuesForParams,
  getParamValue,
  buildParameterHeaders,
};
