import { DataRetentionDeletedClientsRow } from '@/data_retention/types/DataRetentionTableData';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import { ApiError } from '@/_shared/services/apiClientBase';
import use from '@/_shared/compositionApi';
import apiClient from './apiClient';
import { DataRetention } from '../types/dataRetention';

export const getDataRetentionReport = async (lastClientId: number, pageSize: number) : Promise<DataRetention | ApiError > => {
  try {
    return await apiClient.get(`/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention/report`, { params: { last_client_id: lastClientId, per_page: pageSize } });
  } catch (error) {
    if ((error as ApiError).code === '504') {
      const { translate } = use.helpers();
      toast(translate('data_retention.server_error'), ToastType.Info);
    }
    return error as ApiError;
  }
};

export const getDataRetentionDeletedClients = async (): Promise<DataRetentionDeletedClientsRow[]> => {
  try {
    return await apiClient.get(`/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention/deleted`);
  } catch (error) {
    return error;
  }
};

export const getDataRetentionDeletedClientsReport = async () => {
  try {
    const url = `/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention/deleted_report`;
    await window.open(`${url}?device_uuid=${window.localStorage.getItem('deviceUUID')}`);
    return true;
  } catch (error) {
    return error;
  }
};

export const getPendingDeletionsReport = async () => {
  try {
    const response = await apiClient.get(`/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention/pending`);
    return response as DataRetention;
  } catch (error) {
    return error;
  }
};

export const restoreClient = async (clientId: number) => {
  try {
    return apiClient.put(`/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention/${clientId}`);
  } catch (error) {
    return error;
  }
};

export const softDeleteClients = async (clientIds: number[]) => {
  try {
    const url = `/api/v2/organisations/${window.currentOrganisationUnit.organisation_id}/data_retention`;
    const data = { client_ids: clientIds };
    return apiClient.put(url, data);
  } catch (error) {
    return error;
  }
};

export default getDataRetentionReport;
