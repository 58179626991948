import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-additional-info-content" }
const _hoisted_2 = { class: "v-grey-text" }

import {
  computed, ref,
} from 'vue';
import GenericLeftPanel from '@/_shared/components/general/left_panel/GenericLeftPanel.vue';
import use from '@/_shared/compositionApi';
import useServiceCountsStore from '@/_shared/store/serviceCounts';
import { storeToRefs } from 'pinia';
import GenericListEntry from '@/_shared/components/display/GenericListEntry.vue';
import { useRoute } from 'vue-router';
import { ServiceCount } from '@/timeline/types/ServiceCount';


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceCountsLeftPanel',
  setup(__props) {

const serviceCountsStore = useServiceCountsStore();
const { fetchByDate } = serviceCountsStore;
const { serviceCounts } = storeToRefs(serviceCountsStore);
const { translate } = use.helpers();

const route = useRoute();

const onUpdatedDate = (newDate: Date) => {
  fetchByDate(new Date(newDate));
};

const searchTerm = ref('');

const onSearchInput = (searchInput: string) => { searchTerm.value = searchInput; };

const searchResults = computed(() => (searchTerm.value ? serviceCounts.value
  .filter((serviceCount) => serviceCount.serviceName.toLowerCase()
    .includes(searchTerm.value.toLowerCase())) : []));

const serviceCountItems = computed(() => {
  scrollToServiceCounts();
  if (searchTerm.value) {
    return searchResults.value as ServiceCount[];
  }
  return serviceCounts.value as ServiceCount[];
});

const timelineRoute = (serviceId: number) => ({
  name: 'serviceCountList.timeline',
  params: { serviceId },
  query: route.query,
});

const totalRecords = (serviceCount: ServiceCount) => serviceCount.closedInteractionsCount
  + serviceCount.plannedInteractionsCount;

const closedInteractionsMessage = (serviceCount: ServiceCount) => translate(
  'service_counts.number_of_closed_interactions',
  { count: serviceCount.closedInteractionsCount },
);

const outOfInteractionsMessage = (serviceCount: ServiceCount) => translate(
  'service_counts.out_of_interactions_today',
  { count: totalRecords(serviceCount) },
);

const scrollToServiceCounts = () => {
  const serviceCountsCards = document.getElementsByClassName('v-list-entry');
  if (serviceCountsCards.length) {
    serviceCountsCards[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(GenericLeftPanel, {
    onUpdatedDate: onUpdatedDate,
    onSearchInput: onSearchInput
  }, {
    "list-entry": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(serviceCountItems.value, (serviceCount) => {
        return (_openBlock(), _createElementBlock("div", {
          key: serviceCount.serviceId
        }, [
          _createVNode(_component_router_link, {
            to: timelineRoute(serviceCount.serviceId)
          }, {
            default: _withCtx(() => [
              _createVNode(GenericListEntry, {
                icon: serviceCount.iconUrl,
                title: serviceCount.serviceName,
                class: _normalizeClass(["sp-service-count-entry sp-list-item", `sp-list-item-${serviceCount.serviceId}`])
              }, {
                additionalInformation: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h5", null, _toDisplayString(closedInteractionsMessage(serviceCount)), 1),
                    _createElementVNode("h5", _hoisted_2, _toDisplayString(outOfInteractionsMessage(serviceCount)), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["icon", "title", "class"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})