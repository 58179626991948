import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-signature-parameter-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "v-signature-completion-text sp-signature-completion-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';
import { ref, Ref } from 'vue';
import use from '@/_shared/compositionApi';
import { Parameter, CoercedSignature } from '../../types/Parameter';
import BaseParameter from '../BaseParameter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OldSignatureParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const signature = ref(props.parameter.coercedValue) as Ref<CoercedSignature>;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true
    }, {
      "second-line": _withCtx(() => [
        (signature.value && signature.value['base64File'])
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "v-signature-image sp-signature-image",
                alt: "Image of Signature provided",
                src: 'data:image/jpeg;base64,'+signature.value['base64File']
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (signature.value && signature.value['base64File'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(translate)('timeline.parameters.signature.signatureCompletionString',
                {
                  client_name: signature.value['name'],
                  date_signed: _unref(formatDateTime)(signature.value['datetimeSigned']),
                })), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(translate)('timeline.parameters.signature.signatureCompletionWarning')), 1))
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})