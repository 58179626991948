
import {
  defineComponent, computed,
} from 'vue';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';

export default defineComponent({
  components: { BaseAvatar, BaseCheckbox },
  emits: ['toggleSelectEntry'],
  props: {
    entry: {
      type: Object,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      type: Boolean,
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    primaryText: {
      type: String,
    },
    secondaryText: {
      type: String,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const toggleChecked = () => {
      if (props.entry.state) {
        if (props.entry.state === 'active') {
          context.emit('toggleSelectEntry', props.entry.value);
        }
      } else {
        context.emit('toggleSelectEntry', props.entry.value);
      }
    };

    const checkState = computed(() => {
      if (props.isSelected || (props.entry.state && props.entry.state !== 'active')) {
        return 'v-entry-wrapper v-hide';
      }
      return 'v-entry-wrapper';
    });

    return {
      toggleChecked,
      checkState,
    };
  },
});
