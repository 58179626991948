
import { defineComponent, ref, watch } from 'vue';
import { subYears } from 'date-fns';
import use from '@/_shared/compositionApi';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import dateSelectionValid from '../helpers/DateFilterHelper';

export default defineComponent({
  components: {
    BaseDatePickerInput,
  },
  setup(context, { emit }) {
    const { translate } = use.helpers();

    const endDate = ref<Date>(new Date());
    const startDate = ref<Date>(subYears(new Date(), 3));
    const filterByDates = ref<boolean>(true);
    const displayInvalidDate = ref<boolean>(false);

    function toggleDateFilter() {
      filterByDates.value = !filterByDates.value;
      if (!filterByDates.value
        || dateSelectionValid(startDate.value, endDate.value, displayInvalidDate)) {
        emit('calendarEvent', {
          startDate: startDate.value,
          endDate: endDate.value,
          filterByDates: filterByDates.value,
        });
      }
    }

    watch(startDate, (value: Date | '') => {
      if (value === null) value = '';
      if (dateSelectionValid(startDate.value, endDate.value, displayInvalidDate)) {
        emit('calendarEvent', {
          startDate: value,
          endDate: endDate.value,
          filterByDates: filterByDates.value,
        });
      }
    });

    watch(endDate, (value: Date | '') => {
      if (value === null) value = '';
      if (dateSelectionValid(startDate.value, endDate.value, displayInvalidDate)) {
        emit('calendarEvent', {
          startDate: startDate.value,
          endDate: value,
          filterByDates: filterByDates.value,
        });
      }
    });

    return {
      translate,
      toggleDateFilter,
      endDate,
      startDate,
      filterByDates,
      displayInvalidDate,
    };
  },
  emits: ['calendarEvent'],
});
