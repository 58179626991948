import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-no-results" }
const _hoisted_2 = { class: "v-icon" }
const _hoisted_3 = { class: "v-title" }
const _hoisted_4 = { class: "v-no-records-for" }
const _hoisted_5 = { class: "v-subtitle" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'NoResultsMessage',
  props: {
  noResultsTerm: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseIcon, {
        name: "nsx-font-icon--search",
        size: "3x",
        class: "v-icon",
        "foreground-color": "grey",
        "no-hover": true
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, _toDisplayString(_unref(translate)('search.sorry')), 1),
      _renderSlot(_ctx.$slots, "no_results_message"),
      _createElementVNode("div", _hoisted_4, "\"" + _toDisplayString(__props.noResultsTerm) + "\"", 1)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(translate)('search.hint')), 1)
  ]))
}
}

})