import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-documents-section-header-container" }
const _hoisted_2 = { class: "v-documents-section-header-left" }
const _hoisted_3 = { class: "v-documents-section-back-icon" }
const _hoisted_4 = { class: "v-documents-section-header-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "v-documents-section-list" }

import { computed, PropType } from 'vue';
import { GenericFile } from '@/_shared/types/genericFile';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseDocument from '@/_shared/components/display/BaseDocument.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDocumentList',
  props: {
  documents: {
    type: Object as PropType<GenericFile[]>,
    required: true,
  },
  icon: {
    type: String,
  },
  header: {
    type: String,
  },
  showBackButton: {
    type: Boolean,
  },
},
  setup(__props) {

const props = __props;

const iconClass = computed(() => `${props.icon} v-document-section-icon`);
const documentsCount = computed(() => props.documents?.length);


return (_ctx: any,_cache: any) => {
  return true
    ? (_openBlock(), _createBlock(BaseCard, {
        key: 0,
        headerBackground: true,
        padding: false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (__props.showBackButton)
                  ? (_openBlock(), _createBlock(BaseIcon, {
                      key: 0,
                      name: "nsx-font-icon--arrow-left",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleShowDocuments'))),
                      align: "left",
                      size: "lg",
                      "no-hover-background": "",
                      "foreground-color": "blue",
                      "foreground-hover-color": "grey"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("i", {
                class: _normalizeClass(iconClass.value)
              }, null, 2),
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(__props.header) + " ", 1),
                (documentsCount.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(" + _toDisplayString(documentsCount.value) + ")", 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.documents, (document) => {
              return (_openBlock(), _createElementBlock("div", {
                key: document.id,
                class: "sp-document"
              }, [
                _createVNode(BaseDocument, { document: document }, null, 8, ["document"])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})