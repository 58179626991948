import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }
const _hoisted_2 = { class: "v-main-text" }

import { onBeforeMount } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';


export default /*@__PURE__*/_defineComponent({
  __name: 'LondonCareOdsCheckFailModal',
  emits: ['cancelEnableModal'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { translate } = use.helpers();

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    class: "v-integration-nhs-ndsa-fail",
    dangerAlert: false,
    icon: "info",
    onCancelAlert: _cache[0] || (_cache[0] = ($event: any) => (emit('cancelEnableModal'))),
    valid: false
  }, {
    "footer-right": _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("span", null, null, -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_unref(translate)("integrations.london_care_records.ods_code_fail_1")), 1),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
          ]),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_unref(translate)("integrations.london_care_records.ods_code_fail_2")), 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
          ]),
          _createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(_unref(translate)("integrations.london_care_records.ods_code_fail_3")), 1),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})