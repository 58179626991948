import axios, { AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import * as process from 'process';
import {
  ApiClient, ApiError, ApiRequestConfig, ApiResponse,
} from './apiClientBase';

const isStandAloneApp = process.env.VUE_APP_STAND_ALONE === 'true';
const axiosConfig = isStandAloneApp ? { baseURL: 'http://127.0.0.1:3000' } : {};
const client = applyCaseMiddleware(axios.create(axiosConfig));

export default class AxiosApiClient extends ApiClient {
  internalGet<T = unknown, R = ApiResponse<T>>(url: string, config?: ApiRequestConfig): Promise<R> {
    return client.get<T, R>(url, config as AxiosRequestConfig<unknown>);
  }

  internalPost<T = unknown, R = ApiResponse<T>>(url: string, data?: unknown, config?: ApiRequestConfig): Promise<R> {
    return client.post<T, R>(url, data, config as AxiosRequestConfig<unknown>);
  }

  internalPatch<T = unknown, R = ApiResponse<T>>(url: string, data?: unknown, config?: ApiRequestConfig): Promise<R> {
    return client.patch<T, R>(url, data, config as AxiosRequestConfig<unknown>);
  }

  internalPut<T = unknown, R = ApiResponse<T>>(url: string, data?: unknown, config?: ApiRequestConfig): Promise<R> {
    return client.put<T, R>(url, data, config as AxiosRequestConfig<unknown>);
  }

  internalDelete<T = unknown, R = ApiResponse<T>>(url: string): Promise<R> {
    return client.delete<T, R>(url);
  }

  setupInterceptors(
    onRequest:(config: ApiRequestConfig<Record<string, unknown>, Record<string, unknown>>) => ApiRequestConfig,
    onResponse:(resp: ApiResponse<Record<string, unknown>>) => void,
    onResponseError:(error: ApiError) => ApiResponse<unknown> | ApiError,
  ) {
    // ApiRequestConfig is a subset of AxiosRequestConfig. Instead of polluting ApiClient types with unnecessary fields,
    // we merge the changes (headers) back
    client.interceptors.request.use(
      (config) => {
        const apiConfig = onRequest(config);
        Object.assign(config, apiConfig);
        return config;
      },
    );
    client.interceptors.response.use(
      (resp) => {
        onResponse(resp);
        return resp;
      },
      (error) => onResponseError(error),
    );
  }
}
