import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import use from '@/_shared/compositionApi';
import LeftPanelActionBar from '@/_shared/components/general/left_panel/LeftPanelActionBar.vue';
import { useWindowSize } from '@vueuse/core';
import DashboardsSmallScreenError from './DashboardsSmallScreenError.vue';
import DashboardReportsList from './DashboardReportsList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardsLeftPanel',
  setup(__props) {

const { width } = useWindowSize();
const { translate } = use.helpers();

return (_ctx: any,_cache: any) => {
  return (_unref(width) <= 800)
    ? (_openBlock(), _createBlock(DashboardsSmallScreenError, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_Teleport, _mergeProps({ to: "#lp-action-bar" }, _ctx.$attrs), [
          _createVNode(LeftPanelActionBar, {
            "date-picker-disabled": true,
            "search-disabled": true,
            title: _unref(translate)('dashboards.insights')
          }, {
            "custom-action-bar-options": _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { id: "lp-insights-custom-dropdown" }, null, -1)
            ])),
            _: 1
          }, 8, ["title"])
        ], 16)),
        (_openBlock(), _createBlock(_Teleport, _mergeProps({ to: "#lp-container" }, _ctx.$attrs), [
          _createVNode(DashboardReportsList)
        ], 16))
      ]))
}
}

})