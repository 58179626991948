import apiClient from '@/_shared/services/apiClient';
import { IconUrls } from '@/_shared/types/IconUrls';

const fetchCategoryIcons = async () => {
  const url = 'categories/icons';
  try {
    return await apiClient.get<IconUrls>(url, { params: { } });
  } catch (error) {
    return {} as IconUrls;
  }
};

export default fetchCategoryIcons;
