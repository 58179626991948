// eslint-disable-next-line @typescript-eslint/no-explicit-any
import {
  computed, ComputedRef, isRef, Ref, unref,
} from 'vue';
import { CoercedSelection, CoercedValue } from '@/timeline/types/Parameter';
import equal from 'fast-deep-equal/es6';

export const promiseMemorise = <T, A extends unknown[]> (fn: (...args: A) => Promise<T>, delAfter = 1 * 60 * 1000) => {
  const cache: Record<string, Promise<T>> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: A): Promise<T> => {
    const key = JSON.stringify(args);
    if (key in cache) {
      // console.log(fn.name, ': memory in used for args: ', key);
      return cache[key];
    }
    cache[key] = fn(...args).catch((x) => {
      delete cache[key];
      return x;
    });
    setTimeout(() => {
      delete cache[key];
    }, delAfter);
    return cache[key];
  };
};
export const until = (condition : () => unknown, checkInterval = 100) => new Promise<void>((resolve) => {
  const interval = setInterval(() => {
    if (!condition()) return;
    clearInterval(interval);
    resolve();
  }, checkInterval);
});

export const untilWithTimeout = (condition : () => unknown, timeout = 1000, checkInterval = 100) => new Promise<void>((resolve, reject) => {
  const interval = setInterval(() => {
    if (!condition()) return;
    clearInterval(interval);
    resolve();
  }, checkInterval);
  setTimeout(() => {
    clearInterval(interval);
    reject();
  }, timeout);
});

export const useSingletonGenericComputed = <H, Z>(compute: (id:H) => (Ref<Z>| ComputedRef<Z>)) : (id: H) => Ref<Z>|ComputedRef<Z> => {
  const computedInstances:Map<H, ComputedRef<Z>| Ref<Z>> = new Map();
  return (_id: H) => {
    if (computedInstances.has(_id)) return computedInstances.get(_id) as ComputedRef<Z>| Ref<Z>;
    const computedInstance = compute(_id);
    computedInstances.set(_id, computedInstance);
    return computedInstance;
  };
};

export const useSingletonComputed = <H, Z>(getter: (_:H) => Z) : (id: H) => ComputedRef<Z> => useSingletonGenericComputed<H, Z>((id:H) => computed(() => getter(id))) as (id: H) => ComputedRef<Z>;

// eslint-disable-next-line eqeqeq
export const shallowEqual = (a: CoercedValue, b: CoercedValue|undefined):boolean => equal(a, b) || a == b || _equalNull(a, b) || _equalNull(b, a);

const _equalNull = (a: CoercedValue|undefined, b: CoercedValue|undefined) => (a === null && (b === '' || ((b as CoercedSelection)?.values?.length === 0)));

export const deepUnref = <T>(val: T | Ref<T>): T => {
  const value = isRef(val) ? unref(val) : val;

  if (val == null || !(typeof val === 'object' || Array.isArray(val))) {
    return value;
  }

  if (Array.isArray(value)) {
    return unrefArray(value as unknown as []) as unknown as T;
  }

  return unrefObject(value as unknown as Record<string, unknown>) as T;
};

const unrefArray = <z>(arr:z[]) => (arr).map(deepUnref);

const unrefObject = (obj: Record<string, unknown>): Record<string, unknown> => {
  const unreffed = {} as Record<string, unknown>;
  Object.keys(obj).forEach((key) => {
    unreffed[key] = deepUnref(obj[key]);
  });

  return unreffed;
};
