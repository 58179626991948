import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href", "onClick"]
const _hoisted_2 = { class: "v-dropdown-item-title" }
const _hoisted_3 = {
  key: 0,
  class: "icon nr-icon-checked v-dropdown-tick"
}

import { RouterLink } from 'vue-router';
import useNavLinkActive from '@/_shared/Composables/useNavLinkActive';
import { PropType } from 'vue';
import { IconColor } from '@/_shared/types/iconColor';
import BaseIcon from '../general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDropdownOption',
  props: {
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  iconColor: {
    type: String as PropType<IconColor>,
    required: false,
    default: 'black',
  },
  showActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  ...useNavLinkActive().navLinkProps,
},
  setup(__props) {

const props = __props;

const { isNavItemActive } = useNavLinkActive().getActivationCheckers(props, true);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(RouterLink), _mergeProps({ to: "" }, _ctx.$props, {
    custom: "",
    key: props.text
  }), {
    default: _withCtx(({ href, navigate }) => [
      _createElementVNode("a", _mergeProps({
        href: href,
        onClick: navigate,
        class: "v-dropdown-item"
      }, _ctx.$attrs), [
        _createVNode(BaseIcon, {
          class: "v-dropdown-item-icon",
          noHover: "",
          foregroundHoverColor: "grey",
          size: "sm",
          name: __props.icon
        }, null, 8, ["name"]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(__props.text), 1),
        (__props.showActive && _unref(isNavItemActive))
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("", true)
      ], 16, _hoisted_1)
    ]),
    _: 1
  }, 16))
}
}

})