import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

import getLinks from '@/_shared/helpers/RolesAndNotificationsTabs';
import BaseNavigationItem from '../../_shared/components/navigation/BaseNavigationItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsTopBar',
  setup(__props) {

const links = getLinks();

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(links), (link) => {
    return (_openBlock(), _createBlock(BaseNavigationItem, _mergeProps({
      class: "v-msg-nav-item",
      ref_for: true
    }, _ctx.$attrs, {
      to: link.to,
      icon: link.icon,
      text: link.name,
      key: link.name,
      forceActive: link.activeOnChildren,
      "customized-active-matcher": link.customizedActiveMatcher
    }), null, 16, ["to", "icon", "text", "forceActive", "customized-active-matcher"]))
  }), 128))
}
}

})