import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { storeToRefs } from 'pinia';
import useNeedsStore from '@/_shared/store/needs';
import useInteractionsStore from '@/_shared/store/interactions';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import use from '@/_shared/compositionApi';
import CloseRightPanelIcon from '@/_shared/components/buttons/CloseRightPanelIcon.vue';
import CarePlanReview from './CarePlanReview.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReviewRightPanel',
  setup(__props) {

const { translate } = use.helpers();

const route = useRoute();
const router = useRouter();

const needsStore = useNeedsStore();
const { fetch, getNeedById, generateCareplanPath } = needsStore;

const interactionsStore = useInteractionsStore();
const { interactions } = storeToRefs(interactionsStore);

const { clientStore } = use.store.clients();

const clientId = computed(() => route.params.clientId);

const avatarPhoto = computed(() => {
  if (clientId.value) return clientStore.photo(+clientId.value);
  return undefined;
});

const needId = ref<number | null>(null);

const need = computed(() => (needId.value ? getNeedById(needId.value) : null));

const isCareplan = computed(() => route.path.includes('careplan'));

onMounted(() => {
  watch(
    [() => route.params.id, () => interactions.value],
    async ([routeId, theInteractions]) => {
      const interaction = theInteractions.find((i) => i.id === +routeId);
      if (!interaction) {
        return false;
      }

      needId.value = interaction.needId;

      await fetch({
        id: interaction.needId!,
        lastReviewId: interaction.id,
      });

      return () => {
        // Make linter happy
      };
    },
    { immediate: true, flush: 'post' },
  );
});

const closeRightPanel = () => {
  const name = route.path.includes('client') ? 'client.timeline' : 'carer.timeline';
  router.push({
    name,
    query: route.query,
  });
};

const navigateToCarePlan = async () => {
  if (need.value) {
    const url = await generateCareplanPath(need.value.clientSubcategoryId, need.value.clientId);
    router.push(url);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { photo: avatarPhoto.value }, {
    default: _withCtx(() => [
      _createVNode(CloseRightPanelIcon, { onCloseRightPanel: closeRightPanel }),
      (need.value)
        ? (_openBlock(), _createBlock(CarePlanReview, {
            key: 0,
            need: need.value,
            hidePreviousReviews: !isCareplan.value,
            onAction: navigateToCarePlan,
            actionText: _unref(translate)('timeline.need.view_latest_care_plan'),
            canPrint: false
          }, null, 8, ["need", "hidePreviousReviews", "actionText"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["photo"]))
}
}

})