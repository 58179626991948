import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "medical-records-overlay" }
const _hoisted_2 = { key: 0 }

import useUserStore from '@/_shared/store/user';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'MedicalRecords',
  setup(__props) {

const canAccessLondonCareRecord = useUserStore().userCan('any', 'londonCareRecord');
const router = useRouter();

onBeforeMount(() => {
  if (canAccessLondonCareRecord) {
    router.push({ name: 'client.medicalRecords.londonCareRecord' });
  } else {
    router.push({ name: 'client.profile' });
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"margin-top":"100px"} }, "This will be a promotional infographic for medical records once implemented", -1)),
    (!_unref(canAccessLondonCareRecord))
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "You do not currently have access to records"))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(" You can access it ")
        ], 64))
  ]))
}
}

})