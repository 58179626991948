import { safeTranslate } from '@/_shared/translations/i18n';
import {
  CoercedChoice,
  CoercedEventPWSInstance,
  CoercedMultiSelect,
  CoercedSkinInstance,
  CoercedPersonPicker,
  CoercedSignature,
  CoercedSingleSelect,
  Medication,
  Parameter,
  PictureWrapper,
} from '@/timeline/types/Parameter';
import addPreferredTermsToText from '@/_shared/services/clientHelpers';
import {
  computed, ComputedRef, Ref, ref,
} from 'vue';
import use from '@/_shared/compositionApi';
import { format, parseISO } from 'date-fns';
import { clientsRelationsStore } from '@/_shared/store/Relation';
import { fetchCarersForOrgUnit } from '@/_shared/services/datasetsApi';
import { CompositeOption } from '@/_shared/types/baseSelect';
import IInteraction from '@/timeline/types/IInteraction';
import useUserStore from '@/_shared/store/user';

const { translate } = use.helpers();

// TODO unify with sutUpSubParameter in NourishInstanceParameter
const handleNourishInstanceParams = (interactions: IInteraction[]) => {
  interactions.forEach((interaction: IInteraction) => {
    interaction.parameters
      .filter((param) => param.valueType === 'nourish_instance')
      .forEach((param) => {
        if (param.config!.nourishInstanceType === 'skin') {
          const coercedValue = param.coercedValue as CoercedSkinInstance;
          if (coercedValue) {
            interaction.parameters.push({
              ...param,
              id: `${param.id}.1`,
              name: `${param.name}: ${translate('body_map.location')}`,
              archived: param.archived,
              valueType: 'single_selector_search',
              coercedValue: {
                values: [safeTranslate(`datasets.${coercedValue?.metadata?.location}`)],
              } as CoercedSingleSelect,
            });
            interaction.parameters.push({
              ...param,
              id: `${param.id}.2`,
              name: `${param.name}: ${translate('body_map.specificType')}`,
              archived: param.archived,
              valueType: 'single_selector_search',
              coercedValue: {
                values: [safeTranslate(`datasets.${coercedValue?.metadata?.specificType}`)],
              } as CoercedSingleSelect,
            });
          }
        }
        if (param.config!.nourishInstanceType === 'event_pws') {
          const coercedValue = param.coercedValue as CoercedEventPWSInstance;
          if (coercedValue) {
            const dateUnknown = coercedValue.metadata.eventDateTime === '';
            const dateTimeParameter = {
              ...param,
              id: `${param.id}.1`,
              name: `${param.name}: ${translate('events.event_date_time')}`,
              archived: param.archived,
              valueType: dateUnknown ? 'string' : 'datetime',
              coercedValue: dateUnknown ? 'Unknown' : coercedValue?.metadata?.eventDateTime,
            };
            interaction.parameters.push(dateTimeParameter);
            interaction.parameters.push({
              ...param,
              id: `${param.id}.2`,
              name: `${param.name}: ${translate('events.event')}`,
              archived: param.archived,
              valueType: 'single_selector_search',
              coercedValue: {
                values: [safeTranslate(`datasets.${coercedValue?.metadata?.specificType}`)],
              } as CoercedSingleSelect,
            });
            interaction.parameters.push({
              ...param,
              id: `${param.id}.3`,
              name: `${param.name}: ${translate('events.severity')}`,
              archived: param.archived,
              valueType: 'single_selector_search',
              coercedValue: {
                values: [safeTranslate(`datasets.${coercedValue?.metadata?.severity}`)],
              } as CoercedSingleSelect,
            });
          }
        }
        interaction.parameters = interaction.parameters.filter((p) => p.valueType !== 'nourish_instance');
      });
  });
  return interactions;
};

const handleHideEmptyParameters = (interactions: IInteraction[], headersFirstInteraction = false) => {
  let paramIds: number[] = [];
  if (headersFirstInteraction) { interactions.reverse(); }
  interactions.forEach((interaction: IInteraction, index, array) => {
    const checkClosed = (interaction.state === 'closed' || interaction.state !== 'saved');
    if (index === array.length - 1) {
      paramIds = [...new Set(paramIds)];
      interaction.parameters = interaction.parameters.filter((param) => paramIds.includes(param.id as number) || isParameterFilled(param, interaction.closedAt, checkClosed, true));
    } else {
      interaction.parameters = interaction.parameters.filter((param) => isParameterFilled(param, interaction.closedAt, checkClosed, true));
      interaction.parameters.forEach((param) => {
        paramIds.push(param.id as number);
      });
    }
  });
  if (headersFirstInteraction) { interactions.reverse(); }
  return interactions;
};

function getScoreLabel(param: Parameter) {
  const coercedValue = param && param.coercedValue as CoercedChoice;
  return coercedValue?.label || '';
}

function getParamUnit(param: Parameter) {
  return (param && param.unit) || '';
}

// TODO this does not match angulars implementation, if we want to use that for any parameter
// then we are missing on quite a few types here. Maybe we should delegate primitiveValue to
// the parameter itself, along with showIf, data points handling etc
function primitiveValue(parameter: Parameter) {
  let value;
  if (parameter.isArray && parameter.coercedValue as CoercedChoice[]) {
    value = (parameter.coercedValue as CoercedChoice[])
      .map((item) => `${typeof item === 'object' && 'value' in item ? item.value : item}`)
      .join(', ');
  } else {
    const coercedValue = parameter.coercedValue as CoercedSingleSelect;
    value = coercedValue && typeof coercedValue === 'object' && 'value' in coercedValue ? coercedValue.value : coercedValue;
  }
  return value;
}

function hasMultipleChoices(coercedChoices: CoercedChoice[]) {
  return coercedChoices?.length > 0;
}
const formatParameterName = (parameter: Parameter, clientId: number) : ComputedRef<string> => computed(() => {
  let { name } = parameter;
  name = addPreferredTermsToText(clientId, name).value;
  if (parameter.unit) {
    name += ` (${parameter.unit})`;
  }
  return `${name}: `;
});

function formatMedicationValue(parameter: Parameter) {
  const coercedValue = parameter.coercedValue as Medication;
  const colour = coercedValue?.refused ? '#FF6169' : '#6DC278';
  let markup = '';
  if (!coercedValue) {
    return markup;
  }
  if (coercedValue.name) {
    markup += `<br><b>${translate('common.medicament')}:</b> ${coercedValue?.name}`;
  }
  if (coercedValue.quantity) {
    markup += `<br><b>${translate('common.quantity')}:</b> <span style='color: ${colour}'>${coercedValue?.quantity}</span>`;
  }
  if (coercedValue.refused) {
    markup += `<br><b>${translate('common.status')}:</b> <span style='color: ${colour}'>${coercedValue.reason}</span>`;
  }
  if (coercedValue.notes) {
    markup += `<br><b>${translate('common.note')}:</b> ${coercedValue.notes}`;
  }
  if (coercedValue.administeredBy) {
    markup += `<br><b>${translate('common.administered_by')}:</b> ${coercedValue.administeredBy}`;
  }
  if (coercedValue.witnessedBy) {
    markup += `<br><b>${translate('common.witnessed_by')}:</b> ${coercedValue.witnessedBy}`;
  }
  return markup;
}

function formatSignature(parameter: Parameter) {
  const coercedValue = parameter.coercedValue as CoercedSignature;
  if (coercedValue?.datetimeSigned) {
    const formattedDate = new Date(coercedValue.datetimeSigned).toLocaleString(['en-GB'], {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
    }).replace(',', ' -');
    const translation = computed(() => (coercedValue.signingAuthority ? 'signatureCompletionStringWithAuthority' : 'signatureCompletionString'));
    const computedTranslate = computed(() => translate(
      `timeline.parameters.signature.${translation.value}`,
      {
        client_name: coercedValue.name,
        date_signed: formattedDate,
        signing_authority: coercedValue.signingAuthority,
      },
    ));
    return computedTranslate;
  }
  return '';
}
// Returning computed property since elements in Relation store are reactive
// When we made the call to store the returned value may be empty at the beginning, fetch is not executed yet.
const formatPersonPickerValue = ((parameter: Parameter, clientId: number) => {
  const isCarerId = isPersonPickerParamType(parameter, 'carers');
  const stringValue = coercedValueToStr(+(<CoercedPersonPicker>parameter.coercedValue).values[0], clientId, isCarerId);
  return computed(() => {
    const answer = `${stringValue.value} \n`;
    return formatAddedRemoved(parameter.coercedValue as CoercedPersonPicker, answer);
  });
});

const formatPicturesValue = ((parameter: Parameter) => {
  let answer = `${translate('common.no')} \n`;
  if ((<PictureWrapper>parameter.coercedValue)?.pictures?.length > 0) {
    answer = `${translate('common.yes')} (${(<PictureWrapper>parameter.coercedValue).pictures.length}) \n`;
  }
  return answer;
});

const formatNourishInstanceValue = ((parameter: Parameter) => {
  const hasWoundAndLocation = (<CoercedSkinInstance>parameter.coercedValue)?.metadata?.location && (<CoercedSkinInstance>parameter.coercedValue)?.metadata?.specificType;
  const hasEventData = (<CoercedEventPWSInstance>parameter.coercedValue)?.metadata?.specificType && (<CoercedEventPWSInstance>parameter.coercedValue)?.metadata?.severity;
  let answer = '\n';
  if (hasWoundAndLocation) {
    const coercedValue = parameter.coercedValue as CoercedSkinInstance;
    let valueCodename = `datasets.${coercedValue?.metadata?.location}`;
    answer = `${answer}<b>${translate('body_map.location')}:</b> ${safeTranslate(valueCodename)}\n`;

    valueCodename = `datasets.${coercedValue?.metadata?.specificType}`;
    answer = `${answer}<b>${translate('body_map.specificType')}:</b> ${safeTranslate(valueCodename)}\n`;
  }
  if (hasEventData) {
    const coercedValue = parameter.coercedValue as CoercedEventPWSInstance;
    const dateTimeValue = coercedValue.metadata.eventDateTime === '' ? 'Unknown' : format(parseISO(coercedValue.metadata.eventDateTime), 'dd/MM/yyyy - HH:mm');

    answer = `${answer}<b>${translate('events.event_date_time')}:</b> ${dateTimeValue}\n`;

    let valueCodename = `datasets.${coercedValue?.metadata?.specificType}`;
    answer = `${answer}<b>${translate('events.event')}:</b> ${safeTranslate(valueCodename)}\n`;

    valueCodename = `datasets.${coercedValue?.metadata?.severity}`;
    answer = `${answer}<b>${translate('events.severity')}:</b> ${safeTranslate(valueCodename)}\n`;
  }
  return answer;
});

const isPersonPickerParamType = (parameter: Parameter, type:string) => parameter.valueType === 'person_picker' && parameter.config?.personPickerType === type;

function formatAddedRemoved(coercedValue: CoercedPersonPicker| CoercedMultiSelect, answer: string) {
  if (coercedValue?.addedValues?.length) {
    answer = `${answer}${translate('common.added')} (${coercedValue.addedValues.length}) \n`;
  }
  if (coercedValue?.removedValues?.length) {
    answer = `${answer}${translate('common.removed')} (${coercedValue.removedValues.length}) \n`;
  }
  return answer;
}

function formatAnswer(parameter: Parameter, clientId: number) {
  const coercedValue = parameter.coercedValue as CoercedMultiSelect;
  let answer = '\n';
  if (coercedValue?.answer) {
    answer = `${answer}${coercedValue.answer}`;
    if (coercedValue?.values?.length > 0) {
      if (parameter.isArray || parameter.valueType === 'multi' || parameter.valueType === 'combined_multi_search') {
        answer = `${answer} (${coercedValue?.values?.length})`;
      } else if (parameter.valueType === 'single_selector_search') {
        answer = `${coercedValue.values}`;
      } else {
        return formatPersonPickerValue(parameter, clientId);
      }
    }
    answer = `${answer}\n`;
  }
  return formatAddedRemoved(coercedValue, answer);
}

function isParameterFilled(parameter: Parameter, closedAt: string | null, checkClosed: boolean, hideEmptyParameters: boolean | null = false) {
  if (parameter.valueType === 'read_only_text') { return true; }
  if (parameter.valueType === 'picture') {
    const picturesCoercedValue = parameter.coercedValue as PictureWrapper;
    return picturesCoercedValue?.pictures?.length > 0;
  }
  if (['single_selector_search', 'multi', 'combined_multi_search'].includes(parameter.valueType)) {
    return isMultiParameterFilled(parameter, closedAt, checkClosed);
  }
  if (parameter.valueType === 'file') {
    if ((checkClosed || closedAt) && hideEmptyParameters && (parameter.coercedValue === null || parameter.coercedValue === undefined)) {
      return false;
    }
    return (!checkClosed || closedAt !== null);
  }
  const primitiveIsFilled = primitiveValue(parameter);
  return (primitiveIsFilled !== null && primitiveIsFilled !== undefined && primitiveIsFilled !== '') || (parameter.genericFile && !parameter.genericFile.destroyed);
}

function isMultiParameterFilled(parameter: Parameter, closedAt: string | null, checkClosed: boolean) {
  const paramPrimitiveValue = primitiveValue(parameter) as CoercedMultiSelect;
  return (
    (!checkClosed || closedAt !== null) && ((paramPrimitiveValue?.values
        && paramPrimitiveValue?.values?.length > 0)
      || (paramPrimitiveValue?.answer && (paramPrimitiveValue?.answer !== null || paramPrimitiveValue?.answer !== undefined)))
  );
}

function padWithZeros(value: number | string) {
  const valueLength = value.toString().length;
  if (valueLength > 1) { return value; }
  return `0${value}`;
}

function getParameterValue(parameter: Parameter, clientId: number) {
  if (parameter.valueType === 'datetime') {
    if (parameter.coercedValue) {
      return format(parseISO(parameter.coercedValue as string), 'dd/MM/yyyy - HH:mm');
    }
    return '';
  }
  if (parameter.valueType === 'duration') {
    if (parameter.coercedValue) {
      const durationInSeconds = parameter.coercedValue as number;
      const hours = padWithZeros(Math.floor((durationInSeconds / 3600)));
      const minutes = padWithZeros(Math.floor((durationInSeconds / 60) % 60));
      const seconds = padWithZeros(Math.floor(durationInSeconds % 60));
      return `${hours}:${minutes}:${seconds}`;
    }
    return '';
  }
  if (parameter.valueType === 'date') {
    if (parameter.coercedValue) {
      return format(parseISO(parameter.coercedValue as string), 'dd/MM/yyyy');
    }
    return '';
  }
  if (parameter.valueType === 'file') {
    return parameter.genericFile?.fileName || parameter.genericFile?.fileLabel || translate('interaction.no_file_attached');
  }
  if (parameter.valueType === 'checkbox') {
    return parameter.coercedValue ? translate('common.yes') : translate('common.no');
  }
  if (parameter.valueType === 'signature') {
    return formatSignature(parameter);
  }
  if (parameter.valueType === 'picture') {
    return formatPicturesValue(parameter);
  }
  if (parameter.valueType === 'nourish_instance') {
    return formatNourishInstanceValue(parameter);
  }
  if (['multi', 'single_selector_search', 'person_picker', 'combined_multi_search'].includes(parameter.valueType)) {
    return formatAnswer(parameter, clientId);
  }
  return primitiveValue(parameter);
}

const getScoreCssClass = (parameter: Parameter) => {
  const classes = 'v-parameter-score';
  const coercedValue = parameter.coercedValue as CoercedMultiSelect;
  if (hasTotalScoreStyle(parameter)) {
    return `${classes} v-round-label-${coercedValue?.options?.style}`;
  }
  return classes;
};

const hasTotalScoreStyle = (parameter: Parameter) => {
  const coercedValue = parameter.coercedValue as CoercedMultiSelect;
  return coercedValue?.options?.style;
};

function coercedValueToStr(id: number, clientId: number, isCarerId: boolean) {
  if (isCarerId) {
    const carers:Ref<string> = ref('');
    fetchCarersForOrgUnit().then(((value: CompositeOption[]|null) => {
      carers.value = value?.find((op) => +op.value === id)?.text || '';
    }));
    return carers;
  }
  return clientsRelationsStore.getRelationFormatedName(clientId, id);
}
function isValueSelected(option: string, currentAnswer: string | undefined) {
  return currentAnswer?.trim() === option.trim();
}

const allowedPictureExtentions = '.png, .jpeg, .bmp, .jpg';

function formatQuestionOptions(parameter: Parameter) {
  if (parameter.config?.options) {
    return parameter.config.options.split('/');
  }
  return null;
}

function getFirstConfigOption(parameter: Parameter) {
  const options = formatQuestionOptions(parameter);
  if (!options) {
    return null;
  }
  return options[0].trim();
}
// param : Partial<Parameter>

function parseNumberDataPointValues(dataPointValues: string|CoercedChoice[]|CoercedChoice, isArrayParameter:boolean): CoercedChoice| CoercedChoice[] | number {
  if (isArrayParameter) {
    return (dataPointValues as CoercedChoice[]).map(parseCoercedChoiceValueToNumber);
  }
  if (dataPointValues && dataPointValues.constructor === Object) {
    return parseCoercedChoiceValueToNumber(dataPointValues as CoercedChoice);
  }
  return unaryOp(dataPointValues as string) as number;

  function parseCoercedChoiceValueToNumber(choice: CoercedChoice) {
    if (choice && choice.value != null && typeof choice.value === 'string') {
      choice.value = unaryOp(choice.value);
    }
    return choice;
  }

  function unaryOp(value: string) {
    return value ? +value : value;
  }
}

function isWebConsentSignatureFTOn() {
  return useUserStore().getFeatureToggle('webConsentSignatures');
}

const isOldVersionSignatureParameter = (parameter: Parameter) => {
  const isOldVersion = parameter.config?.version !== 2;
  return isOldVersion || !isWebConsentSignatureFTOn() || (parameter.coercedValue && isOldVersion);
};

const isNotNumber = (days: string) => days.trim() === '' || Number.isNaN(Number(days));

const getMinDateToEnable = (parameter: Parameter) => {
  const days = parameter?.config?.allowedNumberOfDaysInThePast;

  if (isNotNumber(days ?? '')) {
    return undefined;
  }
  return new Date(new Date().setDate(new Date().getDate() - Number(days)));
};

const getMaxDateToEnable = (parameter: Parameter) => {
  const days = parameter?.config?.allowedNumberOfDaysIntoTheFuture;

  if (isNotNumber(days ?? '')) {
    return undefined;
  }
  return Number.isNaN(days) ? undefined : new Date(new Date().setDate(new Date().getDate() + Number(days)));
};

export {
  formatAnswer,
  formatMedicationValue,
  formatParameterName,
  formatSignature,
  getParamUnit,
  getParameterValue,
  getScoreCssClass,
  getScoreLabel,
  hasMultipleChoices,
  hasTotalScoreStyle,
  isMultiParameterFilled,
  isParameterFilled,
  isPersonPickerParamType,
  primitiveValue,
  parseNumberDataPointValues,
  formatQuestionOptions,
  isValueSelected,
  isOldVersionSignatureParameter,
  getFirstConfigOption,
  formatPicturesValue,
  formatNourishInstanceValue,
  allowedPictureExtentions,
  handleNourishInstanceParams,
  handleHideEmptyParameters,
  getMinDateToEnable,
  getMaxDateToEnable,
};
