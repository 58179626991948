import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "filterDiv",
  class: "v-events-filter-container"
}
const _hoisted_2 = {
  key: 0,
  class: "v-instance-group"
}

import LeftPanelActionBar from '@/_shared/components/general/left_panel/LeftPanelActionBar.vue';
import use from '@/_shared/compositionApi';
import { computed, ref } from 'vue';
import { clientStore } from '@/_shared/store/clients';
import EventListEntry from '@/events/components/EventListEntry.vue';
import { EventPwsInstance } from '@/_shared/types/NourishInstance';
import TeleportWrapper from '@/_shared//components/general/TeleportWrapper.vue';
import EventsFilter from '@/events/components/EventsFilter.vue';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import { filterTextToDisplay } from '@/_shared/services/instanceFilterHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsLeftPanel',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const eventInstances = clientStore.eventPWSInstances(props.clientId!);
const showFilter = ref(false);

const stateFilter = ref({ name: '', codename: '' });
const subTypeFilter = ref({ name: '', codename: '' });
const severityFilter = ref({ name: '', codename: '' });

const handleFilterChange = (option: {filter: string, value: DropdownFilterOption}) => {
  switch (option.filter) {
    case 'state':
      stateFilter.value = option.value;
      break;
    case 'subType':
      subTypeFilter.value = option.value;
      break;
    case 'severity':
      severityFilter.value = option.value;
      break;
    default:
      break;
  }
};

const allEvents = computed(() => groupEvents(eventInstances.value));

const filterFunction = (event: EventPwsInstance) => {
  const stateMatch = stateFilter.value.codename === 'all'
    || (stateFilter.value.codename === 'new&active' && event.state !== 'archived')
    || stateFilter.value.codename === event.state;
  const subTypeMatch = subTypeFilter.value.codename === 'all'
    || subTypeFilter.value.codename === event.subType;
  const severityMatch = severityFilter.value.codename === 'all'
    || severityFilter.value.codename === event.metadata.severity;

  return stateMatch && subTypeMatch && severityMatch;
};

const filteredEvents = computed(() => Object.keys(allEvents.value).map((state) => ({
  state,
  label: translate(`events.sections.${state}`),
  instances: allEvents.value[state].filter(filterFunction),
})));

const groupEvents = (events: EventPwsInstance[]) => {
  const groups: {[key:string] : EventPwsInstance[]} = {};
  const validStates = ['new', 'active', 'archived'];
  events.forEach((event) => {
    if (!validStates.includes(event.state)) {
      return;
    }
    if (!groups[event.state]) {
      groups[event.state] = [];
    }
    groups[event.state].push(event);
  });
  return groups;
};

const sectionClass = (state: string) => `v-${state.toLowerCase()}`;

const filterText = computed(() => filterTextToDisplay([stateFilter.value.name, subTypeFilter.value.name, severityFilter.value.name]));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TeleportWrapper, { to: "#lp-action-bar" }, {
      default: _withCtx(() => [
        _createVNode(LeftPanelActionBar, {
          ref: "searchComponent",
          "date-picker-disabled": true,
          "instance-view": "",
          onToggleInstanceFilter: _cache[0] || (_cache[0] = ($event: any) => (showFilter.value = !showFilter.value)),
          "filter-selected": showFilter.value,
          "filter-text": filterText.value
        }, null, 8, ["filter-selected", "filter-text"]),
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(EventsFilter, {
            eventsByState: allEvents.value,
            currentState: stateFilter.value,
            currentSubType: subTypeFilter.value,
            currentSeverity: severityFilter.value,
            onFilterChange: handleFilterChange
          }, null, 8, ["eventsByState", "currentState", "currentSubType", "currentSeverity"])
        ], 512), [
          [_vShow, showFilter.value]
        ])
      ]),
      _: 1
    }),
    _createVNode(TeleportWrapper, { to: "#lp-list-entry" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredEvents.value, (eventGroup) => {
          return (_openBlock(), _createElementBlock("div", {
            key: eventGroup.state
          }, [
            (eventGroup.instances && eventGroup.instances.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h4", {
                    class: _normalizeClass(["v-event-section", sectionClass(eventGroup.state)])
                  }, _toDisplayString(eventGroup.label), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventGroup.instances, (instance) => {
                    return (_openBlock(), _createBlock(EventListEntry, {
                      key: instance!.id,
                      reference: true,
                      instance: instance as EventPwsInstance
                    }, null, 8, ["instance"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}
}

})