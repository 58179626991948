import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { BaseListEntry } from '@/_shared/components';
import { computed, ComputedRef } from 'vue';
import { NestedLink } from '@/_shared/types/baseListEntry';
import vTab from '@/_shared/directives/TabHandler';
import useAnalyticsStore from '@/_shared/store/analytics';
import use from '@/_shared/compositionApi';
import { storeToRefs } from 'pinia';
import { ReportPage } from '@/_shared/types/analytics';
import { RouterLink, useRoute } from 'vue-router';
import useUserStore from '@/_shared/store/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardReportsList',
  setup(__props) {

const { translate } = use.helpers();
const analyticsStore = useAnalyticsStore();
const { currentPersonReports } = storeToRefs(analyticsStore);

const { featureToggles } = storeToRefs(useUserStore());

const route = useRoute();

const parentIsActive = (parentLink: NestedLink) => route?.params?.category_id === parentLink.id;

const parentIcon = (parentLink: NestedLink) => (parentIsActive(parentLink) ? 'minus' : 'plus');

const defaultChildrenProps = {
  rightIcon: 'chevron-right',
  withIcon: false,
  invertedColour: true,
  isChild: true,
};

const warningBoards: NestedLink = {
  props: {
    title: translate('dashboards.warnings'),
    ...defaultChildrenProps,
  },
  to: {
    name: 'dashboards.legacycategory',
    params: { category_id: 'warnings' },
  },
  id: 'warnings',
  children: [
    {
      props: {
        title: translate('dashboards.warningDuration'),
        ...defaultChildrenProps,
      },
      to: {
        name: 'dashboards.legacycategory.subcategory',
        params: { category_id: 'warnings', subcategory_id: 'warning_duration' },
      },
      id: 'warning_duration',
    },
    {
      props: {
        title: translate('dashboards.manualWarnings'),
        ...defaultChildrenProps,
      },
      to: {
        name: 'dashboards.legacycategory.subcategory',
        params: { category_id: 'warnings', subcategory_id: 'manual_warnings' },
      },
      id: 'manual_warnings',
    },
    {
      props: {
        title: translate('dashboards.dueWarnings'),
        ...defaultChildrenProps,
      },
      to: {
        name: 'dashboards.legacycategory.subcategory',
        params: { category_id: 'warnings', subcategory_id: 'due_warnings' },
      },
      id: 'due_warnings',
    },
  ],
};

const carePlanBoards:NestedLink = {
  props: {
    title: translate('dashboards.carePlans'),
    ...defaultChildrenProps,
  },
  to: {
    name: 'dashboards.legacycategory',
    params: { category_id: 'care-plans' },
  },
  id: 'care-plans',
  children: [
    {
      props: {
        title: translate('dashboards.carePlanReviewsCompleted'),
        ...defaultChildrenProps,
      },
      to: {
        name: 'dashboards.legacycategory.subcategory',
        params: { category_id: 'care-plans', subcategory_id: 'care_plan_reviews_timeline' },
      },
      id: 'care_plan_reviews_timeline',
    },
    {
      props: {
        title: translate('dashboards.carePlanStatus'),
        ...defaultChildrenProps,
      },
      to: {
        name: 'dashboards.legacycategory.subcategory',
        params: { category_id: 'care-plans', subcategory_id: 'care_plan_review_status' },
      },
      id: 'care_plan_review_status',
    },
  ],
};

const angularNestedLinks = () => [
  featureToggles.value.dashboardWarnings ? warningBoards : undefined,
  featureToggles.value.dashboardCarePlans ? carePlanBoards : undefined,
].filter(Boolean) as NestedLink[];

const nestedLinks: ComputedRef<NestedLink[]> = computed(() => {
  const reports: NestedLink[] = currentPersonReports.value?.map((report) => ({
    props: {
      title: ['universal'].includes(report.type as string) ? report.displayName : report.name,
      ...defaultChildrenProps,
    },
    to: {
      name: 'dashboards.category',
      params: { category_id: report.id },
    },
    id: report.id,
    children: ((report.pages as ReportPage[] || [])).map((page) => {
      const isOverview = page.displayName === 'Overview';
      const name = `dashboards.category${isOverview ? '' : '.subcategory'}`;
      const subCategoryParam = isOverview ? {} : { subcategory_id: page.name };
      return {
        props: {
          title: page.displayName,
          ...defaultChildrenProps,
        },
        to: {
          name,
          params: { category_id: report.id, ...subCategoryParam },
          state: { pageId: `${report.id}-${page.displayName}` },
        },
        id: `${report.id}-${page.displayName}`,
      } as NestedLink;
    }),
  } as NestedLink));
  return angularNestedLinks().concat(reports);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nestedLinks.value, (nestedLink) => {
    return (_openBlock(), _createElementBlock("div", {
      key: nestedLink.id
    }, [
      _createVNode(_unref(RouterLink), {
        to: nestedLink.to
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_unref(BaseListEntry), {
            title: nestedLink.props.title as string,
            children: nestedLink.children as NestedLink[],
            invertedColour: "",
            class: "v-report-entry",
            "with-icon": false,
            rightIcon: parentIcon(nestedLink),
            active: parentIsActive(nestedLink)
          }, {
            childEntry: _withCtx((slotProps) => [
              _createVNode(_unref(RouterLink), {
                to: slotProps.to
              }, {
                default: _withCtx(({isActive}) => [
                  _withDirectives(_createVNode(_unref(BaseListEntry), _mergeProps({
                    class: "v-page-entry",
                    ref_for: true
                  }, { ...slotProps.props, active: !!isActive }), null, 16), [
                    [_unref(vTab)]
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1032, ["title", "children", "rightIcon", "active"])), [
            [_unref(vTab)]
          ])
        ]),
        _: 2
      }, 1032, ["to"])
    ]))
  }), 128))
}
}

})