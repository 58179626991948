import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-daily-note" }
const _hoisted_2 = ["onClick"]

import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { isFuture, isPast, isToday } from 'date-fns';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import use from '../../_shared/compositionApi';
import BaseNote from '../../_shared/components/inputs/BaseNote.vue';
import BaseInput from '../../_shared/components/inputs/BaseInput.vue';
import BaseHandoverFlag from './HandoverFlag.vue';
import Note from '../types/Note';


export default /*@__PURE__*/_defineComponent({
  __name: 'DailyNote',
  props: {
  disabled: {
    type: Boolean,
    required: false,
  },
  showHandover: {
    type: Boolean,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  defaultNotesType: {
    type: String,
    required: false,
  },
  cancelJustification: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const {
  currentInteraction,
  timelineDate,
  isNotBulkClose,
} = storeToRefs(useCurrentInteractionStore());

const { translate } = use.helpers();
const defaultDailyNotes = ref<Note[]>(currentInteraction.value?.defaultNotes?.closePublic || []);
if (props.defaultNotesType === 'cancel') {
  defaultDailyNotes.value = currentInteraction.value?.defaultNotes?.cancel || [];
}
const setHandover = () => {
  if (props.disabled || !currentInteraction.value || handoverNotAvailable()) {
    return;
  }
  // TODO - remove recalculates from here as it is a placeholder to prove it works
  // currentInteraction.value.recalculateActions();
  // currentInteraction.value.recalculateComputedParameters();
  const now = new Date();
  currentInteraction.value.finishAt = now.toString();
  currentInteraction.value.closedAt = now.toString();
  // END TODO

  currentInteraction.value.handover = !currentInteraction.value.handover;
};

const toggleDefaultNoteOption = (option: string) => {
  if (!currentInteraction.value) {
    return;
  }
  if (!currentInteraction.value.notePublic) {
    currentInteraction.value.notePublic = '';
  }
  let notes = currentInteraction.value.notePublic;
  if (notes.includes(option)) {
    notes = notes.replace(`${option}`, ' ');
  } else {
    notes = `${notes} ${option}`;
  }
  currentInteraction.value.notePublic = notes.trim();
};

const optionSelected = (text: string) => currentInteraction.value?.notePublic?.includes(text);
const toggleOption = (option: string) => {
  if (props.disabled) {
    return;
  }
  toggleDefaultNoteOption(option);
};

const iconClass = (defaultNote: Note) => {
  if (defaultNote.note) {
    return optionSelected(defaultNote.note) ? 'selected' : 'deselected';
  }
  return 'deselected';
};

// TODO these code is missing in vue (From angular )
// function maybeDisableHandover() {
// if (DateUtils.isOlderThan(ctrl.interaction.finishAt, 24, 'hours')) {
//   ctrl.handoverDisabled = true;
//   ctrl.handoverDisabledReason = ctrl.t('handover.past_record');
//   return;
// }
//
// if ((ctrl.interaction.finishAt && isInFuture(ctrl.interaction.finishAt))
//     || (ctrl.interaction.startAt && isInFuture(ctrl.interaction.startAt))) {
//   ctrl.handoverDisabled = true;
//   ctrl.handoverDisabledReason = ctrl.t('handover.future_record');
// }
//
// if (signatureParameterHelper.hasSignatureParameter(ctrl.interaction) && isClosed(ctrl.interaction)) {
//   ctrl.handoverDisabled = true;
// }
// }

const handoverDisabledReason = ref();

const handoverNotAvailable = (): boolean => !!props.disabled || handoverOutsideDateRange.value || !isNotBulkClose.value;
if (!isNotBulkClose.value) {
  handoverDisabledReason.value = translate('timeline.interaction.handoverBulkClose');
}

const handoverOutsideDateRange = computed((): boolean => {
  if (timelineDate.value === undefined || timelineDate.value === null) {
    return false;
  }
  const outsideRange = !isToday(timelineDate.value);
  // in case is a new interaction and handover was set before changing date
  if (outsideRange && currentInteraction.value.id === null) {
    resetHandover();
  }
  if (outsideRange) {
    setHandoverDisabledReason();
  }
  return outsideRange;
});

const resetHandover = () => {
  currentInteraction.value.handover = false;
};

const setHandoverDisabledReason = () => {
  if (isPast(timelineDate.value)) {
    handoverDisabledReason.value = translate('timeline.interaction.handoverPastRecord');
  } else if (isFuture(timelineDate.value)) {
    handoverDisabledReason.value = translate('timeline.interaction.handoverFutureRecord');
  }
};

const setDefaultNotes = () => {
  if (defaultDailyNotes.value && defaultDailyNotes.value.length) {
    defaultDailyNotes.value.forEach((defaultNote) => {
      if (defaultNote.default === 'true' && defaultNote.note && currentInteraction.value?.notePublic) {
        toggleDefaultNoteOption(defaultNote.note);
      }
    });
  }
};
setDefaultNotes();

// TODO these code is missing in vue (From angular )
// //         function showHandover() {
// if (currentApp.isFamilyPortal() || covidTracker) { return false; }
// return !($ctrl.viewOnly && !$ctrl.interaction.handover);
// }
//
// function clickOnHandover() {
//   if ($ctrl.handoverDisabledReason) {
//     toastMessage.error($ctrl.handoverDisabledReason);
//   }
// }

//    Todo add reason when handover is disabled on bulk close
//     handover-disabled-reason="{{ $ctrl.t('handover.group_close') }}">


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseNote, {
      title: __props.title ? __props.title : _unref(translate)('timeline.interaction.notePublic'),
      "cancel-justification": __props.cancelJustification
    }, {
      headerRight: _withCtx(() => [
        (__props.showHandover)
          ? (_openBlock(), _createBlock(BaseHandoverFlag, {
              key: 0,
              disabled: handoverNotAvailable(),
              checked: !!_unref(currentInteraction).handover,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (setHandover())),
              handoverDisabledReason: handoverDisabledReason.value
            }, null, 8, ["disabled", "checked", "handoverDisabledReason"]))
          : _createCommentVNode("", true)
      ]),
      mainContent: _withCtx(() => [
        _createVNode(BaseInput, {
          textarea: "",
          transparent: "",
          "input-height": "",
          class: "sp-interaction-note-public",
          disabled: __props.disabled,
          placeholder: __props.disabled? '': _unref(translate)('timeline.interaction.notePlaceholder'),
          modelValue: _unref(currentInteraction).notePublic,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(currentInteraction).notePublic) = $event))
        }, null, 8, ["disabled", "placeholder", "modelValue"])
      ]),
      _: 1
    }, 8, ["title", "cancel-justification"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(defaultDailyNotes.value, (defaultNote) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "v-default-notes",
        key: defaultNote.note,
        onClick: ($event: any) => (toggleOption(defaultNote.note))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["v-default-note", __props.disabled ? 'disabled' : ''])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["v-default-note-value", iconClass(defaultNote)])
          }, _toDisplayString(defaultNote.label || defaultNote.note), 3),
          _createElementVNode("span", {
            class: _normalizeClass(["v-default-note-icon selected", iconClass(defaultNote)])
          }, null, 2)
        ], 2)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}
}

})