import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-level-of-need" }
const _hoisted_2 = { class: "v-level-of-need-title" }
const _hoisted_3 = { class: "v-level-of-need-item" }
const _hoisted_4 = { class: "v-level-of-need-container" }
const _hoisted_5 = { class: "v-level-of-need-description" }
const _hoisted_6 = {
  key: 0,
  class: "v-missing-text"
}

import { computed, PropType } from 'vue';
import { calculateLevelOfNeedClass } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelOfNeed',
  props: {
  levelOfNeed: {
    type: Array as PropType<(string | null)[]>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  fallbackText: {
    type: String,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const displayValue = computed(() => props.levelOfNeed && props.levelOfNeed[1]);
const description = computed(() => props.levelOfNeed && props.levelOfNeed[0]);
const levelOfNeedClass = computed(() => calculateLevelOfNeedClass(displayValue.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass('v-level-of-need-icon v-level-of-need-icon--' + levelOfNeedClass.value)
        }, _toDisplayString(displayValue.value || 'NA'), 3),
        _createElementVNode("span", _hoisted_5, _toDisplayString(description.value), 1)
      ]),
      (!displayValue.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(__props.fallbackText), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})