import { safeTranslate } from '@/_shared/translations/i18n';
import { EventPwsInstance } from '@/_shared/types/NourishInstance';

export function filterOption(values: string[], translationKey: string) {
  const options = values.map((option) => ({
    name: safeTranslate(`${translationKey}.${option}`),
    codename: option,
  }));

  if (options.length !== 1) {
    options.push({
      name: safeTranslate('filter.all'),
      codename: 'all',
    });
  }

  return options.sort(sortOptionsComparator);
}

export function filterOptionsWithCount(values: {name: string, instanceCount: number}[], translationKey: string) {
  const options = values.map((option) => ({
    name: safeTranslate(`${translationKey}.${option.name}`),
    codename: option.name,
    instanceCount: option.instanceCount,
  }));
  let allCount = 0;
  options.forEach((option) => {
    allCount += option.instanceCount;
  });
  if (options.length !== 1) {
    options.push({
      name: safeTranslate('filter.all'),
      codename: 'all',
      instanceCount: allCount,
    });
  }

  return options.sort(sortOptionsComparator);
}

type OptionToSort = { name: string, codename: string, instanceCount?: number };

function sortOptionsComparator(a: OptionToSort, b: OptionToSort) {
  return a.name.localeCompare(b.name);
}

function stateTranslations(translationKey: string) {
  return {
    new: safeTranslate(`${translationKey}.new`),
    active: safeTranslate(`${translationKey}.active`),
    archived: safeTranslate(`${translationKey}.archived`),
  };
}

function getOptions(values: string[], translationKey: string) {
  return [
    values.includes('new') && values.includes('active') && {
      name: `${stateTranslations(translationKey).new} & ${stateTranslations(translationKey).active}`,
      codename: 'new&active',
    },
    values.includes('new') && !values.includes('active') && {
      name: stateTranslations(translationKey).new,
      codename: 'new',
    },
    !values.includes('new') && values.includes('active') && {
      name: stateTranslations(translationKey).active,
      codename: 'active',
    },
    values.includes('archived') && {
      name: stateTranslations(translationKey).archived,
      codename: 'archived',
    },
  ].filter(Boolean) as { name: string, codename: string }[];
}

export const filterStates = (values: string[], translationKey: string) => {
  const options = getOptions(values, translationKey);

  if (options.length !== 1) {
    options.push({ name: safeTranslate('filter.all'), codename: 'all' });
  }

  return options;
};

export function filterEventPWSInstances(currentSeverity: string, currentState: string, instance: EventPwsInstance) {
  const matchesSeverity = currentSeverity === 'all' || instance.metadata.severity === currentSeverity;
  const matchesState = currentState === 'all'
    || (currentState === 'new&active' ? instance.state !== 'archived' : instance.state === currentState);
  return matchesSeverity && matchesState;
}

export function filterTextToDisplay(filters: string[]) {
  const filtersToDisplay: string[] = [];
  filters.forEach((f) => {
    if (filtersToDisplay.includes(f)) {
      return;
    }
    filtersToDisplay.push(f);
  });
  return filtersToDisplay.join(', ');
}
