import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { BaseAlert } from '@/_shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericFileDeleteConfirmation',
  emits: ['deleteFileAction', 'hideDeleteAlert'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();
loadModuleLanguageFileAsync('generic_file');

const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseAlert), {
    icon: "delete",
    onConfirmAlert: _cache[0] || (_cache[0] = ($event: any) => (emit('deleteFileAction'))),
    onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (emit('hideDeleteAlert')))
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_unref(translate)('generic_file.delete_confirm_header')), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_unref(translate)('generic_file.delete_confirm')), 1)
    ]),
    _: 1
  }))
}
}

})