import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gp-connect-error-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "card v-gp-connect-error" }, _createSlots({ _: 2 }, [
    (_ctx.errorType === 'demographics')
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_ctx.response.demographicsError?.title), 1),
            _createElementVNode("h3", null, [
              _createTextVNode(_toDisplayString(_ctx.translate('gp_connect.demographics_error')) + ": ", 1),
              _createElementVNode("ul", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.response.demographicsError?.mismatches, (value) => {
                  return (_openBlock(), _createElementBlock("li", { key: value }, _toDisplayString(value), 1))
                }), 128))
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('gp_connect.demographics_error_gp')) + ": " + _toDisplayString(_ctx.response.demographicsError?.odsCode), 1)
            ])
          ]),
          key: "0"
        }
      : (_ctx.errorType=='error_message')
        ? {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("h2", null, _toDisplayString(_ctx.response.errorMessage.title), 1),
              _createElementVNode("h3", null, [
                _createTextVNode(_toDisplayString(_ctx.response.errorMessage.message) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('gp_connect.gpc_support_team')), 1)
              ])
            ]),
            key: "1"
          }
        : {
            name: "content",
            fn: _withCtx(() => [
              _createElementVNode("h2", null, _toDisplayString(_ctx.translate('gp_connect.error_message')), 1),
              _createElementVNode("h3", null, [
                _createTextVNode(_toDisplayString(_ctx.response.error), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate('gp_connect.gpc_support_team')), 1)
              ])
            ]),
            key: "2"
          }
  ]), 1024))
}