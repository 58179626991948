import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-gp-connect-success" }
const _hoisted_2 = { class: "v-page-loaded-at" }
const _hoisted_3 = { class: "v-heading-text" }
const _hoisted_4 = ["innerHTML"]

import { format } from 'date-fns';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import { CalendarEvent, GpConnectResponse, Sections } from '../types/gp_connect';
import GpConnectPatientDetails from './GpConnectPatientDetails.vue';
import GpConnectDateFilter from './GpConnectDateFilter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GpConnectSuccess',
  props: {
    response: {},
    section: {},
    showDateFilter: { type: Boolean }
  },
  emits: ["calendarEvent"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();
const pageName = translate(Sections[props.section].pageNameKey);

function formatPageLoaded(date: string) {
  return format(Date.parse(date), 'h:mm a - dd/MM/yyyy');
}

function calendarEvent(data: CalendarEvent) {
  emit('calendarEvent', data);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)("gp_connect.page_loaded_at")) + ": " + _toDisplayString(formatPageLoaded(_ctx.response.pageLoadedAt)), 1),
    _createVNode(GpConnectPatientDetails, { response: _ctx.response }, null, 8, ["response"]),
    _createVNode(BaseCard, null, {
      header: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "v-gp-connect-logo",
          src: "/gp-connect-logo.svg",
          alt: "Logo for GP Connect"
        }, null, -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(pageName)), 1)
      ]),
      content: _withCtx(() => [
        (_ctx.showDateFilter)
          ? (_openBlock(), _createBlock(GpConnectDateFilter, {
              key: 0,
              onCalendarEvent: calendarEvent
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "v-gp-connect-html-block",
          innerHTML: _ctx.response.divResponse
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    })
  ]))
}
}

})