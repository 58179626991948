import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-banner-container v-actions-wont-run-banner" }

import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionsWontRunBanner',
  setup(__props) {

const { translate } = use.helpers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(translate)('timeline.interaction.actions_wont_run_banner')), 1))
}
}

})