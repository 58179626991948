import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-camera-background" }
const _hoisted_2 = { class: "v-camera-container" }
const _hoisted_3 = { class: "v-camera-row-container" }
const _hoisted_4 = { class: "v-camera-title" }
const _hoisted_5 = { class: "v-camera-row-container" }
const _hoisted_6 = { class: "v-actions-row" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "v-save-discard"
}

import { ref, onMounted } from 'vue';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { base64toFile } from '@/_shared/helpers/fileHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'CameraModal',
  emits: ['closeCameraModal', 'openPictureModal', 'savePicture'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { translate } = use.helpers();

const camera = ref<HTMLVideoElement>();
const canvas = ref<HTMLCanvasElement>();
const pictureTaken = ref(false);

onMounted(async () => {
  await startCameraStream();
});

async function startCameraStream() {
  if (!camera.value) return;
  camera.value.srcObject = await navigator.mediaDevices.getUserMedia({ video: true });
}

function closeCameraStream() {
  if (!camera.value || !camera.value.srcObject) return;
  (camera.value.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
  camera.value.srcObject = null;
}

function closeStreamAndModal(openPictureModal = false) {
  closeCameraStream();
  emit('closeCameraModal');
  if (openPictureModal) emit('openPictureModal');
}

function takePicture() {
  pictureTaken.value = true;
  const canvasContext = (canvas.value as HTMLCanvasElement).getContext('2d');
  if (!canvasContext) return;
  const cameraElement: HTMLVideoElement = camera.value as HTMLVideoElement;
  canvasContext.canvas.width = cameraElement.videoWidth;
  canvasContext.canvas.height = cameraElement.videoHeight;
  canvasContext.drawImage((camera.value as HTMLVideoElement), 0, 0);
}

function discardPicture() {
  pictureTaken.value = false;
}

const generateThumbnail = () => {
  const thumbnailCanvas = document.createElement('canvas');
  const aspectRatio = canvas.value ? canvas.value.width / canvas.value.height : 1;
  if (aspectRatio > 1) {
    thumbnailCanvas.width = 100;
    thumbnailCanvas.height = 100 / aspectRatio;
  } else {
    thumbnailCanvas.width = 100 * aspectRatio;
    thumbnailCanvas.height = 100;
  }
  const thumbnailContext = thumbnailCanvas.getContext('2d');
  thumbnailContext?.drawImage((canvas.value as HTMLCanvasElement), 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
  return thumbnailCanvas.toDataURL('image/jpeg', 0.5);
};

function savePicture() {
  const picture = (canvas.value as HTMLCanvasElement).toDataURL('image/jpeg');

  const thumbnail = generateThumbnail();
  const file = base64toFile(picture, 'picture.jpeg');
  emit('savePicture', file, thumbnail);

  closeStreamAndModal();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    "card-disabled": true,
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (emit('closeCameraModal')))
  }, {
    backdrop: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(translate)('timeline.parameters.picture.takePhoto')), 1),
            _createVNode(BaseIcon, {
              class: "v-close-button",
              transparent: true,
              name: "nr-icon-remove",
              foregroundColor: "white",
              size: "sm",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (closeStreamAndModal(true)))
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("video", {
              ref_key: "camera",
              ref: camera,
              autoplay: "",
              width: 950,
              height: 650
            }, null, 512), [
              [_vShow, !pictureTaken.value]
            ]),
            _withDirectives(_createElementVNode("canvas", {
              ref_key: "canvas",
              ref: canvas,
              width: 950,
              height: 650
            }, null, 512), [
              [_vShow, pictureTaken.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (!pictureTaken.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("button", {
                    class: "v-take-picture-button",
                    onClick: takePicture
                  }, [
                    _createVNode(BaseIcon, {
                      name: "check",
                      size: "lg",
                      noHover: "",
                      foregroundColor: "blue",
                      position: "relative"
                    })
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("button", {
                    onClick: discardPicture,
                    class: "v-discard-image-button"
                  }, [
                    _createVNode(BaseIcon, {
                      name: "nr-icon-trash",
                      size: "lg",
                      noHover: "",
                      compact: "",
                      foregroundColor: "blue",
                      position: "relative"
                    })
                  ]),
                  _createElementVNode("button", {
                    onClick: savePicture,
                    class: "v-save-image-button"
                  }, [
                    _createVNode(BaseIcon, {
                      name: "check",
                      size: "lg",
                      noHover: "",
                      foregroundColor: "blue",
                      position: "relative"
                    })
                  ])
                ]))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})