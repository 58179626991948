import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-top-actions-bar" }
const _hoisted_2 = { class: "client-navigation-tabs hidden-xs" }

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTopActionsBar',
  props: {
  show: Boolean,
},
  setup(__props) {

_useCssVars(_ctx => ({
  "c95caa2e": (visibility.value),
  "39bb5074": (pointerEvent.value)
}))

const props = __props;
const visibility = computed(() => {
  if (props.show) return 'visible';
  return 'hidden';
});
const pointerEvent = computed(() => {
  if (props.show) return 'auto';
  return 'none';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})