import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-interaction-documents" }

import { computed } from 'vue';
import BaseDocumentList from '@/_shared/components/display/BaseDocumentList.vue';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import use from '../../_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionDocuments',
  setup(__props) {

const { translate } = use.helpers();
const { currentInteraction } = storeToRefs(useCurrentInteractionStore());

const carePlanDocumentsCount = computed(() => currentInteraction.value?.carePlanDocuments.length);
const supportingDocumentsCount = computed(() => currentInteraction.value?.supportingDocuments.length);
const showCareplanDocuments = computed(() => carePlanDocumentsCount.value && carePlanDocumentsCount.value > 0);
const showSupportingDocuments = computed(() => supportingDocumentsCount.value && supportingDocumentsCount.value > 0);

const sortedCareplanDocuments = computed(() => {
  const careplanDocuments = currentInteraction.value?.carePlanDocuments ?? [];
  return careplanDocuments.sort((a, b) => {
    const labelA = a?.fileLabel ?? a?.fileName ?? '';
    const labelB = b?.fileLabel ?? b?.fileName ?? '';
    return labelA.localeCompare(labelB);
  });
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showSupportingDocuments.value)
      ? (_openBlock(), _createBlock(BaseDocumentList, {
          key: 0,
          documents: _unref(currentInteraction).supportingDocuments,
          icon: "nsx-font-icon--attached-document",
          header: _unref(translate)('timeline.interaction.supportingDocuments'),
          "show-back-button": "",
          onToggleShowDocuments: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleShowDocuments')))
        }, null, 8, ["documents", "header"]))
      : _createCommentVNode("", true),
    (showCareplanDocuments.value)
      ? (_openBlock(), _createBlock(BaseDocumentList, {
          key: 1,
          documents: sortedCareplanDocuments.value,
          icon: "nsx-font-icon--care-plan",
          header: _unref(translate)('timeline.interaction.carePlanDocuments'),
          "show-back-button": supportingDocumentsCount.value === 0,
          onToggleShowDocuments: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleShowDocuments')))
        }, null, 8, ["documents", "header", "show-back-button"]))
      : _createCommentVNode("", true)
  ]))
}
}

})