import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "v-tooltip" }

import vBaseTooltip from '@/_shared/directives/BaseTooltip';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTooltip',
  props: {
  tooltipText: {
    type: String,
    default: 'Tooltip text',
  },
  position: {
    default: 'top',
    type: String,
  },
  hide: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ])), [
    [_unref(vBaseTooltip), {tooltipText: __props.tooltipText , position: __props.position, hide: __props.hide}]
  ])
}
}

})