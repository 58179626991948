import apiClient from '@/_shared/services/apiClient';
import { Availability } from '../types/availability';

export interface AvailabilitiesApiResponse {
  availabilities: Availability[]
}

export const fetchAvailabilities = async (carerId: number) => {
  const url = `people/${carerId}/availabilities`;
  try {
    const resp = await apiClient.get<AvailabilitiesApiResponse>(url);
    return resp.availabilities;
  } catch (error) {
    return [];
  }
};

export const createOrUpdateAvailabilities = async (item: Availability) => {
  const url = `organisation_units/${item.organisationUnitId}/availabilities/${item.personId}`;

  try {
    await apiClient.put<Availability>(url, { availability: item });
    return true;
  } catch (error) {
    return false;
  }
};
