import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-date-time-parameter-container" }
const _hoisted_2 = { class: "v-date-time-selector" }
const _hoisted_3 = { class: "v-base-date-time-picker" }

import { toRef, Ref } from 'vue';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import { Parameter } from '../types/Parameter';
import BaseParameter from './BaseParameter.vue';
import { getMinDateToEnable, getMaxDateToEnable } from '../helper/parametersHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const dateValue = toRef(props.parameter, 'coercedValue') as Ref<string>;
const { errorMessage } = useParameterValidator(props.parameter);
const minDate = getMinDateToEnable(props.parameter);
const maxDate = getMaxDateToEnable(props.parameter);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true,
      errorMessage: _unref(errorMessage) || undefined
    }, {
      "lhs-slot-second-line": _withCtx(() => [
        _createVNode(BaseIcon, {
          class: "v-calendar-icon no-hover",
          name: "nr-icon-calendar",
          size: "lg",
          foregroundColor: "black",
          noHoverBackground: true
        })
      ]),
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(BaseDatePickerInput, {
              border: "",
              placeholder: `${_unref(translate)('parameter.date_time_placeholder')}`,
              readOnly: __props.readOnly,
              inputWidth: "150%",
              type: "dateTime",
              noIcon: "",
              modelValue: dateValue.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dateValue).value = $event)),
              minDate: _unref(minDate),
              maxDate: _unref(maxDate),
              class: "sp-date-time-picker"
            }, null, 8, ["placeholder", "readOnly", "modelValue", "minDate", "maxDate"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter", "errorMessage"])
  ]))
}
}

})