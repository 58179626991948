import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-update-careplan-table" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { class: "v-pdf-inclusion-message" }
const _hoisted_4 = { class: "v-message" }
const _hoisted_5 = { class: "v-header-right" }

import { BaseTable } from '@/_shared/components';
import { computed, PropType } from 'vue';
import {
  UpdateCarePlanTableData,
} from '@/careplan/types/UpdateCarePlanTableData';
import use from '@/_shared/compositionApi';
import BaseButton from '../../_shared/components/buttons/BaseButton.vue';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';
import BaseTooltip from '../../_shared/components/feedback/BaseTooltip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateCarePlanTable',
  props: {
  data: {
    type: Object as PropType<UpdateCarePlanTableData>,
    required: true,
  },
  hasSavedForLaterSigntrInteraction: {
    type: Boolean,
    required: true,
  },
  hasAccessToConsentCarePlan: {
    type: Boolean,
    required: true,
  },
},
  emits: ['routeToInteraction', 'updateAgreement'],
  setup(__props, { emit: __emit }) {

const props = __props;

const carePlanTerm = computed(() => window.currentOrganisationUnit.care_plan_term);
const { translate } = use.helpers();
const emit = __emit;

function routeToInteraction() {
  if (!props.hasAccessToConsentCarePlan) return;
  emit('routeToInteraction');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BaseTable), {
      headers: props.data.headers,
      items: props.data.entries,
      "onUpdate:items": _cache[2] || (_cache[2] = ($event: any) => ((props.data.entries) = $event)),
      cellCentered: true,
      stickyHeader: true,
      darkTheme: true,
      customRows: "",
      emptyMessage: _unref(translate)('careplan.no_entries', { care_plan_term: carePlanTerm.value })
    }, {
      default: _withCtx((slotProps) => [
        ((slotProps.item.split_table) == 'split_table')
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              colspan: props.data.headers.length,
              class: "v-td"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(translate)(`careplan.pdf_inclusion_message`, {
                    care_plan_term: carePlanTerm.value,
                  })), 1),
                _createVNode(BaseButton, {
                  class: "v-update-agreement-button",
                  disabled: !props.hasAccessToConsentCarePlan,
                  onClicked: _cache[0] || (_cache[0] = ($event: any) => (emit('updateAgreement')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)('careplan.update_agreement')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(BaseTooltip, {
                  position: "bottom",
                  tooltipText: _unref(translate)('careplan.view_on_timeline')
                }, {
                  default: _withCtx(() => [
                    (props.hasSavedForLaterSigntrInteraction)
                      ? (_openBlock(), _createBlock(BaseIcon, {
                          key: 0,
                          class: "v-clock-icon",
                          name: "clock-o",
                          size: "2x",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (routeToInteraction())),
                          disabled: !props.hasAccessToConsentCarePlan
                        }, null, 8, ["disabled"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["tooltipText"])
              ])
            ], 8, _hoisted_2))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(slotProps.item, (item) => {
              return (_openBlock(), _createElementBlock("td", { key: item }, _toDisplayString(item), 1))
            }), 128))
      ]),
      _: 1
    }, 8, ["headers", "items", "emptyMessage"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(BaseButton, {
        class: "v-update-agreement-button",
        disabled: !props.hasAccessToConsentCarePlan,
        onClicked: _cache[3] || (_cache[3] = ($event: any) => (emit('updateAgreement')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(translate)('careplan.update_agreement')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(BaseTooltip, {
        position: "bottom",
        tooltipText: _unref(translate)('careplan.view_on_timeline')
      }, {
        default: _withCtx(() => [
          (props.hasSavedForLaterSigntrInteraction)
            ? (_openBlock(), _createBlock(BaseIcon, {
                key: 0,
                class: "v-clock-icon",
                name: "clock-o",
                size: "2x",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (routeToInteraction())),
                disabled: !props.hasAccessToConsentCarePlan
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["tooltipText"])
    ])
  ]))
}
}

})