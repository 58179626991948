import apiClient from '@/_shared/services/apiClient';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { formatISODate } from '@/_shared/helpers/useDateTimeHelper';
import { ServiceCount } from '@/timeline/types/ServiceCount';

interface ServiceCountsResponse {
  serviceCounts: ServiceCount[];
}

const useServiceCountsStore = defineStore('serviceCounts', () => {
  const serviceCounts = ref<ServiceCount[]>([]);

  const fetch = async (params: object) => {
    const url = `/api/v2/organisation_units/${window.currentOrganisationUnit.id}/service_counts`;
    const response = await apiClient.get<ServiceCountsResponse>(url, { params });
    serviceCounts.value = response.serviceCounts.length ? sortByServiceName(response.serviceCounts) : [];
  };

  const sortByServiceName = (scs: ServiceCount[]) => {
    const sortedServiceCounts = scs.sort((a, b) => {
      if (a.serviceName < b.serviceName) {
        return -1;
      }
      if (a.serviceName > b.serviceName) {
        return 1;
      }
      return 0;
    });
    return sortedServiceCounts as ServiceCount[];
  };

  const fetchByDate = async (date: Date) => fetch({ date: formatISODate(date) });

  return {
    fetchByDate,
    serviceCounts,
  };
});

export default useServiceCountsStore;
