import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-interaction-header-container" }
const _hoisted_2 = { class: "v-header-left" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "v-interaction-title" }
const _hoisted_5 = { class: "v-cancel-record-note" }

import { computed, ref } from 'vue';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import DailyNote from './DailyNote.vue';
import SubmitButtons from './SubmitButtons.vue';
import AlertBanner from './AlertBanner.vue';
import use from '../../_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'CancelRecordNote',
  emits: ['closeCancelNote'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { currentInteraction, iconUrl, isSticky } = storeToRefs(useCurrentInteractionStore());
const { translate } = use.helpers();
const alertVisible = ref(false);
const hideAlert = () => {
  alertVisible.value = false;
};
const defaultNote = currentInteraction.value?.notePublic || '';

const store = useCurrentInteractionStore();

const cancelCancelNote = () => {
  if (!currentInteraction.value) return;
  currentInteraction.value.notePublic = defaultNote;
  emit('closeCancelNote', false);
};
const showBackButton = computed(() => !store.isCancelled);

const cancelInteraction = () => {
  if (!currentInteraction.value) return;
  if (currentInteraction.value.notePublic == null || currentInteraction.value.notePublic.trim() === '') {
    const missingValue = [`${translate('message.missing_value')} ${translate('timeline.interaction.cancelJustification')}`];
    currentInteraction.value.errors = { missingReasonError: missingValue };
    alertVisible.value = true;
  } else {
    if (isSticky.value) {
      const now = new Date().toISOString();
      currentInteraction.value.finishAt = now;
      if (currentInteraction.value.needsStartAt) {
        currentInteraction.value.startAt = now;
      }
    }
    currentInteraction.value.state = 'cancelled';
    hideAlert();
    emit('closeCancelNote', true);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCard, {
    headerBackground: true,
    padding: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(iconUrl),
            class: "v-service-icon",
            alt: `${_unref(iconUrl)} icon`
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)("common.cancel")) + " " + _toDisplayString(_unref(translate)("timeline.interaction.record")) + " - " + _toDisplayString(_unref(currentInteraction).name), 1)
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(DailyNote, {
          title: _unref(translate)('timeline.interaction.cancelJustification'),
          cancelJustification: true,
          "default-notes-type": "cancel"
        }, null, 8, ["title"])
      ]),
      (alertVisible.value)
        ? (_openBlock(), _createBlock(AlertBanner, {
            key: 0,
            onCloseBanner: hideAlert
          }))
        : _createCommentVNode("", true),
      _createVNode(SubmitButtons, {
        onSaveClicked: cancelInteraction,
        onBackClicked: cancelCancelNote,
        "show-back-button": showBackButton.value
      }, null, 8, ["show-back-button"])
    ]),
    _: 1
  }))
}
}

})