import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-read-only-wrapper" }
const _hoisted_2 = { class: "v-read-only-message" }

import InteractionSection from './InteractionSection.vue';
import use from '../../_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadOnlyNotice',
  props: {
  readOnly: {
    type: Boolean,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (props.readOnly)
    ? (_openBlock(), _createBlock(InteractionSection, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('timeline.interaction.readOnlyMessage')), 1)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})