import { Ref } from 'vue';

function dateSelectionValid(startDate: Date|null, endDate: Date|null, displayInvalidDate: Ref<boolean>) {
  if (
    startDate !== null
      && endDate !== null
      && endDateBeforeStartDate(startDate, endDate)
  ) {
    displayInvalidDate.value = true;
    return false;
  }
  displayInvalidDate.value = false;
  return true;
}

function endDateBeforeStartDate(startDate: Date, endDate: Date) {
  return endDate < startDate;
}

export default dateSelectionValid;
