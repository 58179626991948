export type GpConnectRequest = {
    dataCategory: string,
    clientId: number,
    dateWindow: DateWindow|null
}

export type DateWindow = {
    start: Date,
    end: Date
}

export type GpConnectResponse = {
    divResponse: string|null,
    pageLoadedAt: string,
    personalDetails: PersonalDetails|null,
    error: string|null,
    errorMessage: ErrorMessage|null,
    demographicsError: DemographicsError|null
}

export type PersonalDetails = {
    address: string,
    dob: string,
    familyName: string,
    gender: string,
    givenName: string,
    gpCode: string,
    gpName: string,
    name: string,
    nhsNumber: string,
    pasNumber: string,
    phone: string
}

export type DemographicsError = {
    title: string,
    mismatches: Array<string>
    odsCode: string
}

export type ErrorMessage = {
    toast: string,
    title: string,
    message: string
}

interface GpSection {
  apiSection: string,
  pageNameKey: string,
  hideDate?: boolean,
}

export const Sections: { [name: string]: GpSection } = {
  summary: {
    apiSection: 'SUM',
    pageNameKey: 'gp_connect.summary_button',
    hideDate: true,
  },
  encounters: {
    apiSection: 'ENC',
    pageNameKey: 'gp_connect.encounters_button',
  },
  clinical: {
    apiSection: 'CLI',
    pageNameKey: 'gp_connect.clinical_items_button',
  },
  problems: {
    apiSection: 'PRB',
    pageNameKey: 'gp_connect.problems_button',
  },
  allergies: {
    apiSection: 'ALL',
    pageNameKey: 'gp_connect.allergies_button',
    hideDate: true,
  },
  medications: {
    apiSection: 'MED',
    pageNameKey: 'gp_connect.medications_button',
  },
  referrals: {
    apiSection: 'REF',
    pageNameKey: 'gp_connect.referrals_button',
  },
  observations: {
    apiSection: 'OBS',
    pageNameKey: 'gp_connect.observations_button',
  },
  immunisations: {
    apiSection: 'IMM',
    pageNameKey: 'gp_connect.immunisations_button',
    hideDate: true,
  },
  admin: {
    apiSection: 'ADM',
    pageNameKey: 'gp_connect.administrative_button',
  },
};

export type CalendarEvent = {
  startDate: Date,
  endDate: Date,
  filterByDates: boolean,
}
