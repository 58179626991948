export const instanceCodenames = {
  skin: [
    'skin_pressure_injury',
    'skin_ulcer_instance',
    'skin_tear',
    'skin_burn',
    'skin_laceration',
    'skin_bruise',
    'skin_wounds',
    'skin_surgical_wound',
    'skin_superficial_wound',
    'skin_moisture_lesion',
    'skin_other',
    'skin_unspecified',
  ],
  event_pws: [
    'event_pws_arson',
    'event_pws_assault',
    'event_pws_burn_or_scald',
    'event_pws_chemical_or_biological',
    'event_pws_choking',
    'event_pws_clinical_medical_or_unwell',
    'event_pws_dehydration',
    'event_pws_distressed_reaction_behaviour',
    'event_pws_electrical',
    'event_pws_financial_anomalies_or_missing_property',
    'event_pws_fire',
    'event_pws_hit_by_moving_object',
    'event_pws_impact_with_stationary_object',
    'event_pws_infection',
    'event_pws_medical_equipment',
    'event_pws_medication_error',
    'event_pws_mental_health',
    'event_pws_missing_person_absconsion',
    'event_pws_moving_and_handling',
    'event_pws_physical_altercation',
    'event_pws_pressure_injury',
    'event_pws_property_damage',
    'event_pws_self_harm',
    'event_pws_sharps_injury',
    'event_pws_slip_trip_or_fall',
    'event_pws_traffic_vehicle_or_road_safety',
    'event_pws_trapping_or_crushing',
    'event_pws_unexplained_injury',
    'event_pws_other_event',
  ],
};

export const otherInstanceCodenames = {
  skin: 'skin_other',
  event_pws: 'event_pws_other_event',
};

export type InstanceCodenames = typeof instanceCodenames;

export type NourishInstanceType = keyof InstanceCodenames;

export const DATA_SET_EVENT_SEVERITY = 'data-set-event_severity';
