import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-data-message" }

import { PropType } from 'vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogNoData',
  props: {
  messageType: {
    type: String as PropType<'graph' | 'table'>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const message = props.messageType === 'graph'
  ? translate('log.noDataForGraph')
  : translate('log.noData');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(message)), 1))
}
}

})