import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

import ServiceLog from '@/_shared/components/feature/ServiceLog.vue';
import { ref, watch } from 'vue';

type ServiceLogType = {
  serviceId: number,
  showTotal: boolean,
  showDailyAvg: boolean,
  showTotalDailyAvg: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceLoggers',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  loggerEvent: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const serviceLoggers = ref<ServiceLogType[]>([]);

const props = __props;

watch(() => props.loggerEvent, (event) => {
  serviceLoggers.value.push({
    serviceId: event.detail.serviceId,
    showTotal: event.detail.showTotal,
    showDailyAvg: event.detail.showDailyAvg,
    showTotalDailyAvg: event.detail.showTotalDailyAvg,
  });
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(serviceLoggers.value, (serviceLog) => {
    return (_openBlock(), _createElementBlock("div", {
      key: serviceLog.serviceId
    }, [
      (_openBlock(), _createBlock(_Teleport, {
        to: `#service-logger-for-vue-${serviceLog.serviceId}`
      }, [
        _createVNode(ServiceLog, {
          "client-id": __props.clientId,
          "service-id": serviceLog.serviceId,
          "show-total": serviceLog.showTotal,
          "show-daily-avg": serviceLog.showDailyAvg,
          "show-total-daily-avg": serviceLog.showTotalDailyAvg,
          "show-last-interaction": true
        }, null, 8, ["client-id", "service-id", "show-total", "show-daily-avg", "show-total-daily-avg"])
      ], 8, ["to"]))
    ]))
  }), 128))
}
}

})