import { Options } from '@/_shared/types/baseSelect';
import apiClient from '@/_shared/services/apiClient';
import { folderType, MessageDTO } from '../types/messages';

export interface MessagesApiResponse {
    totalPages: number
    perPage: number
    messages: MessageDTO[]
}

const BASE_URL = 'messages';

export const fetchMessages = async (folder: folderType = 'inbox', page = 0, perPage = 10) => {
  // TODO check API, duplicate keys (and thus duplicate messages are returned)
  const url = `${BASE_URL}?${folder}&page=${page}&per_page=${perPage}`;
  try {
    return await apiClient.get<MessagesApiResponse>(url);
  } catch (error) {
    return {
      totalPages: 0,
      perPage: 10,
      messages: [],
    };
  }
};

export const fetchRecipients = async () => {
  const url = `${BASE_URL}/recipients`;
  try {
    return await apiClient.get<Options>(url);
  } catch (error) {
    return null;
  }
};

export type MessagePatch = { message: Partial<MessageDTO> }

export const patchMessage = async (messageId: number, patch: MessagePatch) => {
  const url = `${BASE_URL}/${messageId}`;
  try {
    await apiClient.patch<MessageDTO, MessagePatch>(url, patch);
    return true;
  } catch (error) {
    return false;
  }
};

export const postMessage = async (message: MessageDTO) => {
  try {
    const url = `${BASE_URL}/async`;
    await apiClient.post<{message: MessageDTO}>(url, { message });
    return true;
  } catch (error) {
    return false;
  }
};

export const setReadStatus = async (messageId: number, status: boolean) => {
  const patch: MessagePatch = { message: { isRead: status } };
  return patchMessage(messageId, patch);
};

export const setArchiveStatus = async (messageId: number, status: boolean) => {
  const patch = { message: { archived: status } };
  return patchMessage(messageId, patch);
};

// export const useMessagesApi() = {
//   fetchMessages: fetchMessages,
//   fetchRecipients: fetchRecipients,
//   postMessage: postMessage,
//   setReadStatus: setReadStatus,
//   setArchiveStatus: setArchiveStatus
// }
