import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-list-entry-inner" }
const _hoisted_2 = { class: "v-warning-info-wrap" }
const _hoisted_3 = { class: "v-note-plain-wrap" }
const _hoisted_4 = { class: "v-plain-note" }
const _hoisted_5 = { class: "v-list-entry-symbol" }
const _hoisted_6 = { class: "v-closer-name" }
const _hoisted_7 = { class: "v-info-wrap" }
const _hoisted_8 = { class: "v-addit-info" }
const _hoisted_9 = { class: "v-addit-info-line v-justification" }
const _hoisted_10 = { class: "v-addit-info-line" }
const _hoisted_11 = {
  key: 0,
  class: "time"
}

import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClosedAlarmInfo',
  setup(__props) {

const { closedAlarms } = storeToRefs(useCurrentInteractionStore());

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(closedAlarms), (alarm) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "v-list-entry-alarm-info",
      key: `${alarm.interactionId}-${alarm.id}`
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(BaseAvatar, {
                  photo: alarm.closerPhoto,
                  size: "small"
                }, null, 8, ["photo"]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(alarm.closerName), 1)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("i", null, "(Warning closed by)", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(alarm.closeJustification), 1),
              _createElementVNode("div", _hoisted_10, [
                (alarm.closedAt)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Closed at: " + _toDisplayString(_unref(formatDateTime)(alarm.closedAt)), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}
}

})