import apiClient from '@/_shared/services/apiClient';
import { AuditLog } from '@/_shared/types/auditLog';

export interface LambdaResponse {
  status: number;
  statusText: string;
}

export interface TokenResponse {
  token: string;
  expiresAt: number;
}

const postAuditLogs = async (auditLogs: AuditLog[], details: [string, string], token: string) => {
  try {
    return await fetch(details[0], {
      method: 'POST',
      body: JSON.stringify(auditLogs),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        'X-Api-Key': details[1],
      },
    }) as LambdaResponse;
  } catch (error) {
    return error;
  }
};

export const fetchAuthToken = async () => {
  try {
    return await apiClient.get<TokenResponse>('/api/v2/audit_trail/auth_token');
  } catch (error) {
    return error;
  }
};

export const fetchAuditTrailDetails = async () => {
  try {
    return await apiClient.get('/api/v2/audit_trail/details');
  } catch (error) {
    return error;
  }
};

export default postAuditLogs;
