import apiClient from '@/_shared/services/apiClient';
import {
  AllReportsResponse, Report, ReportResponse,
} from '@/_shared/types/report';
import useUserStore from '@/_shared/store/user';
import { ApiResponse } from './apiClientBase';

export const fetchAllReports = async (orgUnitId = useUserStore().currentOrgUnitId as number): Promise<Report[]> => {
  try {
    const response: AllReportsResponse = await apiClient.get(`/api/v2/organisation_units/${orgUnitId}/reports`);
    return response.reports;
  } catch (error) {
    return error;
  }
};

export const createReport = async (report: Report, clientIds: number[] = [], fields: string[] = []) => {
  const reportData = getCreateReportData(report);
  if (clientIds.length) {
    reportData.report.params.clientIds = clientIds;
  }
  if (fields.length) {
    reportData.report.params.fields = fields;
  }

  const orgUnitId = useUserStore().currentOrgUnitId;
  try {
    const response = await apiClient.post(`/api/v2/organisation_units/${orgUnitId}/reports`, reportData);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchReport = async (reportId: number): Promise<Report> => {
  try {
    const orgUnitId = useUserStore().currentOrgUnitId;
    const response: ReportResponse = await apiClient.get(`/api/v2/organisation_units/${orgUnitId}/reports/${reportId}`);
    return response.report;
  } catch (error) {
    return error;
  }
};

export const deleteReport = async (reportId: number) => {
  try {
    const orgUnitId = useUserStore().currentOrgUnitId;
    await apiClient.delete(`/api/v2/organisation_units/${orgUnitId}/reports/${reportId}`);
    return true;
  } catch (error) {
    return error;
  }
};

export const downloadReport = async (report: Report) => {
  try {
    const downloadURL = await getReportDownloadURL(report);
    const link = document.createElement('a');
    link.href = downloadURL;
    const fileName = `${report.title}.${report.format}`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadURL);
    return true;
  } catch (error) {
    return error;
  }
};

export const previewReport = async (report: Report) => {
  try {
    const blobURL = await getReportDownloadURL(report);
    window.open(blobURL, '_blank');
    return true;
  } catch (error) {
    return error;
  }
};

const getCreateReportData = (report: Report) => {
  const reportData = {
    report: {
      title: report.title,
      report_class: report.reportClass,
      format: report.format,
      params: {
        page_break: report.params.pageBreak,
        page_numbering: report.params.pageNumbering,
        redaction_option: report.params.redactionOption,
      } as Record<string, string|boolean|number[]|string[]>,
    },
  };
  return reportData;
};

const getReportDownloadURL = async (report: Report) => {
  const apiURL = `${window.location.origin}${report.file.url}`;
  const requestConfig = { responseType: 'blob' as const };
  const response = await apiClient.get(apiURL, requestConfig) as ApiResponse<Blob>;

  return window.URL.createObjectURL(new Blob([response.data]));
};
