import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, mergeProps as _mergeProps } from "vue"

import { BaseListEntry } from '@/_shared/components';
import {
  computed, ComputedRef,
} from 'vue';
import { RouterLink } from 'vue-router';
import dataRetentionIcon from '@/_shared/assets/icons/data_retention.svg';
import use from '@/_shared/compositionApi';
import { NestedLink } from '@/_shared/types/baseListEntry';
import vTab from '@/_shared/directives/TabHandler';
import { clientStore } from '@/_shared/store/clients';
import useDataRetentionStore from '@/_shared/store/dataRetention';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataRetentionLeftPanel',
  setup(__props) {


const { translate } = use.helpers();
const defaultChildrenProps = {
  rightIcon: 'chevron-right',
  withIcon: false,
};

const dataRetentionStore = useDataRetentionStore();
const {
  pendingDeletionsCount,
} = storeToRefs(dataRetentionStore);

const mainBtnTitle = translate('navigation.admin.data_retention.title', { client_term: clientStore.clientTerm(false) });
const nestedLink: ComputedRef<NestedLink[]> = computed(() => [
  {
    props: {
      title: translate('navigation.admin.data_retention.pending', { client_term: clientStore.clientTerm(), count: pendingDeletionsCount.value }),
      ...defaultChildrenProps,
    },
    to: { name: 'admin.data_retention.pending' },

  },
  {
    props: {
      title: translate('navigation.admin.data_retention.report'),
      ...defaultChildrenProps,
    },
    to: { name: 'admin.data_retention.report' },
  },

  {
    props: {
      title: translate('navigation.admin.data_retention.deleted_client', { client_term: clientStore.clientTerm() }),
      ...defaultChildrenProps,
    },
    to: { name: 'admin.data_retention.deleted' },
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, _mergeProps({ to: "#lp-container" }, _ctx.$attrs), [
    _withDirectives((_openBlock(), _createBlock(_unref(BaseListEntry), {
      title: _unref(mainBtnTitle),
      icon: _unref(dataRetentionIcon),
      active: "",
      children: nestedLink.value,
      class: "v-data-retention-left-panel top-entry"
    }, {
      childEntry: _withCtx((slotProps) => [
        _createVNode(_unref(RouterLink), {
          to: (slotProps as NestedLink).to,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(({isActive}) => [
            _withDirectives(_createVNode(_unref(BaseListEntry), _normalizeProps(_guardReactiveProps({ ...((slotProps as NestedLink).props), active : !!isActive})), null, 16), [
              [_unref(vTab)]
            ])
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      _: 1
    }, 8, ["title", "icon", "children"])), [
      [_unref(vTab)]
    ])
  ], 16))
}
}

})