import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-careplan-folder-container" }
const _hoisted_2 = { class: "v-careplan-folder-content" }
const _hoisted_3 = { class: "v-careplan-folder-text" }
const _hoisted_4 = {
  key: 0,
  class: "v-description-container"
}
const _hoisted_5 = ["textContent", "contenteditable"]
const _hoisted_6 = { class: "v-careplan-page-container" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';
import {
  ref, computed, nextTick, PropType,
} from 'vue';
import { CarePlanFolder } from '@/_shared/types/carePlan';
import BaseCarePlanPage from './BaseCarePlanPage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCarePlanFolder',
  props: {
  carePlanFolder: {
    type: Object as PropType<CarePlanFolder>,
  },
  descriptionDisabled: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    required: true,
  },
},
  setup(__props) {



const { translate } = use.helpers();
const editingDesc = ref(false);
const descriptionText = ref('');
const editableSpan = ref<HTMLElement | null>(null);

const placeholder = computed(() => translate('careplan.description_placeholder'));
const displayText = computed(() => (editingDesc.value ? descriptionText.value : descriptionText.value || placeholder.value));

async function startEditing() {
  editingDesc.value = true;
  await nextTick();
  if (editableSpan.value) {
    editableSpan.value.focus();
  }
}

function endEditing() {
  editingDesc.value = false;
}

function handleInput(event: Event) {
  const target = event.target as HTMLElement;
  descriptionText.value = target.innerText;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseIcon, {
        name: __props.iconName,
        size: "10x",
        noHover: "",
        compact: "",
        height: "135px",
        width: "135px",
        class: "v-careplan-folder-icon"
      }, null, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(__props.carePlanFolder?.title), 1),
    (!__props.descriptionDisabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            ref_key: "editableSpan",
            ref: editableSpan,
            onClick: startEditing,
            onBlur: endEditing,
            textContent: _toDisplayString(displayText.value),
            class: "v-desc-editable-text",
            contenteditable: editingDesc.value,
            onInput: handleInput
          }, null, 40, _hoisted_5),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-line" }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.carePlanFolder?.pages, (carePlanPage) => {
        return (_openBlock(), _createBlock(BaseCarePlanPage, {
          key: carePlanPage.title,
          carePlanPage: carePlanPage
        }, null, 8, ["carePlanPage"]))
      }), 128))
    ])
  ]))
}
}

})