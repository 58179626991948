import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-header-left" }
const _hoisted_3 = { class: "v-careplan-page-title" }

import { PropType } from 'vue';
import { CarePlanPage } from '@/_shared/types/carePlan';
import { useRoute } from 'vue-router';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCarePlanPage',
  props: {
  carePlanPage: {
    type: Object as PropType<CarePlanPage>,
    required: true,
  },
},
  setup(__props) {



const toLink = (section: string) => {
  const route = useRoute();
  return { params: { section }, query: route.query };
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: toLink(__props.carePlanPage.toLink),
    class: "v-router-link"
  }, {
    default: _withCtx(() => [
      _createVNode(BaseCard, {
        headerBackground: false,
        padding: false,
        contentTopBorder: false,
        class: "v-careplan-page-card"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(BaseIcon, {
                name: __props.carePlanPage.icon,
                size: "2x",
                noHover: "",
                compact: "",
                class: "v-careplan-page-icon"
              }, null, 8, ["name"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, _toDisplayString(__props.carePlanPage.title), 1)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})