import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  class: "v-list-entry-inner",
  style: {"align-items":"flex-start"}
}
const _hoisted_2 = { class: "v-message-header" }
const _hoisted_3 = { class: "v-message-header-main-col" }
const _hoisted_4 = { class: "v-message-top-row__carer-name" }
const _hoisted_5 = {
  key: 0,
  class: "v-secondary-text recipients"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "v-message-secondary"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "v-message-header-left-col" }

import useMessagesStore from '@/messages/store/messagesStore';
import use from '@/_shared/compositionApi';
import { computed, PropType } from 'vue';
import { formatFriendlyDate, formatFriendlyDateTime } from '@/_shared/helpers/useDateTimeHelper';
import { MessageDTO } from '@/messages/types/messages';
import { BaseAvatar } from '@/_shared/components/';
import { Carer } from '@/_shared/store/carers';
import { toRefs } from '@vueuse/core';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageHeader',
  props: {
  message: {
    type: Object as PropType<MessageDTO>,
    required: true,
  },
  withBody: {
    type: Boolean,
    default: false,
  },
  withHover: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const { messageReadState, getRecipients, getSender } = useMessagesStore();
const { linkify, translate } = use.helpers();

const props = __props;

const { carerStore } = use.store.carers();

const { message } = toRefs(props);

const recipients = computed(() => {
  const recipientsFromStore = getRecipients(message.value);
  return recipientsFromStore.map((recipient) => {
    recipient.text = recipientText(recipient, recipientsFromStore[recipientsFromStore.length - 1].id);
    return recipient;
  });
});

const extraReceivers = computed(() => message.value.extraReceivers);

const avatarPhoto = computed(() => (message.value.fromMe
  ? carerStore.photos(message.value.receiverIds!)
  : carerStore.photo(message.value.senderId!)));

const inboxMessageReadState = computed(() => {
  if (message.value.fromMe) { return ''; }
  return message.value.isRead ? '' : 'v-message-unread';
});

const recipientText = (carer: Carer, lastId: number) => {
  const readState = !messageReadState(carer.id, message.value) ? ' (read)' : '';
  const comma = lastId !== carer.id ? ', ' : '';
  return `${carer.name}${readState}${comma}`;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`v-list-entry v-list-entry-message ${__props.withHover ? 'with-hover' : ''}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(BaseAvatar), {
        photo: avatarPhoto.value,
        size: "medium",
        border: "border",
        class: "v-message-recipient-avatar"
      }, null, 8, ["photo"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", {
            class: _normalizeClass(["v-h3", `${inboxMessageReadState.value} v-message-top-row v-message-top-row${__props.withBody ? '__show-all-names' : ''}`])
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(getSender)(_unref(message))?.name), 1)
          ], 2),
          (__props.withBody)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (recipients.value?.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(translate)('message.to')) + ": ", 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recipients.value, (carer) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: carer.id,
                    class: "v-message-secondary"
                  }, _toDisplayString(carer.text), 1))
                }), 128)),
                (extraReceivers.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(translate)('message.more_user', extraReceivers.value)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: "v-secondary-text",
            innerHTML: _unref(linkify)(_unref(message).subject || '')
          }, null, 8, _hoisted_8),
          (!__props.withBody)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "v-secondary-text",
                innerHTML: _unref(linkify)(_unref(message).body || '')
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, _toDisplayString(__props.withBody ? _unref(formatFriendlyDateTime)(_unref(message).createdAt) : _unref(formatFriendlyDate)(_unref(message).createdAt)), 1)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})