import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-responsible-carers-content" }
const _hoisted_2 = { class: "v-finished-at-info" }
const _hoisted_3 = { class: "v-finished-at-text" }
const _hoisted_4 = { class: "v-finished-at-text" }
const _hoisted_5 = { class: "v-note-section" }
const _hoisted_6 = { key: 0 }

import { PropType, computed } from 'vue';
import IInteraction from '@/timeline/types/IInteraction';
import use from '@/_shared/compositionApi';
import { BaseIcon } from '@/_shared/components';
import CarePlanBanner from '@/careplan/components/CarePlanBanner.vue';
import ClosedBy from '@/timeline/components/ClosedBy.vue';
import { formatDate, formatTime } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanResponsibleCarers',
  props: {
  interaction: {
    type: Object as PropType<IInteraction>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const carerTermPlural = computed(() => window.currentOrganisationUnit.carer_term_plural);
const { translate } = use.helpers();
const finishAtDate = computed(() => formatDate(props.interaction.finishAt || ''));
const finishAtTime = computed(() => formatTime(props.interaction.finishAt || ''));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CarePlanBanner, {
      title: 
      _unref(translate)('careplan.responsible_carer', { carer_term_plural: carerTermPlural.value })
    
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(BaseIcon), {
          name: "calendar-o",
          size: "xs",
          "no-hover": ""
        }),
        _createElementVNode("span", _hoisted_3, _toDisplayString(finishAtDate.value), 1),
        _createVNode(_unref(BaseIcon), {
          name: "clock-o",
          size: "xs",
          "no-hover": ""
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(finishAtTime.value), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (__props.interaction.notePublic)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(translate)("careplan.note")) + ": " + _toDisplayString(__props.interaction.notePublic), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(ClosedBy, {
        responsiblePersonIds: props.interaction.responsiblePersonIds
      }, null, 8, ["responsiblePersonIds"])
    ])
  ], 64))
}
}

})