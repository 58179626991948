
import addPreferredTermsToText from '@/_shared/services/clientHelpers';
import { defineComponent, ref, onBeforeMount } from 'vue';
import use from '@/_shared/compositionApi';
import {
  ServiceSummary,
  ServiceSummaryParams,
} from '../types/ServiceSummary';
import { fetchServiceSummary } from '../services/interactionsApi';

export default defineComponent({
  methods: { addPreferredTermsToText },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    dateInterval: {
      type: String,
    },
    subscribedServiceId: {
      type: Number,
    },
    providedServiceId: {
      type: Number,
    },
    serviceId: {
      type: Number,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      const params: ServiceSummaryParams = {
        clientId: props.clientId,
        providedServiceId: props.providedServiceId,
        subscribedServiceId: props.subscribedServiceId,
        serviceId: props.serviceId,
        dateInterval: props.dateInterval,
      };

      const response = await fetchServiceSummary(params);

      showSection.value = response?.length > 0;
      calculations.value = response;
    });

    const { translate } = use.helpers();
    const calculations = ref<ServiceSummary[]>();
    const showSection = ref(false);

    function getPercentages(value: number, target: number) {
      return Math.round(((100 * value) / target) * 100) / 100;
    }
    function getFormattedValue(calculation: ServiceSummary) {
      let value = calculation.value?.toFixed(2).replace(/[.,]00$/, '');
      if (value != null && calculation.unit) value += ` ${calculation.unit}`;
      return value;
    }

    return {
      translate,
      calculations,
      getPercentages,
      showSection,
      getFormattedValue,
    };
  },
});
