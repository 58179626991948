import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import IInteraction from '@/timeline/types/IInteraction';
import useUserStore from '@/_shared/store/user';
import equal from 'fast-deep-equal/es6';

type Register = {
  [key: number]: IInteraction;
};

const useMultiSelectRegister = defineStore('multiSelectRegister', () => {
  const register = ref({} as Register);
  const bulkCloseEnabled = ref(false);
  const bulkInteractionIds = ref([] as number[]);
  const bulkClientIds = ref([] as number[]);

  const toggleSelect = (interaction: IInteraction) => {
    if (interaction.id && register.value[interaction.id]) {
      delete register.value[interaction.id];
      bulkInteractionIds.value.splice(bulkInteractionIds.value.indexOf(interaction.id), 1);
      bulkClientIds.value.splice(bulkClientIds.value.indexOf(interaction.clientId), 1);
      return false;
    }
    if (interaction.id && !register.value[interaction.id]) {
      register.value[interaction.id] = interaction;
      bulkInteractionIds.value.push(interaction.id);
      bulkClientIds.value.push(interaction.clientId);
      return true;
    }
    return false;
  };

  const $reset = () => {
    register.value = [];
    bulkInteractionIds.value = [];
    bulkClientIds.value = [];
    bulkCloseEnabled.value = false;
  };

  const isSelectableForBulkClose = (interaction: IInteraction) => _isBulkCloseable(interaction) && isSimilarToCurrentSelection(interaction);

  const _isBulkCloseable = (interaction: IInteraction) => interaction.state === 'planned'
    && !interaction.needId
    && useUserStore().userCan('manageInteractionsForServiceId', interaction.serviceId)
    && _hasClosableParams(interaction);

  const isSimilarToCurrentSelection = (interaction: IInteraction) => {
    const firstSelectedInteraction = Object.values(register.value)[0];
    if (!firstSelectedInteraction || firstSelectedInteraction === interaction) { return true; }
    return _isSameTime(interaction, firstSelectedInteraction) && _hasSameParameters(interaction, firstSelectedInteraction);
  };

  const _hasClosableParams = (interaction: IInteraction) => !interaction.parameters.some((parameter) => parameter.valueType === 'picture'
    || (parameter.valueType === 'person_picker' && parameter.config?.personPickerType === 'circle_of_care'));

  const _isSameTime = (i1: IInteraction, i2: IInteraction) => equal(_time(i1), _time(i2));

  const _time = (interaction: IInteraction) => {
    if (interaction.state === 'sticky') { return new Date(new Date(interaction.startAt as string | Date).setHours(0, 0, 0, 0)); }
    return new Date((interaction.startAt || interaction.finishAt) as string | Date);
  };

  const _hasSameParameters = (i1: IInteraction, i2: IInteraction) => {
    const i1ParameterIds = _orderedParameterIds(i1);
    const i2ParameterIds = _orderedParameterIds(i2);
    return equal(i1ParameterIds, i2ParameterIds);
  };

  const _orderedParameterIds = (interaction: IInteraction) => interaction.parameters.map((parameter) => parameter.id).sort();

  const timelineControlsOptions = computed(() => [
    {
      codename: 'single',
      icon: 'nsx-font-icon--person',
      label: 'Single select',
      selected: !bulkCloseEnabled.value,
    },
    {
      codename: 'group',
      icon: 'nsx-font-icon--checkbox-checked',
      label: 'Group select',
      selected: bulkCloseEnabled.value,
    },
  ]);

  return {
    register,
    bulkInteractionIds,
    bulkClientIds,
    bulkCloseEnabled,
    timelineControlsOptions,
    isSelectableForBulkClose,
    isSimilarToCurrentSelection,
    toggleSelect,
    $reset,
  };
});

export default useMultiSelectRegister;
