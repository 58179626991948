import { defineStore } from 'pinia';
import { ref } from 'vue';
import apiClient from '@/_shared/services/apiClient';
import { Need } from '@/timeline/types/Need';
import { ProvidedServiceQueryResponse } from './providedServices';
import { ProvidedService } from '../types/providedService';

interface NeedsResponse {
  need: Need;
}

interface ClientSubcategoryResponse {
  clientSubcategory: {
    id: number,
    clientCategoryId: number,
  },
}

interface NeedProvidedServiceStore {
  [needId: number]: {
    [lastReviewId: number]: ProvidedService[]
  }
}

export interface NeedsQueryParams {
  id: number,
  lastReviewId: number | null,
}

const reviewsLoaded = ref(false);

const fetchNeed = async (params: NeedsQueryParams) => {
  const url = `/api/v2/needs/${params.id}?extended=true&last_review_id=${params.lastReviewId}`;
  return apiClient.get<NeedsResponse>(url);
};

const useNeedsStore = defineStore('needs', () => {
  const need = ref<Record<number, Need>>({});
  const needProvidedServices = ref<NeedProvidedServiceStore>({});
  const needVersion = ref<Need>();

  const getNeedById = (id: number): Need => need.value[id];

  const getProvidedServicesForNeed = (aNeed: Need) => {
    const value = needProvidedServices.value[aNeed.id]?.[aNeed.lastReviewId || 0];
    if (!value) {
      fetchProvidedServices(aNeed);
    }

    return value;
  };

  const fetch = async (params: NeedsQueryParams) => {
    const response = (await fetchNeed(params)).need;
    need.value = { ...need.value, [response.id]: response };
    await fetchProvidedServices(response);
  };

  const fetchVersion = async (params: NeedsQueryParams) => {
    needVersion.value = (await fetchNeed(params)).need;
    await fetchProvidedServices(needVersion.value);
  };

  const fetchProvidedServices = async (aNeed: Need) => {
    const providedServiceIds = aNeed.needProvidedServices.map((p) => p.providedServiceId);
    const url = `/api/v2/organisation_units/${aNeed.organisationUnitId}/provided_services/for_need`;
    const response = await apiClient.post<ProvidedServiceQueryResponse>(url, {
      query: {
        enforced_ids: providedServiceIds,
      },
      clientId: aNeed.clientId,
    });

    const lastReviewId = aNeed.lastReviewId || 0;
    needProvidedServices.value[aNeed.id] = { ...needProvidedServices.value[aNeed.id] || {}, [lastReviewId]: response.providedServices };
  };

  const fetchAllReviews = async (id: number) => {
    const url = `/api/v2/needs/${id}?only_reviews=true`;
    const response = (await apiClient.get<NeedsResponse>(url)).need;
    need.value[response.id]!.reviews = response.reviews;
    reviewsLoaded.value = true;
  };

  const generateCareplanPath = async (id: number, clientId: number, includeNeeds = true) => {
    const url = `/api/v2/client_subcategories/${id}`;
    const response = (await (apiClient.get<ClientSubcategoryResponse>(url, { params: { includeNeeds } }))).clientSubcategory;
    return `/clients/${clientId}/careplan/client_categories/${response.clientCategoryId}/client_subcategories/${id}`;
  };

  const $reset = () => {
    need.value = {};
    reviewsLoaded.value = false;
    needProvidedServices.value = {};
  };

  return {
    fetch,
    fetchAllReviews,
    fetchProvidedServices,
    fetchVersion,
    generateCareplanPath,
    getNeedById,
    getProvidedServicesForNeed,
    need,
    needProvidedServices,
    needVersion,
    $reset,
    reviewsLoaded,
  };
});

export default useNeedsStore;
