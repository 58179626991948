import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-status-action-container" }

import { storeToRefs } from 'pinia';
import useDataRetentionStore from '@/_shared/store/dataRetention';
import BaseCircleButton from '@/_shared/components/buttons/BaseCircleButton.vue';
import use from '@/_shared/compositionApi';
import { getDataRetentionDeletedClientsReport } from '@/_shared/services/dataRetentionApi';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { computed, ref } from 'vue';
import downloadIcon from '@/_shared/assets/icons/download_file.svg';
import DataRetentionReportTable from './DataRetentionReportTable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeletedClients',
  setup(__props) {

const dataRetentionStore = useDataRetentionStore();
const { activeStates } = dataRetentionStore;
const { dataRetentionDeletedClientsTableData } = storeToRefs(dataRetentionStore);
const { translate } = use.helpers();
const hasRecords = computed(
  () => !!dataRetentionDeletedClientsTableData.value.entries.length,
);

const downloadReport = () => {
  getDataRetentionDeletedClientsReport();
};

const reverseOrder = () => {
  dataRetentionStore.reverseDeletedClientsTableOrder();
  tableComponent.value!.el.scrollTo(0, 0);
};

const tableComponent = ref<typeof DataRetentionReportTable | null>(null);

return (_ctx: any,_cache: any) => {
  const _directive_base_tooltip = _resolveDirective("base-tooltip")!
  const _directive_tab = _resolveDirective("tab")!

  return (_openBlock(), _createBlock(BaseRightPanel, { withBGImg: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(BaseCircleButton, {
          class: "v-download-button",
          left: "0 px",
          icon: _unref(downloadIcon),
          enabled: hasRecords.value,
          onClick: downloadReport
        }, null, 8, ["icon", "enabled"]), [
          [_directive_base_tooltip, {
          position: 'bottom',
          tooltipText: _unref(translate)('common.download'),
        }],
          [_directive_tab]
        ])
      ]),
      _createVNode(DataRetentionReportTable, {
        ref_key: "tableComponent",
        ref: tableComponent,
        class: "v-deleted-data-retention-table",
        data: _unref(dataRetentionDeletedClientsTableData),
        emptyMessage: _unref(translate)('data_retention.no_deleted_clients'),
        containsArray: false,
        activeStates: _unref(activeStates),
        onReverseOrder: reverseOrder
      }, null, 8, ["data", "emptyMessage", "activeStates"])
    ]),
    _: 1
  }))
}
}

})