import { RouterLink, useLink, useRoute } from 'vue-router';
import {
  computed, PropType, ref, Ref,
} from 'vue';

const useNavLinkActive = () => {
  const navLinkProps = {
    customizedActiveMatcher: {
      type: Boolean,
      default: false,
    },
    forceActive: {
      type: Object as PropType<Ref<boolean>>,
      default: ref(false),
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(RouterLink?.props || {}),
  };
  const getActivationCheckers = (props :Readonly<{[key in string]?: unknown}>, exactMatch = false) => {
    const currentRoute = useRoute();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isActive, isExactActive, route } = useLink(props);
    const isNavItemActive = computed(() => {
      if (isCustomizedActive.value) {
        return exactMatch ? isExactActive.value : isActive.value;
      }
      return (props.forceActive as Ref<boolean>).value;
    });
    const isCustomizedActive = computed(() => currentRoute.path === route.value.path);
    return {
      isNavItemActive,
    };
  };

  return {
    getActivationCheckers,
    navLinkProps,
  };
};

export default useNavLinkActive;
