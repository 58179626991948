import BaseTableHeader from '@/_shared/types/baseTableHeader';
import use from '@/_shared/compositionApi';
import { DataRetentionDeletedClientsData, DataRetentionDeletedClientsRow } from '../types/DataRetentionTableData';

const { translate } = use.helpers();

const dataRetentionDeletedClientsTable = (entries: DataRetentionDeletedClientsRow[]): DataRetentionDeletedClientsData => {
  const headers = buildHeaders();

  return { headers, entries };
};

const buildHeaders = () => {
  const headers: BaseTableHeader[] = [
    {
      keyName: 'userId',
      displayName: translate('data_retention.client_id'),
      type: 'number',
    },
    {
      keyName: 'pwsInitials',
      displayName: translate('data_retention.initials'),
      type: 'string',
    },
    {
      keyName: 'deletionRequestDate',
      displayName: translate('data_retention.deletion_request_date'),
      type: 'string',
    },
    {
      keyName: 'deletionRequestedBy',
      displayName: translate('data_retention.deletion_requested_by'),
      type: 'string',
    },
    {
      keyName: 'lastActivityDateWhenDeleted',
      displayName: translate('data_retention.last_activity_date_when_deleted'),
      type: 'string',
    },
    {
      keyName: 'deletionDate',
      displayName: translate('data_retention.deletion_date'),
      type: 'string',
      defaultSort: 'desc',
    },
    {
      keyName: 'deletionState',
      displayName: translate('data_retention.deletion_status'),
      type: 'string',
    },
    {
      keyName: 'deletionRequestedBy',
      displayName: translate('data_retention.deletion_request_by'),
      type: 'string',
    },
  ];
  return headers;
};

export default dataRetentionDeletedClientsTable;
