import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onBeforeMount, PropType, ref } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import Integration from '@/integrations/types/integration';
import { asidExistsInLookup } from '../../services/integrationsApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationNhsAsidModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const result = ref<boolean>(false);
const showResult = ref<boolean>(false);
const isLondonCare = ref<boolean>(props.integration.integrationCodename === 'london_care_records');

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
  if (isLondonCare.value) {
    // TECH DEBT With next phase of LCR integration, we will need to add more checks here against the lookup instead if it is London care
    result.value = false;
    showResult.value = true;
    return;
  }
  if (props.ou.id) {
    result.value = await asidExistsInLookup(props.token, props.ou.id);
    showResult.value = true;
  }
});

const title = () => (result.value === true ? translate(`integrations.${props.integration.integrationCodename}.ods_code_success_title`) : translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_title`));

const messages = () => {
  if (result.value === true) {
    return [translate(`integrations.${props.integration.integrationCodename}.ods_code_success_1`), translate(`integrations.${props.integration.integrationCodename}.ods_code_success_2`)];
  }

  if (isLondonCare.value) {
    return [translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_1`),
      translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_2`),
      translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_3`),
      translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_4`),
      translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_5`),
      translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_6`),
    ];
  }
  return [translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_1`),
    props.ou.isBlue ? translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_2_blue`)
      : translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_2`),
    translate(`integrations.${props.integration.integrationCodename}.asid_code_fail_3`),
  ];
};

return (_ctx: any,_cache: any) => {
  return (showResult.value === true)
    ? (_openBlock(), _createBlock(BaseAlert, {
        key: 0,
        class: "v-integration-nhs-asid-alert",
        dangerAlert: false,
        icon: "info",
        onConfirmAlert: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirmAsidModal'))),
        onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancelAsidModal'))),
        confirmText: result.value === true ? _unref(translate)('alert.confirm') : _unref(translate)('alert.continue')
      }, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(title()), 1)
        ]),
        body: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages(), (message) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "v-main-text",
              key: message
            }, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(message), 1),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["confirmText"]))
    : _createCommentVNode("", true)
}
}

})