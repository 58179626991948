import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

import { PropType, ref } from 'vue';
import { LocationDetail } from '@/_shared/types/NourishInstance';
import BodyMapLocationButton
  from '@/timeline/components/BodyMap/BodyMapFilter/BodyMapLocationButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapSideSelector',
  props: {
  instanceLocations: {
    type: Object as PropType<{ frontLocations: LocationDetail[], backLocations: LocationDetail[] }>,
    required: true,
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
  sideToShow: {
    type: String,
    default: 'all',
  },
  instanceCount: {
    type: Number,
    default: 0,
  },
},
  emits: ['sideBtnClicked'],
  setup(__props, { emit: __emit }) {

const props = __props;
const currentSide = ref(props.sideToShow);
const changeSide = (side: string) => {
  currentSide.value = side;
  emit('sideBtnClicked', side);
};
const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-bm-side-selector", [{'v-filter-white-background': __props.whiteBackground }]])
  }, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['all', 'front', 'back'], (location) => {
      return _createVNode(BodyMapLocationButton, {
        key: location,
        isSelected: location ===currentSide.value,
        location: location,
        displayCircle: location != 'all',
        displayCount: location === 'all' && !__props.whiteBackground,
        instanceCount: __props.instanceCount,
        onClick: ($event: any) => (changeSide(location))
      }, null, 8, ["isSelected", "location", "displayCircle", "displayCount", "instanceCount", "onClick"])
    }), 64))
  ], 2))
}
}

})