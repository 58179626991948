import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-icon-container" }
const _hoisted_2 = { class: "v-title-container" }

import { computed, ref } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowLocations',
  props: {
  defaultShowLocations: {
    type: Boolean,
    default: true,
  },
},
  setup(__props) {

// TODO: add 'right-icon="image"' to BaseListEntry and delete this comment
const props = __props;

const { translate } = use.helpers();

const showLocation = ref(props.defaultShowLocations);
const toggleShowLocation = () => { showLocation.value = !showLocation.value; };

const showLocationsContainerStyle = computed(() => ({
  backgroundColor: showLocation.value ? '#172B34' : 'white',
  color: showLocation.value ? 'white' : 'black',
  borderBottomLeftRadius: showLocation.value ? '0px' : '4px',
  borderBottomRightRadius: showLocation.value ? '0px' : '4px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-show-locations",
    style: _normalizeStyle(showLocationsContainerStyle.value),
    onClick: toggleShowLocation
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BaseIcon, {
        name: showLocation.value? 'nsx-font-icon--eye_crossed': 'nsx-font-icon--eye',
        fontSize: "30px",
        noHover: "",
        compact: "",
        "foreground-color": showLocation.value? 'white': 'black',
        "background-color": "transparent"
      }, null, 8, ["name", "foreground-color"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)(showLocation.value? 'body_map.hideLocation': 'body_map.showLocation')), 1)
  ], 4))
}
}

})