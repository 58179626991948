import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-lp-insights-dropdown" }

import {
  computed, onMounted, onUnmounted, ref,
} from 'vue';
import useUserStore from '@/_shared/store/user';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import use from '@/_shared/compositionApi';
import LeftPanelDropdownList
  from '@/_shared/components/general/left_panel/LeftPanelDropdownList.vue';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import vClickOutside from '@/_shared/directives/ClickOutside';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftPanelDropdownMenu',
  props: {
  exportDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ['dropdownOptionSelected'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const isMounted = ref(false);
const dropdownVisible = ref(false);

onMounted(() => (isMounted.value = true));
onUnmounted(() => (isMounted.value = false));

const props = __props;

const userHasPermission = () => useUserStore().userHasPermission('allowExportInsightsTable');

const timelineControlsOptions = computed(() => [
  {
    codename: 'print',
    label: translate('dashboards.print'),
    disabled: false,
  },
  {
    codename: 'export',
    label: translate('dashboards.export'),
    disabled: props.exportDisabled || !userHasPermission(),
  },
] as DropdownOption[]);

const onDropdownOptionClick = (option: DropdownOption) => {
  if (!option.disabled) {
    emit('dropdownOptionSelected', option);
    dropdownVisible.value = false;
  }
};

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (isMounted.value)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#lp-insights-custom-dropdown"
      }, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(BaseTooltip, {
            position: "left",
            "tooltip-text": _unref(translate)('dashboards.pageOptions')
          }, {
            default: _withCtx(() => [
              _createVNode(BaseIcon, {
                class: "v-kebab-menu-icon",
                name: "nsx-font-icon--kebab-menu",
                "no-hover-background": "",
                "foreground-hover-color": "black",
                width: "24px",
                height: "24px",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (dropdownVisible.value = !dropdownVisible.value), ["stop"]))
              })
            ]),
            _: 1
          }, 8, ["tooltip-text"]),
          _createVNode(LeftPanelDropdownList, {
            "show-dropdown": dropdownVisible.value,
            options: timelineControlsOptions.value,
            onOptionSelected: onDropdownOptionClick
          }, null, 8, ["show-dropdown", "options"])
        ])), [
          [_unref(vClickOutside), () => dropdownVisible.value = false]
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})