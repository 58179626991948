<template>
  <div class="v-interaction-section">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
@import "../../_shared/styles/colour.scss";
.v-interaction-section {
  width: 100%;
  border-bottom: 1px solid $v-color-warm-grey;
}
</style>
