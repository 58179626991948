import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-last-closed-review" }
const _hoisted_2 = { class: "v-last-closed-review-title" }
const _hoisted_3 = { class: "v-last-closed-review-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "v-last-closed-review-previous-data"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

import { computed, PropType, ref } from 'vue';
import use from '@/_shared/compositionApi';
import BaseAccordion from '@/_shared/components/general/BaseAccordion.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import PreviousReviewItem from './PreviousReviewItem.vue';
import { Need, NeedReview } from '../../types/Need';
import { groupReviewsByYearAndMonth, shouldShowReview } from './utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'PreviousReviews',
  props: {
  need: {
    type: Object as PropType<Need>,
    required: true,
  },
  reviews: {
    type: Array as PropType<NeedReview[]>,
    required: true,
  },
},
  emits: ['fetchAllReviews', 'showVersion'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();
const toggleAllReviews = () => {
  allReviewsVisible.value = !allReviewsVisible.value;
  emit('fetchAllReviews');
};

const showVersion = (id: number) => {
  emit('showVersion', id);
};

const emit = __emit;

const props = __props;

const allReviewsVisible = ref(false);

const previousReviews = computed(() => props.reviews.filter((r) => shouldShowReview(r, props.need.lastReviewId)));

const groupedReviews = computed(() => groupReviewsByYearAndMonth(props.reviews, props.need.lastReviewId));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('timeline.need.previous_reviews')), 1),
    _createElementVNode("div", _hoisted_3, [
      (!allReviewsVisible.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(previousReviews.value.slice(0, 3), (review) => {
              return (_openBlock(), _createBlock(PreviousReviewItem, {
                key: review.id,
                review: review,
                onShowVersion: showVersion
              }, null, 8, ["review"]))
            }), 128)),
            (!allReviewsVisible.value && previousReviews.value.length > 3)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: toggleAllReviews,
                  class: "v-last-closed-review-content-button"
                }, [
                  _createTextVNode(_toDisplayString(_unref(translate)('timeline.need.show_older_careplan_reviews')) + " ", 1),
                  _createVNode(BaseIcon, {
                    name: "icon nr-icon-plus",
                    foregroundColor: 'white',
                    size: "xs",
                    noHover: ""
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(groupedReviews.value.lastYear.months), ([month, items], index) => {
              return (_openBlock(), _createElementBlock("div", { key: month }, [
                (index === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
                        return (_openBlock(), _createBlock(PreviousReviewItem, {
                          key: item.id,
                          review: item,
                          onShowVersion: showVersion
                        }, null, 8, ["review"]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(BaseAccordion, {
                        title: month,
                        accordionStyle: 'primary'
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
                            return (_openBlock(), _createBlock(PreviousReviewItem, {
                              key: item.id,
                              review: item,
                              onShowVersion: showVersion
                            }, null, 8, ["review"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["title"])
                    ]))
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedReviews.value.previousYears, (yearData) => {
              return (_openBlock(), _createBlock(BaseAccordion, {
                key: yearData.year,
                title: yearData.year
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(yearData.months), ([month, items]) => {
                    return (_openBlock(), _createBlock(BaseAccordion, {
                      key: month,
                      title: month,
                      accordionStyle: 'light'
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item) => {
                          return (_openBlock(), _createBlock(PreviousReviewItem, {
                            key: item.id,
                            review: item,
                            onShowVersion: showVersion
                          }, null, 8, ["review"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["title"]))
            }), 128))
          ]))
    ])
  ]))
}
}

})