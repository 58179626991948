import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import {
  PropType, computed, onBeforeMount,
} from 'vue';
import use from '@/_shared/compositionApi';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import LatestCarePlanConsent from './components/LatestCarePlanConsent.vue';
import AgreementHistory from './components/AgreementHistory.vue';
import UpdateCarePlanAgreement from './components/UpdateCarePlanAgreement.vue';
import CarePlanConsentFolder from './components/CarePlanConsentFolder.vue';

type sectionType = 'latest_consent' | 'update_consent' | 'agreement_history' | '';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanConsentRightPanel',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  section: {
    type: String as PropType<sectionType>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { clientStore } = use.store.clients();
const avatarPhoto = computed(() => {
  if (props.clientId) return clientStore.photo(+props.clientId);
  return undefined;
});
const carePlanTerm = computed(() => window.currentOrganisationUnit.care_plan_term || '');

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('careplan'); // TODO WHEN PURE VUE remove;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { photo: avatarPhoto.value }, {
    default: _withCtx(() => [
      (__props.section === 'latest_consent')
        ? (_openBlock(), _createBlock(LatestCarePlanConsent, {
            key: 0,
            clientId: __props.clientId,
            carePlanTerm: carePlanTerm.value
          }, null, 8, ["clientId", "carePlanTerm"]))
        : (__props.section === 'update_consent')
          ? (_openBlock(), _createBlock(UpdateCarePlanAgreement, {
              key: 1,
              clientId: __props.clientId,
              carePlanTerm: carePlanTerm.value
            }, null, 8, ["clientId", "carePlanTerm"]))
          : (__props.section === 'agreement_history')
            ? (_openBlock(), _createBlock(AgreementHistory, {
                key: 2,
                clientId: __props.clientId,
                carePlanTerm: carePlanTerm.value
              }, null, 8, ["clientId", "carePlanTerm"]))
            : (_openBlock(), _createBlock(CarePlanConsentFolder, { key: 3 }))
    ]),
    _: 1
  }, 8, ["photo"]))
}
}

})