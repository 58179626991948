import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-dropdown",
  ref: "dropdown"
}
const _hoisted_2 = {
  key: 0,
  class: "v-dropdown-menu"
}
const _hoisted_3 = ["onClick"]

import { PropType, ref } from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import vClickOutside from '../../directives/ClickOutside';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownButton',
  props: {
  defaultLabel: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<DropdownOption[]>,
    required: false,
  },
},
  emits: ['optionClicked'],
  setup(__props, { emit: __emit }) {




const emit = __emit;

const dropdownIsVisible = ref(false);

function hideDropdown() {
  if (dropdownIsVisible.value === true) {
    dropdownIsVisible.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (dropdownIsVisible.value = !dropdownIsVisible.value))
  }, [
    _createVNode(BaseButton, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.defaultLabel) + " ", 1),
        _cache[1] || (_cache[1] = _createElementVNode("i", {
          class: _normalizeClass(`fa fa-caret-down fa-xs`)
        }, null, -1))
      ]),
      _: 1
    }),
    (dropdownIsVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.options && __props.options.length > 1)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: option.id
                  }, [
                    _createElementVNode("div", {
                      class: "v-dropdown-option",
                      onClick: ($event: any) => (emit('optionClicked', option.id))
                    }, _toDisplayString(option.label), 9, _hoisted_3)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true)
  ])), [
    [_unref(vClickOutside), hideDropdown]
  ])
}
}

})