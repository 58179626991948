
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    tabs: {
      type: Object as PropType<[(string|number)[], string[]]>,
      required: true,
      validator: ([values, texts]) => values.length === texts.length,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
});
