import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-multiselect-parameter-container" }
const _hoisted_2 = {
  key: 0,
  class: "v-param-multi-question"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "v-dataset-selected" }

import {
  CoercedMultiSelect, CoercedPersonPicker,
  CoercedSelection,
  CoercedSingleSelect,
  Parameter,
} from '@/timeline/types/Parameter';
import use from '@/_shared/compositionApi';
import {
  computed, onBeforeMount, PropType, ref, toRef,
} from 'vue';
import {
  OptGroup, Option, Options,
} from '@/_shared/types/baseSelect';
import DatasetModal from '@/_shared/components/modals/DatasetModal/DatasetModal.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import { isPersonPickerParamType } from '@/timeline/helper/parametersHelper';
import useSelectDatasetModal from '@/timeline/interaction_parameters/AdvancedMultiselect/modal';
import useSelectDatasetAnswers from '@/timeline/interaction_parameters/AdvancedMultiselect/answers';
import useSelectDatasetSelectInput from '@/timeline/interaction_parameters/AdvancedMultiselect/selectInput';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import BaseParameter from '../BaseParameter.vue';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedMultiselect',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  selectorType: {
    type: String as PropType<'multi' | 'single'>,
    required: true,
  },
  options: {
    type: Object as PropType<OptGroup[]>,
    required: true,
  },
  noPreselectedAnswer: {
    type: Boolean,
    default: false,
  },
  previouslySelectedOptionsFromDataset: {
    type: Array as PropType<Option[]>,
    default: [] as Option[],
  },
  valuesNotInOptions: {
    type: Array as PropType<string[]>,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

let originalAnswer: string | null;

let initialPreviouslySelectedOptions: Option[] = [];

const previouslySelectedOptions = computed(() => {
  if (props.previouslySelectedOptionsFromDataset?.length) {
    return props.previouslySelectedOptionsFromDataset;
  }
  return initialPreviouslySelectedOptions;
});

const hasQuestion = _parameterHasQuestion();

onBeforeMount(async () => {
  originalAnswer = (coercedValue.value as CoercedMultiSelect)?.answer;
  initialPreviouslySelectedOptions = selectedOptions.value;

  if (coercedValue.value) {
    hasInitialValues.value = !!((coercedValue.value as CoercedMultiSelect)?.values?.length
        || added.value.length
        || removed.value.length
        || hasPresetAnswer());
  }
});

const { getCoercedValueByParameterId, setCoercedValueByParameterId } = useCurrentInteractionStore();
const { archivedValues } = storeToRefs(useCurrentInteractionStore());

const hasArchivedValue = (isArchived: boolean) => {
  archivedValues.value[props.parameter.name] = isArchived;
};

const { isClosed } = storeToRefs(useCurrentInteractionStore());

const coercedValue = getCoercedValueByParameterId(Number(props.parameter.id));

const currentAnswer = computed(() => {
  if (!hasQuestion) return undefined;
  if (props.noPreselectedAnswer
      && !(coercedValue.value as CoercedMultiSelect)?.values.length
      && props.parameter.config?.question
      && !added.value.length
      && !removed.value.length
      && !isClosed.value
      && originalAnswer === (coercedValue.value as CoercedMultiSelect)?.answer) {
    return '';
  }

  return (coercedValue.value as CoercedMultiSelect)?.answer
      || (typeof coercedValue.value === 'string' && coercedValue.value) // Probably not needed
      || undefined;
});

const isSingle = ref<boolean>((props.selectorType as string) === 'single');
const hasInitialValues = ref(false);
const hasPresetAnswer = () :boolean => {
  if (props.noPreselectedAnswer) return false;
  const tempCoercedValue = coercedValue.value as CoercedMultiSelect;
  return !!tempCoercedValue?.answer && tempCoercedValue?.answer !== '';
};

const { openModal, closeModal, showModal } = useSelectDatasetModal();
const { getNegativeAnswer } = useSelectDatasetAnswers();
const { placeholderMessage, isDisabled } = useSelectDatasetSelectInput(props.readOnly, props.parameter, toRef(props, 'options'));

const selectedOptions = computed(() => {
  const tempCoercedValue = coercedValue.value as CoercedSelection;
  if (tempCoercedValue && typeof tempCoercedValue === 'object' && 'values' in tempCoercedValue && Array.isArray(tempCoercedValue.values)) {
    if (props.valuesNotInOptions?.length) {
      const isArchived = tempCoercedValue.values.some((value) => typeof value === 'string' && props.valuesNotInOptions?.includes(value));
      hasArchivedValue(isArchived);
    } else {
      hasArchivedValue(false);
    }
    return tempCoercedValue.values;
  }
  return [];
});

const hasDataSetOrDataPoint = !!(props.parameter.config?.dataSet || props.parameter.dataPoint || props.parameter.config?.combinedMulti);

const added = computed(() => getAddedOrRemoved('addedValues'));

const removed = computed(() => getAddedOrRemoved('removedValues'));

const getAddedOrRemoved = (type: 'addedValues' | 'removedValues') => {
  if (!hasDataSetOrDataPoint) return [];
  const tempCoercedValue = coercedValue.value as CoercedPersonPicker | CoercedMultiSelect;
  return tempCoercedValue && typeof tempCoercedValue === 'object' && type in tempCoercedValue ? tempCoercedValue[type] : [];
};

const selectAnswer = (option: string) => {
  option = option.trim();
  if (option === 'Yes') {
    openModal(props.readOnly);
  } else if (option === '') {
    if (!removed.value.length) {
      setAnswerToNull();
    }
  } else {
    updateAnswer(option);
  }
};

const updateAnswer = (option: string) => {
  option = option.trim();
  updateCoercedValue(selectedOptions.value, option);
};

const assignSelectedOptions = (selected: Options) => {
  let option = 'Yes';
  if (!props.parameter.id.toString().includes('.') && !selected.length) {
    option = getNegativeAnswer(hasQuestion, props.parameter.config?.options as string);
  }
  updateCoercedValue(selected, option);
  closeModal();
};

const setAnswerToNull = async () => {
  setCoercedValueByParameterId(props.parameter.id as number, null);
};

const updateCoercedValue = async (selected: Options, answer: string) => {
  const value = { answer, values: selected };
  if (isSingle.value) {
    setCoercedValueByParameterId(props.parameter.id as number, value as CoercedSingleSelect);
    return;
  }
  setCoercedValueByParameterId(props.parameter.id as number, {
    ...value,
    ...{
      addedValues: hasInitialValues.value ? optionsDifferences(selected, previouslySelectedOptions.value) : [],
      removedValues: hasInitialValues.value ? optionsDifferences(previouslySelectedOptions.value, selected) : [],
    },
  } as CoercedMultiSelect);
};

function _parameterHasQuestion() {
  if (props.parameter.config?.question && !isPersonPickerParamType(props.parameter, 'circle_of_care')) {
    return props.parameter.config?.question.trim() === 'Yes';
  }
  return false;
}

const optionsDifferences = (lhs: Options, rhs: Options): Option[] => {
  const diff: Option[] = [];
  if (typeof lhs === 'string') {
    return diff;
  }
  lhs.forEach((selectedItem) => {
    let found = false;
    // eslint-disable-next-line
    for (const previouslySelectedItem of rhs) {
      if (selectedItem === previouslySelectedItem) {
        found = true;
        break;
      }
    }
    if (!found) {
      diff.push(selectedItem as Option);
    }
  });
  return diff;
};

const showQuestion = computed(() => {
  const tempCoercedValue = coercedValue.value as null | CoercedSelection;
  return hasQuestion
      && !showModal.value
      && (tempCoercedValue === null || tempCoercedValue.answer !== 'Yes' || !tempCoercedValue.values.length)
      && !(Array.isArray(selectedOptions.value) && selectedOptions.value.length);
});

const showAdded = computed(() => added?.value?.length && !isSingle.value && hasInitialValues.value);
const showRemoved = computed(() => removed?.value?.length && !isSingle.value && hasInitialValues.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BaseParameter, {
        parameter: __props.parameter,
        secondLine: true
      }, {
        "second-line": _withCtx(() => [
          (showQuestion.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(MultipleChoiceQuestion, {
                  parameter: __props.parameter,
                  readOnly: __props.readOnly,
                  currentAnswer: currentAnswer.value,
                  onAnswerChanged: selectAnswer
                }, null, 8, ["parameter", "readOnly", "currentAnswer"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(BaseSelect, {
                    class: "v-data-set-container sp-multi-value-box",
                    "model-value": selectedOptions.value,
                    "show-options": false,
                    options: __props.options as Options,
                    disabled: _unref(isDisabled),
                    "custom-tags": true,
                    placeholder: _unref(placeholderMessage),
                    multiple: true,
                    canClear: false,
                    onOpenModal: _cache[0] || (_cache[0] = ($event: any) => (_unref(openModal)(__props.readOnly))),
                    "remove-icon-display": "none",
                    searchable: false
                  }, null, 8, ["model-value", "options", "disabled", "placeholder"])
                ]),
                (showAdded.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["v-dataset-added", { 'green-base-select' : !__props.readOnly}])
                    }, [
                      _createElementVNode("p", null, _toDisplayString(_unref(translate)('timeline.interaction.added')), 1),
                      _createVNode(BaseSelect, {
                        class: "v-data-set-container sp-multi-value-box-added",
                        modelValue: added.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((added).value = $event)),
                        "show-options": false,
                        options: __props.options as Options,
                        disabled: true,
                        multiple: true,
                        searchable: false
                      }, null, 8, ["modelValue", "options"])
                    ], 2))
                  : _createCommentVNode("", true)
              ])),
          (showRemoved.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["v-dataset-removed", { 'green-base-select' : !__props.readOnly}])
              }, [
                _createElementVNode("p", null, _toDisplayString(_unref(translate)('timeline.interaction.removed')) + ":", 1),
                _createVNode(BaseSelect, {
                  class: "v-data-set-container sp-multi-value-box-removed",
                  modelValue: removed.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((removed).value = $event)),
                  options: __props.options as Options,
                  "show-options": false,
                  disabled: true,
                  multiple: true,
                  searchable: false
                }, null, 8, ["modelValue", "options"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["parameter"])
    ]),
    (_unref(showModal))
      ? (_openBlock(), _createBlock(DatasetModal, {
          key: 0,
          onCloseModal: _unref(closeModal),
          title: _unref(translate)('timeline.parameters.AdvancedMultiselect.selectFromListBelow'),
          "selectable-entries": __props.options as Options,
          "selected-entries": selectedOptions.value,
          "search-input-placeholder": _unref(translate)('timeline.parameters.AdvancedMultiselect.selectFromListBelow'),
          "primary-text-key": "text",
          onUpdatedSelectedEntries: assignSelectedOptions,
          "selector-type": __props.selectorType,
          "is-single": isSingle.value,
          showOptions: false,
          "button-text": _unref(translate)('common.save')
        }, null, 8, ["onCloseModal", "title", "selectable-entries", "selected-entries", "search-input-placeholder", "selector-type", "is-single", "button-text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})