
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import { Library } from '@/_shared/types/library';
import use from '@/_shared/compositionApi';
import ClientType from '@/client_types/types/client_type';
import { BaseCheckbox, BaseInput } from '@/_shared/components/';

export default defineComponent({
  components: { BaseInput, BaseCheckbox },
  props: {
    libraries: {
      type: Object as PropType<Library[]>,
    },
    clientType: {
      type: Object as PropType<ClientType>,
      required: true,
    },
    warningMessage: {
      type: String,
    },
    selectedLibraryCodename: {
      type: String,
    },
  },
  setup(props, context) {
    const { translate } = use.helpers();
    const clientTypeName = ref(props.clientType.name);
    const selectLibrary = (library: Library) => {
      context.emit('selectLibrary', library.codename);
    };

    watch(clientTypeName, () => context.emit('updateName', clientTypeName));

    return {
      translate,
      clientTypeName,
      selectLibrary,
    };
  },
});
