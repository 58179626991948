import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "v-alarms-text" }
const _hoisted_3 = { class: "v-alarm-names-list" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import {
  ref, computed, watch,
} from 'vue';
import use from '@/_shared/compositionApi';
import { Alarm } from '@/timeline/types/Alarm';
import IInteraction from '@/timeline/types/IInteraction';
import { useRoute } from 'vue-router';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import useInteractionsStore from '@/_shared/store/interactions';
import {
  isAnAlarmToDisplay, isSnoozed, compareInteraction, compareAlarmState,
} from '@/timeline/helper/interactionsHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftPanelAlarmsBar',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  showAlarmsBar: {
    type: Boolean,
    required: true,
  },
},
  setup(__props) {

const { alarmStore } = use.store.alarms();
const alarms = ref<Alarm[]>([]);

const props = __props;

const { translate } = use.helpers();

const displayAlarm = (alarm: Alarm, interactionState: string) => isAnAlarmToDisplay(alarm, interactionState) && !alarm.closedAt;

function getCurrentAlarms() {
  return filterAndSortInteractionWithAlarms().map((i: IInteraction) => findFirstAlarm(i)) as Alarm[];
  function filterAndSortInteractionWithAlarms() {
    return interactions.value
      .filter((interaction) => !isSnoozed(interaction) && hasUnclosedAlarms(interaction))
      .sort((i1: IInteraction, i2: IInteraction) => compareInteraction(i1, i2));
  }

  function findFirstAlarm(interaction: IInteraction) {
    return interaction.alarms.find((a) => displayAlarm(a, interaction.state));
  }
}

function sortAlarms(toDayAlarms: Alarm[]) {
  return toDayAlarms.map((a) => addInteractionDetailsToAlarm(a)).sort((a1, a2) => compareAlarmState((a1 as Alarm).state, (a2 as Alarm).state));
  function addInteractionDetailsToAlarm(a: Alarm) {
    const alarm = JSON.parse(JSON.stringify(a));
    alarm.interaction = interactions.value.find((i) => i.id === a.interactionId);
    return alarm;
  }
}

const fetchMostRecentAlarms = async (clientId: number) => {
  const openAlarmsFT = useUserStore().getFeatureToggle('openAlarmsOnClientTimeline');
  if (openAlarmsFT) {
    const query = {
      clientIds: [clientId], closed: false, extended: true,
    };
    const params = { perPage: 3, page: 1 };
    const response = await alarmStore.getQuery(query, params);
    alarms.value = response.alarms;
  } else {
    const toDayAlarms = getCurrentAlarms();
    alarms.value = sortAlarms(toDayAlarms);
  }
};

const hasUnclosedAlarms = (interaction : IInteraction) => interaction.alarms?.some((alarm) => displayAlarm(alarm, interaction.state));

const { interactions } = storeToRefs(useInteractionsStore());
watch(interactions, async () => {
  await fetchMostRecentAlarms(props.clientId);
}, { deep: true });

const highestAlarmState = computed(() => alarms.value[0].state || 'alarm');

const alarmNames = computed(() => {
  const alarmNameList: string[] = [];

  alarms.value.forEach((alarm) => {
    const { interaction } = alarm as Alarm & { interaction: IInteraction };
    alarmNameList.push(interaction?.name);
  });
  return alarmNameList.join(', ');
});

const toLink = () => {
  const route = useRoute();
  return {
    name: 'client.alarms',
    params: { clientId: props.clientId },
    query: route.query,
  };
};

const showAlarms = computed(() => props.showAlarmsBar && alarms && alarms.value.length);


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (showAlarms.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: toLink()
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["v-alarms-bar", `v-alarms-${highestAlarmState.value}`])
            }, [
              _createVNode(BaseIcon, {
                class: "no-hover",
                name: "nsx-font-icon--bell",
                "background-color": "white",
                "background-shape": "circle",
                size: "2x",
                compact: true
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_unref(translate)("left_panel.warnings")), 1),
                _createElementVNode("span", _hoisted_3, _toDisplayString(alarmNames.value), 1)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-chevron-right v-chevron-right" }, null, -1))
            ], 2)
          ]),
          _: 1
        }, 8, ["to"])
      ]))
    : _createCommentVNode("", true)
}
}

})