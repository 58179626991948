import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 1 }

import { usePositionMethods } from '@/_shared/helpers/useInputMethods';
import vTab from '@/_shared/directives/TabHandler';
import BaseIcon from '../general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCircleButton',
  props: {
  position: {
    type: String,
    default: 'relative',
  },
  top: {
    type: String,
    default: '7px',
  },
  left: {
    type: String,
    default: '599px',
  },
  right: {
    type: String,
    required: false,
  },
  bottom: {
    type: String,
    required: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
  reactToTab: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'add',
  },
},
  setup(__props) {

const props = __props;

const { computePosition } = usePositionMethods(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-add-btn-wrapper", __props.hidden ? 'hidden' : '']),
    style: _normalizeStyle(_unref(computePosition)())
  }, [
    (__props.icon === 'add')
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["v-add-btn", __props.active ? 'active' : 'normal'])
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["v-add-button--icon", __props.active ? 'active' : 'normal'])
          }, null, 2)
        ], 2)), [
          [_unref(vTab), __props.reactToTab]
        ])
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(BaseIcon, {
            name: __props.icon,
            size: "sm",
            square: "",
            class: _normalizeClass(["custom-icon", __props.active ? 'active' : 'normal'])
          }, null, 8, ["name", "class"])
        ])),
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}
}

})