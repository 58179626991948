import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvailabilityDay = _resolveComponent("AvailabilityDay")!
  const _component_BaseTable = _resolveComponent("BaseTable")!

  return (_openBlock(), _createBlock(_component_BaseTable, {
    headers: _ctx.days,
    items: _ctx.weeks,
    "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.weeks) = $event)),
    editable: false,
    hasSlot: ""
  }, {
    default: _withCtx((slotProps) => [
      _createVNode(_component_AvailabilityDay, {
        day: slotProps.item as DaySchedule,
        onSaveDay: ($event: any) => (_ctx.saveDay($event, slotProps))
      }, null, 8, ["day", "onSaveDay"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}