import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-time-picker-container v-input-wrapper" }
const _hoisted_2 = { class: "v-input" }
const _hoisted_3 = ["value", "disabled"]
const _hoisted_4 = { class: "v-error" }

import { computed, ref } from 'vue';
import use from '@/_shared/compositionApi';
import { useInputMethods } from '@/_shared/helpers/useInputMethods';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTimePicker',
  props: {
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String],
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
  labelWidth: {
    type: String,
    default: undefined,
  },
  boldLabel: {
    type: Boolean,
    default: undefined,
  },
  inputWidth: {
    type: String,
    default: '100%',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const {
  inputClass, inputStyle, labelClass, labelStyle,
} = useInputMethods(props);

const { translate } = use.helpers();

const edited = ref(false);

const editTime = (event: Event) => {
  edited.value = true;
  emit('update:modelValue', (event.target as HTMLInputElement).value);
};

const timeInputClass = computed(() => {
  let classes = inputClass();
  if (error.value !== '') {
    classes += ' input-error';
  }
  return classes;
});

const error = computed(() => (!edited.value || (props.modelValue && props.modelValue.match(/\d+:\d+/)) ? '' : translate('availability.valid_time')));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "time",
        name: "time",
        class: _normalizeClass(timeInputClass.value),
        style: _normalizeStyle(_unref(inputStyle)()),
        value: __props.modelValue,
        onInput: editTime,
        onChange: editTime,
        onBlur: editTime,
        disabled: __props.disabled
      }, null, 46, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(error.value), 1)
    ])
  ]))
}
}

})