import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-multi-choice-parameter-container" }
const _hoisted_2 = { class: "v-multiple-choices" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "v-multiple-choices-dropdown" }

import { ref, toRef, Ref } from 'vue';
import vClickOutside from '@/_shared/directives/ClickOutside';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import { CoercedChoice, Parameter } from '../types/Parameter';
import MultipleChoiceOption from './MultipleChoiceOption.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleChoiceDropdownParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: -1,
  },
},
  setup(__props) {

const props = __props;

function closeDropdown() {
  if (open.value) {
    open.value = false;
  }
}
function toggleOpen() {
  open.value = !open.value;
  // checkCurrentValue();
}
const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<null | CoercedChoice>;
// const selectedValue = ref<null | CoercedChoice | CoercedChoice[]>(null);

// checkCurrentValue();

// function checkCurrentValue() {
//   if (props.parameter.valueType === 'medication') return;
//   if (typeof props.parameter.coercedValue === 'object') {
//     selectedValue.value = props.parameter.coercedValue as null | CoercedChoice | CoercedChoice[];
//   }
// }

let open = ref<boolean>(false);

function getChoices() {
  const currentChoices: CoercedChoice[] = props.parameter.coercedChoices;
  if (props.parameter.required) {
    return currentChoices;
  }
  return [null, ...currentChoices];
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      "second-line": true
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (!__props.parameter.isArray)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(), _createBlock(MultipleChoiceOption, {
                  class: "v-multiple-choices-selected",
                  key: coercedValue.value as string,
                  parameter: __props.parameter,
                  index: __props.index,
                  option: coercedValue.value || undefined,
                  readOnly: __props.readOnly,
                  showCheckbox: false,
                  onToggleOpen: toggleOpen
                }, null, 8, ["parameter", "index", "option", "readOnly"])),
                _withDirectives(_createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getChoices(), (choice, choiceIndex) => {
                    return (_openBlock(), _createBlock(MultipleChoiceOption, {
                      key: choiceIndex,
                      onToggleOpen: toggleOpen,
                      parameter: __props.parameter,
                      index: __props.index,
                      option: choice || undefined,
                      readOnly: __props.readOnly,
                      class: _normalizeClass({ first: choiceIndex === 0 }),
                      dropDown: ""
                    }, null, 8, ["parameter", "index", "option", "readOnly", "class"]))
                  }), 128))
                ], 512), [
                  [_vShow, _unref(open)]
                ])
              ])), [
                [_unref(vClickOutside), closeDropdown]
              ])
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})