import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-body-map-list-entry" }

import { safeTranslate } from '@/_shared/translations/i18n';
import { computed, PropType } from 'vue';
import LocationIcon from '@/timeline/components/BodyMap/LocationIcon.vue';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import InstanceListEntry from '@/_shared/components/display/InstanceListEntry.vue';
import BodyMapListEntryBadge from '@/timeline/components/BodyMap/BodyMapListEntryBadge.vue';
import { formatDistance } from 'date-fns';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import InstanceStatusBadge from '../InstanceStatusBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapListEntry',
  props: {
    skinInstance: {
      type: Object as PropType<SkinInstance>,
      required: true,
    },
    reference: {
      type: Boolean,
      default: false,
    },
    sideToShow: {
      type: String,
      default: 'all',
    },
    isInstanceView: {
      type: Boolean,
      default: false,
    },
    showLocationIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
  'selectInstance',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const { featureToggles } = storeToRefs(useUserStore());
const isExtendedInfoEnable = computed(() => featureToggles.value.skinManagementOnCritInfoDashboard || false);
const missedInteractionCount = computed(() => props.skinInstance.missedInteractions?.length ?? 0);
const hasMissedInteraction = computed(() => missedInteractionCount.value > 0);
const missedInteractionInfo = computed(() => safeTranslate('common.missed_interactions'));
const followUpNeeded = computed(() => (props.skinInstance.state === 'active' && !props.skinInstance.interactions.some((interacton) => interacton.state === 'planned')));
const identifiedWhen = computed(() => formatDistance(new Date(props.skinInstance?.createdAt), new Date(), { addSuffix: true }));
const showMissedInteractions = computed(() => hasMissedInteraction.value && !props.isInstanceView && isExtendedInfoEnable.value);
const showFollowUpNeeded = computed(() => followUpNeeded.value && !props.isInstanceView && isExtendedInfoEnable.value);
const showIdentifiedWhen = computed(() => props.skinInstance?.state === 'new' && !props.isInstanceView && isExtendedInfoEnable.value);
const showInstanceView = computed(() => props.isInstanceView && isExtendedInfoEnable.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstanceListEntry, {
      title: _unref(safeTranslate)(`datasets.${__props.skinInstance.metadata.specificType}`),
      "additional-headers": [_unref(safeTranslate)(`datasets.${__props.skinInstance.metadata.location}`)],
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selectInstance'))),
      reference: __props.skinInstance.reference,
      icon: __props.showLocationIcon
    }, _createSlots({
      footer: _withCtx(() => [
        (showInstanceView.value)
          ? (_openBlock(), _createBlock(InstanceStatusBadge, {
              key: 0,
              label: _unref(safeTranslate)(`body_map.state.${__props.skinInstance.state}`)
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (showMissedInteractions.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 1,
              label: missedInteractionInfo.value,
              counter: missedInteractionCount.value
            }, null, 8, ["label", "counter"]))
          : _createCommentVNode("", true),
        (showFollowUpNeeded.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 2,
              label: _unref(safeTranslate)('body_map.follow_up_needed')
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (showIdentifiedWhen.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 3,
              label: `${_unref(safeTranslate)('body_map.state.new')} ${identifiedWhen.value}`
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (__props.showLocationIcon)
        ? {
            name: "customIcon",
            fn: _withCtx(() => [
              _createVNode(LocationIcon, {
                "location-number": __props.skinInstance.locationInfo!.count,
                blue: __props.skinInstance.locationInfo!.side !== 'back' && __props.sideToShow !== 'back',
                "lozenge-icon": true
              }, null, 8, ["location-number", "blue"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title", "additional-headers", "reference", "icon"])
  ]))
}
}

})