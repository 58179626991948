import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../../_shared/assets/icons/close.svg'
import _imports_1 from './green_tick.svg'
import _imports_2 from './success.svg'


const _hoisted_1 = { class: "green" }
const _hoisted_2 = ["alt"]
const _hoisted_3 = { class: "bold tada" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = {
  key: 0,
  class: "bold"
}
const _hoisted_6 = { class: "bold" }
const _hoisted_7 = { class: "bold" }
const _hoisted_8 = {
  key: 0,
  class: "option"
}
const _hoisted_9 = {
  key: 1,
  class: "option"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "option bold" }
const _hoisted_12 = { class: "option" }

import { computed, PropType } from 'vue';

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import use from '@/_shared/compositionApi';
import { FormClient } from './formClientType';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientActionSuccessPopup',
  props: {
  client: {
    type: Object as PropType<FormClient>,
    required: true,
  },
  youCanNow: {
    type: Boolean,
    required: true,
  },
  isNewClient: {
    type: Boolean,
    default: true,
  },
  transferredTo: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;
const { translate } = use.helpers();

const successfulText = computed(() => {
  const successText = props.isNewClient
    ? `clients.finished.created_successfully.${props.client.state}`
    : translate('client_profile.transferred_successfully', {
      first_name: props.client.firstName,
      transferred_to: props.transferredTo,
    });

  const accessSuffix = props.isNewClient && props.client.state === 'pre_assessment' && !props.youCanNow ? '_no_access' : '';
  return translate(`${successText}${accessSuffix}`, {
    name: props.client.preferredName || props.client.firstName,
  });
});

const newServiceInformedText = computed(() => translate('client_profile.new_service_informed', {
  first_name: props.client.firstName || props.client.preferredName,
}));

const sendMessage = () => {
  const preferredName = props.client.preferredName ? `(${props.client.preferredName})` : '';
  const toValue = `${props.client.firstName} ${preferredName} ${props.client.lastName} - Transfer`;
  window.location.assign(createMessageUrl(toValue));
};
const createMessageUrl = (clientName: string) => {
  const messageUrl = `${window.location.origin}${window.location.pathname}#/messages/new?subject=${encodeURIComponent(clientName)}`;
  return messageUrl;
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(BaseModal, {
    title: _unref(translate)('clients.finished.success'),
    width: 508,
    header: false,
    footer: false,
    modalClass: "v-client-created-popup"
  }, {
    content: _withCtx(() => [
      _createElementVNode("img", {
        class: "close",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('closeModal')), ["self"])),
        alt: "x"
      }),
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        class: "green",
        src: _imports_1,
        alt: ""
      }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_unref(translate)('clients.finished.success')) + " ", 1),
        (!__props.youCanNow)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "tada",
              src: _imports_2,
              alt: _unref(translate)('clients.finished.success')
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["bold", { 'no-tada': !__props.youCanNow }])
        }, _toDisplayString(successfulText.value), 3),
        (__props.youCanNow)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "tada",
              src: _imports_2,
              alt: _unref(translate)('clients.finished.success')
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      (!__props.isNewClient)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(newServiceInformedText.value), 1)
          ]))
        : _createCommentVNode("", true),
      (__props.youCanNow)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('clients.finished.you_can_now')), 1),
            (__props.isNewClient)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: { name: 'client.profile', params: { clientId: __props.client.id } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)('clients.finished.edit_profile')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]))
              : _createCommentVNode("", true),
            (__props.isNewClient)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    to: { name: 'client.assessments', params: { clientId: __props.client.id } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)(`clients.finished.start_${__props.client.state}`)), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]))
              : _createCommentVNode("", true),
            (!__props.isNewClient)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("a", {
                    onClick: sendMessage,
                    class: "sp-send-message"
                  }, _toDisplayString(_unref(translate)('client_profile.send_a_message')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(translate)('clients.finished.or')), 1),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('closeModal')), ["self"]))
              }, _toDisplayString(_unref(translate)('clients.finished.done_now')), 1)
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})