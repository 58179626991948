import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-care-plan-review-item" }
const _hoisted_2 = { class: "v-care-plan-review-item-title" }
const _hoisted_3 = { class: "v-care-plan-review-item-value" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "v-missing-text"
}

import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReviewItem',
  props: {
  title: {
    type: String,
    required: true,
  },
  value: {
    type: String as PropType<string | null>,
    default: null,
  },
  fallbackText: {
    type: String,
    default: null,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (__props.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: __props.value
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(__props.fallbackText), 1))
    ])
  ]))
}
}

})