import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "v-single-panel-body v-align-content-center"
}
const _hoisted_3 = { class: "v-no-messages-info" }

import { storeToRefs } from 'pinia';
import useMessagesStore from '@/messages/store/messagesStore';
import MessageHeader from '@/messages/components/MessageHeader.vue';
import { useRouter } from 'vue-router';
import use from '@/_shared/compositionApi';
import { MessageDTO } from '@/messages/types/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageList',
  setup(__props) {

const { filteredMessages } = storeToRefs(useMessagesStore());
const router = useRouter();
const openDetails = (messageId : number) => {
  router.push({ name: 'messages.message', params: { messageId } });
};
const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (_unref(filteredMessages).length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredMessages), (message) => {
          return (_openBlock(), _createBlock(MessageHeader, {
            onClick: ($event: any) => (openDetails((message as  MessageDTO).id!)),
            message: message,
            key: (message as  MessageDTO).id!,
            withHover: ""
          }, null, 8, ["onClick", "message"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('message.empty')), 1)
      ]))
}
}

})