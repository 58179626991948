import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-form-header-container" }
const _hoisted_2 = { class: "v-form-header-left" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "v-service-icon"
}
const _hoisted_5 = { class: "v-form-title" }
const _hoisted_6 = { class: "v-form-content-container" }

import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientFormWrapper',
  props: {
  type: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

function getIcon(): string | null {
  switch (props.type) {
    case 'pet':
      return 'pet.svg';
    case 'training':
      return 'training.svg';
    case 'careplan_template':
      return 'care_plan.svg';
    case 'service_management':
      return 'configuration.svg';
    case 'emergency_admission':
      return 'emergency_admission.svg';
    default:
      return 'personal.svg';
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCard, {
    padding: false,
    "content-top-border": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (getIcon())
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`../../_shared/assets/icons/${getIcon()}`),
                class: "v-service-icon",
                alt: "icon"
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("span", _hoisted_4)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(translate)(`client_form.form_titles.${__props.type}`)), 1)
        ])
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }))
}
}

})