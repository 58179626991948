import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import useBaseStore from '@/_shared/store/base';
import { valueToCamel } from '@/_shared/services/keysToCamel';
import fetchCategoryIcons from '@/_shared/services/categoryIconsApi';

type CategoryIcon = Record<string, string>

const useCategoryIconStore = defineStore('categoryIcon', () => {
  const icons = ref<CategoryIcon>({});

  const { initialize } = useBaseStore();

  async function initialLoad() {
    icons.value = await fetchCategoryIcons();
  }

  initialize(initialLoad);

  const getIconUrl = (codename: string) => computed(() => {
    if (!codename || codename === 'loading') return 'loading';
    if (!icons.value[valueToCamel(codename)]) {
      codename = 'personal';
    }
    return icons.value[valueToCamel(codename)];
  });

  return {
    getIconUrl,
    initialize,
  };
});

export default useCategoryIconStore;
