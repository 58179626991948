import { createI18n } from 'vue-i18n';
import { OrganisationUnit, OrganisationUnitAngular } from '@/_shared/types/organisationUnit';
import en from './en.json';
import pt from './pt.json';
import za from './za.json';

const i18n = createI18n({ // <[Record<string, unknown>], 'en' | 'pt' | 'za'>({
  legacy: false,
  locale: window.currentPerson?.language, // useNourishStore().currentPerson().language,
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  messages: {
    en,
    pt,
    za,
  },
});

export const setGlobalTerms = (currentOrganisationUnit: OrganisationUnit) => {
  if (currentOrganisationUnit) {
    setGlobalTermsInternal(
      currentOrganisationUnit.clientTerm,
      currentOrganisationUnit.clientTermPlural,
      currentOrganisationUnit.carerTerm,
      currentOrganisationUnit.carerTermPlural,
      currentOrganisationUnit.carePlanTerm,
      currentOrganisationUnit.eventTerm,
      currentOrganisationUnit.eventTermPlural,
    );
  }
};

export const setGlobalTermsAngular = (currentOrganisationUnit: Partial<OrganisationUnitAngular>) => {
  if (currentOrganisationUnit) {
    setGlobalTermsInternal(
      currentOrganisationUnit.client_term ?? '',
      currentOrganisationUnit.client_term_plural ?? '',
      currentOrganisationUnit.carer_term ?? '',
      currentOrganisationUnit.carer_term_plural ?? '',
      currentOrganisationUnit.care_plan_term ?? '',
      currentOrganisationUnit.event_term ?? '',
      currentOrganisationUnit.event_term_plural ?? '',
    );
  }
};

const setGlobalTermsInternal = (
  client: string,
  clientPlural: string,
  carer: string,
  carerPlural: string,
  carePlan: string,
  event: string,
  eventPlural: string,
) => {
  const lang = i18n.global.locale.value;
  const currentLocaleMessages = i18n.global.getLocaleMessage(lang);
  const termMessages = {
    terms: {
      client,
      clientPlural,
      carer,
      carerPlural,
      carePlan,
      event,
      eventPlural,
    },
  };
  i18n.global.setLocaleMessage(lang, { ...currentLocaleMessages, ...termMessages });
};

const FALLBACK_NAMESPACES = ['datasets'];

export const safeTranslate = (key: string) => {
  const translation = i18n.global.t(key);
  if (translation === key) {
    const keys = key.split('.');
    if (keys.length > 1 && FALLBACK_NAMESPACES.includes(keys[0])) {
      return keys.slice(1).join('.');
    }
  }
  return translation;
};

export default i18n;
