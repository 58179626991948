import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-signature-parameter-container" }
const _hoisted_2 = { class: "v-signature-parameter-wrapper" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "v-read-and-understood" }
const _hoisted_6 = {
  key: 0,
  class: "v-signature-image-wrapper"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  class: "v-read-only-parameters"
}
const _hoisted_9 = {
  key: 3,
  class: "v-edit-text-parameters"
}
const _hoisted_10 = { class: "v-agree-signature" }
const _hoisted_11 = {
  key: 4,
  class: "v-signature-footer-buttons"
}

import {
  ref, Ref, computed, watch, onUnmounted,
} from 'vue';
import use from '@/_shared/compositionApi';
import CaptureSignature from '@/timeline/components/CaptureSignature.vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import ConfirmationModal from '@/_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import { Parameter, CoercedSignature, CoercedChoice } from '../../types/Parameter';
import InlineMultipleChoiceParameter from '../InlineMultipleChoiceParameter.vue';
import TextParameter from '../TextParameter.vue';
import BaseParameter from '../BaseParameter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignatureParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
  carePlanView: {
    type: Boolean,
    default: false,
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const { setCoercedValueByParameterId, setPreviousInteractionState } = useCurrentInteractionStore();
const { newSignatureCVSaved, currentInteraction } = storeToRefs(useCurrentInteractionStore());
const highlightIcon = ref(false);

const signature = ref(props.parameter.coercedValue) as Ref<CoercedSignature | null>;
const showRemainingFields = ref<boolean>(!!(signature.value && signature.value?.base64File)); // may need to change this
const showSigningAuthority = computed(() => newIsSigningOnBehalfOf.value === 'Yes');
const showTapOrClickToSign = computed(() => newIsSigningOnBehalfOf.value?.length);
const showExistingSignature = computed(() => !!(signature.value && signature.value?.base64File));

const isSigningOnBehalfParameter = createParameter(
  'timeline.parameters.signature.areYouSigningOnBehalfOf',
  'isSigningOnBehalf',
  [
    { label: translate('timeline.parameters.signature.yes'), value: 'Yes' },
    { label: translate('timeline.parameters.signature.no'), value: 'No' },
  ],
);
const printNameParameter = createParameter('timeline.parameters.signature.printName', 'name');
const signingAuthorityParameter = createParameter('timeline.parameters.signature.describeAuthority', 'signingAuthority');

function createParameter(
  nameKey: string,
  coercedValueName: 'isSigningOnBehalf' | 'signingAuthority' | 'name',
  coercedChoices: CoercedChoice[] = [],
  showIf: string[] = [],
) {
  const parameter = ref({} as Parameter);
  const parameterKey = { isSigningOnBehalf: 1, name: 2, signingAuthority: 3 } as Record<'isSigningOnBehalf' | 'signingAuthority' | 'name', number>;
  parameter.value.name = translate(nameKey);
  parameter.value.id = `${props.parameter.id}${parameterKey[coercedValueName]}`;
  parameter.value.codename = `${props.parameter.codename}${coercedValueName}`;
  parameter.value.required = props.parameter.required;
  parameter.value.coercedChoices = coercedChoices ? coercedChoices.map((choice) => ({
    label: choice.label,
    value: choice.value,
  })) as CoercedChoice[] : [];
  const coercedValue = props.parameter.coercedValue as CoercedSignature;
  if (coercedValue) {
    if (coercedValueName === 'isSigningOnBehalf') {
      parameter.value.coercedValue = {
        value: coercedValue.isSigningOnBehalf,
        label: coercedValue.isSigningOnBehalf,
      };
    } else {
      parameter.value.coercedValue = coercedValue[coercedValueName] as string;
    }
  }
  if (parameter.value.config) {
    parameter.value.config.showIf = showIf;
  }
  return parameter;
}

const newBase64File = ref<string>('');
const newSigningAuthority = computed(() => signingAuthorityParameter.value?.coercedValue as string);
const newName = computed(() => printNameParameter.value.coercedValue as string);
const newIsSigningOnBehalfOf = computed(() => (isSigningOnBehalfParameter.value?.coercedValue as CoercedChoice)?.value as string);

const showModal = ref(false);
const signatureDate = ref<Date | null>(null);
const signatureSaved = ref(false);

const saveSignature = () => {
  setPreviousInteractionState(currentInteraction.value);
  signatureDate.value = new Date();
  if (newIsSigningOnBehalfOf.value === 'No') {
    signingAuthorityParameter.value.coercedValue = '';
  }
  signature.value = {
    isSigningOnBehalf: newIsSigningOnBehalfOf.value,
    base64File: newBase64File.value,
    datetimeSigned: signatureDate.value.toISOString(),
    name: newName.value,
    signingAuthority: newSigningAuthority.value,
  };
  props.parameter.coercedValue = signature.value;
  showModal.value = false;
  signatureSaved.value = true;
  newSignatureCVSaved.value = true;
};

const setSignatureFile = (base64File: string) => {
  newBase64File.value = base64File;
};

const invalidTextParams = computed(() => {
  if (newName.value && newSigningAuthority.value && newIsSigningOnBehalfOf.value === 'Yes') {
    return newName.value.length < 1 && newSigningAuthority.value.length < 1;
  } if (newName.value && newIsSigningOnBehalfOf.value === 'No') {
    return newName.value.length < 1;
  }
  return true;
});

const invalidPixelCount = ref<boolean>(true);
const validatePixelCount = (pixelCount: number) => {
  invalidPixelCount.value = pixelCount < 500;
};

const disableSaveButton = computed(() => invalidPixelCount.value || invalidTextParams.value || signatureSaved.value);
const resetSignature = ref(false);

const resetValues = () => {
  isSigningOnBehalfParameter.value.coercedValue = '';
  printNameParameter.value.coercedValue = '';
  signingAuthorityParameter.value.coercedValue = '';
  resetSignature.value = true;
  showRemainingFields.value = false;
  props.parameter.coercedValue = signature.value;
  signatureSaved.value = false;
  signature.value = null;
  setCoercedValueByParameterId(props.parameter.id as number, signature.value);
};

const formattedDateTimeSigned = computed(() => formatDateTime((signature.value as CoercedSignature)?.datetimeSigned));

const signedByValue = computed(() => `${signature.value?.name}${signature.value?.signingAuthority ? ` - ${signature.value.signingAuthority}` : ''}`);

watch(() => props.parameter.coercedValue, (newValue) => {
  if (newValue === null) {
    resetValues();
  }
});

onUnmounted(() => {
  if (newSignatureCVSaved.value) {
    newSignatureCVSaved.value = false;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: "",
      hideHeading: __props.hideHeading
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (!__props.carePlanView)
            ? (_openBlock(), _createBlock(InlineMultipleChoiceParameter, {
                key: 0,
                parameter: _unref(isSigningOnBehalfParameter),
                class: "v-on-behalf-of",
                modelValue: _unref(isSigningOnBehalfParameter),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(isSigningOnBehalfParameter) ? (isSigningOnBehalfParameter).value = $event : null)),
                "read-only": showExistingSignature.value
              }, null, 8, ["parameter", "modelValue", "read-only"]))
            : _createCommentVNode("", true),
          (showTapOrClickToSign.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "v-divider" }, null, -1)),
                (!showRemainingFields.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "v-tap-to-sign-wrapper",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (showRemainingFields.value = true)),
                      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (highlightIcon.value = true)),
                      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (highlightIcon.value = false)),
                      onFocus: _cache[4] || (_cache[4] = ($event: any) => (highlightIcon.value = true)),
                      onBlur: _cache[5] || (_cache[5] = ($event: any) => (highlightIcon.value = false)),
                      tabindex: "0"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_unref(translate)('timeline.parameters.signature.tapOrClickToSign')), 1),
                      _createVNode(BaseIcon, {
                        active: highlightIcon.value,
                        class: "v-signature-icon",
                        name: "signature",
                        size: "lg"
                      }, null, 8, ["active"])
                    ], 32))
                  : _createCommentVNode("", true),
                (showRemainingFields.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("label", null, _toDisplayString(_unref(translate)('timeline.parameters.signature.readAndUnderstood')), 1)
                      ]),
                      (showExistingSignature.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("img", {
                              class: "v-signature-image sp-signature-image",
                              alt: "Image of Signature provided",
                              src: 'data:image/jpeg;base64,' + (signature.value?.base64File || '')
                            }, null, 8, _hoisted_7)
                          ]))
                        : (_openBlock(), _createBlock(CaptureSignature, {
                            key: 1,
                            onBase64File: setSignatureFile,
                            onPixelCount: validatePixelCount,
                            "reset-signature": resetSignature.value
                          }, null, 8, ["reset-signature"])),
                      (showExistingSignature.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("label", null, _toDisplayString(_unref(translate)('timeline.parameters.signature.signedBy')) + " " + _toDisplayString(signedByValue.value) + " " + _toDisplayString(_unref(translate)('timeline.parameters.signature.on')) + " " + _toDisplayString(formattedDateTimeSigned.value), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(TextParameter, {
                              parameter: _unref(printNameParameter),
                              modelValue: _unref(printNameParameter),
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(printNameParameter) ? (printNameParameter).value = $event : null)),
                              class: "v-print-name"
                            }, null, 8, ["parameter", "modelValue"]),
                            (showSigningAuthority.value)
                              ? (_openBlock(), _createBlock(TextParameter, {
                                  key: 0,
                                  parameter: _unref(signingAuthorityParameter),
                                  modelValue: _unref(signingAuthorityParameter),
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(signingAuthorityParameter) ? (signingAuthorityParameter).value = $event : null)),
                                  class: "v-describe-authority"
                                }, null, 8, ["parameter", "modelValue"]))
                              : _createCommentVNode("", true)
                          ])),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("label", null, _toDisplayString(_unref(translate)('timeline.parameters.signature.agreeSignature')), 1)
                      ]),
                      (!showExistingSignature.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(BaseButton, {
                              onClick: resetValues,
                              class: "v-cancel-button",
                              colour: "transparent"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(translate)('timeline.parameters.signature.cancel')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(BaseButton, {
                              class: "v-save-button",
                              disabled: disableSaveButton.value,
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (showModal.value = true))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(translate)('timeline.parameters.signature.save')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["parameter", "hideHeading"]),
    (showModal.value)
      ? (_openBlock(), _createBlock(ConfirmationModal, {
          key: 0,
          onCloseModal: _cache[9] || (_cache[9] = ($event: any) => (showModal.value = false)),
          onConfirm: saveSignature,
          firstRowText: _unref(translate)('timeline.parameters.signature.confirmSaveSignature'),
          secondRowText: _unref(translate)('timeline.parameters.signature.confirmSaveSignature2')
        }, null, 8, ["firstRowText", "secondRowText"]))
      : _createCommentVNode("", true)
  ]))
}
}

})