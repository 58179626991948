import { ref } from 'vue';

const useSelectDatasetModal = () => {
  const showModal = ref<boolean>(false);

  const closeModal = () => {
    showModal.value = false;
  };

  const openModal = (readOnly: boolean) => {
    if (readOnly) {
      return;
    }
    showModal.value = true;
  };

  return {
    showModal,
    closeModal,
    openModal,
  };
};

export default useSelectDatasetModal;
