import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-single-number-parameter-container" }
const _hoisted_2 = ["disabled"]

import { PropType, computed } from 'vue';
import useParameterValidator from '@/timeline/helper/useParameterValidator';
import BaseParameter from './BaseParameter.vue';
import { Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberParameter',
  props: {
  parameter: {
    type: Object as PropType<Parameter>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: -1,
  },
},
  setup(__props) {

const props = __props;

const { errorMessage } = useParameterValidator(props.parameter);

const localCoercedValue = computed({
  get() {
    const value = props.parameter.coercedValue as number | string | null;
    return value == null || value === '' ? null : parseFloat(value.toString());
  },
  set(value: number | null) {
    props.parameter.coercedValue = value;
  },
});

const inputWidthClass = computed(() => (localCoercedValue.value && localCoercedValue.value.toString().length > 4
  ? ['maximum-width'] : ['initial-width']));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      errorMessage: _unref(errorMessage) || undefined
    }, {
      "value-slot": _withCtx(() => [
        _createElementVNode("label", null, [
          _withDirectives(_createElementVNode("input", {
            type: "number",
            disabled: __props.readOnly,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localCoercedValue).value = $event)),
            placeholder: "0",
            class: _normalizeClass(["v-number-input", [inputWidthClass.value]])
          }, null, 10, _hoisted_2), [
            [
              _vModelText,
              localCoercedValue.value,
              void 0,
              { number: true }
            ]
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter", "errorMessage"])
  ]))
}
}

})