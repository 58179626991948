import { defineStore } from 'pinia';
import { ref } from 'vue';
import { InteractionAction } from '@/_shared/types/interaction_action';
import fetchInteractionActions from '@/_shared/services/interactionActionsApi';
import IInteraction from '@/timeline/types/IInteraction';
import { Parameter } from '@/timeline/types/Parameter';

// todo refactor the store to be current interaction actions
const useInteractionActionStore = defineStore('interactionAction', () => {
  const interactionActions = ref<InteractionAction[]>([]);

  async function fetchAllInteractionActions(interactionCodename: string) {
    interactionActions.value = await fetchInteractionActions(interactionCodename);
  }

  function interactionHasActions() {
    if (!interactionActions.value.length) return false;
    return true;
  }

  function shouldRunActions(interaction: IInteraction, parameter?: Parameter) {
    if (!interactionActions.value.length) return false;
    if (parameter?.codename) {
      return !!interactionActions.value.filter((interactionAction) => interactionAction.if.parameterCodename && interactionAction.if.parameterCodename === parameter.codename).length;
    }
    if (interaction.state) {
      return !!interactionActions.value.filter((interactionAction) => interactionAction.if.state).length;
    }
    return false;
  }
  async function $reset(interactionCodename: string | null) {
    interactionActions.value = [];
    if (interactionCodename) {
      await fetchAllInteractionActions(interactionCodename);
    }
  }
  return {
    $reset,
    interactionHasActions,
    shouldRunActions,
  };
});

export default useInteractionActionStore;
