import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-multiselect-parameter-container" }

import DataSetPicker from '@/timeline/interaction_parameters/DataSetPicker.vue';
import { Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'MultiSelectDataSetParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const datasetCodenames = [props.parameter.config!.dataSet! as string];


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(DataSetPicker, {
      parameter: __props.parameter,
      readOnly: __props.readOnly,
      "dataset-codenames": datasetCodenames,
      selectorType: "multi"
    }, null, 8, ["parameter", "readOnly"])
  ]))
}
}

})