import { Component } from 'vue';

import { FeatureToggles } from '@/_shared/types/featureToggles';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import useUserStore from '@/_shared/store/user';

// TODO we only need this while using feature toggles
//  to switch between Vue and Angular routes
const componentBaseOnFT = async (
  ft: keyof FeatureToggles,
  component: Component,
  defaultComp: Component = BasePlaceHolderView,
) => ((await ftEnabled(ft)) ? component : defaultComp);
export default componentBaseOnFT;

export const ftEnabled = async (ft: keyof FeatureToggles) => {
  const userStore = useUserStore();
  await userStore.ensureLoaded();
  return userStore.getFeatureToggle(ft);
};
