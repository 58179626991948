import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import { useBodyMapLocationStore } from '@/_shared/store/bodyMapLocations';
import {
  computed, ComputedRef, PropType, ref, watch,
} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationModalLeftContent',
  props: {
  selectedEntries: {
    type: Object as PropType<string[]>,
    required: true,
  },
},
  emits: ['filter', 'update:selectedEntries'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const selectedLocationCodename: ComputedRef<string | undefined> = computed(() => ((props.selectedEntries?.length)
  ? props.selectedEntries[0]
  : undefined));
const { instanceLocations, defaultSide } = useBodyMapLocationsHelper().forLocationCodeName(selectedLocationCodename);
const { locationsCodeNamesOnSide, sectionLocationsCodeNamesAtSide } = useBodyMapLocationStore();
const currentSide = ref('all');
const filterOnSideChange = (side:string) => {
  currentSide.value = side;
  let locationOnside :string[] | undefined;
  if (currentSelection) {
    const { codenames } = getLocationInSection(currentSelection.sectionCodeName, side);
    locationOnside = codenames;
  } else {
    locationOnside = locationsCodeNamesOnSide(side).value;
  }
  if (!locationOnside.includes(selectedEntry.value)) emit('update:selectedEntries', []);
  emit('filter', locationOnside);
};
const selectedEntry = computed(() => (props.selectedEntries?.length ? props.selectedEntries[0] : ''));

watch(selectedEntry, (newValue, oldValue) => {
  if (currentSelection?.defaultLocationCodeName !== newValue) currentSelection = undefined;
  if (!newValue && oldValue) {
    emit('filter', locationsCodeNamesOnSide(currentSide.value).value);
  }
}, { flush: 'post' });
let currentSelection : { sectionCodeName: string, defaultLocationCodeName : string } | undefined;

function getLocationInSection(sectionCodeName: string, side: string) {
  const sectionLocations = sectionLocationsCodeNamesAtSide(sectionCodeName, side);
  const sectionCnames = sectionLocations.codeNames;
  const wholeBodyCname = side === 'all' ? 'whole_body' : `whole_body_${side}`;
  const codenames = [...sectionCnames, wholeBodyCname];
  return { codenames, defaultLocationCodeName: sectionLocations.defaultLocationCodeName };
}

const filterOnClick = (sectionCodeName:string, side : string) => {
  const { codenames, defaultLocationCodeName } = getLocationInSection(sectionCodeName, side);
  emit('update:selectedEntries', [defaultLocationCodeName]);
  emit('filter', codenames);
  currentSelection = { sectionCodeName, defaultLocationCodeName };
};
const sideToShow = computed(() => (currentSide.value === 'back' && defaultSide.value === 'all' ? currentSide.value : defaultSide.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BodyMapImage, {
    showSideSelector: true,
    "instance-locations": _unref(instanceLocations),
    onSectionClicked: filterOnClick,
    onSideChanged: filterOnSideChange,
    clickable: "",
    "white-background": "",
    "side-to-show": sideToShow.value
  }, null, 8, ["instance-locations", "side-to-show"]))
}
}

})