import { RouteLocationNamedRaw, RouteLocationPathRaw } from 'vue-router';
import { Ref } from 'vue';

export type TopBarLink<NamedLocation extends boolean = true> = {
  name: string,
  icon: string,
  to: NamedLocation extends true ? RouteLocationNamedRaw : (RouteLocationPathRaw | RouteLocationNamedRaw),
  activeOnChildren?: Ref<boolean>,
  customizedActiveMatcher?: boolean,
  disabled?: boolean,
  timelineDropdown?: boolean,
}

export type TopBarLinks<NamedLocation extends boolean = true> = TopBarLink<NamedLocation>[];

export function filterLinksWithRouteNames(links: TopBarLinks, RouteNames: string[]) {
  return links.filter((link: TopBarLink) => !RouteNames.includes((link.to).name as string));
}
