const toCamel = (s: string) => s.replace(
  /([\W_]+[a-z0-9])/ig,
  ($1) => $1.toUpperCase(),
)
  .replace(/([\W_]+)/ig, '')
  .replace(/^[A-Z]/, ($1) => $1.toLowerCase());

//     (s: string) => s.replace(
//   /([-_][a-z])/ig,
//   ($1) => $1.toUpperCase()
//     .replace('-', ''))
//     .replace('_', ''),
// // );
// ;
const isObject = (o: unknown) => o === Object(o) && !Array.isArray(o) && typeof o !== 'function';

const keysToCamel = (o: Record<string, unknown> | unknown[]):Record<string, unknown> | unknown[] => {
  if (isObject(o)) {
    const object = o as Record<string, Record<string, unknown> | unknown[]>;
    const newObject: Record<string, unknown> = {};

    Object.keys(object)
      .forEach((k) => {
        newObject[toCamel(k)] = keysToCamel(object[k]);
      });

    return newObject;
  } if (Array.isArray(o)) {
    const arr = o as (Record<string, unknown> | unknown[])[];
    return arr.map((i) => keysToCamel(i));
  }

  return o;
};

export const valueToCamel = (value: string) => toCamel(value);

export default keysToCamel;
