import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-base-router-view"
}
const _hoisted_2 = {
  key: 0,
  class: "v-single-panel-router-view"
}
const _hoisted_3 = {
  key: 1,
  class: "v-inner-flex-container"
}

import useUserStore from '@/_shared/store/user';
import { loadTranslationsAsync } from '@/_shared/translations/i18nLoading';
import {
  watch, onBeforeMount, Ref, ref, computed, watchEffect, nextTick,
} from 'vue';
import BaseTopActionsBar from '@/_shared/components/general/BaseTopActionsBar.vue';
import BaseLeftPanel from '@/_shared/components/general/left_panel/BaseLeftPanel.vue';
import BaseActionsPanel from '@/_shared/components/general/BaseActionsPanel.vue';

import { useRoute } from 'vue-router';
import { useBreakpoints } from '@vueuse/core';
import BaseNavbar from '@/_shared/components/navigation/BaseNavbar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseRouterView',
  setup(__props) {

_useCssVars(_ctx => ({
  "963d6e16": (pointerEvent.value)
}))

const userStoreLoaded = ref(false);

const topBarContentReal = ref(true);
const actionsContentReal = ref(true);
const sideBarContentReal = ref(true);
const rightPanelContentReal: Ref<null|boolean> = ref(true);
const leftPanelHiddenOnMobile = ref(false);
const topBarHiddenOnMobile = ref(false);
const leftPanelMounted = ref(false);

const breakpoints = useBreakpoints({
  xs: 480,
  sm: 800,
  md: 992,
  lg: 1224,
});
onBeforeMount(async () => {
  await useUserStore().ensureLoaded();
  userStoreLoaded.value = true;
  await loadTranslationsAsync();
});

const route = useRoute();

// TODO this is a temporary tweak to hide Vue top bar on mobile view for  routes that aren't yet in vue
//  A mobile view issue: when Angular right main panel is open, the vue top bar is overlapping it
// because vue is on the top of angular.
// The tweak should be removed once all routes are in vue
// further more when a vue right main panel is open, it should overlap the vue top bar and this should be
// handled  in the concerned right main panel component
watchEffect(() => {
  nextTick();
  if ((route.name === 'NotFound' || route.meta?.isAngular) && breakpoints.smallerOrEqual('sm').value) {
    topBarHiddenOnMobile.value = true;
  } else topBarHiddenOnMobile.value = false;
});

watch(() => route.name, (newRoute, oldRoute) => {
  console.log(`route change ${newRoute?.toString()} from ${oldRoute?.toString()}`);
  if (newRoute === 'NotFound') return;
  rightPanelContentReal.value = true;
  topBarContentReal.value = true;
  actionsContentReal.value = true;
  sideBarContentReal.value = true;
  leftPanelHiddenOnMobile.value = false;
});

const pointerEvent = computed(() => {
  if (rightPanelContentReal.value == null || rightPanelContentReal.value) return 'auto';
  return 'none';
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (userStoreLoaded.value && _unref(route).name!=='NotFound')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_view, { name: "BaseNavbar" }, {
          default: _withCtx(() => [
            _createVNode(BaseNavbar)
          ]),
          _: 1
        }),
        _createVNode(_component_router_view, { name: "TopBar" }, {
          default: _withCtx(({ Component }) => [
            Component
              ? (_openBlock(), _createBlock(BaseTopActionsBar, _normalizeProps(_mergeProps({ key: 0 }, {show: topBarContentReal.value && !topBarHiddenOnMobile.value })), {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                      realComponent: topBarContentReal.value,
                      "onUpdate:realComponent": _cache[0] || (_cache[0] = ($event: any) => ((topBarContentReal).value = $event))
                    }, null, 40, ["realComponent"]))
                  ]),
                  _: 2
                }, 1040))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            Component
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view, { name: "Action" }, {
              default: _withCtx(({ Component }) => [
                Component
                  ? (_openBlock(), _createBlock(BaseActionsPanel, _normalizeProps(_mergeProps({ key: 0 }, {show: actionsContentReal.value})), {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                          realComponent: actionsContentReal.value,
                          "onUpdate:realComponent": _cache[1] || (_cache[1] = ($event: any) => ((actionsContentReal).value = $event))
                        }, null, 40, ["realComponent"]))
                      ]),
                      _: 2
                    }, 1040))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            (!Component)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_router_view, { name: "SideBar" }, {
                    default: _withCtx(({ Component }) => [
                      (leftPanelMounted.value)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                            key: 0,
                            realComponent: sideBarContentReal.value,
                            "onUpdate:realComponent": _cache[2] || (_cache[2] = ($event: any) => ((sideBarContentReal).value = $event)),
                            onHideLeftPanelOnMobile: _cache[3] || (_cache[3] = (hide) => leftPanelHiddenOnMobile.value = hide)
                          }, null, 40, ["realComponent"]))
                        : _createCommentVNode("", true),
                      Component
                        ? (_openBlock(), _createBlock(BaseLeftPanel, _mergeProps({ key: 1 }, {show: sideBarContentReal.value}, {
                            onMounted: _cache[4] || (_cache[4] = (event)=>leftPanelMounted.value=event),
                            class: { 'hidden-on-mobile': leftPanelHiddenOnMobile.value}
                          }), null, 16, ["class"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view, {
                        class: "v-main-right-panel",
                        realComponent: rightPanelContentReal.value,
                        "onUpdate:realComponent": _cache[5] || (_cache[5] = ($event: any) => ((rightPanelContentReal).value = $event))
                      }, null, 8, ["realComponent"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})