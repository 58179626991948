import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../_shared/assets/images/wait_screen_img.svg'


const _hoisted_1 = { class: "v-wait-screen" }
const _hoisted_2 = { class: "v-wait-screen-content" }
const _hoisted_3 = { class: "v-wait-screen-text" }
const _hoisted_4 = { key: 0 }

import use from '@/_shared/compositionApi';
import { onBeforeMount } from 'vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';


export default /*@__PURE__*/_defineComponent({
  __name: 'WaitScreen',
  props: {
  clientsTotalNumber: {
    type: Number,
    required: true,
  },
  numberClientsLoaded: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('data_retention'); // TODO WHEN PURE VUE remove
});
const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        alt: "loading",
        src: _imports_0
      }, null, -1)),
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(_toDisplayString(_unref(translate)('data_retention.loading_message_p1')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_unref(translate)('data_retention.loading_message_p2')), 1),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        (__props.clientsTotalNumber)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(translate)('data_retention.loading_progress',
                {
                  clients_loaded: __props.numberClientsLoaded,
                  total_clients: __props.clientsTotalNumber,
                })), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})