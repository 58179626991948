import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue"

import { BaseListEntry } from '@/_shared/components';
import useUserStore from '@/_shared/store/user';
import { useRoute } from 'vue-router';
import { watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfessionalInfoSideBar',
  props: {
  section: {
    type: [String, Array],
  },
},
  emits: ['hideLeftPanelOnMobile'],
  setup(__props, { emit: __emit }) {

const props = __props;
const toLink = (section: string) => {
  const route = useRoute();
  return { name: 'carer.certification', params: { section }, query: route.query };
};

const { userHasPermission, getFeatureToggle } = useUserStore();
const linkVisible = (section: string) => {
  if (section === 'availabilities') {
    const canUpdate = userHasPermission('manageCarers');
    const feature = getFeatureToggle('availabilityModal');
    return feature && canUpdate;
  }
  return true;
};
const emit = __emit;

watch(() => props.section, (section) => {
  let hideLeftPanelOnMobile;
  if (!section || section === '') {
    hideLeftPanelOnMobile = false;
  } else {
    hideLeftPanelOnMobile = true;
  }
  emit('hideLeftPanelOnMobile', hideLeftPanelOnMobile);
}, { immediate: true });


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#lp-container" }, [
      _createVNode(_component_router_link, {
        to: toLink('qualifications')
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseListEntry), {
            title: "Qualifications",
            icon: "ncp-qualifications",
            "right-icon": "chevron",
            "router-link": "",
            active: __props.section == 'qualifications'
          }, null, 8, ["active"])
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_router_link, {
        to: toLink('training')
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseListEntry), {
            title: "Training",
            icon: "ncp-training",
            "right-icon": "chevron",
            active: __props.section == 'training'
          }, null, 8, ["active"])
        ]),
        _: 1
      }, 8, ["to"]),
      (linkVisible('availabilities'))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: toLink('availabilities')
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseListEntry), {
                title: "Availability",
                icon: "nr-icon-schedule",
                "right-icon": "chevron",
                active: __props.section == 'availabilities'
              }, null, 8, ["active"])
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}
}

})