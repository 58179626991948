import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-picture-parameter-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "v-picture-blur sp-picture-blur"
}
const _hoisted_3 = { class: "v-picture-blur-text" }
const _hoisted_4 = { class: "v-picture-input" }
const _hoisted_5 = { class: "v-picture-thumbnails" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "v-add-picture" }
const _hoisted_9 = { class: "v-add-picture-text" }
const _hoisted_10 = ["accept"]

import {
  ref, Ref, toRef, computed,
} from 'vue';
import use from '@/_shared/compositionApi';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { GenericFile } from '@/_shared/types/genericFile';
import ViewImageModal from '@/_shared/components/modals/ViewImageModal/ViewImageModal.vue';
import { allowedPictureExtentions } from '@/timeline/helper/parametersHelper';
import PictureParameterThumbnail from '../components/PictureParameterThumbnail.vue';
import { Parameter, ParameterPicture, PictureWrapper } from '../types/Parameter';
import BaseParameter from './BaseParameter.vue';
import PictureModal from './PictureModal.vue';
import useCurrentInteractionStore from '../store/currentInteractionStore';
import CameraModal from './CameraModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PictureParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  iconUrl: {
    type: String,
    required: true,
  },
  clientId: {
    type: [Number, String],
    required: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const currentInteractionStore = useCurrentInteractionStore();
const { picturesToDelete } = currentInteractionStore;
if (!props.parameter.coercedValue) { props.parameter.coercedValue = {}; }
const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<PictureWrapper>;
if (!coercedValue.value.pictures) { coercedValue.value.pictures = []; }

const pictures = computed({
  get() {
    return coercedValue.value?.pictures || [];
  },
  set(picturesArray: ParameterPicture[]) {
    if (!coercedValue.value?.pictures) { coercedValue.value = {} as PictureWrapper; }
    coercedValue.value.pictures = picturesArray;
  },
});
const maxNumberOfPictures = ref((props.parameter.config?.maxNumberOfPictures as number) || 1);

const fileInputKey = ref(1);

const pictureModalVisible = ref(false);
const cameraModalVisible = ref(false);
const viewImageModalVisible = ref(false);

const openPictureModal = () => { pictureModalVisible.value = true; };
const openCameraModal = () => { cameraModalVisible.value = true; };
const openViewImageModal = (image: ParameterPicture) => {
  viewImageModalVisible.value = true;
  if (image.pictureFile) {
    imageToView.value = image.pictureFile;
  } else if (image.tempFile) {
    imageToView.value = image.tempFile;
  }
};

const imageToView = ref<GenericFile | File>({});

const addPictureToCoercedValue = (file: File, base64Thumbnail: string) => {
  const picture = {} as ParameterPicture;
  picture.tempFile = file as File;
  picture.base64Thumbnail = base64Thumbnail as string;
  picture.position = pictures.value.length;
  const currentPictures = pictures.value;
  currentPictures.push(picture);
  pictures.value = currentPictures;
  props.parameter.edited = true;
};

const deletePicture = async (pictureToDelete: ParameterPicture) => {
  const cvPictures = pictures.value;
  cvPictures.splice(cvPictures.indexOf(pictureToDelete), 1);
  cvPictures.forEach((picture, index) => {
    cvPictures[index].position = index;
  });
  pictures.value = cvPictures;
  if (!props.parameter?.dataPoint && pictureToDelete?.pictureFile && pictureToDelete?.pictureFile.id) {
    picturesToDelete.push({ pictureFile: pictureToDelete.pictureFile as GenericFile, parameterId: props.parameter.id });
  }
  props.parameter.edited = true;
};
const toggleBlur = () => {
  if (!coercedValue.value) { props.parameter.coercedValue = {}; }
  coercedValue.value.blurred = !coercedValue.value.blurred;
};

function onChange(event: Event) {
  const { files } = event.target as HTMLInputElement;
  if (files && files.length === 1) {
    const file: File = files[0];
    const fileReader = new FileReader();
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    fileReader.onload = () => {
      const image = new Image();
      image.onload = () => {
        const aspectRatio = image.width / image.height;
        if (aspectRatio > 1) {
          canvas.width = 100;
          canvas.height = 100 / aspectRatio;
        } else {
          canvas.width = 100 * aspectRatio;
          canvas.height = 100;
        }
        context?.drawImage(image, 0, 0, canvas.width, canvas.height);
        const base64Thumbnail = canvas.toDataURL('image/jpeg', 0.5);
        fileInputKey.value += 1;
        addPictureToCoercedValue(file, base64Thumbnail);
      };
      image.src = fileReader.result as string;
    };
    fileReader.readAsDataURL(file);
  }
}

const fileInput = ref<HTMLInputElement>();

const handleNoCamera = () => {
  pictureModalVisible.value = false;
  fileInput.value?.click();
};

const hasCamera = computed(() => window.hasCamera);

const showBlurCheckbox = () => {
  if (props.readOnly && pictures.value.length < 1) {
    return false;
  }
  return !!props.parameter.config?.allowBlurring;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-file-parameter-container", `sp-picture-parameter sp-picture-parameter-${__props.parameter?.id}`])
  }, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (showBlurCheckbox())
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)('timeline.parameters.picture.blur')), 1),
                _createElementVNode("div", {
                  class: "v-picture-blur-checkbox",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleBlur()))
                }, [
                  _createVNode(BaseCheckbox, {
                    checked: coercedValue.value?.blurred
                  }, null, 8, ["checked"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (pictures.value?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pictures.value, (picture) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: picture.tempFile ? picture.tempFile.lastModified : picture.pictureFile.uuid
                      }, [
                        _createVNode(PictureParameterThumbnail, {
                          picture: picture,
                          "read-only": props.readOnly,
                          onDeletePicture: deletePicture,
                          onOpenViewImageModal: ($event: any) => (openViewImageModal(picture)),
                          "all-blurred": coercedValue.value?.blurred
                        }, null, 8, ["picture", "read-only", "onOpenViewImageModal", "all-blurred"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (pictures.value?.length < maxNumberOfPictures.value && !props.readOnly)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", {
                      class: "v-add-picture-container sp-add-picture",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (hasCamera.value === false ? fileInput.value?.click() : openPictureModal()))
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(BaseIcon, {
                          name: "nsx-font-icon--add_photo_alternate",
                          width: "42px",
                          height: "42px",
                          "no-hover-background": "",
                          noHover: ""
                        }),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(translate)('timeline.parameters.picture.addNew')), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        (pictureModalVisible.value)
          ? (_openBlock(), _createBlock(PictureModal, {
              key: 0,
              title: __props.parameter?.name || '',
              iconUrl: __props.iconUrl || '',
              clientId: __props.clientId || '',
              "file-input-key": fileInputKey.value,
              onClosePictureModal: _cache[2] || (_cache[2] = ($event: any) => (pictureModalVisible.value = false)),
              onOpenCameraModal: openCameraModal,
              onOnChange: onChange,
              onHandleNoCamera: handleNoCamera
            }, null, 8, ["title", "iconUrl", "clientId", "file-input-key"]))
          : _createCommentVNode("", true),
        (cameraModalVisible.value)
          ? (_openBlock(), _createBlock(CameraModal, {
              key: 1,
              onCloseCameraModal: _cache[3] || (_cache[3] = ($event: any) => (cameraModalVisible.value = false)),
              onOpenPictureModal: _cache[4] || (_cache[4] = ($event: any) => (pictureModalVisible.value = true)),
              onSavePicture: addPictureToCoercedValue
            }))
          : _createCommentVNode("", true),
        (viewImageModalVisible.value)
          ? (_openBlock(), _createBlock(ViewImageModal, {
              key: 2,
              onCloseViewImageModal: _cache[5] || (_cache[5] = ($event: any) => (viewImageModalVisible.value = false)),
              file: imageToView.value
            }, null, 8, ["file"]))
          : _createCommentVNode("", true),
        _createElementVNode("label", null, [
          (_openBlock(), _createElementBlock("input", {
            type: "file",
            ref_key: "fileInput",
            ref: fileInput,
            key: fileInputKey.value,
            style: {"display":"none"},
            accept: _unref(allowedPictureExtentions),
            onChange: _cache[6] || (_cache[6] = ($event: any) => (onChange($event as unknown as Event)))
          }, null, 40, _hoisted_10))
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ], 2))
}
}

})