import { defineStore } from 'pinia';
import IInteraction from '@/timeline/types/IInteraction';
import { computed, Ref, ref } from 'vue';
import {
  fetchChildInteractions,
  fetchForClient,
  getInteractions,
  searchInteractions,
  fetchForCarer,
} from '@/timeline/services/interactionsApi';
import { getAssessmentsForClientByProvidedServiceIds } from '@/assessments/services/assessmentsApi';
import { promiseMemorise } from '@/_shared/services/UseUtils';

export interface Interaction extends IInteraction {
  id: number;
}

export interface InteractionQueryResponse {
  interactions: Interaction[];
}

const useInteractionsStore = defineStore('interactions', () => {
  const interactions: Ref<IInteraction[]> = ref([] as IInteraction[]);

  const interactionsIds = computed(() => interactions.value.map((i) => i.id as number));

  async function fetchForClientByDate(
    organisationUnitId: number,
    clientId: number,
    date?: string | null,
    mine = false,
  ) {
    if (date) {
      date = date.substring(0, 10);
    }

    const params = {
      organisationUnitIds: organisationUnitId,
      clientId,
      mine,
      extended: false,
      date,
    };
    return fetchForClient(clientId, params).then((response) => {
      interactions.value = response as IInteraction[];
      return response;
    });
  }

  async function fetchForCarerByDate(
    organisationUnitId: number,
    carerId: number,
    date?: string | null,
    mine = false,
  ) {
    if (date) {
      date = date.substring(0, 10);
    }

    const params = {
      organisationUnitIds: [organisationUnitId],
      carerId,
      mine,
      extended: false,
      date,
    };
    return fetchForCarer(carerId, params).then((response) => {
      interactions.value = response as IInteraction[];
      return response;
    }) as Promise<IInteraction[]>;
  }

  const fetchByServiceId = async (
    organisationUnitId: number,
    serviceId: number,
    date?: string | null,
    mine = false,
  ) => {
    if (date) {
      date = date.substring(0, 10);
    }
    const query = {
      service_id: serviceId,
      organisation_unit_id: organisationUnitId,
      date,
      mine,
      exclude_actions: true,
    };
    return memorisedGetInteractions(query, {}, false).then((response) => {
      interactions.value = response as IInteraction[];
      return response as IInteraction[];
    });
  };

  const memorisedGetInteractions = promiseMemorise(getInteractions, 1000 * 30);

  async function searchForClient(
    organisationUnitId: number | null,
    clientId: number,
    searchText: string,
    ignoreDate: string | null,
    nourishInstanceId: number | null = null,
  ) {
    // TODO cache / debounce?
    const query = {
      search: {
        text: searchText,
        client_id: clientId,
        organisation_unit_id: organisationUnitId,
        ignore_date: ignoreDate,
        nourish_instance_id: nourishInstanceId,
        extended: false,
      },
    };
    return searchInteractions(query);
  }

  async function searchForCarer() {
    // It looks like Angular doesnt do search for carer. This needs to be clarified with product team.
    return [] as IInteraction[];
  }

  function updateInteraction(interaction: IInteraction) {
    const interactionToUpdate = interactions.value.find(
      (storedInteraction: IInteraction) => storedInteraction?.id?.toString() === interaction?.id?.toString(),
    );
    if (interactionToUpdate) {
      Object.assign(interactionToUpdate, interaction);
    } else {
      interactions.value.push(interaction);
    }
  }

  const memorizedClientAssessments = promiseMemorise(
    getAssessmentsForClientByProvidedServiceIds,
    1000 * 60,
  );

  const fetchAssessmentInteractionsByProvidedServiceIds = async (
    clientId: number,
    organisationUnitId: number,
    providedServiceIds: number[],
  ) => {
    interactions.value = await memorizedClientAssessments(
      clientId,
      organisationUnitId,
      providedServiceIds,
    );
  };

  const fetchBulkInteractions = async (
    bulkInteractionIds: number[],
    skipIfExist = true,
    extended = true,
  ): Promise<void> => {
    if (!bulkInteractionIds.length) return;

    const idsToFetch = skipIfExist
      ? bulkInteractionIds.filter((id) => !interactionsIds.value.includes(id))
      : bulkInteractionIds;

    if (!idsToFetch.length) return;

    const chunkSize = 10;
    const chunkList = Array.from({ length: Math.ceil(idsToFetch.length / chunkSize) }, (_, index) => idsToFetch.slice(index * chunkSize, (index + 1) * chunkSize));

    const bulkInteractions = await Promise.all(
      chunkList.map((chunk) => getInteractions({ ids: chunk }, {}, false, extended)),
    ).then((values) => values.flat(2));

    interactions.value.push(...bulkInteractions);
  };

  const $reset = () => {
    interactions.value = [];
  };

  const loadChildInteractions = async (clientId: number, currentInteractionId: number) => {
    const childInteraction = await fetchChildInteractions(clientId, currentInteractionId);
    if (childInteraction) {
      childInteraction.forEach((child: IInteraction) => {
        updateInteraction(child);
      });
    }
  };

  const bulkInteractionIds = ref([] as number[]);

  return {
    fetchForClientByDate,
    fetchForCarerByDate,
    searchForClient,
    searchForCarer,
    updateInteraction,
    fetchAssessmentInteractionsByProvidedServiceIds,
    interactions,
    $reset,
    fetchBulkInteractions,
    loadChildInteractions,
    fetchByServiceId,
    bulkInteractionIds,
  };
});
export default useInteractionsStore;
