import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/warning.svg'


const _hoisted_1 = { class: "london-care-tos-wrapper" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "heading" }
const _hoisted_4 = { class: "main-wrapper" }
const _hoisted_5 = { class: "main-text" }
const _hoisted_6 = { class: "heading" }
const _hoisted_7 = { class: "main-wrapper" }
const _hoisted_8 = { class: "main-text" }
const _hoisted_9 = { class: "heading" }
const _hoisted_10 = { class: "main-wrapper" }
const _hoisted_11 = { class: "main-text" }

import use from '@/_shared/compositionApi';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { fetchLondonCareRecordTosFile } from '../services/LondonCareRecordApi';
import { tosSessionKey } from '../models/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'LondonCareRecordTos',
  props: {
    clientName: {}
  },
  emits: ['confirmTos', 'confirm'],
  setup(__props: any, { emit: __emit }) {

const step: Ref<'start' | 'confirm' | 'cancel'> = ref('start');

const emit = __emit;

const props = __props;

const router = useRouter();

const { translate } = use.helpers();

// Cuts the end off of the URL to redirect to the client profile

const downloadNourishTosFile = () => {
  fetchLondonCareRecordTosFile();
};

const redirectToProfile = () => {
  router.push({ name: 'client.profile' });
};

const confirmTos = () => {
  window.localStorage.setItem(tosSessionKey, 'true');
  emit('confirmTos', true);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (step.value == 'start')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", null, [
                _cache[4] || (_cache[4] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_heading_start")), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_1")) + " " + _toDisplayString(props.clientName) + ".", 1),
                _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_2")), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_3")), 1)
              ]),
              _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_4")) + ":", 1),
              _createElementVNode("p", null, [
                _createTextVNode(" 1) " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_5")) + " ", 1),
                _createElementVNode("a", {
                  class: "v-tos",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadNourishTosFile()))
                }, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_5_link")), 1)
              ]),
              _createElementVNode("p", null, "2) " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_6")), 1),
              _createElementVNode("p", null, "3) " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_7")), 1),
              _createElementVNode("p", null, "4) " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_8")), 1)
            ]),
            _createElementVNode("div", null, [
              _createVNode(BaseButton, {
                class: "button-space cancel",
                onClicked: _cache[1] || (_cache[1] = ($event: any) => (step.value = 'cancel')),
                "button-type": "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)("alert.cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(BaseButton, {
                class: "confirm",
                onClicked: _cache[2] || (_cache[2] = ($event: any) => (step.value = 'confirm'))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)("alert.confirm")), 1)
                ]),
                _: 1
              })
            ])
          ], 64))
        : (step.value == 'confirm')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_heading_confirm")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_confirm_text_1")) + " " + _toDisplayString(props.clientName) + ".", 1)
                ])
              ]),
              _createElementVNode("div", null, [
                _createVNode(BaseButton, {
                  class: "close-tos",
                  onClicked: confirmTos
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(translate)("alert.close")), 1)
                  ]),
                  _: 1
                })
              ])
            ], 64))
          : (step.value == 'cancel')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h3", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_heading_cancel")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_cancel_text_1")), 1),
                    _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_cancel_text_2")), 1)
                  ]),
                  _createElementVNode("p", null, _toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_text_4")) + ":", 1)
                ]),
                _createElementVNode("div", null, [
                  _createVNode(BaseButton, {
                    class: "button-space go-back",
                    onClicked: _cache[3] || (_cache[3] = ($event: any) => (step.value = 'start')),
                    "button-type": "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_go_back")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(BaseButton, {
                    class: "button-space redirect",
                    onClicked: redirectToProfile
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)("london_care_record.tos_profile_popup_close_without_access")), 1)
                    ]),
                    _: 1
                  })
                ])
              ], 64))
            : _createCommentVNode("", true)
    ])
  ]))
}
}

})