import { defineStore, storeToRefs } from 'pinia';
import {
  ref, computed, Ref, ComputedRef,
} from 'vue';
import { InteractionInfo } from '@/assessments/types/assessmentState';
import IInteraction from '@/timeline/types/IInteraction';
import { ProvidedService } from '@/_shared/types/providedService';
import useInteractionsStore from '@/_shared/store/interactions';
import { clientStore } from '@/_shared/store/clients';
import { providedServicesStore } from '@/_shared/store/providedServices';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { AssessmentsList, PlannedAssessments } from '@/assessments/types/assessment';
import { watchTriggerable } from '@vueuse/core';
import { oneAssessmentParPs } from '@/timeline/helper/interactionsHelper';

const useAssessmentStore = defineStore('assessment', () => {
  const currentClientId: Ref<number | undefined> = ref();
  // todo assure that it is always set number
  const currentClientState = computed(() => clientStore.currentOrgUnitState(currentClientId.value as number).value);
  const { assessmentsForState } = storeToRefs(useServiceFilteringStore());
  const foundProvidedServiceIds = computed(() => [...Array.from(new Set(foundProvidedServices.value.map((ps: ProvidedService) => ps.id)))]);

  const interactionsStore = useInteractionsStore();
  const { interactions } = storeToRefs(interactionsStore);

  const assessmentForCurrentState = computed((): InteractionInfo[] => {
    if (!currentClientState.value) return [];
    return assessmentsForState.value[currentClientState.value];
  });

  const dummyAssessmentCodenames = computed(() => assessmentForCurrentState.value
    .filter((interactionInfo) => interactionInfo.showDummy)
    .map((interactionInfo) => interactionInfo.name)); // in the filter for assessments the name is codename

  const otherStates = computed(() => Object.keys(assessmentsForState.value)
    .filter((key) => key !== currentClientState.value)
    .map((key) => assessmentsForState.value[key])
    .flat());

  const providedServicesForInteractionInfo = (assessments: ComputedRef<InteractionInfo[]>) => computed(() => {
    const uniqueCodenames = [...Array.from(new Set(assessments.value?.map((item) => item.name as string)))]; // in the filter for assessments the name is codename
    return providedServicesStore.byCodenames(uniqueCodenames).value;
  });

  const providedServicesForCurrentState = computed(() => providedServicesForInteractionInfo(assessmentForCurrentState).value);

  const providedServicesForOtherStates = computed(() => providedServicesForInteractionInfo(otherStates).value);

  const foundProvidedServices = computed<ProvidedService[]>(() => [
    ...providedServicesForCurrentState.value,
    ...providedServicesForOtherStates.value,
  ]);

  const assessmentProvidedServices = computed(() => {
    if (currentClientState.value && assessmentsForState.value) {
      return {
        current: providedServicesForCurrentState.value as ProvidedService[],
        all: Array.from(new Set([...providedServicesForCurrentState.value, ...providedServicesForOtherStates.value])) as ProvidedService[],
      };
    }
    return { current: [], all: [] };
  });
  const { trigger: triggerFetchClientAssessments } = watchTriggerable([currentClientId, foundProvidedServiceIds], ([newCurrentClientId, newFoundProvidedServiceIds]) => {
    if ((newCurrentClientId && newFoundProvidedServiceIds.length && window.currentOrganisationUnit.id)) {
      interactionsStore.fetchAssessmentInteractionsByProvidedServiceIds(
        newCurrentClientId,
        window.currentOrganisationUnit.id,
        newFoundProvidedServiceIds,
      );
    }
  });

  const topAssessments = computed(() => {
    const stickyDummy = [...allStatesAssessments.value.sticky!, ...allStatesAssessments.value.dummy!];
    const completedAssessments = currentStatePlanedCompletedAssessments.value.completed;
    const plannedAssessments = currentStatePlanedCompletedAssessments.value.planned?.next;
    if (completedAssessments?.length) stickyDummy.push(...oneAssessmentParPs(completedAssessments));
    if (plannedAssessments) stickyDummy.push(...oneAssessmentParPs(plannedAssessments));
    return [...allStatesAssessments.value.planned!.overdue!, ...stickyDummy];
  });

  const allStatesAssessments = computed(() => populateAssessments(assessmentProvidedServices.value.all, false));
  const currentStatePlanedCompletedAssessments = computed(() => populateAssessments(assessmentProvidedServices.value.current, true));

  const populateAssessments = (providedServicesForAssessments: ProvidedService[], isForCurrentState = false): AssessmentsList => {
    const emptyAssessmentsList :AssessmentsList = {
      completed: [], sticky: [], planned: { next: [], overdue: [] }, dummy: [],
    };

    const psForAssessments: AssessmentsList = providedServicesForAssessments.map((ps) => {
      if (!interactions.value) return {};
      const assessmentInteractions: IInteraction[] = interactions.value
        .filter((i: IInteraction) => i.providedServiceId === ps.id);
      let stickyInteractions: IInteraction[] = [];
      if (!isForCurrentState) {
        if ((!assessmentInteractions?.length)
            && dummyAssessmentCodenames.value.includes(ps.codename)) {
          ps.showDummy = true;
          return { dummy: [ps] } as AssessmentsList;
        }
        stickyInteractions = getInteractionsSortedByDate(assessmentInteractions, 'sticky', 'startAt');
      }

      const completedInteractions = getInteractionsSortedByDate(assessmentInteractions, 'closed', 'finishAt', 'desc');
      const plannedInteractions = getInteractionsSortedByDate(assessmentInteractions, 'planned', 'plannedFinishAt')
        .reduce(organizeInteractions(), { next: [], overdue: [] });

      return {
        completed: [...completedInteractions], sticky: [...stickyInteractions], planned: { ...plannedInteractions },
      };
    }).reduce((a: AssessmentsList, b: AssessmentsList) => aggregateAssessmentLists(a, b), {} as AssessmentsList);
    return { ...emptyAssessmentsList, ...psForAssessments };

    function aggregateAssessmentLists(a:AssessmentsList, b: AssessmentsList): AssessmentsList {
      return {
        completed: concat(a.completed, b.completed),
        sticky: concat(a.sticky, b.sticky),
        planned: {
          next: concat(a.planned?.next, b.planned?.next),
          overdue: concat(a.planned?.overdue, b.planned?.overdue),
        },
        dummy: concat(a.dummy, b.dummy),
      };
    }

    function concat<t>(a:t[]|undefined, b:t[]|undefined): t[] {
      return [...(a || []), ...(b || [])];
    }

    function organizeInteractions() {
      return (acc: PlannedAssessments, curr: IInteraction) => {
        if (Date.parse(<string>curr.plannedFinishAt) <= Date.now()) {
          acc.overdue!.push(curr);
        } else {
          acc.next.push(curr);
        }
        return acc;
      };
    }

    function getInteractionsSortedByDate(assessmentInteractions: IInteraction[], state: string, property : keyof IInteraction, order = 'asc') {
      const orderFactor = order === 'asc' ? 1 : -1;
      return assessmentInteractions
        .filter((i: IInteraction) => i.state === state)
        .sort((a: IInteraction, b: IInteraction) => ((Date.parse(<string>a[property])
              - Date.parse(<string>b[property])) * orderFactor));
    }
  };

  const $reset = (id: number) => {
    useInteractionsStore().$reset();
    if (currentClientId.value === id) {
      triggerFetchClientAssessments();
      return;
    }
    currentClientId.value = id;
  };

  return {
    topAssessments,
    allStatesAssessments,
    $reset,
  };
});

export default useAssessmentStore;
