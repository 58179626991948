import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onUpdated } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePlaceHolderView',
  props: {
  realComponent: {
    type: [Boolean],
    require: false,
  },
},
  emits: ['update:realComponent'],
  setup(__props, { emit: __emit }) {

// TODO: Remove all of the component when we finish the migrate from ANGULAR

const emit = __emit;
emit('update:realComponent', false);
onUpdated(() => {
  emit('update:realComponent', false);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("template"))
}
}

})