import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "v-left-panel-dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "v-dropdown-option-icon"
}
const _hoisted_4 = { class: "v-dropdown-option-label" }
const _hoisted_5 = {
  key: 1,
  class: "v-dropdown-check"
}

import { PropType } from 'vue';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeftPanelDropdownList',
  props: {
  options: {
    type: Array as PropType<DropdownOption[]>,
    required: true,
  },
  showDropdown: {
    type: Boolean,
    default: false,
    required: true,
  },
  showCheckIcon: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  'optionSelected',
],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const handleSelection = (option: DropdownOption) => {
  emit('optionSelected', option);
};


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.codename
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["v-dropdown-option", [`sp-${option.codename}`, { disabled: option.disabled }]]),
          onClick: ($event: any) => (handleSelection(option))
        }, [
          (option.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(BaseIcon, {
                  name: option.icon,
                  width: "24px",
                  height: "24px",
                  "no-hover": "",
                  compact: "",
                  "no-hover-background": ""
                }, null, 8, ["name"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, _toDisplayString(option.label), 1),
          (__props.showCheckIcon && option.selected)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(BaseIcon, {
                  name: "check",
                  width: "16px",
                  height: "16px",
                  "no-hover": "",
                  compact: "",
                  "no-hover-background": "",
                  "foreground-color": "blue"
                })
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2)
      ]))
    }), 128))
  ], 512)), [
    [_vShow, __props.showDropdown]
  ])
}
}

})