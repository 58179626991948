import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import use from '@/_shared/compositionApi';
import { computed, Ref } from 'vue';
import { isPersonPickerParamType } from '@/timeline/helper/parametersHelper';
import { CompositeOption, OptGroup, Options } from '@/_shared/types/baseSelect';
import { Parameter } from '@/timeline/types/Parameter';

const { translate } = use.helpers();

const useSelectDatasetSelectInput = (readOnly: boolean, parameter: Parameter, options: Ref<Options>) => {
  const { currentInteraction } = useCurrentInteractionStore();
  const { clientStore } = use.store.clients();
  const { clientId } = currentInteraction;
  const clientName = computed(() => (clientId ? clientStore.preferredOrFirstName(clientId).value : 'Client'));

  const isDisabled = computed(() => readOnly || personHasNoRelations.value);

  const personHasNoRelations = computed(() => isPersonPickerParamType(parameter, 'circle_of_care') && (options.value as Options| CompositeOption[] | OptGroup[])?.length === 0);

  const placeholderMessage = computed(() => {
    if (isDisabled.value && personHasNoRelations.value && clientName.value) {
      return translate(
        'timeline.parameters.AdvancedMultiselect.noCircleOfCare',
        { client_name: clientName.value },
      );
    }
    return '';
  });

  return {
    placeholderMessage,
    isDisabled,
  };
};

export default useSelectDatasetSelectInput;
