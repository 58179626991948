import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-action-div" }

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseActionsPanel',
  props: {
  show: Boolean,
},
  setup(__props) {

_useCssVars(_ctx => ({
  "33f7228b": (visibility.value),
  "3ee16796": (pointerEvent.value)
}))

const props = __props;
const visibility = computed(() => {
  if (props.show) return 'visible';
  return 'hidden';
});
const pointerEvent = computed(() => {
  if (props.show) return 'auto';
  return 'none';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})