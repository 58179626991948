import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-events-filter-dropdowns-container" }

import { computed, PropType } from 'vue';
import { EventPwsInstance } from '@/_shared/types/NourishInstance';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import BaseFilterDropdown
  from '@/_shared/components/general/BaseFilterDropdown.vue';
import {
  filterEventPWSInstances,
  filterOption,
  filterStates,
  filterOptionsWithCount,
} from '@/_shared/services/instanceFilterHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsFilter',
  props: {
  eventsByState: {
    type: Object as PropType<{ [key:string] : EventPwsInstance[] }>,
    required: true,
  },
  currentState: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
  currentSubType: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
  currentSeverity: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
},
  emits: ['filterChange'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const allInstances = computed(() => Object.values(props.eventsByState).flat());
const currentSeverityCodename = computed(() => props.currentSeverity.codename);
const currentStateCodename = computed(() => props.currentState.codename);
const stateValues = computed(() => Array.from(new Set(allInstances.value.map((instance) => instance.state))));
const severityValues = computed(() => Array.from(new Set(allInstances.value.map((instance) => instance.metadata.severity))));
const subTypeValues = computed(() => Array.from(new Set(allInstances.value.map((instance) => instance.subType))));

const stateOptions = computed(() => filterStates(stateValues.value, 'events.sections'));
const severityOptions = computed(() => filterOption(severityValues.value, 'datasets'));

const filteredInstances = computed(() => (currentSeverityCodename.value === 'all' && currentStateCodename.value === 'all' ? allInstances.value
  : allInstances.value.filter((instance) => filterEventPWSInstances(currentSeverityCodename.value, currentStateCodename.value, instance))));

function getInstanceCount(subType: string) {
  return filteredInstances.value.filter((instance) => instance.subType === subType).length;
}

function getSubTypeOptions() {
  const options: { name: string, instanceCount: number }[] = [];
  subTypeValues.value.forEach((subType) => options.push({
    name: subType,
    instanceCount: getInstanceCount(subType),
  }));
  return filterOptionsWithCount(options, 'datasets');
}

const subTypeOptions = computed(() => getSubTypeOptions());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseFilterDropdown, {
      currentOption: __props.currentState,
      allOptions: stateOptions.value,
      onValueChanged: _cache[0] || (_cache[0] = (option) => emit('filterChange', {filter: 'state' , value: option}))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      currentOption: __props.currentSubType,
      allOptions: subTypeOptions.value,
      onValueChanged: _cache[1] || (_cache[1] = (option) => emit('filterChange', {filter: 'subType' , value: option}))
    }, null, 8, ["currentOption", "allOptions"]),
    _createVNode(BaseFilterDropdown, {
      currentOption: __props.currentSeverity,
      allOptions: severityOptions.value,
      onValueChanged: _cache[2] || (_cache[2] = (option) => emit('filterChange', {filter: 'severity' , value: option}))
    }, null, 8, ["currentOption", "allOptions"])
  ]))
}
}

})