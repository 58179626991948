import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-read-only-parameter-container" }

import { Parameter } from '../types/Parameter';
import BaseParameter from './BaseParameter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReadOnlyParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, { parameter: __props.parameter }, null, 8, ["parameter"])
  ]))
}
}

})