import apiClient from '@/_shared/services/apiClient';
import nourishAuthStore from '@/_shared/store/userAuth';
import { storeToRefs } from 'pinia';

interface IMedicalRecordsResponse {
  error?: string | null;
}

export const fetchRecord = async <TRequest extends Record<string, unknown>, TResponse>(url: string, request: TRequest): Promise<TResponse | IMedicalRecordsResponse> => {
  try {
    return await apiClient.post<TResponse>(url, request);
  } catch (error) {
    return { error: `${error.response.data.error}` };
  }
};

export const fetchTosFile = async (integrationCodename: string): Promise<void> => {
  // TODO use apiClient and built-in headers handling
  const url = `api/v2/integrations/integration_tos_file?integration_codename=${integrationCodename}`;
  try {
    const { token, ouId } = storeToRefs(nourishAuthStore());
    const headers = new Headers();
    if (token.value && ouId.value) {
      headers.append('x-api-key', token.value);
      headers.append('Organisation-Unit-Id', ouId.value.toString());
    }
    const value: null | string = window.localStorage.getItem('deviceUUID');
    if (value) {
      headers.append('X-Device-Uuid', value);
    }

    fetch(url, {
      method: 'GET',
      headers,
    })

      .then((response) => response.blob())
      .then((data) => {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  } catch (error) {
    console.log(error);
  }
};
