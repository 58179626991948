import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-interaction-actions-container"
}
const _hoisted_2 = { class: "v-interaction-action-list-wrapper" }
const _hoisted_3 = { class: "v-action-describe sp-action-describe" }
const _hoisted_4 = { class: "checkbox-label ng-binding" }

import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { CoercedProtocolActions } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProtocolActions',
  props: {
  isEditing: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const currentInteractionStore = useCurrentInteractionStore();
const { currentInteraction } = storeToRefs(currentInteractionStore);

const actions = computed(() => {
  const computedParameters = currentInteraction.value?.computedParameters;
  const protocolActions: CoercedProtocolActions[] = [];
  if (computedParameters) {
    computedParameters.forEach((computedParameter) => {
      if (computedParameter.id === 'actions' && computedParameter.coercedValue) {
        // eslint-disable-next-line
        let localActions = computedParameter.coercedValue as CoercedProtocolActions[];
        localActions.forEach((localAction) => {
          if (localAction.checked) {
            protocolActions.push(localAction);
          }
        });
      }
    });
  }
  return protocolActions.filter((action1, index, array) => index === array.findIndex((action2) => action1.text === action2.text));
});

const actionsVisible = computed(() => actions.value.length !== 0 && !props.isEditing);


return (_ctx: any,_cache: any) => {
  return (actionsVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"v-interaction-actions-label-wrap\" data-v-4ff2bada><div class=\"v-interaction-actions-icon-wrap\" data-v-4ff2bada><div class=\"v-interaction-actions-icon sp-actions-icon\" data-v-4ff2bada><i class=\"nsx-font-icon--actions-history\" data-v-4ff2bada></i></div></div><span class=\"v-interaction-actions-label\" data-v-4ff2bada>Actions</span></div>", 1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(actions.value, (action) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "v-interaction-action-for",
              key: action.text
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "action-checkbox sp-action-checkbox" }, [
                _createElementVNode("i", { class: "nsx-font-icon--checkbox-checked v-interaction-actions-checkbox" })
              ], -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(action.text), 1)
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})