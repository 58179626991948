import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-submit-buttons-wrapper" }
const _hoisted_2 = { class: "v-save-button-wrapper" }
const _hoisted_3 = { class: "v-save-button-text" }
const _hoisted_4 = {
  key: 0,
  class: "v-back-button-wrapper"
}

import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'SubmitButtons',
  props: {
  showBackButton: {
    type: Boolean,
  },
},
  emits: ['backClicked', 'saveClicked'],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const { translate } = use.helpers();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "v-save-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('saveClicked')))
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('common.save')), 1)
      ])
    ]),
    (__props.showBackButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "v-back-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('backClicked')))
          }, _toDisplayString(_unref(translate)('common.back')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})