import IInteraction from '@/timeline/types/IInteraction';
import { computed, Ref } from 'vue';

const useInteractionListEntryCorner = (interaction: Ref<IInteraction>) => {
  const isAdhoc = computed(() => !interaction.value.plannedFinishAt && !interaction.value.plannedStartAt);
  const isQuickClose = computed(() => !!interaction.value.nfcTagId && interaction.value.state === 'planned');
  const showCorner = computed(() => isAdhoc.value || isQuickClose.value);
  const cornerIcon = computed(() => {
    if (isAdhoc.value) return 'plus';
    if (isQuickClose.value) return 'timer';
    return '';
  });

  return {
    showCorner,
    cornerIcon,
  };
};

export default useInteractionListEntryCorner;
