import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import ServiceLogger from '@/_shared/components/feature/ServiceLog.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionLog',
  props: {
  title: {
    type: String,
    required: true,
  },
  iconUrl: {
    type: String,
    required: true,
  },
  serviceId: {
    type: Number,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    title: __props.title,
    footer: false,
    iconUrl: __props.iconUrl,
    width: 1100,
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeLog')))
  }, {
    content: _withCtx(() => [
      _createVNode(ServiceLogger, {
        "client-id": __props.clientId,
        "service-id": __props.serviceId
      }, null, 8, ["client-id", "service-id"])
    ]),
    _: 1
  }, 8, ["title", "iconUrl"]))
}
}

})