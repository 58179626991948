import { adminRoutes, allowedAdminRoutes } from '@/_shared/router/routesAdmin';
import { carerRoutes, allowedCarerRoutes } from '@/_shared/router/routesCarer';
import { clientRoutes, allowedClientRoutes } from '@/_shared/router/routesClient';
import { otherRoutes, allowedOtherRoutes } from '@/_shared/router/routesOther';
import { familyPortalRoutes, allowedFamilyPortalRoutes } from '@/_shared/router/routesFamilyPortal';
import BaseEmptyView from '@/_shared/components/routing/BaseEmptyView.vue';
import { RouteAuthContext } from '@/_shared/router/types';
import { serviceCountRoutes, allowedServiceCountRoutes } from '@/_shared/router/routesServiceCount';
import { handoverRoutes, allowedHandoverRoutes } from '@/_shared/router/routesHandover';

const allRoutes = [
  ...clientRoutes,
  ...carerRoutes,
  ...otherRoutes,
  ...adminRoutes,
  ...serviceCountRoutes,
  ...familyPortalRoutes,
  ...handoverRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: BaseEmptyView,
    meta: { skipAuth: true },
  },
];

export default allRoutes;

export const allAllowedRoutes = (routeAuthContext: RouteAuthContext) => {
  const result: (string | symbol | null | undefined)[] = [];
  // use same route modules as above (const allRoutes) !!!
  [
    allowedClientRoutes,
    allowedCarerRoutes,
    allowedOtherRoutes,
    allowedAdminRoutes,
    allowedServiceCountRoutes,
    allowedHandoverRoutes,
  ].forEach((allowedRoutes) => {
    result.push(...allowedRoutes(routeAuthContext));
  });
  result.push(...allowedFamilyPortalRoutes());
  return result;
};
