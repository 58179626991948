import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-modal-content-wrapper sp-picture-modal" }
const _hoisted_2 = { class: "v-icon" }
const _hoisted_3 = { class: "v-options-text" }
const _hoisted_4 = { class: "v-icon" }
const _hoisted_5 = ["accept"]
const _hoisted_6 = { class: "v-options-text sp-select-from-files" }

import { ref, onBeforeMount } from 'vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import use from '@/_shared/compositionApi';
import addPreferredTermsToText from '@/_shared/services/clientHelpers';
import { allowedPictureExtentions } from '@/timeline/helper/parametersHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'PictureModal',
  props: {
  title: {
    type: String,
    required: true,
  },
  iconUrl: {
    type: String,
    required: true,
  },
  clientId: {
    type: [Number, String],
    required: false,
  },
  fileInputKey: {
    type: Number,
  },
},
  emits: ['closePictureModal', 'openCameraModal', 'onChange', 'handleNoCamera'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const openCameraAndClosePicture = () => {
  emit('openCameraModal');
  emit('closePictureModal');
};

const onChange = (event: Event) => {
  emit('onChange', event);
  emit('closePictureModal');
};

const formatTitle = () => {
  const clientId: number = (typeof props.clientId === 'string') ? parseInt(props.clientId, 10) : props.clientId as number;
  const title = addPreferredTermsToText(clientId, props.title);
  return title.value as string;
};

onBeforeMount(async () => {
  if (window.hasCamera === null) {
    await cameraExists();
  } else {
    hasCamera.value = window.hasCamera;
    if (!hasCamera.value) emit('handleNoCamera');
  }
});

const hasCamera = ref<boolean>(false);

async function cameraExists() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    hasCamera.value = stream.active;
    stream.getTracks().forEach((track) => track.stop());
  } catch (e) {
    console.log(e, 'no camera found');
    hasCamera.value = false;
  } finally {
    if (!hasCamera.value) emit('handleNoCamera');
    window.hasCamera = hasCamera.value;
  }
}

const fileInput = ref<HTMLInputElement>();

return (_ctx: any,_cache: any) => {
  return (hasCamera.value)
    ? (_openBlock(), _createBlock(BaseModal, {
        key: 0,
        title: formatTitle(),
        footer: false,
        iconUrl: __props.iconUrl,
        onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (emit('closePictureModal')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openCameraAndClosePicture())),
              class: "v-clickable"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(BaseIcon, {
                  name: "nsx-font-icon--camera",
                  width: "42px",
                  height: "42px",
                  "no-hover-background": "",
                  noHover: ""
                })
              ]),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(translate)('timeline.parameters.picture.takePicture')), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (fileInput.value?.click())),
              class: "v-clickable"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(BaseIcon, {
                  name: "nsx-font-icon--folder",
                  width: "42px",
                  height: "42px",
                  "no-hover-background": "",
                  noHover: ""
                })
              ]),
              _createElementVNode("label", null, [
                _createElementVNode("input", {
                  type: "file",
                  ref_key: "fileInput",
                  ref: fileInput,
                  style: {"display":"none"},
                  accept: _unref(allowedPictureExtentions),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (onChange($event as unknown as Event)))
                }, null, 40, _hoisted_5)
              ]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(translate)('timeline.parameters.picture.selectFromFiles')), 1)
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "iconUrl"]))
    : _createCommentVNode("", true)
}
}

})