import { computed, Ref } from 'vue';
import { formatDisplayTime, isSnoozed, overdueTimeToWords } from '@/timeline/helper/interactionsHelper';
import { format } from 'date-fns';
import { Alarm } from '@/timeline/types/Alarm';
import IInteraction from '@/timeline/types/IInteraction';

const useInteractionListEntryTime = (interaction: Ref<IInteraction>) => {
  const overdueTime = computed(() => {
    if ((getDueAlarm.value || isSnoozed(interaction.value)) && interaction.value.finishAt) {
      const timeToCalculateOverdue = interaction.value.finishAt
        || interaction.value.plannedFinishAt
        || new Date().toDateString();
      const diffTime = Date.now() - new Date(timeToCalculateOverdue).getTime();
      if (diffTime > 0) return overdueTimeToWords(diffTime);
    }
    return false;
  });

  const finishAt = computed(() => formatDisplayTime(interaction.value.finishAt));

  const startAt = computed(() => formatDisplayTime(interaction.value.startAt));

  const dueInDate = computed(() => {
    if (interaction.value.finishAt) {
      const dateFormat = 'd MMM yy';
      return format(new Date(interaction.value.finishAt), dateFormat);
    }
    return false;
  });

  const timeFirstRow = computed(() => {
    if (overdueTime.value) {
      return overdueTime.value;
    }
    if (interaction.value.needsStartAt) {
      return `${dueInDate.value} ${startAt.value}`;
    }
    return dueInDate.value;
  });

  // TODO: Check this is right?
  const timeSecondRow = computed(() => {
    if (overdueTime.value) {
      return false;
    }
    return finishAt.value;
  });

  const timeClass = computed(() => {
    if (getDueAlarm.value) {
      return getDueAlarm.value.state;
    }
    return '';
  });

  const getDueAlarm = computed(() => interaction.value.alarms?.find((alarm: Alarm) => alarm.alarmType === 'due' && !alarm.closedAt));

  return {
    overdueTime,
    startAt,
    finishAt,
    dueInDate,
    timeFirstRow,
    timeSecondRow,
    timeClass,
  };
};

export default useInteractionListEntryTime;
