import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-bm-side-swap-btn-container" }

import { computed } from 'vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapLocationButton',
  props: {
  isSelected: {
    type: Boolean,
    required: true,
  },
  location: {
    type: String,
    required: true,
  },
  displayCircle: {
    type: Boolean,
    required: false,
    default: false,
  },
  displayCount: {
    type: Boolean,
    required: false,
    default: false,
  },
  instanceCount: {
    type: Number,
    default: 0,
  },
},
  emits: ['click'],
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const buttonTextStyle = computed(() => ({
  borderBottom: props.isSelected ? '2px solid #FFFFFF' : 'none',
  fontWeight: props.isSelected ? '700' : '400',
}));

const buttonCircleStyle = computed(() => {
  const frontCircleStyle = {
    backgroundColor: '#66CCCC',
  };
  const backCircleStyle = {
    backgroundColor: '#172B34',
    border: '1px solid #FFFFFF',
  };
  if (props.location === 'all') return {};
  return props.location === 'front' ? frontCircleStyle : backCircleStyle;
});

const getLocationText = () => {
  const locationText = translate(`body_map.${props.location}`);
  const countText = ` - ${props.instanceCount}`;
  return props.displayCount ? locationText + countText : locationText;
};

const getButtonStyle = () => `v-bm-side-swap-btn show-${props.location} ${props.isSelected ? 'v-swap-btn-selected' : ''}`;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(getButtonStyle()),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (__props.displayCircle)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "v-side-button-circle",
            style: _normalizeStyle(buttonCircleStyle.value)
          }, null, 4))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        style: _normalizeStyle(buttonTextStyle.value)
      }, _toDisplayString(getLocationText()), 5)
    ])
  ], 2))
}
}

})