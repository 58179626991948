import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-inner-container" }
const _hoisted_2 = { class: "v-header-container" }

import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import use from '@/_shared/compositionApi';
import { formatFriendlyDayOfTheWeek } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceCountsRightPanel',
  setup(__props) {

const { translate } = use.helpers();
const route = useRoute();
const formattedDate = ref('');

const setDate = async () => {
  const queryDate = route.query.date !== undefined ? new Date(route.query.date as string) : new Date();
  formattedDate.value = `${formatFriendlyDayOfTheWeek(queryDate)}, ${queryDate.getFullYear()}`;
};

watch(() => route.query.date, () => {
  setDate();
}, { immediate: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, {
    class: "hidden-on-mobile",
    withBGImg: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_unref(translate)('navigation.other.service_counts')), 1),
          _createElementVNode("p", null, _toDisplayString(formattedDate.value), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})