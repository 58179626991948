
import {
  defineComponent, PropType, ref, computed,
} from 'vue';
import { uuid } from 'vue-uuid';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import use from '@/_shared/compositionApi';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseTimePicker from '@/_shared/components/inputs/BaseTimePicker.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseToggle from '@/_shared/components/inputs/BaseToggle.vue';
import { DaySchedule, TimeInterval } from '../types/availability';

export default defineComponent({
  components: {
    BaseIcon, BaseModal, BaseTimePicker, BaseInput, BaseToggle,
  },
  props: {
    day: {
      type: Object as PropType<DaySchedule>,
      required: true,
    },
  },
  emits: ['saveDay', 'closeAvailabilityModal'],
  setup(props, context) {
    const { translate } = use.helpers();
    const localDay = JSON.parse(JSON.stringify(props.day));
    const available = ref(localDay.available);
    const flexible = ref(localDay.flexible);
    const note = ref(localDay.note);
    const timeIntervals = ref(localDay.timeIntervals);

    const timeIntervalsWithHash = computed(
      () => timeIntervals.value.map((interval: TimeInterval) => ({ ...interval, hash: uuid.v4() })),
    );

    const addNewTimeInterval = () => {
      timeIntervals.value.push({ start: '', finish: '' });
    };

    const toggleAvailable = () => {
      flexible.value = false;
      available.value = !available.value;
      if (available.value && !timeIntervals.value.length) {
        addNewTimeInterval();
      }
    };

    const toggleFlexible = () => {
      available.value = false;
      flexible.value = !flexible.value;
    };

    const removeTimeInterval = (index: number) => {
      if (timeIntervals.value.length === 1) { return; }
      timeIntervals.value.splice(index, 1);
    };

    // TODO Refactor, deep object comparison
    const changes = () => props.day.available !== available.value
        || props.day.flexible !== flexible.value
        || (props.day.timeIntervals.length
          && props.day.timeIntervals.every((v, i) => v === timeIntervals.value[i]))
        || props.day.note !== note.value;

    const closeModal = () => {
      if (changes()) {
        const answer = window.confirm(translate('common.unsaved_changes'));
        if (!answer) { return; }
      }
      context.emit('closeAvailabilityModal');
    };

    const valid = computed(() => {
      if (!available.value) { return true; }
      return timeIntervals.value.filter((value: TimeInterval) => (value.start.match(/\d+:\d+/) && value.finish.match(/\d+:\d+/))).length !== 0;
    });

    const saveDay = () => {
      const editedDay: DaySchedule = {
        available: available.value,
        flexible: flexible.value,
        note: note.value,
        timeIntervals: available.value ? timeIntervals.value : [],
      };
      context.emit('saveDay', editedDay);
    };

    return {
      translate,
      available,
      flexible,
      note,
      timeIntervals,
      timeIntervalsWithHash,
      toggleFlexible,
      toggleAvailable,
      addNewTimeInterval,
      removeTimeInterval,
      valid,
      saveDay,
      closeModal,
    };
  },
});
