import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-alert-banner-wrapper" }
const _hoisted_2 = { class: "v-alert-icon-and-message" }
const _hoisted_3 = { class: "v-alert-banner-icon" }
const _hoisted_4 = { class: "v-alert-banner-title" }
const _hoisted_5 = { class: "v-alert-banner-message" }
const _hoisted_6 = { class: "v-errors-list" }

import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';
import addPreferredTermsToText from '../../_shared/services/clientHelpers';
import InteractionSection from './InteractionSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AlertBanner',
  emits: ['closeBanner'],
  setup(__props, { emit: __emit }) {

const { currentInteraction } = storeToRefs(useCurrentInteractionStore());
const interactionErrors = currentInteraction.value?.errors;

const emit = __emit;

const closeBanner = (keyType: string) => {
  if (!currentInteraction.value?.errors) return;
  delete (currentInteraction.value.errors as { [key: string]: unknown })[keyType];
  emit('closeBanner');
};

const parseClientName = (error: string) => {
  const clientId = currentInteraction.value?.clientId;
  return clientId ? addPreferredTermsToText(+clientId, error).value : error;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(interactionErrors), (errorType, key) => {
    return (_openBlock(), _createBlock(InteractionSection, {
      class: "alert-banner-section",
      key: key
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(BaseIcon, {
                class: "no-hover",
                name: "bell",
                noHoverBackground: "",
                "foreground-color": "white"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "bannerTitle")
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errorType, (err, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(parseClientName(err)), 1))
                }), 128))
              ])
            ])
          ]),
          _createVNode(BaseIcon, {
            class: "v-alert-banner-close-btn",
            noHoverBackground: "",
            name: "times",
            "foreground-color": "white",
            onClick: ($event: any) => (closeBanner(key))
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 2
    }, 1024))
  }), 128))
}
}

})