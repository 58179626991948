import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import useUserStore from '@/_shared/store/user';
import { computed, onBeforeMount } from 'vue';
import use from '@/_shared/compositionApi';
import { BaseCircleButton } from '@/_shared/components';
import { vBaseTooltip } from '@/_shared/directives';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { storeToRefs } from 'pinia';
import { isMobileView, resizeHandler } from '@/_shared/helpers/mobileViewHelper';
import useEventListener from '@/_shared/Composables/event';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferClientsPlusButton',
  setup(__props) {

const { translate } = use.helpers();
const { userHasPermission } = useUserStore();
const { currentUser } = storeToRefs(useUserStore());
const router = useRouter();

const clientTerm = window?.currentOrganisationUnit?.client_term || 'Resident';

const showAddBtn = computed(() => userHasPermission('allowPeopleTransfer') && currentUser.value.organisationUnits.length > 1);
const isNotTransferRoute = computed(() => router.currentRoute.value.name !== 'client.profile.transfer');

const buttonWrapperPosition = computed(() => (
  isMobileView.value ? 'relative' : 'absolute'
));
const tooltipPosition = computed(() => (isMobileView.value ? 'top' : 'bottom'));

useEventListener(window, 'resize', resizeHandler);
onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('client_profile');
});
function showTransferForm() {
  router.push({ name: 'client.profile.transfer' });
}


return (_ctx: any,_cache: any) => {
  return (showAddBtn.value)
    ? _withDirectives((_openBlock(), _createBlock(_unref(BaseCircleButton), {
        key: 0,
        class: "sp-add-button",
        top: "19px",
        right: "71px",
        left: "auto",
        position: buttonWrapperPosition.value,
        onClick: showTransferForm
      }, null, 8, ["position"])), [
        [_vShow, isNotTransferRoute.value],
        [_unref(vBaseTooltip), { tooltipText: _unref(translate)('client_profile.transfer_clients', { client_term: _unref(clientTerm), }),
                      position: tooltipPosition.value }]
      ])
    : _createCommentVNode("", true)
}
}

})