export type folderType = 'inbox' | 'sent' | 'archived_inbox' | 'archived_sent'

export interface MessageDTO {
  id?: number;

  senderId: number;

  extraReceivers: number;

  receiverIds?: Array<number>;

  recipientIds: Array<number|string>; // used by POST (create) API

  subject: string;

  body: string;

  archived?: boolean;

  isRead?: boolean;

  isSent?: boolean;

  isReadBy?: number[];

  fromMe?: boolean;

  createdAt: string;
}

export const createNewMessage = (senderId: number) => <MessageDTO>{ senderId };
