import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-event-pws-parameter-container" }
const _hoisted_2 = { class: "v-event-pws-parameter-wrapper" }
const _hoisted_3 = { class: "v-datasets-selector-outer-wrapper" }

import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import use from '@/_shared/compositionApi';
import {
  computed,
  PropType, ref, Ref, watch,
} from 'vue';
import {
  CoercedEventPWSInstance, CoercedSingleSelect, Parameter,
} from '@/timeline/types/Parameter';
import DateTimeParameter from '@/timeline/interaction_parameters/DateTimeParameter.vue';
import SingleSelectDataSetParameter from '@/timeline/interaction_parameters/SingleSelectDataSetParameter.vue';
import useDatasetStore from '@/_shared/store/datasets';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import {
  DATA_SET_EVENT_SEVERITY,
  otherInstanceCodenames,
} from '@/_shared/store/composables/datasetsInstances';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventPWSParameter',
  props: {
  parameter: {
    type: Object as PropType<Parameter>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const { getSpecificTypeIndex, nourishInstanceTypeDataSets } = useDatasetStore();
const { currentInteraction } = storeToRefs(useCurrentInteractionStore());
const { setCoercedValueByParameterId } = useCurrentInteractionStore();
const parameterCoercedValue = props?.parameter?.coercedValue as CoercedEventPWSInstance;

const eventDateTimeParameter = ref(createDateTimeParam(props.parameter.id, 'event_date_time', parameterCoercedValue?.metadata?.eventDateTime));
const eventParameter: Ref<Parameter> = ref(setUpSubParameter(props.parameter.id, 'event', parameterCoercedValue?.metadata?.specificType));
const severityParameter: Ref<Parameter> = ref(setUpSubParameter(props.parameter.id, 'severity', parameterCoercedValue?.metadata?.severity));

function createDateTimeParam(paramId: string | number, parameterType: string, value: string) {
  const param = {} as Parameter;
  param.name = translate(`events.${parameterType}`);
  param.codename = `${paramId}_${parameterType.toLowerCase()}`;
  param.required = false;
  param.config = {
    allowedNumberOfDaysInThePast: props.parameter.config?.allowedNumberOfDaysInThePast,
    allowedNumberOfDaysIntoTheFuture: props.parameter.config?.allowedNumberOfDaysIntoTheFuture,
  };
  param.valueType = 'datetime';
  param.id = Number(`${paramId}.1`);
  param.coercedValue = value || '';
  currentInteraction.value.parameters.push(param);
  return param;
}

function setUpSubParameter(paramId: string | number, parameterType: string, value: string) {
  const param = {} as Parameter;
  const position = parameterType === 'event' ? 2 : 3;
  const eventDataSet = props.parameter.config?.nourishInstanceType ? nourishInstanceTypeDataSets(props.parameter.config?.nourishInstanceType) : '';
  param.name = translate(`events.${parameterType}`);
  param.codename = `${paramId}_${parameterType.toLowerCase()}`;
  param.required = true;
  param.config = {};
  param.valueType = 'single_selector_search';
  param.config.dataSet = parameterType === 'severity' ? DATA_SET_EVENT_SEVERITY : eventDataSet;
  param.id = Number(`${paramId}.${position}`);
  const coercedValue = { values: [] as string[] } as CoercedSingleSelect;
  if (value) {
    coercedValue.values.push(value);
  }
  param.coercedValue = coercedValue;
  currentInteraction.value.parameters.push(param);
  return param;
}

const eventTypesLookup = computed(() => getSpecificTypeIndex(props.parameter.config!.nourishInstanceType!).value || {});

const lookupSubType = (): string => {
  const subType = eventTypesLookup.value[(eventParameter.value.coercedValue as CoercedSingleSelect).values[0]];
  return subType || otherInstanceCodenames[props.parameter.config!.nourishInstanceType!];
};

watch(() => ({
  metadata: {
    eventDateTime: eventDateTimeParameter.value?.coercedValue as string,
    specificType: (eventParameter.value.coercedValue as CoercedSingleSelect)?.values[0],
    severity: (severityParameter.value.coercedValue as CoercedSingleSelect)?.values[0],
  },
}), (partialCoercedInstance) => {
  const newCoercedValue = {
    subType: lookupSubType(),
  } as CoercedEventPWSInstance;
  newCoercedValue.metadata = { eventDateTime: '', specificType: '', severity: '' };
  newCoercedValue.metadata.eventDateTime = partialCoercedInstance.metadata.eventDateTime ? partialCoercedInstance.metadata.eventDateTime : '';
  newCoercedValue.metadata.specificType = partialCoercedInstance.metadata.specificType ? partialCoercedInstance.metadata.specificType : '';
  newCoercedValue.metadata.severity = partialCoercedInstance.metadata.severity ? partialCoercedInstance.metadata.severity : '';
  setCoercedValueByParameterId(props.parameter.id as number, newCoercedValue);
  props.parameter.edited = true;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: ""
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(DateTimeParameter, {
            parameter: eventDateTimeParameter.value,
            "read-only": __props.readOnly
          }, null, 8, ["parameter", "read-only"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(SingleSelectDataSetParameter, {
              id: `v-event-selector${props.parameter?.id}`,
              key: eventParameter.value.codename,
              parameter: eventParameter.value,
              "read-only": __props.readOnly
            }, null, 8, ["id", "parameter", "read-only"])),
            (_openBlock(), _createBlock(SingleSelectDataSetParameter, {
              id: `v-severity-selector${props.parameter?.id}`,
              key: severityParameter.value.codename,
              parameter: severityParameter.value,
              "read-only": __props.readOnly
            }, null, 8, ["id", "parameter", "read-only"]))
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})