import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-advanced-edit-client-type-container" }
const _hoisted_2 = { class: "v-advanced-edit-client-type--edit-name" }
const _hoisted_3 = { class: "v-padding-20" }
const _hoisted_4 = { class: "v-advanced-edit-client-type--update-v-input-box" }
const _hoisted_5 = { class: "v-warning-text" }
const _hoisted_6 = { class: "v-advanced-edit-client-type--edit-libraries" }
const _hoisted_7 = { class: "v-padding-20" }
const _hoisted_8 = { class: "v-advanced-edit-client-type--edit-libraries-list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "v-advanced-edit-client-type--library-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate("client_types.header")) + " " + _toDisplayString(_ctx.translate("common.name")), 1),
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseInput, {
          border: true,
          modelValue: _ctx.clientTypeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.clientTypeName) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('validateClientTypeChanges', _ctx.clientTypeName))),
          disabled: _ctx.$props.clientType.nourishOwned
        }, null, 8, ["modelValue", "disabled"]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.warningMessage), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translate('client_types.library_associated')) + "*", 1),
      _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.libraries, (library) => {
          return (_openBlock(), _createElementBlock("div", {
            key: library.codename
          }, [
            _createElementVNode("div", {
              onClick: ($event: any) => (_ctx.selectLibrary(library)),
              class: "v-advanced-edit-client-type--library-entry"
            }, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(library.name), 1),
              _createVNode(_component_BaseCheckbox, {
                checked: library.codename === _ctx.selectedLibraryCodename,
                radio: true
              }, null, 8, ["checked"])
            ], 8, _hoisted_9),
            _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1))
          ]))
        }), 128))
      ])
    ])
  ]))
}