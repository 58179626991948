import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-target-wrapper"
}
const _hoisted_2 = { class: "v-calculation-block" }
const _hoisted_3 = { class: "v-calculation-block-inner" }
const _hoisted_4 = { class: "v-calculation-name" }
const _hoisted_5 = { class: "v-calculation-value" }
const _hoisted_6 = {
  key: 0,
  class: "v-calculation-unit"
}
const _hoisted_7 = {
  key: 0,
  class: "v-calculation-block"
}
const _hoisted_8 = { class: "v-calculation-block-inner" }
const _hoisted_9 = { class: "v-calculation-name" }
const _hoisted_10 = { class: "v-calculation-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showSection)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calculations, (calculation) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "v-calculations-wrapper",
            key: calculation.name
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.addPreferredTermsToText(_ctx.clientId, calculation.name || '').value), 1),
                  _createElementVNode("p", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getFormattedValue(calculation)), 1),
                    (calculation.target)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, " / " + _toDisplayString(calculation.target) + _toDisplayString(calculation.unit), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              (calculation.value !== undefined && calculation.target)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.translate('timeline.targets.progress')), 1),
                      _createElementVNode("p", _hoisted_10, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getPercentages(calculation.value, calculation.target)), 1),
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "v-calculation-unit" }, " %", -1))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}