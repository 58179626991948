import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dry-run-logs" }

import {
  runInteractionActions,
} from '@/timeline/services/interactionsApi';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { onBeforeMount, ref } from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DryRunActions',
  props: {
  showModal: {
    type: Boolean,
    required: true,
  },
  interactionId: {
    type: Number,
    required: true,
  },
  serviceCodename: {
    type: String,
    required: true,
  },
},
  emits: ['closeDryRunActions', 'runActions'],
  setup(__props) {

const props = __props;



const dryRunLogs = ref();

onBeforeMount(async () => {
  const logs = await runInteractionActions(props.interactionId as number, false);
  if (logs) {
    dryRunLogs.value = formatLogs(logs as string);
  }
});

const formatLogs = (logs: string) => logs.split('\n');


return (_ctx: any,_cache: any) => {
  return (__props.showModal)
    ? (_openBlock(), _createBlock(BaseModal, {
        key: 0,
        title: `Service codename: ${__props.serviceCodename}`,
        footer: false,
        onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeDryRunActions')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dryRunLogs.value, (log) => {
              return (_openBlock(), _createElementBlock("div", {
                key: log,
                class: "action-log"
              }, _toDisplayString(log), 1))
            }), 128))
          ]),
          _createVNode(BaseButton, {
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('runActions')))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Really Run Actions")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})