import BaseTableHeader from '@/_shared/types/baseTableHeader';
import {
  UpdateCarePlanTableData,
  UpdateCarePlanTableRow,
} from '@/careplan/types/UpdateCarePlanTableData';
import { CarePlanNeedVersions } from '@/_shared/types/carePlan';
import use from '@/_shared/compositionApi';

const { translate } = use.helpers();

const updateCarePlanTableData = (entries: UpdateCarePlanTableRow[]): UpdateCarePlanTableData => {
  const headers = buildHeaders();

  return { headers, entries };
};

const buildHeaders = () => {
  const headers: BaseTableHeader[] = [
    {
      keyName: 'folder',
      displayName: translate('careplan.folder'),
      type: 'string',
    },
    {
      keyName: 'page',
      displayName: translate('careplan.page'),
      type: 'string',
    },
    {
      keyName: 'need',
      displayName: translate('careplan.need'),
      type: 'string',
    },
    {
      keyName: 'updatedOn',
      displayName: translate('careplan.updated_on'),
      type: 'string',
    },
    {
      keyName: 'updatedBy',
      displayName: translate('careplan.updated_by'),
      type: 'string',
    },
    {
      keyName: 'updateReason',
      displayName: translate('careplan.update_reason'),
      type: 'string',
    },
    {
      keyName: 'reviewNote',
      displayName: translate('careplan.review_note'),
      type: 'string',
    },
  ];
  return headers;
};
export const updateCarePlanTableDataEntries = (updateCarePlanEntries: CarePlanNeedVersions) => {
  if (updateCarePlanEntries) {
    const { changesSinceLastInteraction } = updateCarePlanEntries;
    const { changesIncludedInInteraction } = updateCarePlanEntries;

    if (changesIncludedInInteraction.length === 0) {
      return changesSinceLastInteraction;
    }
    const separator = { split_table: 'split_table' };
    return [...changesSinceLastInteraction, separator, ...changesIncludedInInteraction] as UpdateCarePlanTableRow[];
  }
  return [];
};

export default updateCarePlanTableData;
