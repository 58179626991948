import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-context-container"
}
const _hoisted_2 = { class: "v-context-label" }
const _hoisted_3 = { class: "v-context-content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  style: {"cursor":"pointer"}
}
const _hoisted_7 = {
  key: 1,
  style: {"cursor":"pointer"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.shouldShow())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate('timeline.context.context')) + ": ", 1),
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.showingMore)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                "resize-textarea": "",
                class: "v-context-textarea v-context-collapsed",
                disabled: "",
                innerHTML: _ctx.getContextLines(2)
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.showingMore)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                "resize-textarea": "",
                class: "v-context-textarea v-context-expanded",
                disabled: "",
                innerHTML: _ctx.getContextLines()
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasMore())
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "v-context-show-more",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleShowMore()))
            }, [
              (!_ctx.showingMore)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.translate('timeline.context.showMore')) + "...", 1))
                : _createCommentVNode("", true),
              (_ctx.showingMore)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.translate('timeline.context.showLess')) + "...", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}