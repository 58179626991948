import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "v-body-map-wrapper" }
const _hoisted_2 = { class: "v-body-map-images" }
const _hoisted_3 = { class: "v-body-map-text" }
const _hoisted_4 = {
  key: 0,
  class: "v-body-map-table-wrapper"
}
const _hoisted_5 = { key: 0 }

import { safeTranslate } from '@/_shared/translations/i18n';
import { computed } from 'vue';
import { clientStore } from '@/_shared/store/clients';
import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { useRouter } from 'vue-router';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import use from '@/_shared/compositionApi';
import { InstanceLocations } from '@/_shared/types/NourishInstance';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanBodyMap',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  bodyMapEvent: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const router = useRouter();

const routeToTimeline = (instanceId: string) => {
  router.push({
    name: 'client.timeline.body_map.instance',
    params: { clientId: props.clientId, instanceId },
  });
};

const props = __props;

const skinInstances = computed(() => clientStore.skinInstances(props.clientId!).value);

const showArchived = computed(() => !!props.bodyMapEvent?.detail.showArchived || false);

const { instanceLocations, allInstances } = useBodyMapLocationsHelper(showArchived).forSkinInstances(skinInstances);

const headers = [
  { keyName: 'count', displayName: '' },
  { keyName: 'location', displayName: `${translate('body_map.location')}*` },
  { keyName: 'referenceNumber', displayName: translate('body_map.reference_number') },
  { keyName: 'startOn', displayName: translate('body_map.start_on') },
  { keyName: 'wound', displayName: translate('body_map.wound') },
  { keyName: 'state', displayName: translate('common.status') },
];

const filteredSkinInstances = computed(() => {
  const filteredGroups = allInstances.value.filter((instanceGroup) => (
    showArchived.value ? true : instanceGroup.state !== 'Inactive'));
  return filteredGroups.flatMap((instanceGroup) => instanceGroup.instances)
    .sort((a, b) => (a.locationInfo?.count || 0) - (b.locationInfo?.count || 0));
});

const tableItems = computed(() => {
  if (filteredSkinInstances.value.length) {
    return filteredSkinInstances.value.map((skinInstance) => ({
      id: `skin_instance_${skinInstance.id}`,
      count: skinInstance.locationInfo?.count,
      location: safeTranslate(`datasets.${skinInstance.metadata.location}`),
      referenceNumber: skinInstance.reference,
      startOn: formatDate(skinInstance.stateTime),
      wound: safeTranslate(`datasets.${skinInstance.metadata.specificType}`),
      state: translate(`body_map.state.${skinInstance.state}`),
    }));
  }
  return [];
});

const formatDate = (date: Date) => new Date(date)
  .toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).replace(/\//g, '/');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#bodymap-for-vue" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['front', 'back'], (view) => {
          return _createElementVNode("div", {
            key: view,
            class: "v-body-map-image"
          }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(translate)(`body_map.${view}`)), 1),
            (skinInstances.value.length)
              ? (_openBlock(), _createBlock(BodyMapImage, {
                  key: 0,
                  "left-panel": false,
                  "instance-locations": _unref(instanceLocations),
                  "side-to-show": view,
                  "show-side-selector": false,
                  "white-background": true
                }, null, 8, ["instance-locations", "side-to-show"]))
              : (_openBlock(), _createBlock(BodyMapImage, {
                  key: 1,
                  "left-panel": false,
                  "instance-locations": {} as InstanceLocations,
                  "side-to-show": view,
                  "show-side-selector": false,
                  "white-background": true
                }, null, 8, ["side-to-show"]))
          ])
        }), 64))
      ]),
      (skinInstances.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(BaseTable, {
              class: "v-body-map-table",
              headers: headers,
              items: tableItems.value,
              editable: false,
              hasRowSlot: "",
              cellCentered: true
            }, {
              default: _withCtx((slotProps) => [
                ((slotProps.item as string).includes('skin_instance_'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(BaseIcon, {
                        name: "nsx-font-icon--body-map",
                        compact: true,
                        id: `instance_icon_${(slotProps.item as string).split('_')[2]}`,
                        class: "v-instance-icon",
                        "no-hover-background": true,
                        "no-hover": true,
                        onClick: ($event: any) => (routeToTimeline((slotProps.item as string).split('_')[2]))
                      }, null, 8, ["id", "onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["items"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})