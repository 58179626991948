
import { defineComponent, ref } from 'vue';
import use from '@/_shared/compositionApi';

export default defineComponent({
  props: {
    protocolContext: {
      type: String,
    },
  },
  setup(props) {
    const lineBreak = '<br>';
    const showingMore = ref<boolean>(false);
    const hasMore = () => props.protocolContext && props.protocolContext.split(lineBreak).filter(Boolean).length > 2;

    const toggleShowMore = () => {
      showingMore.value = !showingMore.value;
    };

    const shouldShow = () => props.protocolContext;

    const getContextLines = (count = 0) => {
      if (props.protocolContext) {
        const allLines = props.protocolContext.split(lineBreak);
        if (count === 0) {
          return allLines.join(lineBreak);
        }
        return allLines.slice(0, count).join(lineBreak);
      }
      return '';
    };

    const { translate } = use.helpers();

    return {
      hasMore,
      showingMore,
      toggleShowMore,
      shouldShow,
      getContextLines,
      translate,
    };
  },
});
