import apiClient from '@/_shared/services/apiClient';
import { CompositeOption } from '@/_shared/types/baseSelect';
import use from '@/_shared/compositionApi';
import { Carer } from '@/timeline/types/Carer';
import { Relation, RelationsApiResponse } from '@/_shared/store/Relation';
import { promiseMemorise } from '@/_shared/services/UseUtils';
import { Dataset } from '@/timeline/types/Dataset';
import { Config } from '../../timeline/types/Config';

const { translate } = use.helpers();

type DatasetApiResponse = Dataset | null

export type Datasets = Record<string, Dataset>

type DatasetsApiResponse = Datasets

interface CarerApiResponse {
  carers: Carer[]
}

export const fetchDataset = async (name: string): Promise<Dataset | null> => {
  const url = `/api/v2/data_sets/name?${name}`; // TODO use other endpoint for now
  try {
    return (await apiClient.get<DatasetApiResponse>(url));
  } catch (error) {
    // TODO handle this
    return null;
  }
};

export const fetchDatasetsByCodenames = async (codenames: string[]): Promise<Datasets | null> => {
  const url = '/api/v2/data_sets/'; // TODO use other endpoint for now
  try {
    return (await apiClient.post<DatasetsApiResponse>(url, { codenames }));
  } catch (error) {
    // TODO handle this
    return null;
  }
};

export const fetchAllDatasets = async (): Promise<Datasets> => {
  const url = '/api/v2/data_sets/'; // TODO use other endpoint for now
  try {
    return (await apiClient.get<DatasetsApiResponse>(url));
  } catch (error) {
    // TODO handle this
    return {};
  }
};

export const OriginalFetchRelations = async (clientId: number): Promise<CompositeOption[] | null> => {
  const url = `/api/v2/clients/${clientId}/relations`;
  try {
    const response = (await apiClient.get<RelationsApiResponse>(url));
    const options: CompositeOption[] = [];
    response.relations.forEach((relation: Relation) => {
      const option: CompositeOption = {
        value: `${relation.person.id}`,
        text: formatRelationName(relation),
        photo: relation.person.photo,
        state: relation.archived ? 'archived' : 'active',
      };
      options.push(option);
    });
    return options;
  } catch (error) {
    // TODO handle this
    return null;
  }
};
export const fetchRelations = promiseMemorise(OriginalFetchRelations);
export const OriginalFetchCarersForOrgUnit = async (orgUnitId = window.currentOrganisationUnit.id, config: Config = {}): Promise<CompositeOption[] | null> => {
  const url = `/api/v2/organisation_units/${orgUnitId}/carers`;
  try {
    const response = await apiClient.get<CarerApiResponse>(url, config);
    const options: CompositeOption[] = [];
    response.carers.forEach((carer: Carer) => {
      const option: CompositeOption = {
        value: `${carer.id}`,
        text: formatCarerName(carer),
        photo: carer.photo,
        state: config.params?.state,
      };
      options.push(option);
    });
    return options;
  } catch (error) {
    // TODO handle this
    console.log(`error: ${error}`);
    return null;
  }
};
// TODO using Memorize for now until we find better way to catch api call
export const fetchCarersForOrgUnit = promiseMemorise(OriginalFetchCarersForOrgUnit);

// TODO Move into separate api file

const originalFetchCarersQuery = async (config: Config = {}):Promise<CompositeOption[] | null> => {
  try {
    const state = config.query?.state as string || undefined;
    const response = await apiClient.post<CarerApiResponse, Config>('/api/v2/carers/query', config);

    const options: CompositeOption[] = [];
    response.carers.forEach((carer: Carer) => {
      const option: CompositeOption = {
        value: `${carer.id}`,
        text: formatCarerName(carer, state),
        photo: carer.photo,
        state,
      };
      options.push(option);
    });
    return options;
  } catch (error) {
    // TODO handle this
    console.log(`error: ${error}`);
    return null;
  }
};

export const fetchCarersQuery = promiseMemorise(originalFetchCarersQuery);
function formatRelationName(relation: Relation) {
  let formattedValue = relation.person.name;
  if (relation.relationship) {
    formattedValue = `${formattedValue} - ${relation.relationship}`;
  }
  if (relation.supportRoleLabel) {
    formattedValue = `${formattedValue} - ${relation.supportRoleLabel}`;
  }
  if (relation.archived) {
    formattedValue = `${formattedValue} (${translate('common.archived')})`;
  }
  return formattedValue;
}

function formatCarerName(carer: Carer, state: string | undefined = undefined) {
  let formattedValue = carer.name;
  if (carer.organisationRoleName) {
    formattedValue = `${formattedValue} - ${carer.organisationRoleName}`;
  }
  if (state) {
    formattedValue = `${formattedValue} (${translate(`common.${state}`)})`;
  }
  return formattedValue;
}
