import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Teleport as _Teleport, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "v-interactions-left-panel-wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "v-no-additional-results"
}
const _hoisted_4 = { class: "v-no-additional-results-header" }
const _hoisted_5 = { class: "v-no-additional-results-title" }
const _hoisted_6 = {
  key: 1,
  class: "v-additional-results"
}
const _hoisted_7 = { key: 0 }

import hideElementsWithClassesNames from '@/_shared/helpers/useAngularPartHider';
import {
  computed,
  ref,
  onBeforeMount,
  watch,
  VNode,
  SetupContext, type FunctionalComponent,
} from 'vue';
import {
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
  onBeforeRouteLeave,
} from 'vue-router';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import { formatISODate } from '@/_shared/helpers/useDateTimeHelper';
import LeftPanelActionBar from '@/_shared/components/general/left_panel/LeftPanelActionBar.vue';
import use from '@/_shared/compositionApi';
import LeftPanelAlarmsBar from '@/_shared/components/general/left_panel/LeftPanelAlarmsBar.vue';
import NoResultsMessage from '@/_shared/components/general/left_panel/NoResultsMessage.vue';
import useInteractionsStore from '@/_shared/store/interactions';
import {
  isBefore, isSameDay, parseISO,
} from 'date-fns';
import { NourishInstance } from '@/_shared/types/NourishInstance';
import { clientStore, Client } from '@/_shared/store/clients';
import DropdownOption from '@/_shared/components/buttons/types/DropdownOption';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import { redirectToTimeline } from '@/_shared/router/helpers/routesClientHelper';
import InstancesTimelineFComponent from '@/timeline/components/InstancesTimelineFComponent';
import useBodyMapRoutes from '@/timeline/helper/useBodyMapRoutes';
import { syncRef } from '@vueuse/core';
import TimelineInteractionListEntry
  from '@/timeline/components/InteractionListEntry/TimelineInteractionListEntry.vue';
import { instancesStore } from '@/_shared/store/Instances';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import IInteraction from '../types/IInteraction';
import InteractionsListEntry from './InteractionsListEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionsLeftPanel',
  emits: ['hideLeftPanelOnMobile'],
  setup(__props, { emit: __emit }) {

_useCssVars(_ctx => ({
  "d93c4c2e": (additionalResultsPadding.value)
}))

const { translate } = use.helpers();
const { featureToggles } = storeToRefs(useUserStore());
const interactionStore = useInteractionsStore();
const { interactions: currentInteractions } = storeToRefs(interactionStore);
const searchInteractions = ref<IInteraction[]>([]);
const loading = ref(true);
const organisationUnitId = window.currentOrganisationUnit.id;
const currentSearchTerm = ref('');
const routeParamsId = computed(() => {
  if (carerTimeline) { return +route.params.carerId; }
  if (serviceCountsTimeline) { return +route.params.serviceId; }
  return +route.params.clientId;
});

const filterOptions: DropdownOption[] = [
  { codename: 'all', label: translate('filter.all') },
  { codename: 'schedule', label: translate('filter.service_schedule') },
];
const clientsIds = computed(() => currentInteractions.value.map((interaction: IInteraction) => interaction.clientId));
const clients = ref<Client[]>();
const route = useRoute();
const {
  isWithInBodyMapNestedRoutes,
  isInstanceViewerNestedRoute,
  currentRouteInstanceViewerPath,
} = useBodyMapRoutes(route);

const showBodyMap = ref(isWithInBodyMapNestedRoutes.value);
syncRef(isWithInBodyMapNestedRoutes, showBodyMap, { direction: 'ltr' });

const showBodyMapFilter = ref(false);

const specificInstanceView = ref(isInstanceViewerNestedRoute.value);
syncRef(isInstanceViewerNestedRoute, specificInstanceView, { direction: 'ltr' });

const searchComponent = ref<InstanceType<typeof LeftPanelActionBar> | null>(null);

const router = useRouter();

const date = ref(route.query.date ? new Date(route.query.date as string) : new Date());

const familyPortal = (route.name as string).includes('portalClient');
const carerTimeline = (route.name as string).includes('carer');
const serviceCountsTimeline = (route.name as string).includes('serviceCountList');
const clientTimeline = !familyPortal && !carerTimeline && !serviceCountsTimeline;

let fetchInteractions = interactionStore.fetchForClientByDate;

if (carerTimeline) {
  fetchInteractions = interactionStore.fetchForCarerByDate;
}
if (serviceCountsTimeline) {
  fetchInteractions = interactionStore.fetchByServiceId;
}

const searchInteractionsByType = clientTimeline ? interactionStore.searchForClient : interactionStore.searchForCarer;

const onUpdatedDate = async (newDate: Date) => {
  if (new Date(newDate) === date.value) {
    return;
  }
  date.value = new Date(newDate);
  if (route.query.date) {
    delete route.query.date;
  }

  const name = familyPortal ? 'portalClient.timeline' : route.name as string;
  await router.push({
    name,
    query: {
      date: formatISODate(date.value),
      ...route.query,
    },
  });

  currentInteractions.value = [];
  getCurrentInteractions();
};

onBeforeMount(async () => {
  await getCurrentInteractions().then(() => {
    setTimeout(() => {
      scrollToPlannedInteractions();
    }, 300);
  });
  clients.value = serviceCountsTimeline ? clientStore.clients(clientsIds.value) : [];
});

// HACK this is needed until careplane review is implemented in Vue
// careplan interaction is always using Angular and we need to update interaction store
// when review is completed
watch(() => route.name, (newRoute, oldRoute) => {
  if ((oldRoute === 'client.timeline.needinteraction' && newRoute === 'client.timeline')
    || (oldRoute === 'carer.timeline.needinteraction' && newRoute === 'carer.timeline')
    || (oldRoute === 'serviceCountList.timeline.needinteraction' && newRoute === 'serviceCountList.timeline')) {
    getCurrentInteractions();
  }
});

const getCurrentInteractions = async (id = routeParamsId.value) => {
  if (!organisationUnitId || !id) {
    return;
  }
  loading.value = true;
  currentInteractions.value = [];
  const formattedDate = formatISODate(date.value);
  const mine = route.query?.mine === 'true' || undefined;
  const interactions = await fetchInteractions(organisationUnitId, id, formattedDate, mine);
  const clientIds = interactions.filter((interaction) => interaction.nourishInstanceId).map((interaction) => interaction.clientId);
  instancesStore.byIds(clientIds);
  loading.value = false;
};

const scrollToPlannedInteractions = () => {
  const plannedInteractions = document.getElementsByClassName('open-interactions');
  if (plannedInteractions.length) {
    plannedInteractions[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const searchAdditionalInteractions = async (searchTerm: string) => {
  currentSearchTerm.value = searchTerm;
  if (organisationUnitId && routeParamsId.value && searchTerm?.length > 0) {
    searchInteractions.value = await searchInteractionsByType(organisationUnitId, routeParamsId.value, searchTerm, new Date(date.value).toString());
  } else {
    clearSearch();
  }
};

const clearSearch = () => {
  currentSearchTerm.value = '';
  searchInteractions.value = [];
};

const filteredCurrentInteractions = computed(() => {
  const filteredByDate = currentInteractions.value.filter((interaction: IInteraction) => {
    const today = new Date(date.value);
    if (interaction.state === 'sticky' && interaction.startAt) {
      return isSameDay(parseISO(interaction.startAt), today) || isBefore(parseISO(interaction.startAt), today);
    }
    if (interaction.startAt) {
      return isSameDay(parseISO(interaction.startAt), today);
    }
    return !interaction.finishAt || isSameDay(parseISO(interaction.finishAt), today);
  });
  const filteredByTime = filteredByDate.sort((a: IInteraction, b: IInteraction) => {
    if (a.state === 'sticky' && b.state !== 'sticky') {
      return -1;
    }
    if (a.state !== 'sticky' && b.state === 'sticky') {
      return 1;
    }

    const orderByAddress = window.currentOrganisationUnit.is_ordered_by_address;
    let sortOrder = 0;
    if (orderByAddress) {
      const addressA = (clientStore.addressCaption(a.clientId).value || '').toLowerCase();
      const addressB = (clientStore.addressCaption(b.clientId).value || '').toLowerCase();
      sortOrder = addressA.localeCompare(addressB, 'en', { numeric: true });
    } else {
      const preferredNameA = clientStore.preferredOrFirstName(a.clientId).value;
      const preferredNameB = clientStore.preferredOrFirstName(b.clientId).value;
      sortOrder = preferredNameA < preferredNameB ? -1 : 1;
    }

    const aTimeToSort = a.startAt || a.finishAt;
    const bTimeToSort = b.startAt || b.finishAt;
    if (aTimeToSort && bTimeToSort) {
      return new Date(aTimeToSort).getTime() - new Date(bTimeToSort).getTime() || sortOrder;
    }
    return 0;
  });

  if (currentSearchTerm.value?.length > 0) {
    return filteredByTime.filter((interaction: IInteraction) => {
      const getInteractionName = interaction.name || interaction.displayName;
      const interactionName = getInteractionName.toLowerCase();
      if (serviceCountsTimeline) {
        const clientIds = _findClientIds(currentSearchTerm.value);
        return clientIds.includes(interaction.clientId);
      }
      return interactionName.includes(currentSearchTerm.value.toLowerCase());
    });
  }
  return filteredByTime;
});

const _findClientIds = (input: string) => {
  if (!clients.value) {
    return [];
  }
  const filteredClients = clients.value.filter((client: Client) => {
    const fullName = `${client.firstName} ${client.lastName} ${client.preferredName}`.toLowerCase();
    return fullName.includes(input.toLowerCase());
  });
  return filteredClients ? (filteredClients as Client[]).map((client: Client) => client.id) : [];
};

watch(() => clientsIds.value, (newClientsIds) => {
  clients.value = serviceCountsTimeline ? clientStore.clients(newClientsIds) : [];
});

const stickyCurrentInteractions = computed(
  () => filteredCurrentInteractions.value.filter((interaction: IInteraction) => interaction.state === 'sticky'),
);

const closedCurrentInteractions = computed(
  () => filteredCurrentInteractions.value.filter((interaction: IInteraction) => interaction.state === 'closed' || interaction.state === 'cancelled'),
);

const plannedCurrentInteractions = computed(
  () => filteredCurrentInteractions.value.filter((interaction: IInteraction) => interaction.state === 'planned'),
);

const closedSearchInteractions = computed(
  () => searchInteractions.value.filter((interaction: IInteraction) => interaction.state === 'closed' || interaction.state === 'cancelled'),
);
const plannedSearchInteractions = computed(
  () => searchInteractions.value.filter((interaction: IInteraction) => interaction.state === 'planned'),
);

const noResults = computed(() => filteredCurrentInteractions.value.length === 0
  && searchInteractions.value.length === 0
  && currentSearchTerm.value);

const noAdditionalResults = computed(() => filteredCurrentInteractions.value.length
  && !searchInteractions.value.length
  && currentSearchTerm.value);

const scheduleFilterActive = ref(route.query.mine === 'true');
const selectedFilterLabel = computed(() => (scheduleFilterActive.value ? 'Schedule' : 'All'));

const getInteractionsForFilterAction = async (optionCodename: string) => {
  if (!organisationUnitId || !routeParamsId.value) {
    return;
  }
  const formattedDate = formatISODate(date.value);
  scheduleFilterActive.value = (optionCodename === 'schedule');

  interactionStore.fetchForClientByDate(organisationUnitId, routeParamsId.value, formattedDate, scheduleFilterActive.value);
  router.replace({
    path: `${router.currentRoute.value.path}`,
    query: {
      ...route.query,
      mine: scheduleFilterActive.value.toString(),
    },
  });
};

const additionalResultsPadding = computed(() => {
  if (currentSearchTerm.value && !filteredCurrentInteractions.value.length) {
    return '0px';
  }
  return '20px';
});

const toggleBodyMap = async () => {
  showBodyMap.value = !showBodyMap.value;

  const routeName = showBodyMap.value ? 'client.timeline.body_map' : 'client.timeline';
  const navigationResult = await router.push({ name: routeName, query: route.query, force: true });

  if (navigationResult) {
    showBodyMap.value = !showBodyMap.value;
  } else if (!showBodyMap.value) {
    showBodyMapFilter.value = false;
    specificInstanceView.value = false;
  }
};

watch(() => showBodyMap.value, async (newValue, oldValue) => {
  if (oldValue && !newValue && route.name === 'client.timeline.service_selector') {
    await redirectToTimeline(router, route);
  }
});

const toggleInstanceView = async (instance: NourishInstance) => {
  specificInstanceView.value = !specificInstanceView.value;
  const navigationResult = specificInstanceView.value
    ? await router.push({ path: currentRouteInstanceViewerPath(instance.id).value, force: true, query: route.query })
    : await router.push({ name: 'client.timeline.body_map', force: true, query: route.query });

  if (navigationResult) {
    // revert toggle if navigation failed
    specificInstanceView.value = !specificInstanceView.value;
  }
};

const showAlarmsBar = computed(() => !(showBodyMap.value || specificInstanceView.value) && clientTimeline);

const emit = __emit;

const hiddenLeftPanelRoutes: (string | symbol)[] = ['client.timeline.needinteraction', 'client.timeline.service_selector'];
watch(() => route.name, (routeName) => {
  emit('hideLeftPanelOnMobile', routeName && hiddenLeftPanelRoutes.includes(routeName));
}, { immediate: true });

// clear search if url changed
onBeforeRouteUpdate((to, from) => {
  if (from.query.date !== to.query.date) {
    multiSelectRegister.$reset();
  }
  const toId = to.params.clientId || to.params.carerId || to.params.serviceId;
  const fromId = from.params.clientId || from.params.carerId || from.params.serviceId;
  if (toId === fromId) return true;
  getCurrentInteractions(+toId);

  // Typing is not inferred correctly from defineExpose (potentially linked to https://github.com/vuejs/core/issues/6644)
  (searchComponent.value?.closeSearchHandler as unknown as () => void)();

  multiSelectRegister.$reset();

  return true;
  // there is an ongoing issue with defineExpose and workaround is to use as any to avoid ts complains
});
// TODO to remove once client list is in vue
// a tweak to hide angular left panel since vue is faster than angular in component loading
hideElementsWithClassesNames(['double-panel-left', 'stats-container', 'text-center sp-client-term', 'sp-date sub-header']);

// Bulk close
const multiSelectRegister = useMultiSelectRegister();
const { bulkCloseEnabled, timelineControlsOptions } = storeToRefs(multiSelectRegister);

const toggleGroupSelection = (option: DropdownOption) => {
  multiSelectRegister.$reset();
  if (route.name === 'serviceCountList.timeline.interaction'
    || route.name === 'serviceCountList.timeline.bulkClose') {
    router.push({
      name: 'serviceCountList.timeline',
      params: route.params,
      query: route.query,
    });
  }
  bulkCloseEnabled.value = option.codename === 'group';
};

onBeforeRouteLeave(() => {
  multiSelectRegister.$reset();
});

const bodyMapTimelineSearchTerm = ref('');
const instanceViewSearchTerm = ref('');
const handleSearchTermChange = (newSearchTerm: string) => {
  if (!showBodyMap.value) {
    searchAdditionalInteractions(newSearchTerm);
  } else if (!specificInstanceView.value) bodyMapTimelineSearchTerm.value = newSearchTerm;
  else instanceViewSearchTerm.value = newSearchTerm;
};

const bodyMapFilterState = ref({ name: '', codename: '' } as DropdownFilterOption);
const bodyMapFilterSubType = ref({ name: '', codename: '' } as DropdownFilterOption);
const bodyMapFilterSide = ref('');
const handleStateChange = (newState: DropdownFilterOption) => { bodyMapFilterState.value = newState; };
const handleSubTypeChange = (newSubType: DropdownFilterOption) => { bodyMapFilterSubType.value = newSubType; };
const handleSideChange = (newSide: string) => { bodyMapFilterSide.value = newSide; };

const dynamicComponentDetails = ref({ props: {}, on: {} });
watch(route, () => {
  switch (true) {
    case isInstanceViewerNestedRoute.value: {
      dynamicComponentDetails.value = {
        props: { searchTerm: instanceViewSearchTerm },
        on: {},
      };
      break;
    }
    case isWithInBodyMapNestedRoutes.value: {
      dynamicComponentDetails.value = {
        props: {
          showBodyMapFilter,
          searchTerm: bodyMapTimelineSearchTerm,
          sideToShow: bodyMapFilterSide,
          filterState: bodyMapFilterState,
          filterSubType: bodyMapFilterSubType,
        },
        on: {
          selectInstance: toggleInstanceView,
          sideChange: handleSideChange,
          stateChange: handleStateChange,
          subTypeChange: handleSubTypeChange,
        },
      };
      break;
    }
    default: {
      dynamicComponentDetails.value = {
        props: {},
        on: {},
      };
    }
  }
}, { immediate: true });

const emptyFunctionalComponent = (comp: VNode) => {
  if ((comp.type as unknown) !== InstancesTimelineFComponent) {
    return false;
  }
  const fctComp = comp.type as FunctionalComponent;
  return !fctComp(comp.props!, {} as SetupContext);
};

const filterText = computed(() => {
  let sideText = translate('filter.all');
  if (!['all', ''].includes(bodyMapFilterSide.value)) sideText = translate(`body_map.${bodyMapFilterSide.value}`);
  const filters = [bodyMapFilterState.value.name, bodyMapFilterSubType.value.name, sideText];
  const filtersToDisplay: string[] = [];
  filters.forEach((f) => {
    if (filtersToDisplay.includes(f)) return;
    filtersToDisplay.push(f);
  });
  return filtersToDisplay.join(', ');
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, _mergeProps({ to: "#lp-action-bar" }, _ctx.$attrs), [
      _createVNode(LeftPanelActionBar, {
        ref_key: "searchComponent",
        ref: searchComponent,
        onSearchTerm: handleSearchTermChange,
        onUpdatedFilterOption: getInteractionsForFilterAction,
        onUpdatedDate: onUpdatedDate,
        onCloseSearch: clearSearch,
        onToggleBodyMap: toggleBodyMap,
        onToggleInstanceFilter: _cache[0] || (_cache[0] = ($event: any) => (showBodyMapFilter.value = !showBodyMapFilter.value)),
        onToggleInstanceView: toggleInstanceView,
        onTimelineControlsOptionSelected: toggleGroupSelection,
        instanceView: showBodyMap.value,
        "show-body-map-button": _unref(clientTimeline),
        filterSelected: showBodyMapFilter.value,
        "filter-text": filterText.value,
        specificInstanceView: specificInstanceView.value,
        "filter-options": _unref(clientTimeline) ? filterOptions : [],
        "selected-filter-label": selectedFilterLabel.value,
        "current-date": date.value,
        "timeline-controls-options": _unref(serviceCountsTimeline) ? _unref(timelineControlsOptions) : []
      }, null, 8, ["instanceView", "show-body-map-button", "filterSelected", "filter-text", "specificInstanceView", "filter-options", "selected-filter-label", "current-date", "timeline-controls-options"])
    ], 16)),
    (_openBlock(), _createBlock(_Teleport, { to: "#lp-alarms" }, [
      _createVNode(LeftPanelAlarmsBar, {
        "client-id": routeParamsId.value,
        currentInteractions: _unref(currentInteractions),
        showAlarmsBar: showAlarmsBar.value
      }, null, 8, ["client-id", "currentInteractions", "showAlarmsBar"])
    ])),
    (!loading.value && !noResults.value)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#lp-list-entry"
        }, [
          _createVNode(_component_router_view, {
            name: "SideBarInnerRouteView",
            class: "v-interactions-left-panel-wrapper"
          }, {
            default: _withCtx(({ Component: comp }) => [
              (comp && !emptyFunctionalComponent(comp))
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(comp), _mergeProps({ key: 0 }, { ...dynamicComponentDetails.value.props }, _toHandlers({ ...dynamicComponentDetails.value.on })), null, 16))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(closedCurrentInteractions.value, (interaction) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: interaction.id!
                      }, [
                        (_unref(featureToggles).newInteractionCardsAndTopBar)
                          ? (_openBlock(), _createBlock(TimelineInteractionListEntry, {
                              key: 0,
                              interaction: interaction
                            }, null, 8, ["interaction"]))
                          : (_openBlock(), _createBlock(InteractionsListEntry, {
                              key: 1,
                              interaction: interaction,
                              carerTimeline: !_unref(clientTimeline),
                              familyTimeline: _unref(familyPortal)
                            }, null, 8, ["interaction", "carerTimeline", "familyTimeline"]))
                      ]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stickyCurrentInteractions.value, (interaction) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: interaction.id!,
                        class: "open-interactions"
                      }, [
                        (_unref(featureToggles).newInteractionCardsAndTopBar)
                          ? (_openBlock(), _createBlock(TimelineInteractionListEntry, {
                              key: 0,
                              interaction: interaction
                            }, null, 8, ["interaction"]))
                          : (_openBlock(), _createBlock(InteractionsListEntry, {
                              key: 1,
                              interaction: interaction
                            }, null, 8, ["interaction"]))
                      ]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plannedCurrentInteractions.value, (interaction) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: interaction.id!,
                        class: "open-interactions"
                      }, [
                        (_unref(featureToggles).newInteractionCardsAndTopBar)
                          ? (_openBlock(), _createBlock(TimelineInteractionListEntry, {
                              key: 0,
                              interaction: interaction
                            }, null, 8, ["interaction"]))
                          : (_openBlock(), _createBlock(InteractionsListEntry, {
                              key: 1,
                              interaction: interaction,
                              carerTimeline: !_unref(clientTimeline),
                              familyTimeline: _unref(familyPortal)
                            }, null, 8, ["interaction", "carerTimeline", "familyTimeline"]))
                      ]))
                    }), 128)),
                    (_unref(clientTimeline))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          (noAdditionalResults.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                  _createElementVNode("h2", _hoisted_5, _toDisplayString(_unref(translate)('search.additional_results')), 1)
                                ]),
                                _createVNode(NoResultsMessage, {
                                  additionalResults: true,
                                  noResultsTerm: currentSearchTerm.value
                                }, {
                                  no_results_message: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(translate)('search.no_additional_records_for')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["noResultsTerm"])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(closedSearchInteractions.value, (interaction) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: interaction.id!
                                  }, [
                                    (_unref(featureToggles).newInteractionCardsAndTopBar)
                                      ? (_openBlock(), _createBlock(TimelineInteractionListEntry, {
                                          key: 0,
                                          interaction: interaction
                                        }, null, 8, ["interaction"]))
                                      : (_openBlock(), _createBlock(InteractionsListEntry, {
                                          key: 1,
                                          interaction: interaction,
                                          compact: ""
                                        }, null, 8, ["interaction"]))
                                  ]))
                                }), 128)),
                                (closedSearchInteractions.value.length && plannedSearchInteractions.value.length)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[1] || (_cache[1] = [
                                      _createElementVNode("hr", { class: "v-separator" }, null, -1)
                                    ])))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plannedSearchInteractions.value, (interaction) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: interaction.id!
                                  }, [
                                    (_unref(featureToggles).newInteractionCardsAndTopBar)
                                      ? (_openBlock(), _createBlock(TimelineInteractionListEntry, {
                                          key: 0,
                                          interaction: interaction
                                        }, null, 8, ["interaction"]))
                                      : (_openBlock(), _createBlock(InteractionsListEntry, {
                                          key: 1,
                                          interaction: interaction,
                                          compact: ""
                                        }, null, 8, ["interaction"]))
                                  ]))
                                }), 128))
                              ]))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 1
          })
        ]))
      : (!loading.value && noResults.value)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 1,
            to: "#lp-no-results"
          }, [
            _createVNode(NoResultsMessage, { noResultsTerm: currentSearchTerm.value }, {
              no_results_message: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(translate)('search.no_records_for')), 1)
              ]),
              _: 1
            }, 8, ["noResultsTerm"])
          ]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})