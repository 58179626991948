import apiClient from '@/_shared/services/apiClient';
import IInteraction from '@/timeline/types/IInteraction';
import { AssessmentState } from '../types/assessmentState';

export interface assessmentsApiResponse {
  interactions: IInteraction[];
}

export const getAssessmentStates = async () => {
  const url = '/api/v2/assessments/assessment_states';
  try {
    return await apiClient.get(url, { params: { } }) as AssessmentState[];
  } catch (error) {
    return [];
  }
};

export const getAssessmentsForClientByProvidedServiceIds = async (
  clientId: number,
  orgUnitId: number,
  providedServiceIds: number[] | undefined = undefined,
  limit = 10,
) => {
  const url = `/api/v2/clients/${clientId}/assessmentsProvidedServices`;
  const params = {
    organisation_unit_id: orgUnitId,
    providedServiceIds,
    limit,
  };
  try {
    const response = await apiClient.post<assessmentsApiResponse>(url, params);
    return response.interactions;
  } catch (error) {
    console.log(error);
    return [];
  }
};
