import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-outer-form" }
const _hoisted_2 = { class: "v-inner-form" }
const _hoisted_3 = { class: "v-floated-form-wrapper" }
const _hoisted_4 = { class: "v-bg-image" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-double-panel-right", { open: _ctx.open }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      (_ctx.withBGImg)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-bg-overlay" }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.photoSrc,
                alt: "Profile picture of resident"
              }, null, 8, _hoisted_5)
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-bg-fade" }, null, -1))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 2))
}