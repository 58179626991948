import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-uid", "disabled"]

import SignaturePad from 'signature_pad';
import { onMounted, reactive, watch } from 'vue';

import type { Options as SigOptions } from 'signature_pad';
import type { PropType, StyleValue } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VueSignaturePadWrapper',
  props: {
  sigOption: {
    type: Object as PropType<SigOptions>,
    default: () => ({
      backgroundColor: 'rgb(255,255,255)',
      penColor: 'rgb(0, 0, 0)',
    }),
  },
  w: {
    type: String,
    default: '100%',
  },
  h: {
    type: String,
    default: '100%',
  },
  clearOnResize: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  defaultUrl: {
    type: String,
    default: '',
  },
},
  emits: ['begin', 'end'],
  setup(__props, { expose: __expose, emit: __emit }) {


// todo this need to be update to work with last version of  SignaturePad 5.0
// for instance onBegin and onEnd are not available anymore
// instead beginStroke and endStroke should be used
const canvasStyle: StyleValue = {
  width: '100%',
  height: '100%',
};

const emit = __emit;

const props = __props;

const state = reactive<{
  sig?: SignaturePad;
  option: SigOptions;
  uid: string;
  disabled?: boolean;
}>({
  sig: undefined,
  option: {
    backgroundColor: 'rgb(255,255,255)',
    penColor: 'rgb(0, 0, 0)',
    ...props.sigOption,
  },
  uid: `canvas${Math.random()}`,
});

watch(
  () => props.disabled,
  (val) => {
    if (val) {
      state.sig?.off();
    } else {
      state.sig?.on();
    }
  },
);

const draw = () => {
  const canvas = document.getElementById(state.uid) as HTMLCanvasElement;
  state.sig = new SignaturePad(canvas, state.option);
  state.sig.onBegin = () => emit('begin');
  state.sig.onEnd = () => emit('end');

  function resizeCanvas(c: HTMLCanvasElement) {
    let url;
    if (!isEmpty()) {
      url = save();
    }
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const reg = /px/;
    c.width = reg.test(props.w)
      ? Number(props.w.replace(/px/g, '')) * ratio
      : c.offsetWidth * ratio;
    c.height = reg.test(props.h)
      ? Number(props.h.replace(/px/g, '')) * ratio
      : c.offsetHeight * ratio;
    c.getContext('2d')!.scale(ratio, ratio);
    clear();
    if (!props.clearOnResize && url !== undefined) fromDataURL(url);
  }

  window.addEventListener('resize', () => resizeCanvas(canvas));
  resizeCanvas(canvas);
  if (props.defaultUrl !== '') {
    fromDataURL(props.defaultUrl);
  }
  if (props.disabled) {
    state.sig.off();
  } else {
    state.sig.on();
  }
};

const clear = () => {
  state.sig!.clear();
};
const save = (format?: 'image/jpeg' | 'image/svg+xml') => (format ? state.sig?.toDataURL(format) : state.sig?.toDataURL());
const fromDataURL = (url: string) => {
  state.sig!.fromDataURL(url);
};
const isEmpty = () => state.sig!.isEmpty();
const undo = () => {
  const data = state.sig!.toData();
  if (data) {
    data.pop();
    state.sig!.fromData(data);
  }
};

onMounted(() => {
  draw();
});

__expose({
  save,
  clear,
  isEmpty,
  undo,
  fromDataURL,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ width: __props.w, height: __props.h }),
    onTouchmove: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("canvas", {
      id: state.uid,
      "data-uid": state.uid,
      disabled: state.disabled,
      style: canvasStyle
    }, null, 8, _hoisted_1)
  ], 36))
}
}

})