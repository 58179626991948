
import {
  defineComponent,
} from 'vue';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';

export default defineComponent({
  components: { BaseAvatar, BaseCheckbox },
  emits: ['toggleSelectEntry'],
  props: {
    entry: {
      type: Object,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      type: Boolean,
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    primaryText: {
      type: String,
    },
    secondaryText: {
      type: String,
    },
  },
  setup(props, context) {
    const toggleChecked = () => {
      context.emit('toggleSelectEntry', props.entry.id);
    };

    return {
      toggleChecked,
    };
  },
});
