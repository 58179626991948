import { defineStore } from 'pinia';
import { ref } from 'vue';

const useNotificationsStore = defineStore('notifications', () => {
  const roleIsSelected = ref(false);

  const selectRole = () => {
    roleIsSelected.value = true;
  };

  const $reset = () => {
    roleIsSelected.value = false;
  };

  return {
    roleIsSelected,
    $reset,
    selectRole,
  };
});

export default useNotificationsStore;
