import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "v-h3" }
const _hoisted_3 = { class: "v-shift-allocation-labels pull-right margin-top-sm" }
const _hoisted_4 = { class: "v-label success" }
const _hoisted_5 = { class: "v-label warning" }
const _hoisted_6 = { class: "v-label danger" }
const _hoisted_7 = { class: "v-availability-save-button" }

import {
  computed,
  onBeforeMount,
  ref, watch,
} from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import use from '@/_shared/compositionApi';
import { createOrUpdateAvailabilities, fetchAvailabilities } from '@/professional_info/services/availabilitiesApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { Availability, DaySchedule, WeekSchedule } from '../types/availability';
import AvailabilityWeek from './AvailabilityWeek.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AvailabilityContainer',
  props: {
  carerId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const { toast, ToastType } = use.helpers();

const props = __props;

const { translate } = use.helpers();

const weeksOptions = computed(() => [
  { text: translate('professional_info.availability.week_pattern.1'), value: 1 },
  { text: translate('professional_info.availability.week_pattern.2'), value: 2 },
  { text: translate('professional_info.availability.week_pattern.3'), value: 3 },
  { text: translate('professional_info.availability.week_pattern.4'), value: 4 },
]);

const availability = ref<Availability>();

const saveAvailability = async (editedAvailability: Availability) => {
  const resp: boolean = await createOrUpdateAvailabilities(
    editedAvailability,
  );
  if (resp) {
    toast(translate('ajax.notices.save_success'), ToastType.Success);
  }
};

onBeforeMount(async () => {
  [availability.value] = await fetchAvailabilities(+props.carerId);
  await loadModuleLanguageFileAsync('professional_info'); // TODO WHEN PURE VUE remove
});

const repeatFrequency = computed(() => availability.value?.repeatFrequency || 0);

watch(
  () => (repeatFrequency.value),
  (newRepeatFrequency: number, prevRepeatFrequency: number) => {
    if (!availability.value || prevRepeatFrequency === 0) return;
    const emptyDay: DaySchedule = {
      available: false,
      flexible: false,
      note: '',
      timeIntervals: [],
    };
    const emptyWeek: WeekSchedule = {
      monday: emptyDay,
      tuesday: emptyDay,
      wednesday: emptyDay,
      thursday: emptyDay,
      friday: emptyDay,
      saturday: emptyDay,
      sunday: emptyDay,
    };
    const newWeeks = new Array(newRepeatFrequency).fill({ days: emptyWeek });
    for (let i = 0; i < Math.min(newRepeatFrequency, prevRepeatFrequency); i += 1) {
      newWeeks[i] = availability.value.weeks[i];
    }
    availability.value.weeks = newWeeks;
  },
  { deep: true },
);

return (_ctx: any,_cache: any) => {
  return (availability.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(BaseDatePickerInput, {
          border: "",
          date: "",
          boldLabel: "",
          labelWidth: "300px",
          inputWidth: "50%",
          label: _unref(translate)('professional_info.availability.start_on'),
          modelValue: availability.value.startOn,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((availability.value.startOn) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(BaseSelect, {
          border: "",
          boldLabel: "",
          labelWidth: "300px",
          inputWidth: "50%",
          label: _unref(translate)('professional_info.availability.week_pattern_label'),
          options: weeksOptions.value,
          modelValue: availability.value.repeatFrequency,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((availability.value.repeatFrequency) = $event))
        }, null, 8, ["label", "options", "modelValue"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availability.value.weeks, (week, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(translate)('common.week')) + " " + _toDisplayString(index+1), 1),
            _createVNode(AvailabilityWeek, {
              weekDays: week.days,
              "onUpdate:weekDays": ($event: any) => ((week.days) = $event)
            }, null, 8, ["weekDays", "onUpdate:weekDays"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(translate)('professional_info.availability.available_notice')), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(translate)('professional_info.availability.unavailable_but_flexible_notice')), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(translate)('professional_info.availability.unavailable_notice')), 1)
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(BaseButton, {
            onClicked: _cache[2] || (_cache[2] = ($event: any) => (saveAvailability(availability.value as Availability)))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Save")
            ])),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})