import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { PropType } from 'vue';
import { TransformedOption } from '@/_shared/types/baseSelect';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectCustomTag',
  props: {
  option: {
    type: Object as PropType<TransformedOption>,
    required: true,
  },
},
  emits: ['removeOption'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const removeOption = (option: TransformedOption) => {
  emit('removeOption', option);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([__props.option.disabled ? 'is-disabled' : '', 'multiselect-tag v-tag'])
  }, [
    _createTextVNode(_toDisplayString(__props.option.label) + " ", 1),
    _createElementVNode("span", {
      class: "multiselect-tag-remove",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (removeOption(__props.option)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("span", { class: "multiselect-tag-remove-icon" }, null, -1)
    ]))
  ], 2))
}
}

})