import { RouteLocationRaw } from 'vue-router';

export interface TableLink {
    text: string;
    bold: boolean;
    italic: boolean;
    to: RouteLocationRaw;
}

export function createTableLink(
  text: string,
  to: RouteLocationRaw,
  bold = false,
  italic = false,
): TableLink {
  return {
    text, bold, italic, to,
  };
}
