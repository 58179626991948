import { RouteLocationNormalized, Router } from 'vue-router';
import useUserStore from '@/_shared/store/user';
import { clientStore } from '@/_shared/store/clients';

async function maybeRedirectByClientState(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const aoFabMenuToggle = useUserStore().getFeatureToggle('aoFabMenu');
  if (!aoFabMenuToggle) return true;
  const clientState = await clientStore.asyncById(+to.params.clientId).then((client) => client?.stateInOrg as string);
  const statesWithoutTimeline = ['enrolled', 'pre_assessment'];
  if (statesWithoutTimeline.includes(clientState)) {
    if (from.name !== 'client.profile') {
      return {
        name: 'client.profile',
        params: { clientId: to.params.clientId },
      };
    }
    return false;
  }
  return true;
}

export default maybeRedirectByClientState;

export const redirectToTimeline = async (router: Router, route: RouteLocationNormalized) => {
  await router.push({
    name: 'client.timeline',
    params: route.params,
    query: route.query,
  });
};
