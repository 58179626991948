import { Parameter } from '@/timeline/types/Parameter';
import { computed } from 'vue';
import { ensureDate } from '@/_shared/helpers/useDateTimeHelper';

const useParameterValidator = (parameter : NonNullable<Parameter>) => {
  const validationRule: RegExp|null = (() => {
    if (parameter.config && parameter.config.validationRule) {
      return new RegExp(parameter.config.validationRule);
    }
    return null;
  })();
  const errorMessage = computed(() => {
    const cvTmp = getCoercedValue();
    if (validationRule && cvTmp && !validationRule.test(cvTmp)) return parameter?.config?.validationComment;
    return undefined;
  });

  function getCoercedValue() {
    const tmpCv = (parameter.coercedValue)?.toString();
    if (parameter.valueType === 'string') {
      if (parameter.coercedValue instanceof Object) return (parameter.coercedValue as { value: string })?.value;
      return tmpCv;
    }
    if (!tmpCv || !(parameter.valueType === 'datetime' || parameter.valueType === 'date')) return tmpCv;
    if (parameter.valueType === 'datetime') return ensureDate(tmpCv)?.toISOString();
    return ensureDate(tmpCv)?.toLocaleDateString() || '';
  }
  return { errorMessage };
};
export default useParameterValidator;
