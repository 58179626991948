import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-data-message" }

import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'PastVersionsNoData',
  setup(__props) {

const { translate } = use.helpers();

const message = translate('log.noData');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(message)), 1))
}
}

})