import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-notifications-wrapper" }

import '@/_shared/styles/style.scss';

export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsMain',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "v-single-panel-body" }, null, -1)
  ])))
}
}

})