import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseDropdownList from '@/_shared/components/general/BaseDropdownList.vue';
import use from '@/_shared/compositionApi';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import { useRoute, useRouter } from 'vue-router';
import {
  computed, onBeforeMount, ref,
} from 'vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { MenuSection } from '@/_shared/types/baseDropdownList';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddClientDropdownMenu',
  props: {
  show: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Boolean,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();
const { featureToggles } = storeToRefs(useUserStore());

const props = __props;

const emit = __emit;

const router = useRouter();
const route = useRoute();
const showForm = async (type: string) => {
  emit('update:modelValue', true);
  await router.push({ name: 'clientList.new', params: { type }, query: route.query });
};

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('clients'); // TODO WHEN PURE VUE remove;
  menuSections.value = [
    {
      title: translate('clients.add_client_menu_title'),
      options: [
        {
          show: featureToggles.value.aoEnquiryStatus,
          text: translate('clients.enquiry_form_title'),
          method: () => showForm('enquiry'),
        },
        {
          show: true,
          text: translate('clients.pre_assess_form_title'),
          method: () => showForm('pre_assess'),
        },
        {
          show: featureToggles.value.aoNewStatuses,
          text: translate('clients.admit_form_title'),
          method: () => showForm('admit'),
        },
        {
          show: featureToggles.value.aoNewStatuses,
          text: translate('clients.emergency_admission_form_title'),
          method: () => showForm('emergency_admission'),
        },
        {
          show: true,
          text: translate('clients.careplan_template_form_title'),
          method: () => showForm('careplan_template'),
        },
        {
          show: true,
          text: translate('clients.pet_animal_form_title'),
          method: () => showForm('pet'),
        },
        {
          show: true,
          text: translate('clients.service_management_form_title'),
          method: () => showForm('service_management'),
        },
        {
          show: true,
          text: translate('clients.training_form_title'),
          method: () => showForm('training'),
        },
      ],
    },
  ];
});

const menuSections = ref<MenuSection[]>([]);

const dropdownListPosition = computed(() => (props.isMobileView ? 'TopCenter' : 'BottomLeft'));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseDropdownList, {
    show: __props.show,
    "menu-sections": menuSections.value,
    position: dropdownListPosition.value
  }, null, 8, ["show", "menu-sections", "position"]))
}
}

})