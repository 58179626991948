import { defineStore } from 'pinia';
import { ref, computed, Ref } from 'vue';
import fetchServiceFilters, { SkinInstanceServicesFilter } from '@/_shared/services/serviceFiltersApi';
import { InteractionInfo } from '@/assessments/types/assessmentState';
import { ProvidedService } from '@/_shared/types/providedService';
import { providedServicesStore } from '@/_shared/store/providedServices';
import { clientStore } from '@/_shared/store/clients';
import { valueToCamel } from '@/_shared/services/keysToCamel';

const useServiceFilteringStore = defineStore('serviceFiltering', () => {
  const currentClientId: Ref<number> = ref(0);
  const currentClientState = computed(() => clientStore.currentOrgUnitState(+currentClientId.value).value);
  const currentClientType = computed(() => (currentClientId.value ? (clientStore.byId(+currentClientId.value)?.clientType || '') : ''));

  const isForAssessment: Ref<boolean> = ref(false);
  let filteringNonAdhoc: number[] = [];
  let editableServices: number[] = [];
  const newSkinInstanceServicesFilter = ref<SkinInstanceServicesFilter[]>([]);
  const managedSkinInstanceServicesFilter = ref<SkinInstanceServicesFilter[]>([]);
  const filteringClientTypes = ref<Record<string, number[]>>({});
  const assessmentsForState = ref<Record<string, InteractionInfo[]>>({});
  const interactionsForState = ref<Record<string, InteractionInfo[]>>({});
  const interactionExcludeStates = ref<string[]>([]);

  let initialized = false;
  let initializing = false;

  const initialize = async () => {
    if (!initialized && !initializing) {
      initializing = true;
      const fetchedFilters = await fetchServiceFilters();
      filteringNonAdhoc = fetchedFilters.nonAdHocServices;
      editableServices = fetchedFilters.editableServices;
      filteringClientTypes.value = fetchedFilters.clientTypeServices;
      newSkinInstanceServicesFilter.value = fetchedFilters.newSkinInstanceServices;
      managedSkinInstanceServicesFilter.value = fetchedFilters.managedSkinInstanceServices;
      fetchedFilters.stateFilters?.forEach((stateFilter) => {
        assessmentsForState.value[stateFilter.codename] = stateFilter.assessments;
        interactionsForState.value[stateFilter.codename] = stateFilter.interactions;
        if (stateFilter.excludeFromAdhoc) {
          interactionExcludeStates.value.push(stateFilter.codename);
        }
      });
      initialized = true;
      initializing = false;
    } else if (initializing) {
      setTimeout(() => {
        initialize();
      }, 100);
    }
  };

  const bodyMapTimelineMounted = ref(false);
  const instanceViewTimelineMounted = ref(false);
  const bodyMapMounted = computed(() => bodyMapTimelineMounted.value || instanceViewTimelineMounted.value);
  const currentInstance = ref<{ subType?: string | undefined, state?: string | undefined, instanceId?: number | undefined }>({});

  const newSkinInstanceServices = computed(() => (
    newSkinInstanceServicesFilter.value ? providedServices.value
      .filter((ps) => newSkinInstanceServicesFilter.value
        .map((filter) => filter.id)
        .includes(ps.serviceId)) : []));

  const matchingManagedSkinInstanceServices = computed(() => {
    const { subType, state } = currentInstance.value;
    if (subType) {
      const filtered = managedSkinInstanceServicesFilter.value.filter((filter) => {
        const subTypeMatch = (filter.subType as string[]).includes(subType);

        if (state === 'new') {
          return subTypeMatch && ['active', 'active_or_archived'].includes(filter.state as string);
        }
        if (filter.state === 'active_or_archived') {
          return subTypeMatch && ['active', 'archived'].includes(state as string);
        }
        return subTypeMatch && filter.state === state;
      });
      if (filtered.length) {
        const matchedServiceIds = filtered.map((filter) => filter.id);
        return providedServices.value.filter((ps) => matchedServiceIds.includes(ps.serviceId));
      }
      return [];
    }
    return [];
  });

  const providedServices = computed(() => providedServicesStore.getProvidedServicesExcludeDeleted.value);

  const filteredNonAdhocProvidedServices = computed(() => {
    if (!(currentClientState.value || currentClientType)) return [];
    let toReturn: ProvidedService[];

    if (bodyMapTimelineMounted.value) {
      // Bodymap / instance viewer timeline view
      toReturn = [...newSkinInstanceServices.value];
    } else {
      // Timeline view
      toReturn = providedServices.value;
    }
    if (instanceViewTimelineMounted.value) toReturn = [...matchingManagedSkinInstanceServices.value];
    // Filter out careplan review
    toReturn = toReturn.filter((ps) => ps.codename !== 'careplan_review');
    // Filter out services you can not edit
    toReturn = toReturn.filter((ps) => editableServices.includes(ps.serviceId));
    // Filter out non adhoc services
    if (!bodyMapMounted.value) toReturn = toReturn.filter((ps) => !filteringNonAdhoc.includes(ps.serviceId));

    if (isForAssessment.value && assessmentsForState.value && assessmentsForState.value[currentClientState.value]) {
      // in the filter for assessments the name is codename
      const codenames = assessmentsForState.value[currentClientState.value].map((interactionInfo) => interactionInfo.name);
      toReturn = toReturn.filter((ps) => ps.codename && codenames?.includes(ps.codename));
    } else {
      const codenames = interactionsForState.value[currentClientState.value]?.map((interactionInfo) => interactionInfo.codename);
      // Work out if we are excluding or including
      if (interactionExcludeStates.value.includes(currentClientState.value)) {
        toReturn = toReturn.filter((ps) => !codenames?.includes(ps.codename));
      } else {
        toReturn = toReturn.filter((ps) => codenames?.includes(ps.codename) || nonLibraryInteractions.value?.includes(ps.serviceId));
      }
    }
    // Now filter by care type if the care type has been linked to a library it will be in filteringClientTypes
    if (filteringClientTypes.value[valueToCamel(currentClientType.value)]) {
      toReturn = toReturn.filter((ps) => (filteringClientTypes.value[valueToCamel(currentClientType.value)].includes(ps.serviceId) || nonLibraryInteractions.value.includes(ps.serviceId)));
    }

    const uniqueProvidedServices = new Set([...toReturn]);
    const uniqueProvidedServicesArray = [...uniqueProvidedServices];
    uniqueProvidedServicesArray.sort(alphanumericSort);
    return uniqueProvidedServicesArray;
  });

  const alphanumericSort = (a: ProvidedService, b: ProvidedService): number => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // If names are equal, compare numerically
    const numA = parseInt(nameA.match(/\d+/)?.[0] || '0', 10);
    const numB = parseInt(nameB.match(/\d+/)?.[0] || '0', 10);

    return numA - numB;
  };

  const nonLibraryInteractions = computed(() => filteringClientTypes.value.idsNotFromLibraries || []);
  const $reset = async (clientId: number, assessmentRoute:boolean) => {
    await initialize();
    if (currentClientId.value !== clientId) currentClientId.value = clientId;
    isForAssessment.value = assessmentRoute;
  };

  // Todo refactor the store to use route instead of instanceViewTimelineMounted,bodyMapTimelineMounted,
  // TODO: Remove excported functions that are only used for tests. This needs to be refactored when we find a better way to mock functions
  return {
    // ------- to refactor
    instanceViewTimelineMounted,
    bodyMapTimelineMounted,
    bodyMapMounted,
    currentInstance,
    // -------
    assessmentsForState,
    providedServices,
    filteredNonAdhocProvidedServices,
    matchingManagedSkinInstanceServices,
    newSkinInstanceServices,
    newSkinInstanceServicesFilter,
    managedSkinInstanceServicesFilter,
    filteringClientTypes,
    alphanumericSort,
    initialize,
    $reset,
  };
});

export default useServiceFilteringStore;
