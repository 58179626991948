import use from '@/_shared/compositionApi';

const { translate } = use.helpers();

const useSelectDatasetAnswers = () => {
  const baseQuestionsAnswers = [
    translate('provided_service.data_sets.options.yes_no', 1, { locale: 'en' }),
    translate('provided_service.data_sets.options.yes_none', 1, { locale: 'en' }),
    translate('provided_service.data_sets.options.yes_none_known', 1, { locale: 'en' }),
    translate('provided_service.data_sets.options.yes_not_recorded', 1, { locale: 'en' }),
  ];

  const questionAnswersSet = new Set(baseQuestionsAnswers
    .map((x) => x
      .split('/')
      .map((y) => y.trim()))
    .flat());

  const getNegativeAnswer = (hasQuestion: boolean, qOptions: string) => {
    const questionOptions = qOptions.split('/') || null;
    const defaultAnswer = isQuestionOptionsDefault(qOptions) ? (questionOptions as string[])[1].trim() : '';
    return hasQuestion ? defaultAnswer : '';
  };

  // we are forcing the translation to english since paramter config are in english always

  const isQuestionOptionsDefault = (qOptions: string) => {
    if (qOptions) {
      const normalisedOptions = normalise(qOptions);
      return baseQuestionsAnswers.map(normalise).some((x) => x === normalisedOptions);
    }
    return false;
    function normalise(option: string) {
      return option.toLowerCase().trim()
        .replace(/\s/g, '');
    }
  };

  return {
    getNegativeAnswer,
    questionAnswersSet,
  };
};

export default useSelectDatasetAnswers;
