import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-assessments-left-panel-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "v-assessments-left-panel-wrapper"
}

import { storeToRefs } from 'pinia';
import LeftPanelActionBar from '@/_shared/components/general/left_panel/LeftPanelActionBar.vue';
import useAssessmentStore from '@/_shared/store/assessments';
import {
  computed, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import IInteraction from '@/timeline/types/IInteraction';
import { ProvidedService } from '@/_shared/types/providedService';
import { Assessment } from '@/assessments/types/assessment';
import InteractionsListEntry from '@/timeline/components/InteractionsListEntry.vue';
import BaseListEntry from '@/_shared/components/display/BaseListEntry.vue';
import use from '@/_shared/compositionApi';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
import { fetchClientStatusTerm } from '@/_shared/services/clientStateOverride';
import hideElementsWithClassesNames from '@/_shared/helpers/useAngularPartHider';
import TeleportWrapper from '@/_shared//components/general/TeleportWrapper.vue';
import { oneAssessmentParPs } from '@/timeline/helper/interactionsHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssessmentsLeftPanel',
  emits: ['hideLeftPanelOnMobile'],
  setup(__props, { emit: __emit }) {

const { topAssessments, allStatesAssessments } = storeToRefs(useAssessmentStore());
const categoryIconStore = useCategoryIconStore();
const route = useRoute();
const router = useRouter();
const selectedFilterValue = ref<string>(
  route.query.interactionState ? (route.query.interactionState as string) : 'current',
);
const searchComponent = ref<InstanceType<typeof LeftPanelActionBar> | null>();
/* TODO hoping ayyoub/ben can help with this,
Jamie: When closing an interaction this is then empty, which means working out curent state is empty
Which means that the dummy interactions are not shown
Have the two dummy ones that i manually added in code, note and weight then close a Note, either from dummy or adhoc.
On save the dummy's can not calculate because currentClientId is ''
*/
const currentClientId = computed(() => +route.params.clientId);
const { clientStore } = use.store.clients();
const currentClientState = computed(() => clientStore.currentOrgUnitState(currentClientId.value).value);
const currentAssessments = computed(() => {
  switch (selectedFilterValue.value) {
    case 'planned':
      return [...oneAssessmentParPs(allStatesAssessments.value.sticky!), ...oneAssessmentParPs(allStatesAssessments.value.planned!.next)] as IInteraction[];
    case 'closed': {
      const uniqueAssessments = oneAssessmentParPs(allStatesAssessments.value.completed!).sort((a, b) => Date.parse(b.finishAt!) - Date.parse(a.finishAt!));
      return [...uniqueAssessments] as IInteraction[];
    }
    case 'overdue':
      return [...oneAssessmentParPs(allStatesAssessments.value.sticky!), ...allStatesAssessments.value.planned!.overdue] as IInteraction[];
    default:
      return topAssessments.value;
  }
});
// TODO do we need unique dummy assessments?

const dummyActive = (currentServiceId: number) => {
  const { serviceId } = route.query;
  return parseInt(serviceId as string, 10) === currentServiceId;
};

const searchingTerm = ref<string>('');

const searchResults = computed(() => {
  if (allStatesAssessments.value && searchingTerm.value) {
    const getAssessmentName = (assessment: Assessment) => assessment.name || assessment.displayName;
    const byName = (assessment: Assessment) => getAssessmentName(assessment).toLowerCase().includes(searchingTerm.value);
    const completed = allStatesAssessments.value.completed!.filter(byName);
    const planned = allStatesAssessments.value.planned!.next.filter(byName).slice(0, 10);
    return [...planned, ...completed];
  }
  return [];
});

const searchAssessments = (searchTerm: string) => {
  searchingTerm.value = searchTerm.toLowerCase().trim();
};

const setNewFilterValue = (codename: string) => {
  selectedFilterValue.value = codename;
  if (route.query.interactionState) {
    router.replace({
      query: {
        ...route.query,
        interactionState: codename,
      },
    });
  } else {
    router.push({
      query: {
        ...route.query,
        interactionState: codename,
      },
    });
  }
};

const newAssessmentLink = (serviceId: number) => ({
  name: 'client.assessments.interaction_new',
  query: { ...route.query, serviceId: +serviceId },
});

const compact = (assessment: IInteraction | ProvidedService) => {
  if ('state' in assessment && 'plannedFinishAt' in assessment && assessment.plannedFinishAt) {
    return assessment.state === 'planned' && Date.parse(assessment.plannedFinishAt) <= Date.now();
  }
  return false;
};

const { translate } = use.helpers();

const clientStateTerm = () => (currentClientState.value ? fetchClientStatusTerm(currentClientState.value) : '');

const filterOptions = computed(() => [
  {
    codename: 'current',
    label: clientStateTerm(),
  },
  {
    codename: 'closed',
    label: translate('filter.completed'),
  },
  { codename: 'planned', label: translate('filter.scheduled') },
  { codename: 'overdue', label: translate('filter.overdue') },
]);

const clearSearch = () => {
  searchingTerm.value = '';
};

// TODO to remove once client list is in vue
// a tweak to hide angular left panel since vue is faster than angular in component loading
hideElementsWithClassesNames([
  'double-panel-left',
  'stats-container',
  'text-center sp-client-term',
  'sp-date sub-header',
]);

const emit = __emit;
watch(
  () => route.name,
  (routeName) => {
    emit('hideLeftPanelOnMobile', routeName === 'client.assessments.assessment_selector');
  },
  { immediate: true },
);

// clear search if url changed
watch(currentClientId, (clientId, previousClientId) => {
  if (clientId === previousClientId) return;

  // Typing is not inferred correctly from defineExpose (potentially linked to https://github.com/vuejs/core/issues/6644)
  (searchComponent.value?.closeSearchHandler as unknown as () => void)();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(TeleportWrapper, {
    to: "#lp-action-bar",
    class: "v-assessments-left-panel"
  }, {
    default: _withCtx(() => [
      _createVNode(LeftPanelActionBar, {
        ref_key: "searchComponent",
        ref: searchComponent,
        onSearchTerm: searchAssessments,
        onUpdatedFilterOption: setNewFilterValue,
        onCloseSearch: _cache[0] || (_cache[0] = ($event: any) => (clearSearch())),
        "filter-options": filterOptions.value,
        "selected-filter-label": 
        filterOptions.value.find((option) => option.codename === selectedFilterValue.value)?.label
      ,
        "date-picker-disabled": true
      }, null, 8, ["filter-options", "selected-filter-label"]),
      _createVNode(TeleportWrapper, { to: "#lp-list-entry" }, {
        default: _withCtx(() => [
          (searchingTerm.value.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentAssessments.value, (assessment) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: assessment.id!
                  }, [
                    ((assessment as unknown as ProvidedService).showDummy && assessment.serviceId)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: newAssessmentLink(assessment.serviceId)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(BaseListEntry, {
                              active: dummyActive(assessment.serviceId),
                              title: assessment.name,
                              icon: _unref(categoryIconStore).getIconUrl(assessment.categoryCodename).value,
                              fontSize: "20px",
                              blankIcon: ""
                            }, {
                              headerRightCol: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(dummyActive(assessment.serviceId) ? 'v-interaction-selected-pointer' : '')
                                }, null, 2)
                              ]),
                              _: 2
                            }, 1032, ["active", "title", "icon"])
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (!(assessment as ProvidedService).showDummy)
                        ? (_openBlock(), _createBlock(InteractionsListEntry, {
                            key: 1,
                            interaction: assessment as IInteraction,
                            compact: 
              (assessment as IInteraction).state === 'planned' && Date.parse((assessment as IInteraction).plannedFinishAt!) <= Date.now()
            ,
                            assessments: true
                          }, null, 8, ["interaction", "compact"]))
                        : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResults.value, (assessment) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: assessment.name
                  }, [
                    ((assessment as unknown as ProvidedService).showDummy)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: newAssessmentLink(assessment.serviceId)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(BaseListEntry, {
                              active: dummyActive(assessment.serviceId),
                              title: assessment.name,
                              icon: _unref(categoryIconStore).getIconUrl(assessment.categoryCodename).value,
                              fontSize: "20px",
                              blankIcon: ""
                            }, {
                              headerRightCol: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass(dummyActive(assessment.serviceId) ? 'v-interaction-selected-pointer' : '')
                                }, null, 2)
                              ]),
                              _: 2
                            }, 1032, ["active", "title", "icon"])
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      : (_openBlock(), _createBlock(InteractionsListEntry, {
                          key: 1,
                          interaction: assessment as IInteraction,
                          compact: compact(assessment),
                          assessments: true
                        }, null, 8, ["interaction", "compact"]))
                  ]))
                }), 128))
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})