import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardsSmallScreenError',
  setup(__props) {

const { translate } = use.helpers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseCard, { class: "v-screen-too-narrow-message" }, {
    content: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_unref(translate)('dashboards.screenTooNarrow')), 1)
    ]),
    _: 1
  }))
}
}

})