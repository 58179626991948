import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-expandable-text" }
const _hoisted_2 = {
  key: 0,
  class: "v-show-more-link"
}

import { computed, ref, watchEffect } from 'vue';
import use from '../../compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseExpandingText',
  props: {
  text: {
    type: String,
    required: true,
  },
  limitLength: {
    type: Number,
    default: 200,
  },
  linkStyle: {
    type: String,
    default: '#66cccc',
  },
  showMoreText: {
    type: String,
    default: null,
  },
  showLessText: {
    type: String,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const linkStyling = getLinkStyle();
const description = ref<string>();
const showMoreEnabled = ref<boolean>(false);
const showMoreButtonDisplayed = ref<boolean>(false);
const showMoreString = computed(() => (props.showMoreText ? props.showMoreText : translate('common.show_more')));
const showLessString = computed(() => (props.showLessText ? props.showLessText : translate('common.show_less')));

function toggleInfo() {
  showMoreEnabled.value = !showMoreEnabled.value;
}

function getLinkStyle() {
  return { color: props.linkStyle };
}

watchEffect(() => {
  if (props.text && props.text.length > props.limitLength) {
    description.value = showMoreEnabled.value === true ? props.text
      : `${props.text.slice(0, props.limitLength)}...`;
    showMoreButtonDisplayed.value = true;
  } else {
    description.value = props.text;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(description.value), 1),
    (showMoreButtonDisplayed.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (!showMoreEnabled.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleInfo())),
                style: _normalizeStyle(_unref(linkStyling))
              }, _toDisplayString(showMoreString.value), 5))
            : _createCommentVNode("", true),
          (showMoreEnabled.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleInfo())),
                style: _normalizeStyle(_unref(linkStyling))
              }, _toDisplayString(showLessString.value), 5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})