import { onUnmounted } from 'vue';

const hideElementsWithClassesNames = (elementClassesNames:string[]) => {
  elementClassesNames.forEach((el:string) => {
    const element = document.getElementsByClassName(el)[0] as HTMLElement;
    if (element) element.classList.add('hide-on-transition');
  });
  onUnmounted(() => {
    elementClassesNames.forEach((el:string) => {
      const element = document.getElementsByClassName(el)[0] as HTMLElement;
      if (element) element.classList.remove('hide-on-transition');
    });
  });
};

export default hideElementsWithClassesNames;
