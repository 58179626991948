import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-params-entry-info-col"
}
const _hoisted_2 = {
  key: 1,
  class: "v-params-entry-description-text sp-param-info"
}
const _hoisted_3 = { class: "v-params-entry-score-col" }

import use from '@/_shared/compositionApi';
import {
  computed, Ref, ref, toRef, watch,
} from 'vue';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import { shallowEqual } from '@/_shared/services/UseUtils';
import { CoercedChoice, CoercedValue, Parameter } from '../types/Parameter';
import ParameterScore from './ParameterScore.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleChoiceOption',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  option: {
    type: Object as () => CoercedChoice,
    required: false,
  },
  index: {
    type: Number,
    default: -1,
  },
  dropDown: {
    type: Boolean,
    required: false,
  },
  showCheckbox: {
    type: Boolean,
    default: true,
  },
},
  emits: ['toggleOpen'],
  setup(__props, { emit: __emit }) {

_useCssVars(_ctx => ({
  "ab22e822": ((nbrFlexCol.value*76)+'px')
}))

const props = __props;

const emit = __emit;

const { translate } = use.helpers();
const arrayValue = ref<CoercedChoice[]>([]);
const optionLabel = ref<string>(translate('common.select_from_list'));
const optionDescription = ref<string | null>(null);
const infoVisible = ref<boolean>(false);

const option = ref(props.option || null);

const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<CoercedValue>;
const optionValue = ref<number | null>(option?.value?.value as number);

if (option.value !== null
  && typeof option.value.label === 'string'
  && typeof option.value.value !== 'undefined') {
  optionLabel.value = option.value.label;
  optionValue.value = option.value.value as number;
  if (typeof option.value.description === 'string') {
    optionDescription.value = option.value.description;
  }
}
const nbrFlexCol = computed(() => (optionDescription.value || props.dropDown ? 2 : 1));
watch(coercedValue, (newValue) => {
  if (newValue && Array.isArray(newValue)) {
    arrayValue.value = newValue as CoercedChoice[];
  } else { arrayValue.value = []; }
}, { immediate: true });

function selectOptionAndCalculate() {
  if (props.parameter.isArray && props.option) {
    arrayValue.value = coercedValue.value as [] || [];
    const valueArray = arrayValue.value.map((item) => item.label);
    const optionIndex = valueArray.indexOf(props.option?.label);
    if (optionIndex === -1) {
      arrayValue.value.push(props.option);
    } else {
      arrayValue.value.splice(optionIndex, 1);
    }
    coercedValue.value = arrayValue.value;
  } else {
    coercedValue.value = props.option || null;
  }
  emit('toggleOpen');
}

function optionIsChecked() {
  if (props.parameter.isArray && props.option !== null) {
    if (Array.isArray(arrayValue.value)) {
      const arrayValues = arrayValue.value.map((val: CoercedChoice) => val.label);
      return arrayValues.includes(props.option?.label);
    }
  }
  return shallowEqual(coercedValue.value, props.option);
}

function toggleInfo() {
  infoVisible.value = !infoVisible.value;
}

const showScore = computed(() => props.parameter.valueType === 'number' && !(optionValue.value === null || optionValue.value === undefined));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-multiple-choices-option params-entry-row", {disabled: __props.readOnly}])
  }, [
    (optionDescription.value|| __props.dropDown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (optionDescription.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["icon icon-info sp-toggle-info", { 'active': infoVisible.value }]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleInfo()))
              }, null, 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["v-params-entry-main-col sp-choice-option", {checked: optionIsChecked()}]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (!__props.readOnly && selectOptionAndCalculate()))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(["v-params-entry-param-label", {'read-only': __props.readOnly}])
      }, _toDisplayString(optionLabel.value), 3),
      (__props.showCheckbox)
        ? (_openBlock(), _createBlock(BaseCheckbox, {
            key: 0,
            checked: optionIsChecked(),
            radio: __props.dropDown
          }, null, 8, ["checked", "radio"]))
        : _createCommentVNode("", true),
      (infoVisible.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(optionDescription.value), 1))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      (showScore.value)
        ? (_openBlock(), _createBlock(ParameterScore, {
            key: 0,
            parameter: __props.parameter,
            choice: optionValue.value
          }, null, 8, ["parameter", "choice"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})