import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-double-panel-left" }

import { computed, onMounted, onUnmounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLeftPanel',
  props: {
  show: Boolean,
  interactions: Boolean,
},
  emits: ['mounted'],
  setup(__props, { emit: __emit }) {

_useCssVars(_ctx => ({
  "5f9867d2": (backgroundColor.value),
  "31bea45b": (pointerEvent.value),
  "9fd266e0": (visibility.value)
}))

const props = __props;
const emit = __emit;
onMounted(() => {
  emit('mounted', true);
});
onUnmounted(() => {
  emit('mounted', false);
});
const visibility = computed(() => {
  if (props.show) return 'visible';
  return 'hidden';
});
const pointerEvent = computed(() => {
  if (props.show) return 'auto';
  return 'none';
});
// Todo  remove onec angular is out
//  a tweak to overcame problem  when vue an angular left-panels are both displayed (for short time)
//  this happen when transitioning for an angular state to vue state , the angular left panel is not yet completely destroyed
//  and the vue left panel is already rendered
const backgroundColor = computed(() => 'transparent');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div id=\"lp-action-bar\" class=\"v-lp-action-bar\" data-v-feda5c20></div><div id=\"lp-alarms\" data-v-feda5c20></div><div class=\"v-outer-container\" data-v-feda5c20><div class=\"v-inner-container\" data-v-feda5c20><div id=\"lp-container\" class=\"v-scrollable-container scrollable\" data-v-feda5c20><div id=\"lp-list-entry\" data-v-feda5c20></div><div id=\"lp-no-results\" data-v-feda5c20></div></div></div></div>", 3)
  ])))
}
}

})