import { defineStore } from 'pinia';
import {
  computed, ref, Ref,
} from 'vue';
import { formatDate, monthsSinceDate } from '@/_shared/helpers/useDateTimeHelper';
import { fetchClientStatusTerm, fetchClientStatusTerms } from '@/_shared/services/clientStateOverride';
import dataRetentionTable from '@/data_retention/services/dataRetentionTable';
import pendingDeletionsTable from '@/data_retention/services/pendingDeletionsTable';
import { DataRetentionReportRow, DataRetentionDeletedClientsRow, PendingDeletionRow } from '@/data_retention/types/DataRetentionTableData';
import dataRetentionDeletedClientsTable from '@/data_retention/services/dataRetentionDeletedClientsTable';
import use from '@/_shared/compositionApi';
import {
  getDataRetentionDeletedClients,
  getDataRetentionReport,
  getPendingDeletionsReport,
  restoreClient,
  softDeleteClients,
} from '../services/dataRetentionApi';
import { Client, DataRetention, OrganisationUnit } from '../types/dataRetention';
import { createTableLink } from '../types/tableLink';

const clientStateTerm = (status: string): string => (status ? fetchClientStatusTerm(status) : '');
const { translate } = use.helpers();

const checkValueForNull = (value: string): string => (value == null ? translate('data_retention.no_data') : value);
const activeStates = fetchClientStatusTerms(['admitted', 'onboarding', 'emergency_admission']);

const formatOrNA = (date: string): string => {
  if (date == null) { return checkValueForNull(date); }
  return formatDate(date);
};

const getMonthsSinceDate = (date: string): number | string => {
  if (date == null) { return checkValueForNull(date); }
  return monthsSinceDate(date);
};

const sortOnState = (orgUnit1 : OrganisationUnit, orgUnit2: OrganisationUnit) => {
  if (orgUnit1.state === 'admitted') {
    if (orgUnit2.state === 'admitted') return orgUnit1.orgUnitId - orgUnit2.orgUnitId;
    return -1;
  }
  if (orgUnit2.state === 'admitted') return 1;
  if (orgUnit1.state === 'left') {
    if (orgUnit2.state === 'left') return orgUnit1.orgUnitId - orgUnit2.orgUnitId;
    return 1;
  }
  if (orgUnit2.state === 'left') return -1;
  return orgUnit1.orgUnitId - orgUnit2.orgUnitId;
};

const processEntries = (clients: Client[]): DataRetentionReportRow[] => clients.map((client) => {
  const processedStatus: string[] = [];
  const processedOrgUnitName: string[] = [];
  const processedDurationOfLastState: number[] = [];
  const processedLastStateUpdate: string[] = [];
  const processedLastInteractionDate: string[] = [];
  const processedElapsedTime: number[] = [];
  client.organisationUnits.sort(sortOnState).forEach((orgUnit) => {
    processedStatus.push(clientStateTerm(orgUnit.state));
    processedOrgUnitName.push(orgUnit.orgUnitName);
    processedDurationOfLastState.push(getMonthsSinceDate(orgUnit.stateLastUpdatedDate) as number);
    processedLastStateUpdate.push(formatOrNA(orgUnit.stateLastUpdatedDate));
    processedLastInteractionDate.push(formatOrNA(orgUnit.lastInteractionDate));
    processedElapsedTime.push(getMonthsSinceDate(orgUnit.lastChangeDate) as number);
  });
  return {
    id: client.clientId,
    birthDate: formatOrNA(client.birthDate),
    clientId: client.clientId,
    clientLastUpdateDateNotFormatted: new Date(client.clientLastUpdateDate as string).valueOf(),
    clientLastUpdateDate: formatOrNA(client.clientLastUpdateDate),
    fullName: client.fullName,
    healthNumber: checkValueForNull(client.healthNumber),
    orgUnitName: processedOrgUnitName,
    state: processedStatus,
    lastStatusUpdate: processedLastStateUpdate,
    durationOfLastStatus: processedDurationOfLastState,
    lastInteractionDate: processedLastInteractionDate,
    elapsedTime: processedElapsedTime,
  } as DataRetentionReportRow;
});

const useDataRetentionStore = defineStore('dataRetention', () => {
  const clients: Ref<DataRetentionReportRow[]> = ref([]);
  const clientsTotalNumber: Ref<number> = ref(0);
  const loading: Ref<boolean> = ref(true);
  const pendingDeletionDetails: Ref<PendingDeletionRow[]> = ref([]);
  const numberClientFetched = computed(() => clients.value.length);
  const deletedClients: Ref<DataRetentionDeletedClientsRow[]> = ref([]);
  const pendingDeletionsCount = computed(() => pendingDeletionDetails.value.length);

  async function fetch(lastClientId = 0, pageSize = 100) {
    const response = await getDataRetentionReport(lastClientId, pageSize);
    if (Object.prototype.hasOwnProperty.call(response, 'code')) {
      clients.value = [];
      loading.value = false;
      return;
    }
    const processedEntries = processEntries((<DataRetention>response).clients as Client[]);
    clients.value = clients.value.concat(processedEntries);
    if (numberClientFetched.value <= pageSize) clientsTotalNumber.value = (<DataRetention>response).clientsTotalNumber as number;
    if (numberClientFetched.value < clientsTotalNumber.value) {
      await fetch(clients.value[numberClientFetched.value - 1].clientId);
    } else {
      loading.value = false;
    }
  }

  const getDataToDisplay = (lastElementIndex = 0, parPage = 10) : DataRetentionReportRow[] => (
    dataRetentionTableData.value.entries.slice(lastElementIndex, lastElementIndex + parPage) || []
  );
  const getHeaders = () => dataRetentionTableData.value.headers;

  async function softDelete(clientIds: number[]) {
    const response = await softDeleteClients(clientIds);
    if (response.status === 200) {
      clientsTotalNumber.value -= clientIds.length;
      clients.value = clients.value.filter((client) => !clientIds.includes(client.clientId));
      setTimeout(async () => { await fetchPendingDeletions(); }, 1000);
    }
    return response.status;
  }

  const dataRetentionTableData = computed(() => {
    const sortedClients = clients.value.slice().sort((a, b) => a.clientLastUpdateDateNotFormatted - b.clientLastUpdateDateNotFormatted);
    return dataRetentionTable(sortedClients);
  });

  const fetchDeletedClients = async () => {
    deletedClients.value = await getDataRetentionDeletedClients();
  };

  const dataRetentionDeletedClientsTableData = computed(() => dataRetentionDeletedClientsTable(deletedClients.value));

  const reverseDataRetentionTableOrder = () => {
    dataRetentionTableData.value.entries.reverse();
    changeElapseTimetHeaderSort();
  };

  const reverseDeletedClientsTableOrder = () => {
    dataRetentionDeletedClientsTableData.value.entries.reverse();
    changeDeletionDateSort();
  };

  const reversePendingDeletedTableOrder = () => {
    pendingDeletionsTableData.value.entries.reverse();
    changePlannedDeletionDateSort();
  };

  const changeElapseTimetHeaderSort = () => {
    const h = dataRetentionTableData.value.headers.find((header) => header.keyName === 'elapsedTime');
    if (h) {
      h.defaultSort = h.defaultSort === 'asc' ? 'desc' : 'asc';
    }
  };

  const changeDeletionDateSort = () => {
    const h = dataRetentionDeletedClientsTableData.value.headers.find((header) => header.keyName === 'deletionDate');
    if (h) {
      h.defaultSort = h.defaultSort === 'asc' ? 'desc' : 'asc';
    }
  };

  const changePlannedDeletionDateSort = () => {
    const h = pendingDeletionsTableData.value.headers.find((header) => header.keyName === 'deletionDate');
    if (h) {
      h.defaultSort = h.defaultSort === 'asc' ? 'desc' : 'asc';
    }
  };

  const reset = () => {
    clients.value = [];
    clientsTotalNumber.value = 0;
    loading.value = true;
    deletedClients.value = [];
  };

  async function fetchPendingDeletions() {
    const response = await getPendingDeletionsReport();
    pendingDeletionDetails.value = response?.map((entry: PendingDeletionRow) => ({
      ...entry,
      id: entry.clientId,
      requestActionedByNameAndId: createTableLink(
        `${entry.deletionRequestActionedBy} ${entry.deletionRequestActionedById}`,
        { name: 'carer.profile', params: { carerId: entry.deletionRequestedBy.personId } },
      ),
    }));
  }

  const pendingDeletionsTableData = computed(() => pendingDeletionsTable(pendingDeletionDetails.value));

  const fetchInitialData = () => {
    fetchPendingDeletions();
    fetch();
  };

  async function restoreSoftDeletedClient(clientId: number) {
    const restoredClient = await restoreClient(clientId);
    pendingDeletionDetails.value = pendingDeletionDetails.value.filter((entry) => entry.clientId !== clientId);
    clients.value = clients.value.concat(processEntries(restoredClient.clients));
    clientsTotalNumber.value += 1;
  }

  return {
    clientsTotalNumber,
    loading,
    fetchInitialData,
    fetch,
    getDataToDisplay,
    getHeaders,
    numberClientFetched,
    fetchDeletedClients,
    dataRetentionDeletedClientsTableData,
    reverseDeletedClientsTableOrder,
    deletedClients,
    reset,
    reverseDataRetentionTableOrder,
    pendingDeletionsCount,
    pendingDeletionsTableData,
    restoreSoftDeletedClient,
    fetchPendingDeletions,
    reversePendingDeletedTableOrder,
    activeStates,
    softDelete,
  };
});

export default useDataRetentionStore;
