import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-card-heading-inner" }
const _hoisted_2 = { class: "v-main-title" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseCardHeader',
  props: {
  title: {
    type: String,
  },
  icon: {
    type: String,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(`v-header-icon ${__props.icon}`)
    }, null, 2),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(__props.title), 1)
  ]))
}
}

})