import {
  CoercedMultiSelect,
  FileWrapper,
  Parameter,
  Medication,
  ShowIf,
  PictureWrapper,
  CoercedSkinInstance,
  CoercedEventPWSInstance,
} from '../types/Parameter';

function getFileOrSignatureValue(parameter: Parameter): string | null {
  if (parameter.tempFile || (parameter.coercedValue && (parameter.coercedValue as FileWrapper).base64File)) {
    return 'has_file';
  }
  return null;
}
function getPicturesCount(parameter: Parameter): string | null {
  const coercedValue = parameter.coercedValue as PictureWrapper;
  if (coercedValue?.pictures?.length > 0) {
    return coercedValue.pictures.length.toString();
  }
  return null;
}

export function getValues(parameter: Parameter): string[] {
  let values: Array<string | number | boolean | undefined | null> = [];
  switch (parameter.valueType) {
    case 'file':
    case 'signature':
      values.push(getFileOrSignatureValue(parameter));
      break;
    case 'picture':
      values.push(getPicturesCount(parameter));
      break;
    case 'datetime': {
      const date = new Date(<Date>parameter.coercedValue);
      if (date) {
        values.push(date.getTime().toString());
      }
      break;
    }
    case 'date': {
      const date = new Date(<Date>parameter.coercedValue);
      if (date) {
        values.push(date.toLocaleDateString('en-GB'));
      }
      break;
    }
    case 'nourish_instance': {
      if (parameter.config!.nourishInstanceType === 'skin') {
        const coercedValue = parameter.coercedValue as CoercedSkinInstance;
        if (coercedValue) {
          values.push(JSON.stringify(coercedValue));
        }
      }
      if (parameter.config!.nourishInstanceType === 'event_pws') {
        const coercedValue = parameter.coercedValue as CoercedEventPWSInstance;
        if (coercedValue) {
          values.push(JSON.stringify(coercedValue));
        }
      }
      break;
    }
    case 'medication':
      values.push((<Medication>parameter.coercedValue)?.name);
      break;
    default:
      if (Array.isArray(parameter.coercedValue)) {
        // Multiple choices multiple answers
        values = parameter.coercedValue.map((item) => item.value);
      } else if (parameter.coercedValue instanceof Object) {
        const value = (parameter.coercedValue as Record<string, string>)?.value;
        if (value) {
          // Multiple choices, one answer
          values.push(value);
        } else if ((<CoercedMultiSelect>parameter.coercedValue).values) {
          // Multi selector - coerced value values is an array of strings
          values = (<CoercedMultiSelect>parameter.coercedValue).values;
        }
      } else {
        values.push(parameter.coercedValue);
      }
  }
  return values.flatMap((value) => ((value !== null && value !== undefined) ? [value.toString()] : []));
}

function regexTest(regex: string, string: string): boolean {
  try {
    return new RegExp(regex).test(string);
  } catch {
    return false;
  }
}

export function testShowIf(showIfConfig: ShowIf): boolean {
  if (!showIfConfig.target) {
    return false;
  }
  const values = getValues(showIfConfig.target as Parameter);
  return regexTest(showIfConfig.regex, values.toString());
}
