import use from '@/_shared/compositionApi';
import { TopBarLinks } from '@/_shared/types/baseTopBarLink';
import useUserStore from '@/_shared/store/user';

import campaign from '@/_shared/assets/images/notifications/campaign.svg';
import configuration from '@/_shared/assets/icons/configuration.svg';

export default function getLinks(): TopBarLinks<false> {
  const { translate } = use.helpers();
  const { getFeatureToggle } = useUserStore();

  const menuItems = [
    {
      name: translate('roles.title'),
      icon: configuration,
      to: { path: '/roles', force: true },
      customizedActiveMatcher: true,
    },
    {
      name: translate('notifications.title'),
      icon: campaign,
      to: { path: '/notifications', force: true },
      customizedActiveMatcher: true,
      disabled: !getFeatureToggle('configurableEscalations'),
    },
  ];

  return menuItems.filter((l) => !l.disabled);
}
