import apiClient from '@/_shared/services/apiClient';
import { AnalyticsReport, ReportPage } from '@/_shared/types/analytics';

export interface ReportsApiResponse {
  reports: AnalyticsReport[],
  order: string|null,
}

export interface PagesApiResponse { pages: ReportPage[] }

export interface EmbedTokenResponse {
  token: string;
  tokenId: string;
  expiration: string|null;
}

export const getAllReports = async () => {
  try {
    const response = await apiClient
      .get<ReportsApiResponse>('/api/v2/me/analytics/all_reports');
    return _orderReports(response);
  } catch (error) {
    console.error(error.message);
    return [];
  }
};

export const getCurrentPersonReports = async () => {
  try {
    const response = await apiClient
      .get<ReportsApiResponse>('/api/v2/me/analytics/reports');
    return _orderReports(response);
  } catch (error) {
    console.error(error);
    return [];
  }
};

const _orderReports = (response: ReportsApiResponse) => {
  if (response.order === null) return response.reports;
  const orderingArray = response.order?.split(',').map((name) => name.trim().toLowerCase());
  const sanitizeDisplayName = (displayName: string) => displayName?.replace(/(?:(,| )+)/g, '_')?.toLowerCase();
  const lastPosition = response.reports.length;
  const indexInOrderArray = (name: string|undefined) => {
    if (!name) return lastPosition;
    const index = orderingArray.indexOf(sanitizeDisplayName(name));
    return index === -1 ? lastPosition : index;
  };

  return response.reports.sort((a, b) => (indexInOrderArray(a.displayName) - indexInOrderArray(b.displayName)));
};

export const getPages = async (workspaceId: string, reportId: string) => {
  try {
    const response = await apiClient
      .get<PagesApiResponse>(`/api/v2/me/analytics/${workspaceId}/reports/${reportId}/pages`);
    return response.pages;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEmbedToken = async (workspaceId: string, reportId: string) => {
  try {
    return apiClient
      .get<EmbedTokenResponse>(`/api/v2/me/analytics/${workspaceId}/reports/${reportId}/token`);
  } catch (error) {
    console.error(error);
    return {};
  }
};
// to be used only within unit tests
export const testable = {
  orderReports: _orderReports,
};
