import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-note-wrapper" }
const _hoisted_2 = { class: "v-note-header-wrapper" }
const _hoisted_3 = { class: "v-note-header" }
const _hoisted_4 = { class: "v-note-input-area" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNote',
  props: {
  title: {
    type: String,
    required: true,
  },
  iconName: {
    default: 'nr-icon-add-note',
  },
  iconCssStyle: {
    type: null || String,
    default: null,
  },
  cancelJustification: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BaseIcon, {
          name: __props.iconName,
          size: "sm",
          compact: "",
          "no-hover": "",
          class: "v-note-icon",
          customCss: __props.iconCssStyle
        }, null, 8, ["name", "customCss"]),
        _createElementVNode("span", {
          class: _normalizeClass(['v-note-header-text', { 'required-field': __props.cancelJustification }])
        }, _toDisplayString(__props.title), 3)
      ]),
      _renderSlot(_ctx.$slots, "headerRight")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "mainContent")
    ])
  ]))
}
}

})