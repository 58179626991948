import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "v-confirm-tos-container" }
const _hoisted_4 = { class: "v-confirm-tos-container" }
const _hoisted_5 = { class: "v-confirm-tos-container" }
const _hoisted_6 = { class: "v-confirm-tos-container" }
const _hoisted_7 = { class: "v-confirm-tos-container" }
const _hoisted_8 = {
  key: 0,
  class: "v-tos-error"
}

import { PropType, ref } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import use from '@/_shared/compositionApi';
import Integration from '../../types/integration';
import { downloadTos } from '../../helpers/useIntegrationsHelpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationNhsTosModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
},
  emits: ['continueTosEnableModal', 'cancelEnableModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { translate } = use.helpers();
const showConfirmTosError = ref<boolean>(false);
const tosCheckboxValue0 = ref<boolean>(false);
const tosCheckboxValue1 = ref<boolean>(false);
const tosCheckboxValue2 = ref<boolean>(false);
const tosCheckboxValue3 = ref<boolean>(false);
const tosCheckboxValue4 = ref<boolean>(false);
const tosCheckboxClicked = (number: number) => {
  showConfirmTosError.value = false;
  switch (number) {
    case (0):
      tosCheckboxValue0.value = !tosCheckboxValue0.value;
      break;
    case (1):
      tosCheckboxValue1.value = !tosCheckboxValue1.value;
      break;
    case (2):
      tosCheckboxValue2.value = !tosCheckboxValue2.value;
      break;
    case (3):
      tosCheckboxValue3.value = !tosCheckboxValue3.value;
      break;
    case (4):
      tosCheckboxValue4.value = !tosCheckboxValue4.value;
      break;
    default: break;
  }
};

const openIntegrationInfoPage = (integrationInfoUrl: string) => {
  window.open(integrationInfoUrl, '_blank');
};

const canContinueFromTos = () => (!props.integration.nationalDataSharingUrl || tosCheckboxValue0.value === true)
  && tosCheckboxValue1.value === true
  && tosCheckboxValue2.value === true
  && tosCheckboxValue3.value === true
  && (props.integration.integrationCodename !== 'london_care_records' || tosCheckboxValue4.value === true);

const emit = __emit;
const continueFromTos = () => {
  if (canContinueFromTos()) {
    emit('continueTosEnableModal');
    return;
  }
  showConfirmTosError.value = true;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    class: "v-integration-nhs-tos-alert",
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: continueFromTos,
    onCancelAlert: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('cancelEnableModal'))),
    confirmText: _unref(translate)('alert.continue')
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(translate)("integrations.integration_authorisation")), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.tos_text`)) + " ", 1),
          (__props.integration.nationalDataSharingUrl)
            ? (_openBlock(), _createElementBlock("u", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (openIntegrationInfoPage(__props.integration.nationalDataSharingUrl as string)))
              }, _toDisplayString(_unref(translate)('integrations.gp_connect.gp_connect')) + " " + _toDisplayString(_unref(translate)('integrations.gp_connect.national_data_sharing')) + ". ", 1))
            : (_openBlock(), _createElementBlock("u", {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(downloadTos)(__props.integration)))
              }, _toDisplayString(_unref(translate)('integrations.tos')) + ". ", 1))
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
        (__props.integration.nationalDataSharingUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, [
                _createElementVNode("b", null, [
                  _createTextVNode(_toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.signed`)) + " ", 1),
                  _createElementVNode("u", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (openIntegrationInfoPage(__props.integration.nationalDataSharingUrl as string)))
                  }, _toDisplayString(_unref(translate)('integrations.gp_connect.national_data_sharing')) + ". ", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(BaseCheckbox, {
                  light: "",
                  class: "v-confirm-tos0",
                  checked: tosCheckboxValue0.value,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (tosCheckboxClicked(0)))
                }, null, 8, ["checked"])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, [
            _createElementVNode("b", null, [
              _createTextVNode(_toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.accept_tos`)) + " ", 1),
              _createElementVNode("u", {
                class: "sp-accept-tos-link",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(downloadTos)(__props.integration)))
              }, _toDisplayString(_unref(translate)('integrations.tos')) + ". ", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(BaseCheckbox, {
              light: "",
              class: "v-confirm-tos1",
              checked: tosCheckboxValue1.value,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (tosCheckboxClicked(1)))
            }, null, 8, ["checked"])
          ])
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, [
            _createElementVNode("b", null, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.nhs_tos_nurses_text`)) + ".", 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(BaseCheckbox, {
              light: "",
              class: "v-confirm-tos2",
              checked: tosCheckboxValue2.value,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (tosCheckboxClicked(2)))
            }, null, 8, ["checked"])
          ])
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", null, [
          _createElementVNode("span", null, [
            _createElementVNode("b", null, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.nhs_tos_auth_text`)) + ".", 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(BaseCheckbox, {
              light: "",
              class: "v-confirm-tos3",
              checked: tosCheckboxValue3.value,
              onClick: _cache[7] || (_cache[7] = ($event: any) => (tosCheckboxClicked(3)))
            }, null, 8, ["checked"])
          ])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
        (__props.integration.integrationCodename === 'london_care_records')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, [
                  _createElementVNode("b", null, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.nhs_tos_training_text`)) + ".", 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(BaseCheckbox, {
                    light: "",
                    class: "v-confirm-tos4",
                    checked: tosCheckboxValue4.value,
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (tosCheckboxClicked(4)))
                  }, null, 8, ["checked"])
                ])
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      (showConfirmTosError.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(translate)('integrations.gp_connect.unaccepted_terms')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["confirmText"]))
}
}

})