import { ref } from 'vue';

const useBaseStore = () => {
  const initialized = ref(false);
  let initializing = false;

  const initialize = async (initialLoad: () => void) => {
    if (!initialized.value && !initializing) {
      initializing = true;
      await initialLoad();
      initialized.value = true;
      initializing = false;
    }
    if (!initialized.value && initializing) {
      while (initializing) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(resolve, 50);
        });
      }
    }
  };

  return {
    initialized, initialize,
  };
};

export default useBaseStore;
