
import {
  PropType, ref, watch, defineComponent,
} from 'vue';
import { Options, CompositeOption } from '@/_shared/types/baseSelect';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import { Client } from '@/_shared/store/clients';
import AssignModalEntry from './AssignModalEntry.vue';

export default defineComponent({
  components: {
    AssignModalEntry,
    BaseInput,
    BaseSelect,
  },
  props: {
    selectAllText: {
      type: String,
    },
    selectDropdownOptions: {
      type: Object as PropType<Options>,
      default: () => [] as Options,
    },
    searchInputPlaceholder: {
      type: String,
    },
    selectableEntries: {
      type: Object as PropType<Client[]>, // Can change this to add different types if we reuse this
      required: true,
    },
    primaryTextKey: {
      type: String,
      required: true,
    },
    secondaryTextKey: {
      type: String,
      required: true,
    },
    selectedToReassign: {
      type: Object as PropType<Map<number, number[]>>,
      required: true,
    },
    selectedEntriesCount: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:selectedToReassign', 'update:selectedEntriesCount'],
  setup(props, context) {
    const searchTerm = ref('');
    const filteredSelectableEntries = ref(props.selectableEntries);
    const selectedDropdownOption = ref<number>();
    const firstOption = props.selectDropdownOptions[0] as CompositeOption;
    if (firstOption && firstOption.value) { selectedDropdownOption.value = firstOption.value as number; }

    const allSelectedChecker = () => {
      if (props.selectedToReassign && props.selectedToReassign.get(selectedDropdownOption.value!)) {
        return filteredSelectableEntries.value.every((entry: Client) => props.selectedToReassign.get(selectedDropdownOption.value!)!.includes(entry.id));
      }
      return false;
    };

    const filteredSelectableEntriesIds = () => {
      if (!filteredSelectableEntries.value) { return []; }
      return filteredSelectableEntries.value.map((entry: Client) => entry.id);
    };

    const entryMatchesSearchAndSelect = (entry: Client) => entryMatchesSearchTerm(entry) && entryMatchesSelectedOption(entry);

    const entryMatchesSearchTerm = (entry: Client) => entry.name && entry.name.toLowerCase().includes(searchTerm.value.toLowerCase());

    const entryMatchesSelectedOption = (entry: Client) => selectedDropdownOption.value && entry.activeOrganisationUnitIds.includes(selectedDropdownOption.value);

    const entryPrimaryText = (entry: Client) => entry[props.primaryTextKey] as string || '';

    const entrySecondaryText = (entry: Client) => entry[props.secondaryTextKey] as string || '';

    const toggleSelectAll = () => {
      if (!selectedDropdownOption.value) { return; }
      if (allSelectedChecker()) {
        const { selectedToReassign } = props;
        selectedToReassign.set(selectedDropdownOption.value, []);
        context.emit('update:selectedToReassign', selectedToReassign);
      } else {
        const { selectedToReassign } = props;
        selectedToReassign.set(selectedDropdownOption.value, filteredSelectableEntriesIds());
        context.emit('update:selectedToReassign', selectedToReassign);
      }
      countSelectedEntries();
    };

    const toggleSelectEntry = (entryId: number) => {
      const selectedForOuId = props.selectedToReassign.get(selectedDropdownOption.value!);
      if (!selectedForOuId) { return; }
      if (selectedForOuId && selectedForOuId.includes(entryId)) {
        const { selectedToReassign } = props;
        selectedToReassign.set(selectedDropdownOption.value!, selectedForOuId.filter((id: number) => id !== entryId));
        context.emit('update:selectedToReassign', selectedToReassign);
      } else {
        selectedForOuId.push(entryId);
        const { selectedToReassign } = props;
        selectedToReassign.set(selectedDropdownOption.value!, selectedForOuId);
        context.emit('update:selectedToReassign', selectedToReassign);
      }
      countSelectedEntries();
    };

    const filterEntries = () => {
      if (!props.selectableEntries || !filteredSelectableEntries.value) { return; }
      if (!selectedDropdownOption.value) { filteredSelectableEntries.value = []; }
      filteredSelectableEntries.value = props.selectableEntries.filter(
        (entry: Client) => entryMatchesSearchAndSelect(entry),
      );
    };
    filterEntries();

    const countSelectedEntries = () => {
      let count = 0;
      props.selectedToReassign.forEach((value) => {
        count += value.length;
      });
      context.emit('update:selectedEntriesCount', count);
    };

    const entrySelected = ((entry: Client) => {
      if (
        selectedDropdownOption.value
        && selectedDropdownOption
        && props.selectedToReassign
        && props.selectedToReassign.get(selectedDropdownOption.value)) {
        return props.selectedToReassign.get(selectedDropdownOption.value)!.includes(entry.id);
      }
      return false;
    });

    watch(selectedDropdownOption, () => {
      filterEntries();
    });

    return {
      searchTerm,
      toggleSelectAll,
      toggleSelectEntry,
      filterEntries,
      filteredSelectableEntries,
      selectedDropdownOption,
      entryPrimaryText,
      entrySecondaryText,
      entrySelected,
    };
  },
});
