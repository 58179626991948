import { HealthProfile } from '@/_shared/types/healthProfile';
import { Datapoint, DataPointValue } from '@/_shared/store/dataPoints';
import useClientDashboardStore from '@/_shared/store/clientDashboards';

const CATEGORIES: Record<string, string[]> = {
  abilities: [
    'mobilityAssistiveAids', 'typeCommunication', 'languageSpoken', 'visualAids',
    'visualImpairment', 'hearingImpairment', 'hearingAids', 'unspecifiedAbilities',
  ],
  allergies: [
    'foodAllergies', 'environmentalAllergies', 'medicationAllergies', 'unspecifiedAllergies',
  ],
  diet: [
    'nutritionRoute', 'dietType', 'religiousDiet', 'foodTexture', 'foodFluid',
    'nutritionAids', 'oralProstheticDevices', 'unspecifiedDiet',
  ],
};
const FALSEY_STRINGS = ['no', 'n/a', 'not applicable', 'none known', 'not recorded', 'none', '', 'false'];

function dataPointHasValue(value: DataPointValue| boolean | number | undefined) {
  if (!value) { return false; }
  if (varIsType(value, '[object Array]')) { return (value as string[]).length > 0; }
  if (varIsType(value, '[object Object]')) { return Object.keys(value).length > 0; }
  if (varIsType(value, '[object String]')) { return !(stringIsFalsey((value as string).trim()) || numberIsFalsey(value as string)); }
  if (varIsType(value, '[object Boolean]')) { return value; }
  if (varIsType(value, '[object Number]')) { return value > 0; }
  return true;
  function numberIsFalsey(val: string) {
    if (!Number.isNaN(val)) {
      return +val <= 0;
    }
    return false;
  }

  function stringIsFalsey(val: string) {
    val = val.toLowerCase();
    return FALSEY_STRINGS.some((v) => v === val);
  }

  function varIsType(val: unknown, type: string) {
    return Object.prototype.toString.call(val) === type;
  }
}

function getCategoryBadges(codenamesWithValues: string[]) {
  const badgeCategories = Object.keys(CATEGORIES);
  const categoryBadges: string[] = [];

  badgeCategories.forEach((category) => {
    if (anyInBadgeCategory(category, codenamesWithValues)) { categoryBadges.push(category); }
  });
  return categoryBadges;

  function anyInBadgeCategory(category: string, codenames: string[]) {
    if (!category || !codenames?.length || !Object.keys(CATEGORIES).includes(category)) {
      return false;
    }
    return CATEGORIES[category].some((i: string) => codenames.includes(i));
  }
}

function setCategories() {
  useClientDashboardStore().initialize();
  const { getCategoryDataPoints } = useClientDashboardStore();
  ['abilities', 'allergies', 'diet'].forEach((category) => {
    const remoteCategoryDataPoints = getCategoryDataPoints('critical_information', category).value;
    if (remoteCategoryDataPoints) {
      CATEGORIES[category] = remoteCategoryDataPoints;
    }
  });
}

function getClientBadges(clientHealthProfile: HealthProfile, dataPoints: Record<string, Datapoint>) {
  setCategories();
  const codenamesWithValues = Object.keys(dataPoints).reduce((validDataPoints: string[], codename: string) => {
    if (dataPoints && dataPointHasValue(dataPoints[codename].value)) {
      validDataPoints.push(codename);
    }
    return validDataPoints;
  }, []);

  const newBadges = getCategoryBadges(codenamesWithValues);

  if (hasDolsBadge()) { newBadges.push('dols'); }
  if (hasAwiBadge()) { newBadges.push('awi'); }
  if (hasDnarBadge()) { newBadges.push('dnar'); }
  if (hasCovidBadge()) { newBadges.push('c19'); }
  if (hasRespectBadge()) { newBadges.push('respect'); }

  return newBadges;

  function dataPointsHaveBadge(badgeName: string) {
    const badgeKey = `${badgeName}InPlace`;
    return dataPoints
            && dataPoints[badgeKey]
            && dataPoints[badgeKey].value
            && (dataPoints[badgeKey].value as {value: string, label: string}).value
            && ['yes', 'Yes', 'applied', 'Applied'].includes((dataPoints[badgeKey].value as {value: string, label: string}).value);
  }

  function hasDolsBadge() {
    const dataPointsHasDols = dataPointsHaveBadge('dols');
    const dataPointsHasAwi = dataPointsHaveBadge('awi');
    return dataPointsHasDols && !dataPointsHasAwi;
  }

  function hasAwiBadge() {
    const dataPointsHasAwi = dataPointsHaveBadge('awi');
    return dataPointsHasAwi;
  }

  function hasDnarBadge() {
    const dataPointsHasDnar = dataPointsHaveBadge('dnacpr');
    return dataPointsHasDnar;
  }

  function hasCovidBadge() {
    const healthProfileHasCovid = clientHealthProfile && clientHealthProfile.covid19;
    return healthProfileHasCovid;
  }

  function hasRespectBadge() {
    const dataPointsHasRespect = dataPointsHaveBadge('respect');
    return dataPointsHasRespect;
  }
}

const useClientBadgesHelper = () => ({ getClientBadges });

export default useClientBadgesHelper;
