export default class LoadingStatus {
  private _enabled = true;

  get enabled() { return this._enabled; }

  set enabled(val) {
    if (!val) {
      if (this._requestCount > 0) { window.loadingCounter -= 1; }
      LoadingStatus.hideLoader();
      this._requestCount = 0;
    }
    this._enabled = val;
  }

  get disabled() { return !this._enabled; }

  private _requestCount = 0;

  requestStarted() {
    if (this.enabled) {
      if (this._requestCount === 0) { window.loadingCounter = window.loadingCounter + 1 || 1; }
      this._requestCount += 1;
      LoadingStatus.showLoader();
    }
  }

  requestFinished() {
    if (this.enabled) {
      this._requestCount -= 1;
      if (this._requestCount < 1) {
        window.loadingCounter -= 1;
        this._requestCount = 0;
        LoadingStatus.hideLoader();
      }
    }
  }

  // this is defined by Rails project / Angular
  // TODO re-implement
  // must be also working in standalone Vue web app
  static showLoader() {
    document.body.classList.add('loading');
  }

  static hideLoader() {
    if (!window.loadingCounter) document.body.classList.remove('loading');
  }
}
