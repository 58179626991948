import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "v-table-wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

import {
  onBeforeMount, PropType, ref,
} from 'vue';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import {
  BaseTable, BaseTooltip, BaseModal, BaseIcon,
} from '@/_shared/components';
import BaseTableRow from '@/_shared/types/baseTableRow';
import { Client, ClientInformation } from '@/_shared/store/clients';
import BaseTableHeader from '@/_shared/types/baseTableHeader';
import { fetchClientInformationForOu } from '../../services/integrationsApi';
import Integration from '../../types/integration';

interface ClientTableRow extends BaseTableRow {
  patient: string[],
  nhs_number: string,
  dob: string,
  integration_id: string,
  errors: string[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationInformationModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const modalTitle = `${props.ou.name} ${translate('integrations.info_modal_title')}`;
const headers = ref<BaseTableHeader[]>([]);
const clientsForTable = ref<ClientTableRow[]>([]);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
  if (props.ou.id) {
    const encryptedIntegrationIdEnabled = useUserStore().getFeatureToggle('encryptedIntegrationId');
    let respClients = await fetchClientInformationForOu(props.integration, props.ou.id);
    // Filter all the ones with no error messages unless integration id selected so we don't have too much hacking around to do in the back end
    respClients = respClients.filter((client) => client.integrationId || (client.errorMessages?.length ?? 0) > 0);
    // If we are only checking duplicates
    respClients.forEach((client, i) => clientsForTable.value.push({
      id: i,
      archived: false,
      patient: getNameUrl(client),
      nhs_number: client.healthNumber ? client.healthNumber : '',
      dob: client.birthDate ? client.birthDate : '',
      integration_id: client.integrationId,
      errors: errorReason(client),
    }));
    clientsForTable.value.sort((a, b) => compareStrings(a.patient[0], b.patient[0]));
    headers.value = [
      {
        keyName: 'patient',
        displayName: `${window.currentOrganisationUnit.client_term}`,
        type: 'string',
      },
      {
        keyName: 'nhs_number',
        displayName: `${translate('client.health_number')}`,
        type: 'string',
      },
      {
        keyName: 'dob',
        displayName: `${translate('client.birth_date')}`,
        type: 'string',
      },
    ];
    if (encryptedIntegrationIdEnabled === true) {
      headers.value.push(
        {
          keyName: 'integration_id',
          displayName: `${translate('integrations.integration_id')}`,
          type: 'string',
        },
      );
    }
    headers.value.push({
      keyName: 'errors',
      displayName: `${translate('integrations.errors')}`,
      type: 'string',
    });
  }
});

function getNameUrl(client: Client) {
  const clientUrl = `${window.location.origin}${window.location.pathname}#/clients/${client.id}/profile/sections/general_info`;
  return [`${client.firstName} ${client.lastName}`, clientUrl];
}

function errorReason(client: ClientInformation) {
  const messages: string[] = [];
  if (client.errorMessages?.length) {
    client.errorMessages.map((message) => messages.push(translate(message)));
  }
  return messages;
}

const copyIntegrationId = (integrationId: string) => {
  navigator.clipboard.writeText(integrationId);
};

function compareStrings(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  // eslint-disable-next-line
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseModal), {
    title: modalTitle,
    footer: false,
    width: 800,
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
    icon: "round-icon round-icon-sm temp-icon-size-reduce nr-icon-bell"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(BaseTable), {
          headers: headers.value,
          items: clientsForTable.value,
          "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((clientsForTable).value = $event)),
          editable: false,
          hasSlot: ""
        }, {
          default: _withCtx((slotProps) => [
            (slotProps.key === 'patient' && slotProps)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: (slotProps.item as Array<string>)[1]
                }, _toDisplayString((slotProps.item as Array<string>)[0]), 9, _hoisted_2))
              : (slotProps.key === 'integration_id')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    (slotProps.item && slotProps.item !== '')
                      ? (_openBlock(), _createBlock(_unref(BaseTooltip), {
                          key: 0,
                          position: "top",
                          tooltipText: _unref(translate)('integrations.integration_id_copy')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(BaseIcon), {
                              compact: "",
                              class: "v-no-margin v-integration-id",
                              name: "copy",
                              onClick: ($event: any) => (copyIntegrationId(slotProps.item as string))
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1032, ["tooltipText"]))
                      : _createCommentVNode("", true)
                  ]))
                : (slotProps.key === 'errors')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.item, (message) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: message as string
                        }, [
                          _createTextVNode(_toDisplayString(message), 1),
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(slotProps.item), 1))
          ]),
          _: 1
        }, 8, ["headers", "items"])
      ])
    ]),
    _: 1
  }))
}
}

})