import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sp-responsible-carer-title" }
const _hoisted_2 = { class: "closer-name sp-responsible-carer" }
const _hoisted_3 = { class: "sp-closed-by" }
const _hoisted_4 = { class: "closer-name sp-closed-by" }
const _hoisted_5 = { class: "sp-last-edited-by" }
const _hoisted_6 = { class: "closer-name sp-last-edited-by" }

import { computed, PropType } from 'vue';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';
import { Person, personStore } from '@/_shared/store/people';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import InteractionSection from './InteractionSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClosedBy',
  props: {
  responsiblePersonIds: {
    type: Array as PropType<number[]>,
    required: true,
  },
  isEditing: {
    type: Boolean,
  },
  closerId: {
    type: Number as PropType<number | null>,
  },
  lastEditedId: {
    type: Number as PropType<number | null>,
  },
},
  setup(__props) {

const { translate } = use.helpers();

const props = __props;

const { featureToggles } = storeToRefs(useUserStore());

const responsibleCarers = computed<Person[]>(() => personStore
  .people(props.responsiblePersonIds)
  .sort((a, b) => {
    if (!a?.name) return -1;
    if (!b?.name) return 1;
    return (a.name).localeCompare(b.name);
  }));

const closedBy = ((featureToggles.value.editingResponsibleCarersForReportsAndInteractions && !props.isEditing && props?.closerId) ? personStore.people([props?.closerId])[0] : null);

const lastEditedBy = ((featureToggles.value.editingResponsibleCarersForReportsAndInteractions && !props.isEditing && props?.lastEditedId) ? personStore.people([props?.lastEditedId])[0] : null);

const showResponsibleCarersTitle = computed(() => featureToggles.value.editingResponsibleCarersForReportsAndInteractions && responsibleCarers.value.length);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showResponsibleCarersTitle.value)
      ? (_openBlock(), _createBlock(InteractionSection, {
          key: 0,
          class: "interaction-section title"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(translate)(`timeline.interaction.responsiblePeople`)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(responsibleCarers.value, (responsibleCarer) => {
      return (_openBlock(), _createBlock(InteractionSection, {
        key: responsibleCarer.id,
        class: "interaction-section"
      }, {
        default: _withCtx(() => [
          _createVNode(BaseAvatar, {
            photo: responsibleCarer.photo,
            size: "small"
          }, null, 8, ["photo"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(responsibleCarer.name), 1)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_unref(closedBy))
      ? (_openBlock(), _createBlock(InteractionSection, {
          key: 1,
          class: "interaction-section title"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)(`timeline.interaction.closedBy`)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(closedBy))
      ? (_openBlock(), _createBlock(InteractionSection, {
          key: 2,
          class: "interaction-section"
        }, {
          default: _withCtx(() => [
            _createVNode(BaseAvatar, {
              photo: _unref(closedBy).photo,
              size: "small"
            }, null, 8, ["photo"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(closedBy).name), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(lastEditedBy))
      ? (_openBlock(), _createBlock(InteractionSection, {
          key: 3,
          class: "interaction-section title"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(translate)(`timeline.interaction.lastEditedBy`)), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(lastEditedBy))
      ? (_openBlock(), _createBlock(InteractionSection, {
          key: 4,
          class: "interaction-section"
        }, {
          default: _withCtx(() => [
            _createVNode(BaseAvatar, {
              photo: _unref(lastEditedBy).photo,
              size: "small"
            }, null, 8, ["photo"]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(lastEditedBy).name), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})