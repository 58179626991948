import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-text" }
const _hoisted_2 = { class: "final-popup-text" }
const _hoisted_3 = { class: "confirmation-bullet" }
const _hoisted_4 = { class: "bullet-text" }
const _hoisted_5 = { class: "confirmation-bullet" }
const _hoisted_6 = { class: "bullet-text" }
const _hoisted_7 = { class: "confirmation-bullet" }
const _hoisted_8 = { class: "bullet-text" }

import use from '@/_shared/compositionApi';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import { fetchGpConnectTosFile } from '../services/GpConnectApi';
import { redirectToProfile } from '../../helpers/medicalRecordFunctions';


export default /*@__PURE__*/_defineComponent({
  __name: 'GpConnectTos',
  props: {
    clientName: {}
  },
  emits: ['confirmTos'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;
const { translate } = use.helpers();

// const confirmationOne = ref(false)
// const confirmationTwo = ref(false)
// const confirmationThree = ref(false)

// const valid = computed(() => {
//   return confirmationOne.value && confirmationTwo.value && confirmationThree.value
// })

// Cuts the end off of the URL to redirect to the client profile
// function getProfileUrl() {
//  const url = window.location.href;
//  const urlSegments = url.split('/');
//  const urlProfileSegments = urlSegments.slice(0, 7);
//  return urlProfileSegments.join('/');
// }

function downloadNourishTosFile() {
  fetchGpConnectTosFile();
}

function confirmTos() {
  window.localStorage.setItem('v-gp-connect-tos-confirmed', 'true');
  emit('confirmTos', true);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    dangerAlert: true,
    icon: "bell",
    onConfirmAlert: confirmTos,
    onCancelAlert: _unref(redirectToProfile),
    wide: ""
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode("Important")
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_1")) + " " + _toDisplayString(_ctx.clientName) + ".", 1),
        _createElementVNode("p", null, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_2")), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_3")), 1)
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_4")) + ":", 1),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_5")) + " ", 1),
              _createElementVNode("span", {
                class: "v-tos",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadNourishTosFile()))
              }, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_5_link")), 1)
            ])
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_6")), 1)
          ])
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(translate)("gp_connect.tos_profile_popup_text_7")), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onCancelAlert"]))
}
}

})