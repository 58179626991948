
import { defineComponent } from 'vue';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import use from '@/_shared/compositionApi';
import { GpConnectResponse } from '../types/gp_connect';

export default defineComponent({
  components: {
    BaseCard,
  },
  props: {
    response: {
      type: Object as () => GpConnectResponse,
      required: true,
    },
    errorType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { toast, ToastType, translate } = use.helpers();
    if (props.errorType === 'error_message' && props.response.errorMessage && props.response.errorMessage.toast && props.response.errorMessage.toast !== '') {
      toast(props.response.errorMessage.toast, ToastType.Danger);
    }
    return {
      translate,
    };
  },
});
