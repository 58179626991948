import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import InteractionsListEntryGeneric from '@/timeline/components/InteractionListEntry/InteractionsListEntryGeneric.vue';
import IInteraction from '@/timeline/types/IInteraction';
import { computed, PropType } from 'vue';
import {
  interactionLink,
} from '@/timeline/helper/interactionsHelper';
import { providedServicesStore } from '@/_shared/store/providedServices';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
import { BaseIcon } from '@/_shared/components';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceInteractionListEntry',
  props: {
  interaction: {
    type: Object as PropType<IInteraction>,
    required: true,
  },
},
  setup(__props) {


const props = __props;
const route = useRoute();
const { getIconUrl } = useCategoryIconStore();
const icon = computed(() => {
  const categoryCodename = providedServicesStore.categoryCodename(props.interaction?.providedServiceId);
  return getIconUrl(categoryCodename.value)?.value;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InteractionsListEntryGeneric, {
    interaction: __props.interaction,
    link: _unref(interactionLink)(_unref(route), __props.interaction)
  }, {
    icon: _withCtx(() => [
      _createVNode(_unref(BaseIcon), {
        name: icon.value,
        fontSize: "36px",
        noHover: "",
        compact: "",
        width: "36px",
        height: "36px",
        backgroundColor: "white",
        foregroundColor: "black",
        foregroundHoverColor: "white",
        class: "align"
      }, null, 8, ["name"])
    ]),
    _: 1
  }, 8, ["interaction", "link"]))
}
}

})