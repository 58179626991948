import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { defineStore } from 'pinia';
import { StorageSerializers, RemovableRef, useStorage } from '@vueuse/core';

export interface AuthApiResponse {
  organisationUnits: OrganisationUnit[];
  person: {
    language: 'en' | 'pt' | 'za',
    authenticationToken: string,
    id: number,
    firstName: string,
    lastName: string,
    // email: string
  }
}

// TODO test logout behaviour
const useUserAuthStore = defineStore('userAuth', () => {
  const token = useStorage('token', '', sessionStorage); // returns Ref<string>
  const ouId: RemovableRef<null|number> = useStorage('ouId', null, sessionStorage);
  const currentOrganisationUnit: RemovableRef<OrganisationUnit|null> = useStorage('currentOrganisationUnit', null, sessionStorage, { serializer: StorageSerializers.object });
  const authLogout = () => {
    token.value = '';
    ouId.value = null;
    currentOrganisationUnit.value = null;
    window.currentOrganisationUnit = {};
    // TODO in standalone Vue ???
    // useNourishStore().invalidate()
  };

  const authLogin = (authResponse: AuthApiResponse) => {
    // HACK just for local testing
    try {
      const ous = authResponse.organisationUnits;

      const { authenticationToken } = authResponse.person;

      token.value = authenticationToken;

      currentOrganisationUnit.value = (ous && ous.length) ? ous[0] : undefined;
      ouId.value = currentOrganisationUnit.value?.id;
      window.currentOrganisationUnit = currentOrganisationUnit.value as OrganisationUnit;
      // TODO set this up throug nourish store ?
      // i18n.global.locale.value = language;
      // setGlobalTerms(currentOrganisationUnit as OrganisationUnit);
      // TODO OrgUnit selector
      return true;
    } catch {
      authLogout();
      return false;
    }
  };

  const loggedIn = () => token.value && ouId.value;

  return {
    token,
    ouId,
    authLogin,
    authLogout,
    loggedIn,
    currentOrganisationUnit,
  };
});

export default useUserAuthStore;
