import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = {
  key: 0,
  class: "v-instance-group"
}
const _hoisted_3 = {
  key: 3,
  class: "v-instance-group"
}

import {
  computed, ref, onMounted, onUnmounted, Ref, PropType, watchEffect,
} from 'vue';
import BodyMapFilter from '@/timeline/components/BodyMap/BodyMapFilter/BodyMapFilter.vue';
import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import ShowLocations from '@/timeline/components/BodyMap/ShowLocations.vue';
import BodyMapListEntry from '@/timeline/components/BodyMap/BodyMapListEntry.vue';
import { clientStore } from '@/_shared/store/clients';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import use from '@/_shared/compositionApi';
import { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapTimeline',
  props: {
  showBodyMapFilter: {
    type: Boolean,
  },
  searchTerm: {
    type: String,
    default: '',
  },
  clientId: {
    type: Number,
    required: true,
  },
  sideToShow: {
    type: String,
    default: '',
  },
  filterState: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
  filterSubType: {
    type: Object as PropType<DropdownFilterOption>,
    default() {
      return { name: '', codename: '' };
    },
  },
  listOnly: {
    type: Boolean,
    optional: true,
    default: false,
  },
},
  emits: ['selectInstance', 'stateChange', 'subTypeChange', 'sideChange'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const emit = __emit;

const skinInstances = computed(() => clientStore.skinInstances(props.clientId!).value);

const sectionClass = (state: string) => `v-${state.toLowerCase()}`;

const showLocations = ref(true);

const dropdownState = ref(props.filterState);
const dropdownSubType = ref(props.filterSubType);
const handleFilterChange = (option: {filter: string, value: DropdownFilterOption}) => {
  switch (option.filter) {
    case 'state':
      dropdownState.value = option.value;
      emit('stateChange', option.value);
      break;
    case 'subType':
      dropdownSubType.value = option.value;
      emit('subTypeChange', option.value);
      break;
    default:
      break;
  }
};
const {
  getInstanceLocations, defaultSide, allInstances,
} = useBodyMapLocationsHelper().forSkinInstances(skinInstances);
const showInstanceForSide = (side: string, instanceSide: string) => side === 'all' || [side, 'both'].includes(instanceSide);
const showInstanceForState = (instance: SkinInstance, state = dropdownState.value) => state.codename === 'all' || (state.codename === 'new&active' && ['new', 'active'].includes(instance.state)) || state.codename === instance.state;
const showInstanceForSubType = (instance: SkinInstance, subType = dropdownSubType.value) => subType.codename === 'all' || subType.codename === instance.subType;
const showInstanceForFilter = (instance: SkinInstance, state = dropdownState.value, subType = dropdownSubType.value) => showInstanceForState(instance, state) && showInstanceForSubType(instance, subType);
const showInstanceForSearchTerm = (instance: SkinInstance) => {
  let instanceSearchable = instance.reference;
  if (instance.locationInfo) {
    if (instance.locationInfo.count) instanceSearchable += ` ${instance.locationInfo.count.toString()}`;
    instanceSearchable += ` ${translate(`datasets.${instance.metadata.location}`)}`;
    instanceSearchable += ` ${translate(`datasets.${instance.metadata.specificType}`)}`;
  }
  return instanceSearchable.toLowerCase().includes(props.searchTerm.toLowerCase());
};
const toggleShowLocations = () => {
  if (!allInstances.value.length) {
    showLocations.value = false;
    return;
  }
  showLocations.value = !showLocations.value;
};

const serviceFilteringStore = useServiceFilteringStore();
const { bodyMapTimelineMounted } = storeToRefs(serviceFilteringStore);

// todo need to be moved to plus button / or just hide it
// todo   remove code from onmounted , to be able to use keepAlive -->
onMounted(async () => {
  // If we're coming from listOnly mode we don't want to trigger this.
  if (!props.listOnly) {
    bodyMapTimelineMounted.value = true;
  }
  // if (route.name === 'client.timeline.service_selector' && !newSkinInstanceServices.value.length) {
  //   await redirectToTimeline(router, route);
  // }
});

onUnmounted(() => {
  // If we're coming from listOnly mode we don't want to trigger this.
  if (!props.listOnly) {
    bodyMapTimelineMounted.value = false;
  }
});

const currentSide = ref(props.sideToShow.length ? props.sideToShow : defaultSide.value);
const handleSideChange = (newSide: string) => {
  currentSide.value = newSide;
  emit('sideChange', newSide);
};

const filterInstances = (instancesAndState: {state: string, instances: SkinInstance[]}[], filterFunction: (skinInstance: SkinInstance) => boolean) => {
  const instances = [] as {state: string, instances: SkinInstance[]}[];
  instancesAndState.forEach((instancesWithState) => {
    const stateInstances = instancesWithState.instances.filter((instance) => filterFunction(instance));
    instances.push({ state: instancesWithState.state, instances: stateInstances });
  });
  return instances;
};
const currentInstances = computed(() => {
  const filterFunction = (skinInstance: SkinInstance) => {
    const instanceLocation = skinInstance.locationInfo?.side;
    if (!instanceLocation) {
      return currentSide.value === 'all';
    }
    return showInstanceForSide(currentSide.value, instanceLocation) && showInstanceForSearchTerm(skinInstance);
  };
  return filterInstances(allInstances.value, filterFunction);
});
const filteredInstances = computed(() => {
  const filterFunction = (skinInstance: SkinInstance) => showInstanceForFilter(skinInstance);
  return filterInstances(currentInstances.value, filterFunction);
});

const allSideFilters = ref([] as DropdownFilterOption[]);
watchEffect(() => {
  const deepCopy = (bodyMapFilterOption: DropdownFilterOption) => JSON.parse(JSON.stringify(bodyMapFilterOption));
  if (currentSide.value !== 'all') return;
  allSideFilters.value = [deepCopy(dropdownState.value), deepCopy(dropdownSubType.value)];
});
const instancesCount = computed(() => {
  const filterFunction = (skinInstance: SkinInstance) => showInstanceForFilter(skinInstance, allSideFilters.value[0], allSideFilters.value[1]) && showInstanceForSearchTerm(skinInstance);
  const instancesToCount = filterInstances(allInstances.value, filterFunction);
  let instanceCount = 0;
  instancesToCount.forEach((instancesAndState) => {
    instanceCount += instancesAndState.instances.length;
  });
  return instanceCount;
});
const filteredInstanceLocations = computed(() => {
  let allSkinInstances: SkinInstance[] = [];
  filteredInstances.value.forEach((instancesAndState) => {
    allSkinInstances = allSkinInstances.concat(instancesAndState.instances);
  });

  return getInstanceLocations(allSkinInstances);
});

const listOnlyFilteredSkinInstances = computed(() => {
  const newInstances: SkinInstance[] = [];
  const missedInteractionsInstances: SkinInstance[] = [];
  const cancelledInteractionsInstances: SkinInstance[] = [];

  allInstances.value.forEach((instanceGroup) => {
    instanceGroup.instances.forEach((instance) => {
      if (instance.state === 'new') {
        //   sorted by instance creation date
        newInstances.push(instance);
      } else if (instance?.missedInteractions?.length) {
        //   active wounds with missed interactions (planned in the past)
        missedInteractionsInstances.push(instance);
      } else if (instance.state === 'active' && (
        !instance.interactions.some((interaction) => interaction.state === 'planned')
      )) {
        //   active wounds with no planned interactions (1 or more cancelled interactions and no planned)
        cancelledInteractionsInstances.push(instance);
      }
    });
  });
  // sorted by instance creation date
  newInstances.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  missedInteractionsInstances.sort((a, b) => {
    const aPlanned = a.interactions.filter((i) => i.state === 'planned');
    const bPlanned = b.interactions.filter((i) => i.state === 'planned');
    const aEarliest = aPlanned.length ? new Date(Math.min(...aPlanned.map((i) => new Date(i.finishAt ? i.finishAt : '').getTime()))).getTime() : new Date().getTime();
    const bEarliest = bPlanned.length ? new Date(Math.min(...bPlanned.map((i) => new Date(i.finishAt ? i.finishAt : '').getTime()))).getTime() : new Date().getTime();
    return aEarliest - bEarliest;
  });
  // sorted by wound creation date
  cancelledInteractionsInstances.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  return [...newInstances, ...missedInteractionsInstances, ...cancelledInteractionsInstances];
});
const showListOnlySection = computed(() => (props.listOnly && listOnlyFilteredSkinInstances.value.length > 0));
const filterDiv : Ref<HTMLElement|null> = ref(null);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      ref_key: "filterDiv",
      ref: filterDiv,
      class: "v-body-map-filter-container"
    }, [
      _createVNode(BodyMapFilter, {
        skinInstances: currentInstances.value,
        currentState: dropdownState.value,
        currentSubType: dropdownSubType.value,
        onFilterChange: handleFilterChange
      }, null, 8, ["skinInstances", "currentState", "currentSubType"])
    ], 512), [
      [_vShow, __props.showBodyMapFilter]
    ]),
    (!__props.listOnly)
      ? (_openBlock(), _createBlock(ShowLocations, {
          key: 0,
          onClick: toggleShowLocations
        }))
      : _createCommentVNode("", true),
    (!__props.listOnly && filterDiv.value)
      ? (_openBlock(), _createBlock(BodyMapImage, {
          key: 1,
          showBodyMapImage: showLocations.value,
          "left-panel": true,
          "instance-locations": filteredInstanceLocations.value,
          "side-to-show": currentSide.value,
          "show-side-selector": __props.showBodyMapFilter,
          teleportFilterTo: filterDiv.value,
          onSideChanged: handleSideChange,
          "instance-count": instancesCount.value
        }, null, 8, ["showBodyMapImage", "instance-locations", "side-to-show", "show-side-selector", "teleportFilterTo", "instance-count"]))
      : _createCommentVNode("", true),
    (!__props.listOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredInstances.value, (instanceGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              key: instanceGroup.state
            }, [
              (instanceGroup.instances.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h4", {
                      class: _normalizeClass(["v-body-map-section", sectionClass(instanceGroup.state)])
                    }, _toDisplayString(instanceGroup.state), 3),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(instanceGroup.instances, (instance) => {
                      return (_openBlock(), _createBlock(BodyMapListEntry, {
                        key: instance.id,
                        reference: true,
                        skinInstance: instance,
                        sideToShow: currentSide.value,
                        showLocationIcon: true,
                        onSelectInstance: ($event: any) => (emit('selectInstance', instance))
                      }, null, 8, ["skinInstance", "sideToShow", "onSelectInstance"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (showListOnlySection.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listOnlyFilteredSkinInstances.value, (instance) => {
              return (_openBlock(), _createBlock(BodyMapListEntry, {
                key: instance.id,
                reference: true,
                skinInstance: instance,
                sideToShow: currentSide.value,
                showLocationIcon: false,
                onSelectInstance: ($event: any) => (emit('selectInstance', instance))
              }, null, 8, ["skinInstance", "sideToShow", "onSelectInstance"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})