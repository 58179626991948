import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-image-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "v-clickable-layer"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["id"]

import {
  computed, nextTick, onBeforeUnmount, PropType, Ref, ref, toRef,
} from 'vue';
import LocationIcon from '@/timeline/components/BodyMap/LocationIcon.vue';
import { LocationDetail, InstanceLocations } from '@/_shared/types/NourishInstance';
import frontImage from '@/_shared/assets/images/body-map/body-map-front.svg';
import backImage from '@/_shared/assets/images/body-map/body-map-back.svg';
import BodyMapSideSelector from '@/timeline/components/BodyMap/BodyMapFilter/BodyMapSideSelector.vue';
import { syncRef } from '@vueuse/core';
import { useBodyMapLocationStore } from '@/_shared/store/bodyMapLocations';
import { storeToRefs } from 'pinia';

const xOffset = -4;
const yOffset = 6;

export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapImage',
  props: {
  leftPanel: {
    type: Boolean,
    default: false,
  },
  instanceLocations: {
    type: Object as PropType<InstanceLocations>,
    required: true,
  },
  sideToShow: {
    type: String,
    default: 'all',
  },
  showSideSelector: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  showBodyMapImage: {
    type: Boolean,
    default: true,
  },
  teleportFilterTo: {
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
  instanceCount: {
    type: Number,
    default: 0,
  },
},
  emits: ['sideChanged', 'sectionClicked'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
// half width of location icon
const currentSide :Ref<string> = ref(props.sideToShow);
syncRef(toRef(props, 'sideToShow'), currentSide, { direction: 'ltr' });
const showLocation = (locationDetails: LocationDetail) => {
  if (locationDetails.side === 'back') {
    return currentSide.value === 'all' || currentSide.value === 'back';
  }
  if (locationDetails.side === 'front') {
    return currentSide.value === 'all' || currentSide.value === 'front';
  }
  return true;
};

const bodyMapImage: Ref<HTMLElement|null> = ref(null);
const imageWidth = ref(0);
const imageHeight = ref(0);

const setImageSize = () => {
  const image = bodyMapImage.value as HTMLElement;
  imageWidth.value = image.offsetWidth;
  imageHeight.value = image.offsetHeight;
};

const locationsToShow = computed(() => (isShowBackImage.value
  ? props.instanceLocations?.backLocations
  : props.instanceLocations?.frontLocations));
const { bodySectionsLocations } = storeToRefs(useBodyMapLocationStore());

const isShowBackImage = computed(() => ((currentSide.value === 'all' && props.sideToShow === 'back') || currentSide.value === 'back'));
const clickableSection = computed(() => (isShowBackImage.value ? bodySectionsLocations.value?.backLocations : bodySectionsLocations.value?.frontLocations));
const bodyMapSrc = computed(() => (isShowBackImage.value ? backImage : frontImage));
const getRelativePosition = (location : LocationDetail, section = false) => computed(() => {
  const xVarOffset = section ? 0 : xOffset;
  const yVarOffset = section ? 0 : yOffset;
  if (!(imageWidth.value || imageHeight.value)) return {};
  const x = imageWidth.value * location.x;
  const y = imageHeight.value * location.y;
  type CssCoord = {
    left: string,
    top: string,
    width?: string,
    height?: string,
    background?: string,
    border?: string,
  };
  const coord : CssCoord = {
    left: `${x - xVarOffset}px`, top: `${y - yVarOffset}px`,
  };
  if (section && location.x2 && location.y2) {
    const x2 = (imageWidth.value * location.x2) - xVarOffset;
    const y2 = (imageHeight.value * location.y2) - yVarOffset;
    coord.width = `${x2 - x}px`;
    coord.height = `${y2 - y}px`;
    coord.background = 'red';
    coord.border = '2px dashed white';
  }
  return coord;
});
const changeSide = (newSide: string) => {
  currentSide.value = newSide;
  emit('sideChanged', newSide);
};
onBeforeUnmount(async () => {
  if (!props.teleportFilterTo) return;
  // wait until teleport is disabled
  await nextTick();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, {
      disabled: !__props.teleportFilterTo,
      to: __props.teleportFilterTo
    }, [
      (__props.showSideSelector)
        ? (_openBlock(), _createBlock(BodyMapSideSelector, {
            key: 0,
            instanceLocations: __props.instanceLocations,
            whiteBackground: __props.whiteBackground,
            sideToShow: __props.sideToShow,
            "instance-count": __props.instanceCount,
            onSideBtnClicked: changeSide
          }, null, 8, ["instanceLocations", "whiteBackground", "sideToShow", "instance-count"]))
        : _createCommentVNode("", true)
    ], 8, ["disabled", "to"])),
    (__props.showBodyMapImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['v-body-map', {'v-white-background': __props.whiteBackground }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (__props.clickable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(clickableSection.value, (location) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: `s-location-${location.codename}-${location.side}`,
                      class: _normalizeClass(["v-skin-instance-locations", `v-skin-instance-locations-${location.side} v-body-section-${location.codename}-${location.side}`]),
                      style: _normalizeStyle(getRelativePosition(location, true).value),
                      onClick: _withModifiers(($event: any) => (_ctx.$emit('sectionClicked', location.codename,currentSide.value)), ["stop"])
                    }, null, 14, _hoisted_3)), [
                      [_vShow, showLocation(location)]
                    ])
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("img", {
              class: _normalizeClass(["v-body-map-img", `${__props.leftPanel ? 'v-body-map-img-left-panel' : ''} v-body-map-image-${
          currentSide.value !== 'back' ? 'front' : 'back'
        }`]),
              src: bodyMapSrc.value,
              onLoad: _cache[0] || (_cache[0] = ($event: any) => (setImageSize())),
              alt: "body-map",
              ref_key: "bodyMapImage",
              ref: bodyMapImage
            }, null, 42, _hoisted_4),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locationsToShow.value, (location) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                id: `location-${location.codename}-${location.side}`,
                key: location.codename,
                class: _normalizeClass(["v-skin-instance-locations", `v-skin-instance-locations-${location.side} ${
          location.multi ? 'v-skin-instance-multi' : ''
        }`]),
                style: _normalizeStyle(getRelativePosition(location).value)
              }, [
                _createVNode(LocationIcon, {
                  blue: currentSide.value !== 'back' && location.side !== 'back',
                  "location-number": location.count,
                  "large-area": location.large,
                  "multiple-instances": location.multi,
                  "lozenge-icon": false
                }, null, 8, ["blue", "location-number", "large-area", "multiple-instances"])
              ], 14, _hoisted_5)), [
                [_vShow, showLocation(location)]
              ])
            }), 128))
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})