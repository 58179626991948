import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-searchable-section-wrapper"
}
const _hoisted_2 = { class: "v-searchable-section-header" }
const _hoisted_3 = ["onClick"]

import { SelectorSection } from '@/_shared/types/baseSearchableList';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSearchableListSection',
  props: {
  section: {
    type: Object as PropType<SelectorSection>,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.section.items.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(__props.section.header), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.section.items, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "v-searchable-section-item sp-service",
            onClick: ($event: any) => (item.callback()),
            key: item.key
          }, _toDisplayString(item.name), 9, _hoisted_3))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})