import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-close-right-panel" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseRightPanelIcon',
  emits: ['closeRightPanel'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseIcon, {
      transparent: true,
      name: "times",
      foregroundColor: "black",
      size: "xs",
      height: "14px",
      compact: "",
      class: "sp-close-right-panel",
      "foreground-hover-color": "grey",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeRightPanel')))
    })
  ]))
}
}

})