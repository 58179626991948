import apiClient from '@/_shared/services/apiClient';
import { ClientDashboard } from '@/_shared/types/clientDashboard';

const getClientDashboards = async () => {
  try {
    return await apiClient.get('/api/v2/client_dashboards') as ClientDashboard[];
  } catch (error) {
    return error;
  }
};

export default getClientDashboards;
