import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-backdrop" }
const _hoisted_2 = { class: "v-close-button-container" }
const _hoisted_3 = { class: "v-image-container" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "v-download-bar" }
const _hoisted_6 = ["href", "download"]
const _hoisted_7 = { class: "v-download-text" }

import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { GenericFile } from '@/_shared/types/genericFile';
import { ref, onBeforeMount } from 'vue';
import use from '@/_shared/compositionApi';
import { sanitizeFileName } from '@/_shared/helpers/fileHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewImageModal',
  props: {
  file: {
    type: Object as () => GenericFile | File,
    required: true,
  },
},
  emits: ['closeViewImageModal'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props = __props;
const { translate } = use.helpers();

const imageURL = ref('');
const imageName = ref('');

onBeforeMount(() => {
  if (props.file instanceof File) {
    imageName.value = sanitizeFileName(props.file.name as string);
    const file: File = props.file as File;
    const reader = new FileReader();
    reader.onload = (e) => {
      imageURL.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  } else {
    const deviceUuid = window.localStorage.getItem('deviceUUID');
    const params = `?device_uuid=${deviceUuid}`;
    imageName.value = sanitizeFileName(props.file?.fileName as string);
    const url = props.file?.relativeUrl || props.file?.url || '';
    imageURL.value = `${url}${params}`;
  }
});


return (_ctx: any,_cache: any) => {
  return (imageURL.value.length > 0)
    ? (_openBlock(), _createBlock(BaseModal, {
        key: 0,
        "card-disabled": true,
        onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (emit('closeViewImageModal')))
      }, {
        backdrop: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(BaseIcon, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('closeViewImageModal'))),
                class: "v-close-button foreground-color-black",
                name: "nsx-font-icon--cancel",
                "foreground-color": "white",
                "background-color": "$v-color-black",
                size: "2x",
                compact: ""
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: imageURL.value,
                alt: imageName.value,
                class: "v-image"
              }, null, 8, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("a", {
                  href: imageURL.value,
                  download: imageName.value,
                  class: "v-download"
                }, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(translate)('common.download')), 1),
                  _createVNode(BaseIcon, {
                    class: "v-download-button",
                    name: "download",
                    "no-hover-background": "",
                    foregroundColor: "white",
                    size: "2x"
                  })
                ], 8, _hoisted_6)
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})