import CollectionStore from '@/_shared/store/collectionStore';
import {
  ClientInstances,
  fetchInstances,
} from '@/_shared/services/nourishInstancesApi';
import { EventPwsInstance, SkinInstance } from '@/_shared/types/NourishInstance';
import IInteraction from '@/timeline/types/IInteraction';

export default class InstancesStore extends CollectionStore<ClientInstances, ClientInstances[]> {
  fetch(ids: number[]): Promise<ClientInstances[]> {
    // todo this temporary no endpoint to get multiple clients skin instances, we may not even needed but it's as safe guard
    return Promise.all(ids.map((id) => fetchInstances(id)));
  }

  clientInstanceByInstanceId(clientId: number, instanceId: number) {
    const { instances } = this.byId(clientId);
    return instances ? instances.find((i) => instanceId === i.id) : undefined;
  }

  skinInstancesByClientId(clientId: number): SkinInstance[] {
    const { instances } = this.byId(clientId);
    return instances ? instances.filter((i) => i.instanceType === 'skin') as SkinInstance[] : [];
  }

  updateNourishInstances(updatedInteractions: IInteraction[]) {
    updatedInteractions?.forEach((interaction) => {
      const { instances } = this.byId(interaction.clientId);
      if (!instances?.length) {
        return;
      }

      const instance = instances.find((i) => i.id === interaction.nourishInstanceId && interaction.nourishInstanceId !== null);
      instance?.interactions.forEach((i) => {
        if (i.id === interaction.id) {
          i.state = interaction.state;
          i.finishAt = interaction.finishAt;
        }
      });
    });
  }

  eventPWSInstancesByClientId(clientId: number): EventPwsInstance[] {
    const { instances } = this.byId(clientId);
    return instances ? instances.filter((i) => i.instanceType === 'event_pws') as EventPwsInstance[] : [];
  }

  extractData(data: ClientInstances[]) {
    return data;
  }

  interactionRelatedToInstance(clientId: number, instanceId: number, interactionId: number) {
    const instance = this.byId(clientId).instances.find((i) => i.id === instanceId);
    if (!instance) {
      return false;
    }
    return instance.startInteractionId === interactionId
      || instance.lastInteractionId === interactionId
      || instance.interactions.some((i) => i.id === interactionId);
  }
}

export const instancesStore: InstancesStore = new InstancesStore();
