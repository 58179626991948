import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-instance-badge-section" }
const _hoisted_2 = { class: "v-instance-interaction-status" }
const _hoisted_3 = {
  key: 0,
  class: "v-instance-counter"
}
const _hoisted_4 = { class: "v-instance-interaction-status-label" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapListEntryBadge',
  props: {
  label: {
    type: String,
    required: false,
  },
  counter: {
    type: Number,
    required: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      (props.counter != undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.counter), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(__props.label), 1)
    ])
  ]))
}
}

})