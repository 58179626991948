import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]

import { ref, watch } from 'vue';
import { CoercedChoice, Parameter } from '../types/Parameter';
import {
  formatMedicationValue,
  formatParameterName,
  getParameterValue,
  hasTotalScoreStyle,
} from '../helper/parametersHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'ParameterInfo',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  clientId: {
    type: Number,
    required: true,
  },
  computedParameter: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const label = ref('');
let value: unknown = '';

const parameterValueClass = `v-parameter${props.computedParameter ? '-score-value' : '-value'}`;
const parameterLabelId = `sp-${props.parameter.id}-${props.computedParameter ? 'name' : 'label'}`;
const parameterValueId = `sp-${props.parameter.id}-value${props.computedParameter ? '-label' : ''}`;

const scoredParamValueText = (parameter: Parameter) => {
  const coercedValue = parameter.coercedValue as CoercedChoice;

  if (parameter.valueType === 'medication') {
    return formatMedicationValue(parameter);
  }

  if (coercedValue?.label) {
    return coercedValue.label;
  }
  if (hasTotalScoreStyle(parameter)) {
    return '';
  }
  if (coercedValue?.value) {
    return coercedValue.value;
  }
  return coercedValue;
};

const getValue = props.computedParameter ? scoredParamValueText : getParameterValue;

watch(() => props.parameter, async () => {
  value = getValue(props.parameter, props.clientId);
}, { immediate: true, deep: true });

watch(
  () => formatParameterName(props.parameter, props.clientId).value,
  (name) => { label.value = name; },
  { immediate: true },
);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.parameter.valueType !== 'medication')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "v-parameter-label",
          id: parameterLabelId
        }, _toDisplayString(label.value), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass([parameterValueClass, __props.computedParameter ? '': 'v-pre-wrap']),
      id: parameterValueId,
      innerHTML: _unref(value)
    }, null, 10, _hoisted_1)
  ], 64))
}
}

})