import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import InteractionPlusButton from '@/timeline/components/InteractionPlusButton.vue';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelinePlusButtonWrapper',
  setup(__props) {

const serviceFilteringStore = useServiceFilteringStore();
const {
  bodyMapTimelineMounted,
  instanceViewTimelineMounted,
  matchingManagedSkinInstanceServices,
  newSkinInstanceServices,
} = storeToRefs(serviceFilteringStore);

const showPlusButtonForBodyMap = computed(() => {
  if (bodyMapTimelineMounted.value && !newSkinInstanceServices.value.length) {
    return false;
  }
  return !(instanceViewTimelineMounted.value && !matchingManagedSkinInstanceServices.value.length);
});


return (_ctx: any,_cache: any) => {
  return (showPlusButtonForBodyMap.value)
    ? (_openBlock(), _createBlock(InteractionPlusButton, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})