import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "v-toggle" }
const _hoisted_2 = ["checked"]

import { computed } from 'vue';
import { useInputMethods } from '@/_shared/helpers/useInputMethods';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseToggle',
  props: {
  checked: {
    type: Boolean,
  },
  label: {
    type: String,
    default: '',
  },
  labelWidth: {
    type: String,
    default: '',
  },
  boldLabel: {
    type: Boolean,
    default: false,
  },
  blockToggle: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'row',
  },
},
  emits: ['toggleChange'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { labelClass, labelStyle } = useInputMethods(props);
const isChecked = computed(() => props.checked);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-toggle-wrapper",
    style: _normalizeStyle({ flexDirection: __props.direction as import('csstype').Property.FlexDirection })
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: isChecked.value,
        onClick: [
          _cache[0] || (_cache[0] = _withModifiers(($event: any) => (__props.blockToggle || __props.disabled), ["prevent"])),
          _cache[1] || (_cache[1] = ($event: any) => (__props.disabled ? null : emit('toggleChange')))
        ]
      }, null, 8, _hoisted_2),
      _createElementVNode("span", {
        class: _normalizeClass(["v-slider", `${__props.disabled ? 'v-toggle-disabled' : ''}`]),
        onClick: [
          _cache[2] || (_cache[2] = _withModifiers(($event: any) => (__props.blockToggle || __props.disabled), ["prevent"])),
          _cache[3] || (_cache[3] = ($event: any) => (__props.disabled ? null : emit('toggleChange')))
        ]
      }, null, 2)
    ])
  ], 4))
}
}

})