import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-transfer-form-header-container sp-transfer-form-details" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-transfer-form-title" }
const _hoisted_5 = { class: "v-content" }
const _hoisted_6 = { class: "v-transfer-form" }
const _hoisted_7 = { class: "v-h4" }
const _hoisted_8 = { class: "v-form-required-info" }
const _hoisted_9 = { class: "v-transfer-form" }
const _hoisted_10 = { class: "v-h4" }
const _hoisted_11 = { class: "v-transfer-form" }
const _hoisted_12 = { class: "v-h4" }
const _hoisted_13 = { class: "toggle" }
const _hoisted_14 = { class: "v-copied_later" }
const _hoisted_15 = {
  key: 0,
  class: "v-transfer-form"
}
const _hoisted_16 = { class: "v-h4" }
const _hoisted_17 = { class: "toggle" }
const _hoisted_18 = { class: "v-form-actions" }
const _hoisted_19 = ["innerHTML"]

import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { BaseCard, CloseRightPanelIcon } from '@/_shared/components';
import { useRouter } from 'vue-router';
import use from '@/_shared/compositionApi';
import generalInfoIcon from '@/_shared/assets/icons/general_info.svg';
import {
  computed, reactive, ref,
} from 'vue';
import { clientStore } from '@/_shared/store/clients';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import ConfirmationModal from '@/_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';
import { useDebounceFn } from '@vueuse/core';
import { transferClient, getMissingClientServices } from '../services/transferClientsApi';
import BaseIcon from '../../_shared/components/general/BaseIcon.vue';
import BaseButton from '../../_shared/components/buttons/BaseButton.vue';
import BaseSelect from '../../_shared/components/inputs/BaseSelect.vue';
import BaseToggle from '../../_shared/components/inputs/BaseToggle.vue';
import TransferDetails from '../types/TransferDetails';
import ClientActionSuccessPopup from '../../clients/components/ClientActionSuccessPopup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferClientsForm',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const router = useRouter();
const { currentUser } = storeToRefs(useUserStore());
const closeForm = () => {
  router.push({ name: 'client.profile.organisationState' });
  if (showWarningModal.value) closeWarningModal();
};

const firstName = computed(() => clientStore.firstName(props.clientId as number).value);
const currentOU = window?.currentOrganisationUnit;
const clientTerm = currentOU?.client_term || 'Resident';
const carePlanTerm = window.currentOrganisationUnit.care_plan_term || '';
const copyToNewServiceToggle = ref(true);
const cannotBeArchived = ref(['care_template', 'service_management', 'training'].includes(clientStore.clientType(props.clientId as number)));
const canBeArchived = ref(!cannotBeArchived.value);
const archiveToggle = ref(canBeArchived.value);

const transferDetails = reactive({} as TransferDetails);
const orgUnits = computed(() => {
  const organisationUnitsIds = clientStore.orgUnits(props.clientId as number);
  return currentUser.value.organisationUnits
    .filter((ou) => !organisationUnitsIds?.includes(ou.id))
    .map((ou) => ou.name)
    .sort((a, b) => a.localeCompare(b));
});
const selectedOU = ref('');
const saveDisabled = computed(() => !!(selectedOU.value === '' && currentOU.id) || transferInProgress.value);
const transferInProgress = ref(false);
const missingServicesCache = reactive<{ [ouId: number]: string[] }>({});
const showSuccessModal = ref(false);

const client = clientStore.byId(props.clientId);

const save = useDebounceFn(async () => {
  transferInProgress.value = true;
  transferDetails.client_id = props.clientId;
  transferDetails.source_org_unit_id = currentOU?.id as number;
  transferDetails.dest_org_unit_id = currentUser.value.organisationUnits.filter((ou) => ou.name === selectedOU.value)[0].id;
  transferDetails.copy_care_plan = copyToNewServiceToggle.value;
  transferDetails.archive_in_current_ou = archiveToggle.value;
  await handleClientTransferRequest();
}, 300);

const handleClientTransferRequest = async () => {
  if (!transferDetails.copy_care_plan) {
    return submitTransferClient(transferDetails, props.clientId);
  }
  const missingServices = missingServicesCache[transferDetails.dest_org_unit_id] || await fetchMissingServices();
  if ((missingServices ?? []).length) {
    formattedNeedServiceNames.value = `<br /><ul>${missingServices.map((service) => `<li>${service}</li>`).join('')}</ul>`;
    showWarningModal.value = true;
  } else {
    await submitTransferClient(transferDetails, props.clientId);
  }
  return null;
};

const fetchMissingServices = async () => {
  const missingServices = await getMissingClientServices(transferDetails) as string[];
  if ((missingServices ?? []).length) missingServicesCache[transferDetails.dest_org_unit_id] = missingServices;
  return missingServices;
};

const submitTransferClient = async (details: TransferDetails, clientId: number) => {
  if (showWarningModal.value) showWarningModal.value = false;
  const response = await transferClient(details, clientId);
  if (response.status === 200) {
    showSuccessModal.value = true;
  }
  transferInProgress.value = false;
  // closeForm();
};

const closeWarningModal = () => {
  transferInProgress.value = false;
  showWarningModal.value = false;
};

const closeSuccessModal = () => {
  showSuccessModal.value = false;
  router.push({ name: 'client.profile.organisationState' });
};

const showWarningModal = ref(false);
const formattedNeedServiceNames = ref<string>('');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, null, {
    default: _withCtx(() => [
      _createVNode(_unref(CloseRightPanelIcon), { onCloseRightPanel: closeForm }),
      _createVNode(_unref(BaseCard), {
        padding: false,
        "content-top-border": false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(BaseIcon, {
                  name: _unref(generalInfoIcon),
                  size: "2x",
                  noHover: "",
                  compact: "",
                  backgroundColor: "white",
                  foregroundColor: "black",
                  foregroundHoverColor: "white",
                  class: "v-general-info-icon"
                }, null, 8, ["name"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, _toDisplayString(_unref(translate)('client_profile.transfer_clients', { client_term: _unref(clientTerm), })), 1)
                ])
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h4", _hoisted_7, _toDisplayString(_unref(translate)('client_profile.transfer_details')), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(translate)('client_profile.response_required')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h4", _hoisted_10, _toDisplayString(_unref(translate)('client_profile.select_service_to_transfer', { first_name: firstName.value, })), 1),
              _createVNode(BaseSelect, {
                placeholder: _unref(translate)('client_profile.choose_one'),
                options: orgUnits.value,
                modelValue: selectedOU.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOU).value = $event)),
                searchable: true,
                required: true,
                border: "",
                "input-width": "310px"
              }, null, 8, ["placeholder", "options", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h4", _hoisted_12, _toDisplayString(_unref(translate)('client_profile.copy_to_new_service', { first_name: firstName.value, care_plan_term: _unref(carePlanTerm), })), 1),
              _createElementVNode("span", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_unref(translate)('common.no')), 1),
                _createVNode(BaseToggle, {
                  checked: copyToNewServiceToggle.value,
                  onToggleChange: _cache[1] || (_cache[1] = ($event: any) => (copyToNewServiceToggle.value = !copyToNewServiceToggle.value)),
                  direction: "row-reverse"
                }, null, 8, ["checked"]),
                _createElementVNode("span", null, _toDisplayString(_unref(translate)('common.yes')), 1)
              ]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(translate)('client_profile.copied_later')), 1)
            ]),
            (canBeArchived.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("h4", _hoisted_16, _toDisplayString(_unref(translate)('client_profile.archive_client', { first_name: firstName.value, })), 1),
                  _createElementVNode("span", _hoisted_17, [
                    _createElementVNode("span", null, _toDisplayString(_unref(translate)('common.no')), 1),
                    _createVNode(BaseToggle, {
                      checked: archiveToggle.value,
                      onToggleChange: _cache[2] || (_cache[2] = ($event: any) => (archiveToggle.value = !archiveToggle.value))
                    }, null, 8, ["checked"]),
                    _createElementVNode("span", null, _toDisplayString(_unref(translate)('common.yes')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(BaseButton, {
                class: "v-form-action-button sp-cancel",
                "button-type": "secondary",
                size: "medium",
                disabled: false,
                onClick: closeForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)('common.cancel')), 1)
                ]),
                _: 1
              }),
              _createVNode(BaseButton, {
                class: "v-form-action-button sp-save",
                "button-type": "primary",
                size: "medium",
                disabled: saveDisabled.value,
                onClick: _unref(save),
                colour: "black-text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)('common.save')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      (showWarningModal.value)
        ? (_openBlock(), _createBlock(ConfirmationModal, {
            key: 0,
            "title-text": _unref(translate)('client_profile.transfer_clients', { client_term: _unref(clientTerm) }),
            "first-row-text": _unref(translate)('client_profile.transfer_confirmation_1', { dest_ou_name: selectedOU.value, care_plan_term: _unref(carePlanTerm) }),
            "third-row-text": _unref(translate)('client_profile.transfer_confirmation_2', { first_name: firstName.value, dest_ou_name: selectedOU.value }),
            onCloseModal: closeWarningModal,
            onConfirm: _cache[3] || (_cache[3] = ($event: any) => (submitTransferClient(transferDetails, __props.clientId)))
          }, {
            "second-row": _withCtx(() => [
              _createElementVNode("div", { innerHTML: formattedNeedServiceNames.value }, null, 8, _hoisted_19)
            ]),
            _: 1
          }, 8, ["title-text", "first-row-text", "third-row-text"]))
        : _createCommentVNode("", true),
      (showSuccessModal.value)
        ? (_openBlock(), _createBlock(ClientActionSuccessPopup, {
            key: 1,
            client: _unref(client),
            "transferred-to": selectedOU.value,
            youCanNow: true,
            onCloseModal: closeSuccessModal,
            "is-new-client": false
          }, null, 8, ["client", "transferred-to"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})