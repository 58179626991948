import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-main-text" }

import { onBeforeMount, PropType } from 'vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import Integration from '../../types/integration';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationNhsConfirmationModal',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
  ou: {
    type: Object as PropType<OrganisationUnit>,
    required: true,
  },
},
  emits: ['confirmToken', 'cancelEnableModal'],
  setup(__props, { emit: __emit }) {



const emit = __emit;
const { translate } = use.helpers();

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseAlert, {
    class: "v-integration-nhs-confirmation-alert",
    dangerAlert: false,
    icon: "info",
    onConfirmAlert: _cache[0] || (_cache[0] = ($event: any) => (emit('confirmToken', __props.token))),
    onCancelAlert: _cache[1] || (_cache[1] = ($event: any) => (emit('cancelEnableModal')))
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_confirmation_1`)) + " " + _toDisplayString(__props.token) + ", " + _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_confirmation_2`)) + " " + _toDisplayString(__props.ou.name) + " " + _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_confirmation_3`)) + " " + _toDisplayString(__props.integration.name) + " " + _toDisplayString(_unref(translate)(`integrations.${__props.integration.integrationCodename}.ods_code_confirmation_4`)) + " " + _toDisplayString(__props.ou.name) + ".", 1)
      ])
    ]),
    _: 1
  }))
}
}

})