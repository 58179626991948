import IInteraction from '@/timeline/types/IInteraction';
import { computed, Ref } from 'vue';
import { responsiblePersonId } from '@/timeline/helper/interactionsHelper';
import { personStore } from '@/_shared/store/people';

const useInteractionListEntryPeople = (interaction: Ref<IInteraction>) => {
  const hasResponsiblePeople = computed(() => interaction.value.responsiblePersonIds?.length);

  const responsiblePeopleNames = computed(() => {
    const personIds = interaction.value?.responsiblePersonIds?.slice(0, 2);
    if (!personIds?.length) { return ''; }
    const names = personStore.names(personIds);
    if (interaction.value.responsiblePersonIds?.length > 2) {
      return `${names} +${interaction.value.responsiblePersonIds.length - 2}`;
    }
    return names?.split(', ').join(' & ');
  });

  const responsiblePersonPhoto = computed(() => {
    const responsibleId = responsiblePersonId(interaction.value);
    if (!responsibleId) { return undefined; }
    return personStore.photo(responsibleId);
  });

  return {
    hasResponsiblePeople,
    responsiblePeopleNames,
    responsiblePersonPhoto,
  };
};

export default useInteractionListEntryPeople;
