import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-assign-modal--content-wrapper v-assign-modal-content" }
const _hoisted_2 = { class: "v-assign-modal--search-wrapper" }
const _hoisted_3 = { class: "v-assign-modal--search-input-field" }
const _hoisted_4 = { class: "v-search-icon" }
const _hoisted_5 = { class: "v-assign-modal--select-all" }
const _hoisted_6 = { class: "v-assign-modal--list-wrapper" }

import {
  PropType, ref, computed, watch, Ref,
} from 'vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { Carer } from '@/_shared/store/carers';
import BaseToggle from '@/_shared/components/inputs/BaseToggle.vue';
import AssignModalEntry from './AssignModalEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssignModalContent',
  props: {
  selectAllText: {
    type: String,
  },
  searchInputPlaceholder: {
    type: String,
  },
  selectableEntries: {
    type: Object as PropType<Carer[]>, // Can change this to add different types if we reuse this
    required: true,
  },
  primaryTextKey: {
    type: String,
    required: true,
  },
  secondaryTextKey: {
    type: String,
    required: true,
  },
  selectedEntries: {
    type: Object as PropType<number[]>,
    required: true,
  },
  selectedEntriesCount: {
    type: Number,
    required: true,
  },
},
  emits: ['update:selectedEntries', 'update:selectedEntriesCount'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const searchTerm = ref('');
const selectedEntries = ref(props.selectedEntries);
const filteredSelectableEntries: Ref<Carer[]> = ref([]);

const allSelected = computed(() => selectedEntries.value.length === props.selectableEntries.length);

const entryMatchesSearchTerm = (entry: Carer) => entry.name && entry.name.toLowerCase().includes(searchTerm.value.toLowerCase());

const entryPrimaryText = (entry: Carer) => entry[props.primaryTextKey] as string || '';

const entrySecondaryText = (entry: Carer) => entry[props.secondaryTextKey] as string || '';

const toggleSelectAll = () => {
  const ids = filteredSelectableEntriesIds();
  ids.forEach((id) => {
    if (!selectedEntries.value.includes(id)) {
      selectedEntries.value.push(id);
    }
  });
  emit('update:selectedEntries', selectedEntries);
  countSelectedEntries();
};

const filteredSelectableEntriesIds = () => {
  if (!filteredSelectableEntries.value || allSelected.value) {
    selectedEntries.value.splice(0, selectedEntries.value.length);
    return [];
  }
  return filteredSelectableEntries.value.map((entry: Carer) => entry.id);
};

const toggleSelectEntry = (entryId: number) => {
  if (selectedEntries.value.includes(entryId)) {
    const removeIndex = selectedEntries.value.findIndex((entry) => entry === entryId);
    selectedEntries.value.splice(removeIndex, 1);
  } else {
    selectedEntries.value.push(entryId);
  }
  emit('update:selectedEntries', selectedEntries);
  countSelectedEntries();
};

const filterEntries = () => {
  if (!props.selectableEntries || !filteredSelectableEntries.value) {
    return;
  }
  filteredSelectableEntries.value = props.selectableEntries.filter(
    (entry: Carer) => entryMatchesSearchTerm(entry),
  );
};
watch(() => props.selectableEntries, (value) => {
  filteredSelectableEntries.value = value;
  filterEntries();
}, { immediate: true });
const countSelectedEntries = () => {
  emit('update:selectedEntriesCount', selectedEntries.value.length);
};

const entrySelected = ((entry: Carer) => selectedEntries.value && selectedEntries.value.includes(entry.id));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(BaseIcon, {
            name: "search no-margin no-hover",
            size: "lg",
            foregroundColor: "grey"
          })
        ]),
        _createVNode(BaseInput, {
          class: "v-assign-search",
          border: false,
          modelValue: searchTerm.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchTerm).value = $event)),
          placeholder: __props.searchInputPlaceholder,
          onInput: filterEntries
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BaseToggle, {
          labelWidth: "100px",
          label: __props.selectAllText,
          onToggleChange: toggleSelectAll,
          checked: allSelected.value
        }, null, 8, ["label", "checked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSelectableEntries.value, (entry) => {
        return (_openBlock(), _createBlock(AssignModalEntry, {
          key: entry['id'],
          entry: entry,
          onToggleSelectEntry: toggleSelectEntry,
          primaryText: entryPrimaryText(entry),
          secondaryText: entrySecondaryText(entry),
          isSelected: entrySelected(entry),
          showAvatar: true
        }, null, 8, ["entry", "primaryText", "secondaryText", "isSelected"]))
      }), 128))
    ])
  ]))
}
}

})