import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import vClickOutside from '@/_shared/directives/ClickOutside';
import TimelineNavigationDropdown
  from '@/_shared/components/navigation/TimelineNavigationDropdown.vue';
import { PropType, ref } from 'vue';
import BaseNavigationItem from '@/_shared/components/navigation/BaseNavigationItem.vue';
import { TopBarLink } from '@/_shared/types/baseTopBarLink';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimelineDropdownItem',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  link: {
    type: Object as PropType<TopBarLink>,
    required: true,
  },
},
  setup(__props) {




const dropdownVisible = ref(false);
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const hideDropdown = () => {
  dropdownVisible.value = false;
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "v-timeline-dropdown-container",
    onClick: toggleDropdown
  }, [
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(BaseNavigationItem, {
        to: __props.link.to,
        icon: __props.link.icon,
        text: __props.link.name,
        key: __props.link.name,
        forceActive: __props.link.activeOnChildren
      }, {
        "right-col-icon": _withCtx(() => [
          _createVNode(BaseIcon, {
            class: "v-dropdown-expand-icon",
            noHover: "",
            name: dropdownVisible.value ? 'nsx-font-icon--expand-top' : 'nsx-font-icon--expand-bottom'
          }, null, 8, ["name"])
        ]),
        _: 1
      }, 8, ["to", "icon", "text", "forceActive"]))
    ]),
    _createVNode(TimelineNavigationDropdown, {
      "person-id": __props.clientId,
      "dropdown-visible": dropdownVisible.value
    }, null, 8, ["person-id", "dropdown-visible"])
  ])), [
    [_unref(vClickOutside), hideDropdown]
  ])
}
}

})