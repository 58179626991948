import IInteraction from '@/timeline/types/IInteraction';
import {
  InteractionLogTableData,
  InteractionLogTableDataEntry,
} from '@/timeline/types/InteractionLogData';
import use from '@/_shared/compositionApi';
import BaseTableHeader from '@/_shared/types/baseTableHeader';
import { format, isFuture, parseISO } from 'date-fns';
import { personStore } from '@/_shared/store/people';
import { fetchAllInteractionsResponsiblePepole } from '@/_shared/services/serviceLogTable';
import {
  getParamEntries,
  buildParameterHeaders,
  getValuesForParams,
} from './interactionParamsHelpers';

const { translate } = use.helpers();

const interactionsPastVersionsTable = async (interactions: IInteraction[], interactionClientId: number, needsStartAt: boolean): Promise<InteractionLogTableData | null> => {
  if (!interactions.length) return null;

  if (interactions.some((interaction) => interaction.parameters?.length)) {
    await getValuesForParams(interactions, interactionClientId);
  }
  // todo, this is needed because composable is not reactive
  await fetchAllInteractionsResponsiblePepole(interactions);

  const [entries, headers] = [
    buildEntries(interactions),
    buildHeaders(interactions.slice(-1)[0], interactionClientId, needsStartAt),
  ];

  return {
    headers,
    entries,
  };
};

const buildHeaders = (interaction: IInteraction, clientId: number, needsStartAt: boolean) => {
  const parameterHeaders: BaseTableHeader[] = buildParameterHeaders(interaction);

  const headers: BaseTableHeader[] = [
    {
      keyName: 'finishAt',
      displayName: translate('timeline.interaction.finishAt'),
      type: 'string',
    },
    {
      keyName: 'savedAt',
      displayName: translate('timeline.interaction.savedAt'),
      type: 'string',
    },
    ...parameterHeaders,
    {
      keyName: 'responsiblePeople',
      displayName: translate('timeline.interaction.responsiblePeople'),
      type: 'string',
    },
    {
      keyName: 'originatorId',
      displayName: translate('timeline.interaction.originatorId'),
      type: 'string',
    },
    {
      keyName: 'state',
      displayName: translate('timeline.interaction.state'),
      type: 'string',
    },
    {
      keyName: 'dailyNote',
      displayName: translate('timeline.interaction.notePublic'),
      type: 'string',
    },
    {
      keyName: 'handover',
      displayName: translate('timeline.interaction.handover'),
      type: 'string',
    },
  ];
  if (needsStartAt) {
    headers.unshift({
      keyName: 'startAt',
      displayName: translate('timeline.interaction.startAt'),
      type: 'string',
    });
  }
  return headers;
};

const buildEntries = (interactions: IInteraction[]): InteractionLogTableDataEntry[] => {
  const lastInteraction = interactions[0];
  if (lastInteraction.state === 'saved' && lastInteraction.finishAt && isFuture(parseISO(lastInteraction.finishAt))) {
    lastInteraction.parameters = lastInteraction.parameters?.filter((p) => !((p.dataPoint && p.dataPoint.length) || p.valueType === 'combined_multi_search')) || [];
    interactions[0] = lastInteraction;
  }
  return interactions.map((interaction) => {
    const startAt = interaction.startAt ? format(new Date(interaction.startAt), 'dd/MM/yyyy - HH:mm') : undefined;
    const finishAt = interaction.finishAt ? format(new Date(interaction.finishAt), 'dd/MM/yyyy - HH:mm') : undefined;
    const savedAt = interaction.savedAt ? format(new Date(interaction.savedAt), 'dd/MM/yyyy - HH:mm') : undefined;
    const entries: InteractionLogTableDataEntry = {
      startAt: startAt || '',
      id: interaction.id as number,
      finishAt: finishAt as string,
      savedAt: savedAt as string,
      ...getParamEntries([...(interaction.parameters || []), ...(interaction.computedParameters || [])]),
    };
    if (interaction.responsiblePersonIds?.length) {
      entries.responsiblePeople = personStore.namesSorted(interaction.responsiblePersonIds);
    }

    if (interaction.originatorId) {
      entries.originatorId = personStore.name(interaction.originatorId);
    }

    if (interaction.state) {
      entries.state = translate(`timeline.pastInteractions.states.${interaction.state}`);
    }

    if (interaction.notePublic) {
      entries.dailyNote = interaction.notePublic;
    }

    entries.handover = interaction.handover ? 'Yes' : 'No';

    return entries;
  });
};

export default interactionsPastVersionsTable;
