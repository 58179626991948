type Binding = {
  event: BindingEvent | TargetEvent
  value: unknown
}

type BindingEvent = (this: HTMLElement, ev: MouseEvent) => unknown
type TargetEvent = {target: Node | null}

const vClickOutside = {
  beforeMount(el: Element, binding: Binding) {
    binding.event = ((event: TargetEvent) => {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.value instanceof Function) {
          binding.value(event);
        }
      }
    }) as BindingEvent;
    document.body.addEventListener('click', binding.event as BindingEvent);
  },
  unmounted(el: Element, binding: Binding) {
    document.body.removeEventListener('click', binding.event as BindingEvent);
  },
};

export default vClickOutside;
