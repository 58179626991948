import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useBreakpoints } from '@vueuse/core';
import { useRouter } from 'vue-router';
import use from '@/_shared/compositionApi';
import { vBaseTooltip } from '@/_shared/directives/';
import BaseCircleButton from '../_shared/components/buttons/BaseCircleButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewMessageButton',
  setup(__props) {

const { translate } = use.helpers();
const breakpoints = useBreakpoints({
  xs: 480,
  sm: 800,
  md: 992,
  lg: 1224,
});

const isGreaterThanSmall = breakpoints.greater('sm').value;
const router = useRouter();
const newMessage = () => {
  router.push({ name: 'messagesNew' });
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(BaseCircleButton, {
    class: "sp-add-button",
    top: "19px",
    right: "71px",
    left: "auto",
    position: "absolute",
    onClick: newMessage,
    "react-to-tab": ""
  }, null, 512)), [
    [_unref(vBaseTooltip), { tooltipText: _unref(translate)('message.new'), position: _unref(isGreaterThanSmall) ? 'bottom' : 'top'}]
  ])
}
}

})