import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vModelText as _vModelText, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-duration-parameter-container" }
const _hoisted_2 = { class: "v-duration-input-wrapper" }
const _hoisted_3 = { class: "v-input-wrap" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "v-input-wrap" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "v-input-wrap" }
const _hoisted_8 = ["disabled"]

import { ref, toRef, watch } from 'vue';
import { BaseIcon } from '@/_shared/components';
import BaseParameter from './BaseParameter.vue';
import { Parameter } from '../types/Parameter';
import '@vuepic/vue-datepicker/dist/main.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'DurationParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;
const durationValue = toRef(props.parameter, 'coercedValue');
const seconds = ref<string>('');
const minutes = ref<string>('');
const hours = ref<string>('');

watch(durationValue, () => {
  setHoursMinutesSeconds();
});

if (props.parameter.coercedValue && typeof props.parameter.coercedValue === 'number') {
  setHoursMinutesSeconds();
}

function setHoursMinutesSeconds() {
  const value = props.parameter.coercedValue as number;
  hours.value = padWithZeros((value / 3600).toString());
  minutes.value = padWithZeros(((value / 60) % 60).toString());
  seconds.value = padWithZeros((value % 60).toString());
}

function padWithZeros(number: string) {
  const intNumber = parseInt(number, 10);
  if (intNumber < 10) {
    return `0${intNumber}`;
  }
  return intNumber.toString();
}

function recalculateDuration(newValue: number, type: string) {
  const value = newValue.toString().replace(/\D/g, '');
  const intValue = parseInt(value, 10);
  if (intValue === 0) {
    return '00';
  }
  if (['minutes', 'seconds'].includes(type) && intValue > 59) {
    return '59';
  }
  return padWithZeros(value);
}

function addSeconds(secondsValue: number) {
  let newSeconds = parseInt(seconds.value || '0', 10) + secondsValue;
  if (newSeconds < 0 && parseInt(minutes.value || '0', 10) > 0) {
    newSeconds = 59;
    addMinutes(-1);
  }
  if (newSeconds <= 0 && parseInt(minutes.value || '0', 10) === 0) {
    newSeconds = 0;
    if (parseInt(hours.value || '0', 10) > 0) {
      newSeconds = 59;
      addHours(-1);
      minutes.value = '59';
    }
  }
  if (newSeconds >= 60) {
    newSeconds = 0;
    addMinutes(1);
  }
  seconds.value = padWithZeros(newSeconds.toString());
}

function addMinutes(minutesValue: number) {
  let newMinutes = parseInt(minutes.value || '0', 10) + minutesValue;
  if (newMinutes < 0 && parseInt(hours.value || '0', 10) > 0) {
    newMinutes = 59;
    addHours(-1);
  }
  if (newMinutes <= 0 && parseInt(hours.value || '0', 10) === 0) {
    newMinutes = 0;
  }
  if (newMinutes >= 60) {
    newMinutes = 0;
    addHours(1);
  }
  minutes.value = padWithZeros(newMinutes.toString());
}

function addHours(hoursValue: number) {
  let newHours = parseInt(hours.value || '0', 10) + hoursValue;
  if (newHours < 0) {
    newHours = 0;
  }
  hours.value = padWithZeros(newHours.toString());
}

async function updateCoercedValue() {
  const secondsValue = parseInt(seconds.value || '0', 10);
  const minutesInSeconds = (parseInt(minutes.value || '0', 10) * 60);
  const hoursInSeconds = (parseInt(hours.value || '0', 10) * 3600);
  const totalSeconds = secondsValue + minutesInSeconds + hoursInSeconds;
  if (totalSeconds === 0) {
    durationValue.value = null;
    return;
  }
  durationValue.value = totalSeconds;
}

function maybeUpdateHours(aHours: number) {
  if (!props.readOnly) {
    addHours(aHours);
    setNullToZeros();
    updateCoercedValue();
  }
}

function maybeUpdateMinutes(aMinutes: number) {
  if (!props.readOnly) {
    addMinutes(aMinutes);
    setNullToZeros();
    updateCoercedValue();
  }
}

function maybeUpdateSeconds(aSeconds: number) {
  if (!props.readOnly) {
    addSeconds(aSeconds);
    setNullToZeros();
    updateCoercedValue();
  }
}

function setNullToZeros() {
  if (seconds.value === null || seconds.value === '0') {
    seconds.value = '00';
  }
  if (minutes.value === null || minutes.value === '0') {
    minutes.value = '00';
  }
  if (hours.value === null || hours.value === '0') {
    hours.value = '00';
  }
}

// const durationObj = ref({ hours, minutes, seconds });
//
// watch(durationObj, () => updateCoercedValue(durationObj.value));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true
    }, {
      "lhs-slot-second-line": _withCtx(() => [
        _createVNode(_unref(BaseIcon), {
          class: "v-clock-icon no-hover",
          name: "clock-o",
          size: "lg",
          foregroundColor: "black",
          noHoverBackground: true
        })
      ]),
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-increase-hours",
              name: "chevron-up",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (maybeUpdateHours(1)))
            }, null, 8, ["disabled"]),
            _createElementVNode("label", null, [
              _withDirectives(_createElementVNode("input", {
                disabled: __props.readOnly,
                onFocus: _cache[1] || (_cache[1] = ($event: any) => (($event.target as HTMLInputElement)?.select())),
                type: "number",
                min: "0",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hours).value = $event)),
                onChange: _cache[3] || (_cache[3] = _withModifiers(($event: any) => {hours.value = recalculateDuration(+hours.value, 'hours'); updateCoercedValue();}, ["stop"])),
                placeholder: "HH",
                pattern: "[0-9]+",
                class: "sp-parameter-input-duration-hours v-duration-input"
              }, null, 40, _hoisted_4), [
                [_vModelText, hours.value]
              ])
            ]),
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-decrease-hours",
              name: "chevron-down",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[4] || (_cache[4] = ($event: any) => (maybeUpdateHours(-1)))
            }, null, 8, ["disabled"])
          ]),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "v-input-divider" }, [
            _createElementVNode("span", null, ":")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-increase-minutes",
              name: "chevron-up",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (maybeUpdateMinutes(1)))
            }, null, 8, ["disabled"]),
            _createElementVNode("label", null, [
              _withDirectives(_createElementVNode("input", {
                disabled: __props.readOnly,
                onFocus: _cache[6] || (_cache[6] = ($event: any) => (($event.target as HTMLInputElement)?.select())),
                type: "number",
                min: "0",
                maxlength: "2",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((minutes).value = $event)),
                onChange: _cache[8] || (_cache[8] = _withModifiers(($event: any) => {minutes.value = recalculateDuration(+minutes.value, 'minutes'); updateCoercedValue();}, ["stop"])),
                class: "sp-parameter-input-duration-minutes v-duration-input",
                placeholder: "MM",
                pattern: "[0-9]+"
              }, null, 40, _hoisted_6), [
                [_vModelText, minutes.value]
              ])
            ]),
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-decrease-minutes",
              name: "chevron-down",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[9] || (_cache[9] = ($event: any) => (maybeUpdateMinutes(-1)))
            }, null, 8, ["disabled"])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "v-input-divider" }, [
            _createElementVNode("span", null, ":")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-increase-seconds",
              name: "chevron-up",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[10] || (_cache[10] = ($event: any) => (maybeUpdateSeconds(1)))
            }, null, 8, ["disabled"]),
            _createElementVNode("label", null, [
              _withDirectives(_createElementVNode("input", {
                disabled: __props.readOnly,
                onFocus: _cache[11] || (_cache[11] = ($event: any) => (($event.target as HTMLInputElement)?.select())),
                type: "number",
                min: "0",
                maxlength: "2",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((seconds).value = $event)),
                onChange: _cache[13] || (_cache[13] = _withModifiers(($event: any) => {seconds.value = recalculateDuration(+seconds.value, 'seconds'); updateCoercedValue();}, ["stop"])),
                class: "sp-parameter-input-duration-seconds v-duration-input",
                placeholder: "SS",
                pattern: "[0-9]+"
              }, null, 40, _hoisted_8), [
                [_vModelText, seconds.value]
              ])
            ]),
            _createVNode(_unref(BaseIcon), {
              class: "v-input-chevron sp-decrease-seconds",
              name: "chevron-down",
              foregroundColor: "black",
              foregroundHoverColor: "grey",
              noHoverBackground: "",
              disabled: __props.readOnly,
              onClick: _cache[14] || (_cache[14] = ($event: any) => (maybeUpdateSeconds(-1)))
            }, null, 8, ["disabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})