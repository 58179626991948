import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-picture-thumbnail"
}
const _hoisted_2 = { class: "v-blurred-picture-cover" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "v-blurred-picture-cover-content" }
const _hoisted_6 = { class: "sp-eye-crossed" }
const _hoisted_7 = { class: "v-picture-sensitive-content-text" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "sp-picture-placeholder" }
const _hoisted_13 = { class: "v-picture-thumbnail" }
const _hoisted_14 = { class: "v-picture-placeholder" }
const _hoisted_15 = { class: "sp-picture-placeholder-text" }

import { PropType, ref, watch } from 'vue';
import use from '@/_shared/compositionApi';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { ParameterPicture } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'PictureParameterThumbnail',
  props: {
  picture: {
    type: Object as () => ParameterPicture,
    required: true,
  },
  allBlurred: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  readOnly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  emits: ['deletePicture', 'openViewImageModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const pictureBlurred = ref(props.allBlurred);
const { translate } = use.helpers();
const openPicture = () => {
  emit('openViewImageModal');
};

const togglePictureBlurred = () => {
  pictureBlurred.value = !pictureBlurred.value;
};
watch(() => props.allBlurred, (newValue) => {
  pictureBlurred.value = newValue;
});
const base64Thumbnail = ref<string>(props.picture.base64Thumbnail || '');
const pictureUrl = ref(props.picture?.pictureFile?.url);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-picture-thumbnail-wrapper", `sp-thumbnail-${__props.picture?.pictureFile?.uuid}`])
  }, [
    (base64Thumbnail.value.length > 0 || pictureUrl.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (pictureBlurred.value = props.allBlurred)),
          onFocusout: _cache[4] || (_cache[4] = ($event: any) => (pictureBlurred.value = props.allBlurred))
        }, [
          (pictureBlurred.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (base64Thumbnail.value?.length > 0)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        alt: "Picture",
                        src: base64Thumbnail.value,
                        class: "v-picture-thumbnail-img"
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        alt: "Picture",
                        src: pictureUrl.value,
                        class: "v-picture-thumbnail-img"
                      }, null, 8, _hoisted_4))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(BaseIcon, {
                      name: "nsx-font-icon--eye_crossed",
                      width: "24px",
                      height: "24px",
                      "no-hover-background": "",
                      "no-hover": "",
                      foregroundHoverColor: "black"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('timeline.parameters.picture.sensitiveContent')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (togglePictureBlurred())),
                    class: "v-picture-thumbnail-view sp-un-blur-picture"
                  }, _toDisplayString(_unref(translate)('timeline.parameters.picture.view')), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "v-picture-thumbnail sp-picture-thumbnail",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (openPicture()))
              }, [
                (base64Thumbnail.value?.length > 0)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      alt: "Picture",
                      src: base64Thumbnail.value,
                      class: "v-picture-thumbnail-img"
                    }, null, 8, _hoisted_8))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      alt: "Picture",
                      src: pictureUrl.value,
                      class: "v-picture-thumbnail-img"
                    }, null, 8, _hoisted_9))
              ])),
          (!props.readOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(BaseIcon, {
                  name: "nsx-font-icon--delete_bin",
                  width: "24px",
                  height: "24px",
                  compact: "",
                  "no-hover-background": "",
                  class: "sp-delete-picture",
                  foregroundHoverColor: "black",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('deletePicture', __props.picture)))
                })
              ]))
            : _createCommentVNode("", true)
        ], 32))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(BaseIcon, {
                  class: "v-picture-placeholder-icon",
                  name: "nsx-font-icon--img_placeholder",
                  width: "48px",
                  height: "48px",
                  "no-hover-background": "",
                  "no-hover": "",
                  foregroundHoverColor: "black"
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(translate)('timeline.parameters.picture.imageLoading')), 1)
        ]))
  ], 2))
}
}

})