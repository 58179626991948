import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-signature-consent-container" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-signature-consent-title" }
const _hoisted_5 = { class: "v-header-right" }
const _hoisted_6 = {
  key: 1,
  class: "v-not-completed"
}

import { computed, onBeforeMount, ref } from 'vue';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import UpdateCarePlanTable from '@/careplan/components/UpdateCarePlanTable.vue';
import {
  UpdateCarePlanTableData,
  UpdateCarePlanTableRow,
} from '@/careplan/types/UpdateCarePlanTableData';
import { getNeedsVersions } from '@/_shared/services/careplanApi';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';
import useCarePlanStore from '@/careplan/store/carePlanStore';
import { storeToRefs } from 'pinia';
import {
  getInteractions,
  updateInteraction,
  hasAccessToConsentSignature,
} from '@/timeline/services/interactionsApi';
import { useRouter, useRoute } from 'vue-router';
import IInteraction from '@/timeline/types/IInteraction';
import { ToastType, toast } from '@/_shared/services/nourishHelpers';
import updateCarePlanTableData, {
  updateCarePlanTableDataEntries,
} from '../services/updateCarePlanTable';
import BaseButton from '../../_shared/components/buttons/BaseButton.vue';
import { fetchServiceIdByCodename } from '../services/agreementHistoryApi';
import BaseTooltip from '../../_shared/components/feedback/BaseTooltip.vue';
import ConfirmationModal from '../../_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateCarePlanAgreement',
  props: {
  clientId: {
    type: Number,
    required: true,
  },
  carePlanTerm: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const clientId = ref(props.clientId);
const plannedInteractions = ref<IInteraction[]>([]);
const { translate } = use.helpers();
const carePlanStore = useCarePlanStore();
const { getNewCarePlanReport } = carePlanStore;
const { consentCarePlanReportId, consentCarePlanServiceId } = storeToRefs(carePlanStore);
const router = useRouter();
const route = useRoute();
const loading = ref(true);
const showModal = ref(false);
const hasSavedForLaterSigntrInteraction = ref(false);
const hasAccessToConsentCarePlan = ref(false);

const data = ref({
  headers: [],
  entries: [],
} as UpdateCarePlanTableData);

onBeforeMount(async () => {
  const needsVersions = await getNeedsVersions(clientId.value);
  const updateCarePlanEntries = updateCarePlanTableDataEntries(needsVersions) as UpdateCarePlanTableRow[];

  data.value = updateCarePlanTableData(updateCarePlanEntries);
  data.value.entries.forEach((entry: UpdateCarePlanTableRow) => {
    const formattedDateAndTime = computed(() => formatDateTime(entry.updatedOn));
    entry.updatedOn = formattedDateAndTime.value;
  });

  consentCarePlanServiceId.value = await fetchServiceIdByCodename('consent_care_plan_signature');
  plannedInteractions.value = await getInteractions({
    clientId: clientId.value,
    serviceId: consentCarePlanServiceId.value as number,
    state: 'planned',
  }, { sort: '-time' }, false, false);

  loading.value = false;
  hasSavedForLaterSigntrInteraction.value = plannedInteractions.value.length > 0;
  hasAccessToConsentCarePlan.value = await hasAccessToConsentSignature(clientId.value) as boolean;
});

const hasEntries = computed(() => data.value.entries.length > 0);
const latestPlannedInteraction = computed(() => plannedInteractions.value[0]);

const onUpdateAgreementClick = () => {
  if (hasSavedForLaterSigntrInteraction.value) {
    showModal.value = true;
  } else {
    runAndAttachCarePlanReport();
  }
};

async function runAndAttachCarePlanReport() {
  const { report } = await getNewCarePlanReport(clientId.value);
  if (consentCarePlanReportId.value as number && report?.state === 'ready') {
    await Promise.all(plannedInteractions.value.map(async (interaction: IInteraction) => {
      interaction.state = 'cancelled';
      interaction.notePublic = translate('careplan.new_interaction_created', {
        interaction_name: interaction.name,
      });
      interaction.parameters.forEach((parameter) => {
        delete parameter.value;
      });
      await updateInteraction(interaction);
    }));
    routeBackToTimeline();
  } else {
    toast(translate('ajax.errors.timeout'), ToastType.Danger);
  }
}

function routeBackToTimeline() {
  router.push({
    name: 'client.timeline.interaction_new',
    query: {
      ...route.query,
      interactionState: 'admitted',
      serviceId: consentCarePlanServiceId.value as number,
      date: new Date().toISOString(),
    },
  });
}

function routeBackToInteraction() {
  if (!hasAccessToConsentCarePlan.value) return;
  router.push({
    name: 'client.timeline.interaction',
    params: {
      ...route.query,
      clientId: clientId.value,
      id: latestPlannedInteraction.value?.id,
    },
  });
}


return (_ctx: any,_cache: any) => {
  return (!loading.value)
    ? (_openBlock(), _createBlock(BaseCard, {
        key: 0,
        headerBackground: true,
        padding: true
      }, _createSlots({
        content: _withCtx(() => [
          (hasEntries.value)
            ? (_openBlock(), _createBlock(UpdateCarePlanTable, {
                key: 0,
                data: data.value,
                hasSavedForLaterSigntrInteraction: hasSavedForLaterSigntrInteraction.value,
                hasAccessToConsentCarePlan: hasAccessToConsentCarePlan.value,
                onRouteToInteraction: routeBackToInteraction,
                onUpdateAgreement: onUpdateAgreementClick
              }, null, 8, ["data", "hasSavedForLaterSigntrInteraction", "hasAccessToConsentCarePlan"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(translate)('careplan.no_entries', {
              care_plan_term: __props.carePlanTerm,
            })), 1))
        ]),
        _: 2
      }, [
        (hasEntries.value)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(BaseIcon, {
                        name: "round-icon nsx-font-icon--care_plan_signature",
                        size: "2x",
                        noHover: "",
                        compact: "",
                        backgroundColor: "black",
                        foregroundColor: "white",
                        foregroundHoverColor: "white",
                        class: "v-service-icon"
                      }),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(translate)(`careplan.update_agreement_title`, {
                      care_plan_term: __props.carePlanTerm,
                    })), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(BaseButton, {
                        class: "v-update-agreement-button",
                        disabled: !hasAccessToConsentCarePlan.value,
                        onClicked: onUpdateAgreementClick
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(translate)('careplan.update_agreement')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]),
                      _createVNode(BaseTooltip, {
                        position: "bottom",
                        tooltipText: _unref(translate)('careplan.view_on_timeline')
                      }, {
                        default: _withCtx(() => [
                          (hasSavedForLaterSigntrInteraction.value)
                            ? (_openBlock(), _createBlock(BaseIcon, {
                                key: 0,
                                class: "v-clock",
                                name: "clock-o",
                                size: "2x",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (routeBackToInteraction())),
                                disabled: !hasAccessToConsentCarePlan.value
                              }, null, 8, ["disabled"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["tooltipText"])
                    ]),
                    (showModal.value)
                      ? (_openBlock(), _createBlock(ConfirmationModal, {
                          key: 0,
                          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = false)),
                          onConfirm: _cache[2] || (_cache[2] = ($event: any) => (runAndAttachCarePlanReport())),
                          firstRowText: _unref(translate)(`careplan.cancel_saved_for_later_interaction_1`, {
                care_plan_term: __props.carePlanTerm,
              }),
                          secondRowText: _unref(translate)(`careplan.cancel_saved_for_later_interaction_2`, {
                care_plan_term: __props.carePlanTerm,
              })
                        }, null, 8, ["firstRowText", "secondRowText"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024))
    : _createCommentVNode("", true)
}
}

})