import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "v-status-action-container" }
const _hoisted_3 = { class: "v-loading" }
const _hoisted_4 = { class: "v-delete-bottom-container" }
const _hoisted_5 = { class: "centered-content" }
const _hoisted_6 = { class: "v-delete-clients-content" }
const _hoisted_7 = { class: "v-delete-clients-content-text" }
const _hoisted_8 = { class: "centered-content" }
const _hoisted_9 = { class: "v-delete-clients-content" }
const _hoisted_10 = { class: "sp-v-delete-clients-content-text-container" }
const _hoisted_11 = { class: "v-delete-clients-content-text" }
const _hoisted_12 = { class: "sp-v-clients-list" }

import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import use from '@/_shared/compositionApi';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import useDataRetentionStore from '@/_shared/store/dataRetention';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { DataRetentionReportRow, StatusMap, DataRetentionTableData } from '@/data_retention/types/DataRetentionTableData';
import BaseRightPanel from '@/_shared/components/general/BaseRightPanel.vue';
import { watchOnce } from '@vueuse/core';
import trashIcon from '@/_shared/assets/icons/trash.svg';
import DataRetentionReportTable from './DataRetentionReportTable.vue';
import WaitScreen from './WaitScreen.vue';

const parPage = 100;

export default /*@__PURE__*/_defineComponent({
  __name: 'RetentionReport',
  setup(__props) {

const { translate } = use.helpers();
const router = useRouter();

const DeleteBtnDisabled = ref(true);
const showDeleteModal = ref(false);
const showDeleteConfirmationModal = ref(false);
const toBeDeletedClients = ref<DataRetentionReportRow[]>([]);

const dataRetentionStore = useDataRetentionStore();
const {
  clientsTotalNumber,
  numberClientFetched,
  loading,
} = storeToRefs(dataRetentionStore);
const { activeStates } = dataRetentionStore;
const { getDataToDisplay, getHeaders } = dataRetentionStore;
const clientTerm = computed(() => window.currentOrganisationUnit.client_term);
const clientTerms = computed(() => window.currentOrganisationUnit.client_term_plural);
function enableDeleteButton(enable: boolean) {
  DeleteBtnDisabled.value = !enable;
}
const tableComponent = ref<typeof DataRetentionReportTable| null>(null);
let currentPage = 0;
const data = ref({
  headers: [],
  entries: [],
} as DataRetentionTableData);
const loadFirstPage = () => {
  data.value = {
    headers: getHeaders(),
    entries: getDataToDisplay(0, parPage),
  };
  currentPage = 1;
};
if (!loading.value) {
  loadFirstPage();
} else {
  watchOnce(loading, () => {
    loadFirstPage();
  });
}

const loadNextPage = () => {
  if (currentPage * parPage >= clientsTotalNumber.value) return;
  data.value.entries = [...data.value.entries, ...getDataToDisplay(currentPage * parPage, parPage)];
  currentPage += 1;
};
const reverseOrder = () => {
  dataRetentionStore.reverseDataRetentionTableOrder();
  tableComponent.value!.el.scrollTo(0, 0);
  data.value.entries = [];
  loadFirstPage();
};

function handleDeleteClients(selectedClients: DataRetentionReportRow[]) {
  toBeDeletedClients.value = selectedClients;
}

const organisedToBeDeletedClients = computed(() => organizeClientsByStatus(toBeDeletedClients.value));

function organizeClientsByStatus(clients: DataRetentionReportRow[]): StatusMap {
  return clients.reduce((statusMap, client) => {
    client.state.forEach((state) => {
      if (!statusMap[state]) {
        statusMap[state] = [];
      }
      statusMap[state].push({ clientId: client.clientId, clientName: client.fullName });
    });
    return statusMap;
  }, {} as StatusMap);
}

function closeDeleteModal() {
  showDeleteModal.value = false;
  showDeleteConfirmationModal.value = false;
  router.push({ name: 'admin.data_retention.pending' });
}

const handleDelete = () => {
  const hasActiveClient = toBeDeletedClients.value.some((client) => client.state.some((state) => activeStates.includes(state)));
  if (!hasActiveClient) {
    dataRetentionStore.softDelete(toBeDeletedClients.value.map((client) => client.clientId));
  }
  showDeleteConfirmationModal.value = hasActiveClient;
  showDeleteModal.value = !hasActiveClient;
};

function confirmDeleteConfirmationModal() {
  dataRetentionStore.softDelete(toBeDeletedClients.value.map((client) => client.clientId));
  showDeleteConfirmationModal.value = false;
  showDeleteModal.value = true;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseRightPanel, { withBGImg: false }, {
    default: _withCtx(() => [
      (_unref(loading))
        ? (_openBlock(), _createBlock(WaitScreen, {
            key: 0,
            "clients-total-number": _unref(clientsTotalNumber),
            "number-clients-loaded": _unref(numberClientFetched)
          }, null, 8, ["clients-total-number", "number-clients-loaded"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(translate)('data_retention.loaded', { total_clients: _unref(clientsTotalNumber) })), 1),
              _createVNode(BaseButton, {
                disabled: DeleteBtnDisabled.value,
                onClicked: handleDelete,
                class: "v-delete-selected"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)('data_retention.delete_selected')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _createVNode(DataRetentionReportTable, {
              ref_key: "tableComponent",
              ref: tableComponent,
              class: "v-data-retention-table",
              data: data.value,
              activeStates: _unref(activeStates),
              onEnableDeleteButton: enableDeleteButton,
              onLoadMore: loadNextPage,
              onReverseOrder: reverseOrder,
              onDeleteClients: handleDeleteClients,
              "empty-message": _unref(translate)('data_retention.no_clients', { clientTerm: clientTerms.value })
            }, null, 8, ["data", "activeStates", "empty-message"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(BaseButton, {
                disabled: DeleteBtnDisabled.value,
                onClicked: handleDelete,
                class: "v-delete-selected"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(translate)('data_retention.delete_selected')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            (showDeleteModal.value)
              ? (_openBlock(), _createBlock(BaseModal, {
                  key: 0,
                  title: _unref(translate)('data_retention.delete_modal.archived_clients_title'),
                  "icon-svg": _unref(trashIcon),
                  width: 700,
                  onConfirm: closeDeleteModal,
                  "confirm-text": _unref(translate)('alert.close'),
                  onCloseModal: closeDeleteModal
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(translate)("data_retention.delete_modal.archived_clients_text")), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title", "icon-svg", "confirm-text"]))
              : _createCommentVNode("", true),
            (showDeleteConfirmationModal.value)
              ? (_openBlock(), _createBlock(BaseModal, {
                  key: 1,
                  title: _unref(translate)('data_retention.delete_modal.active_clients_title'),
                  "icon-svg": _unref(trashIcon),
                  width: 700,
                  onConfirm: confirmDeleteConfirmationModal,
                  "confirm-text": _unref(translate)('common.delete'),
                  "cancel-hidden": false,
                  "cancel-text": _unref(translate)('common.cancel'),
                  onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (showDeleteConfirmationModal.value = false)),
                  fromRestoreModal: ""
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organisedToBeDeletedClients.value, (clients, status) => {
                          return (_openBlock(), _createElementBlock("div", { key: status }, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(translate)('data_retention.delete_modal.active_clients_list', {status: status, client_term: clientTerm.value})), 1),
                              _createElementVNode("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(clients, (client) => {
                                  return (_openBlock(), _createElementBlock("p", {
                                    key: client.clientId
                                  }, _toDisplayString(client.clientId) + " " + _toDisplayString(client.clientName), 1))
                                }), 128)),
                                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                              ])
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title", "icon-svg", "confirm-text", "cancel-text"]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }))
}
}

})