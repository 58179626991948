import BaseTableHeader from '@/_shared/types/baseTableHeader';
import { clientStore } from '@/_shared/store/clients';
import use from '@/_shared/compositionApi';
import { PendingDeletionRow, PendingDeletionsTableData } from '../types/DataRetentionTableData';

const { translate } = use.helpers();

const clientTerm = clientStore.clientTerm(false);

const pendingDeletionsTable = (entries: PendingDeletionRow[]): PendingDeletionsTableData => {
  const headers = buildHeaders();
  return { headers, entries };
};

const buildHeaders = () => {
  const headers: BaseTableHeader[] = [
    {
      keyName: 'client',
      displayName: clientTerm,
      type: 'string',
    },
    {
      keyName: 'clientId',
      displayName: translate('data_retention.client_id'),
      type: 'number',
    },
    {
      keyName: 'deletionRequestDate',
      displayName: translate('data_retention.delete_request_date'),
      type: 'string',
    },
    {
      keyName: 'deletionDate',
      displayName: translate('data_retention.planned_deletion_date'),
      type: 'string',
      defaultSort: 'asc',
    },
    {
      keyName: 'requestActionedByNameAndId',
      displayName: translate('data_retention.delete_request_actioned_by'),
      type: 'link',
    },
    {
      keyName: 'restoreClient',
      displayName: translate('data_retention.restore'),
      type: 'button',
    },
  ];
  return headers;
};

export default pendingDeletionsTable;
