import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-pagination-container"
}
const _hoisted_2 = {
  key: 2,
  disabled: true,
  class: "v-pagination-button"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 3,
  disabled: true,
  class: "v-pagination-button"
}

import { ref, watchEffect } from 'vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePaging',
  props: {
  totalPages: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    default: 1,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const currentPage = ref(0);
const { translate } = use.helpers();

watchEffect(() => {
  currentPage.value = props.modelValue;
});

const prev = () => {
  currentPage.value -= 1;
  emit('update:modelValue', currentPage.value);
};

const next = () => {
  currentPage.value += 1;
  emit('update:modelValue', currentPage.value);
};

const setPage = (page: number) => {
  currentPage.value = page;
  emit('update:modelValue', currentPage.value);
};

const showPage = (page: number) => {
  const maximumPage = Math.ceil(currentPage.value / 10) * 10;
  const minimumPage = maximumPage - 9;
  return (page !== props.totalPages && page >= minimumPage && page <= maximumPage);
};

const extraPagesToRight = () => {
  if (props.totalPages < 11) { return false; }
  const currentSetOfPages = Math.ceil(currentPage.value / 10) - 1;
  const finalSetOfPages = Math.ceil(props.totalPages / 10) - 1;
  return props.totalPages > 11 && currentSetOfPages < finalSetOfPages;
};

const extraPagesToLeft = () => {
  if (props.totalPages === 1) { return false; }
  const currentSetOfPages = Math.ceil(currentPage.value / 10);
  return props.totalPages > 11 && currentSetOfPages > 1;
};

return (_ctx: any,_cache: any) => {
  return (__props.totalPages > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (currentPage.value > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: prev,
              class: "v-pagination-button v-previous"
            }, _toDisplayString(_unref(translate)('pagination.previous_label')), 1))
          : _createCommentVNode("", true),
        (currentPage.value > 10 || __props.totalPages === 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (setPage(1))),
              class: _normalizeClass(["v-pagination-button v-pages", 1 === currentPage.value ? 'v-selected-page' : ''])
            }, " 1 ", 2))
          : _createCommentVNode("", true),
        (extraPagesToLeft())
          ? (_openBlock(), _createElementBlock("button", _hoisted_2, " ... "))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.totalPages, (page) => {
          return (_openBlock(), _createElementBlock("div", { key: page }, [
            (showPage(page))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (setPage(page)),
                  class: _normalizeClass(["v-pagination-button v-pages", page === currentPage.value ? 'v-selected-page' : ''])
                }, [
                  _createElementVNode("span", null, _toDisplayString(page), 1)
                ], 10, _hoisted_3))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (extraPagesToRight())
          ? (_openBlock(), _createElementBlock("button", _hoisted_4, " ... "))
          : _createCommentVNode("", true),
        (__props.totalPages > 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (setPage(__props.totalPages))),
              class: _normalizeClass(["v-pagination-button v-pages", __props.totalPages === currentPage.value ? 'v-selected-page' : ''])
            }, _toDisplayString(__props.totalPages), 3))
          : _createCommentVNode("", true),
        ((currentPage.value) < __props.totalPages)
          ? (_openBlock(), _createElementBlock("button", {
              key: 5,
              onClick: next,
              class: "v-pagination-button v-next"
            }, _toDisplayString(_unref(translate)('pagination.next_label')), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})