import { DirectiveBinding } from 'vue';

const vBaseTooltip = {
  mounted(el: Element, binding: DirectiveBinding) {
    init(el, binding);
  },
  updated(el: Element, binding: DirectiveBinding) {
    init(el, binding);
  },
};

const init = (el: Element, binding: DirectiveBinding) => {
  const position = binding.value.position || 'bottom';
  const tooltipText = binding.value.tooltipText || 'Tooltip text';
  const hide = binding.value.hide || 'false';
  el.setAttribute('position', position);
  el.setAttribute('hide', hide);
  el.setAttribute('baseTooltip', tooltipText);
};

export default vBaseTooltip;
