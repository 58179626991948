import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-inline-multi-choice-parameter-container" }
const _hoisted_2 = { class: "v-inline-multi-choice-col" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "v-params-entry-selector"
}
const _hoisted_5 = { class: "v-inline-multi-choice-label" }

import useParameterValidator from '@/timeline/helper/useParameterValidator';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import use from '@/_shared/compositionApi';
import { toRef, computed, Ref } from 'vue';
import { CoercedChoice, Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'InlineMultipleChoiceParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: -1,
  },
},
  setup(__props) {

const props = __props;
// todo problem with reactivity in choiceMatchesCoercedValue
const { translate } = use.helpers();
const showScore = props.parameter.valueType === 'number';
const { errorMessage } = useParameterValidator(props.parameter);

const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<CoercedChoice | null>;

function choiceMatchesCoercedValue(choice: CoercedChoice | null) {
  if (coercedValue.value && choice) {
    // eslint-disable-next-line eqeqeq
    return choice.value == coercedValue.value.value && choice.label == coercedValue.value.label;
  }
  if (coercedValue.value == null && choice == null) {
    return true;
  }
  return false;
}

function getOptionLabel(option: CoercedChoice | null) {
  if (option && typeof option.label === 'string') {
    return option.label;
  }
  return props.parameter.isComputed ? '' : translate('common.none');
}

function selectOptionAndCalculate(choice: CoercedChoice | null) {
  if (props.readOnly) {
    return;
  }
  coercedValue.value = choice;
}

const getChoices = computed(() => {
  const currentChoices = props.parameter.coercedChoices;
  if (props.parameter.required || props.parameter.isComputed) {
    return currentChoices;
  }
  return [null, ...currentChoices];
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true,
      showScore: showScore,
      errorMessage: _unref(errorMessage) || undefined
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getChoices.value, (choice, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["v-inline-multi-choice-input", {checked: choiceMatchesCoercedValue(choice)}]),
              key: index,
              onClick: ($event: any) => (selectOptionAndCalculate(choice))
            }, [
              (!__props.parameter.isComputed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, _toDisplayString(getOptionLabel(choice)) + " " + _toDisplayString(__props.parameter.unit), 1)
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["parameter", "errorMessage"])
  ]))
}
}

})