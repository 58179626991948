import { Parameter, ParameterPicture, PictureWrapper } from '@/timeline/types/Parameter';
import apiClient from '@/_shared/services/apiClient';
import { uuid } from 'vue-uuid';
import IInteraction from '@/timeline/types/IInteraction';
import { ref } from 'vue';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import GenericFile from '../types/GenericFile';

interface GenericFileApiResponse {
  genericFile: GenericFile
}

export function constructFileUrl(relativeUrl: string, inline: boolean) {
  const deviceUuid: null | string = window.localStorage.getItem('deviceUUID');
  if (deviceUuid) {
    relativeUrl += `?device_uuid=${deviceUuid}`;
  }
  if (inline) {
    relativeUrl += '&disposition=inline';
  }
  return relativeUrl;
}

const allowedContentTypes = ref<string[]>([]);
const lastFetchedAllowedContentTypes = ref<number>(0);

export const updateGenericFile = async (genericFile: GenericFile) => {
  const url = `/api/v2/generic_files/${genericFile.id}`;
  try {
    const response = (await apiClient.put<GenericFileApiResponse>(url, { genericFile }));
    return response.genericFile;
  } catch (errorsResponse) {
    debugger;
  }
  return <GenericFile>{};
};

export const deleteGenericFile = async (genericFile: GenericFile) => {
  if (!genericFile.id) return genericFile;
  const url = `/api/v2/generic_files/${genericFile.id}`;
  try {
    return await apiClient.delete<GenericFileApiResponse>(url);
  } catch (errorsResponse) {
    debugger;
  }
  return <GenericFile>{};
};

const validContentType = async (file: File) => {
  const allowedFileContentTypes = await getAllowedContentTypes();
  if (allowedFileContentTypes.length === 0) return true;
  return allowedFileContentTypes.includes(file.type);
};

export const createGenericFile = async (orgUnitId: number, data: FormData) => {
  if (await validContentType(data.get('generic_file[attachment]') as File)) {
    const url = `/api/v2/organisation_units/${orgUnitId}/generic_files`;
    try {
      const response = (await apiClient.post<GenericFileApiResponse, FormData>(url, data));
      return response.genericFile;
    } catch (errorsResponse) {
      debugger;
    }
    return <GenericFile>{};
  }
  return <GenericFile>{};
};

export const createFile = async (parameter: Parameter, interaction: IInteraction) => {
  if (parameter.valueType === 'picture') {
    if (interaction.organisationUnitId) {
      const coercedValue = ref(parameter.coercedValue as PictureWrapper);
      await Promise.all(
        coercedValue.value.pictures.map(async (picture: ParameterPicture, index: number) => {
          if (picture.tempFile && !picture.pictureFile) {
            const formData = mapFile(parameter, interaction, picture.tempFile);
            await createGenericFile(interaction.organisationUnitId, formData)
              .then((genericFile: GenericFile | undefined) => {
                if (genericFile !== undefined) {
                  picture.pictureFile = genericFile;
                  picture.position = index;
                  picture.tempFile = null;
                }
              });
          }
        }),
      );
    }
  } else {
    if (!parameter.tempFile) { return; }
    const formData = mapFile(parameter, interaction, parameter.tempFile);
    if (interaction.organisationUnitId) {
      const genericFile = await createGenericFile(interaction.organisationUnitId, formData);
      if (genericFile !== undefined) {
        parameter.coercedValue = { file: genericFile };
      }
      parameter.tempFile = null;
    }
  }
};

export const getAllowedContentTypes = () => {
  const { featureToggles } = storeToRefs(useUserStore());
  if (featureToggles.value.uploadFilesFiltering && lastFetchedAllowedContentTypes.value < (new Date().getTime() - 6000000)) {
    const url = '/api/v2/generic_files/allowed_content_types';
    try {
      apiClient.get<string[]>(url).then((response) => {
        allowedContentTypes.value = response;
        lastFetchedAllowedContentTypes.value = new Date().getTime();
      });
    } catch (errorsResponse) {
      debugger;
    }
  }
  return allowedContentTypes.value;
};

const mapFile = (parameter: Parameter, interaction: IInteraction, tempFile: File) => {
  const fileData: FileData = {
    parameter_id: parameter.id,
    attachment: tempFile,
    uuid: uuid.v4(),
    person_id: interaction.clientId,
    service_id: interaction.serviceId,
  };

  if (interaction.id) {
    fileData.interaction_id = interaction.id;
  }

  if (parameter.valueType === 'picture') {
    fileData.sensitive = !!(parameter.coercedValue as PictureWrapper)?.blurred;
  }

  const formData = new FormData();
  Object.entries(fileData).forEach((entry) => formData.append(`generic_file[${entry[0]}]`, entry[1] as string | Blob));
  return formData;
};

export const updateFile = async (file: GenericFile, parameter: Parameter) => {
  file.updatedLabel = false;
  await updateGenericFile(file)
    .then((genericFile: GenericFile | undefined) => {
      if (genericFile !== undefined && parameter.valueType === 'file') {
        parameter.genericFile = genericFile;
        parameter.coercedValue = {
          file: genericFile,
        };
      } else if (genericFile !== undefined && parameter.valueType === 'picture') {
        (parameter.coercedValue as PictureWrapper).pictures.forEach((picture: ParameterPicture) => {
          if (picture.pictureFile?.id === genericFile.id) {
            picture.pictureFile = genericFile;
          }
        });
      }
    });
};

export const deleteFile = async (file: GenericFile, parameter: Parameter) => {
  file.deleted = false;
  await deleteGenericFile(file)
    .then(() => {
      file.destroyed = true;
      parameter.coercedValue = null;
      parameter.genericFile = null;
    });
};

export const softDeleteFile = async (file: GenericFile, parameter: Parameter) => {
  file.deleted = false;
  parameter.coercedValue = null;
  parameter.genericFile = null;
};

interface FileData {
  parameter_id: string | number
  attachment?: File | null
  uuid: string
  person_id: number
  interaction_id?: number
  service_id?: number
  file_label?: string
  sensitive?: boolean
}
