import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-instance-reference" }

import BaseListEntry from '@/_shared/components/display/BaseListEntry.vue';
import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstanceListEntry',
  props: {
    title: {
      type: String,
      required: true,
    },
    additionalHeaders: {
      type: Array as PropType<string[]>,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: false,
    },
  },
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-instance-list-entry", {'v-instance-list-entry-with-icon': __props.icon}])
  }, [
    _createVNode(BaseListEntry, {
      "inverted-colour": "",
      "with-icon": __props.icon,
      title: __props.title,
      extended: "",
      "font-size": "16px"
    }, _createSlots({
      headerMainCol: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.additionalHeaders, (header, index) => {
          return (_openBlock(), _createElementBlock("h2", {
            key: header,
            class: _normalizeClass(["v-additional-header v-main-title", `v-additional-header-${index + 1}`])
          }, _toDisplayString(header), 3))
        }), 128))
      ]),
      extendedMainCol: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(__props.reference), 1),
        (_ctx.$slots.footer)
          ? _renderSlot(_ctx.$slots, "footer", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      headerRightCol: _withCtx(() => [
        _renderSlot(_ctx.$slots, "eventDateTime")
      ]),
      _: 2
    }, [
      (__props.icon)
        ? {
            name: "customIcon",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "customIcon")
            ]),
            key: "0"
          }
        : undefined,
      (__props.icon)
        ? {
            name: "extendedLeftCol",
            fn: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-extended-left-col" }, null, -1))
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["with-icon", "title"])
  ], 2))
}
}

})