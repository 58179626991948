import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  computed, PropType, provide, ref, inject,
} from 'vue';
import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import {
  Options, Option, OptGroup, DataSetCompositeOption,
} from '@/_shared/types/baseSelect';
import DatasetModalContent from '@/_shared/components/modals/DatasetModal/DatasetModalContent.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'DatasetModal',
  props: {
  title: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
  },
  searchInputPlaceholder: {
    type: String,
  },
  selectableEntries: {
    type: Object as PropType<Options>,
    default: () => [] as Options,
  },
  selectedEntries: {
    type: Object as PropType<Options>,
  },
  primaryTextKey: {
    type: String as PropType<keyof DataSetCompositeOption>,
    required: true,
  },
  secondaryTextKey: {
    type: String,
  },
  selectorType: {
    type: String,
    default: 'single',
  },
  showOptions: {
    type: Boolean,
    default: false,
  },
  isSingle: {
    type: Boolean,
    default: false,
  },
},
  emits: ['updatedSelectedEntries', 'closeModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

provide('canClearBaseSelect', false);

const emit = __emit;
const localSelectedEntries = ref(props.selectedEntries);
const selectedEntriesValues: string[] = Object.values(localSelectedEntries.value || []);
const updateSelectedEntries = ((updatedSelectedEntries: Options) => {
  localSelectedEntries.value = updatedSelectedEntries;
});

const updatedSelectedEntries = () => {
  emit('updatedSelectedEntries', localSelectedEntries.value);
};

const transformedDataSetOptions = computed((): DataSetCompositeOption[] => {
  if (props.selectableEntries.length === 0) {
    return [];
  }
  const firstOption = props.selectableEntries[0];
  if (typeof firstOption === 'object' && 'optGroup' in firstOption) {
    // this is an optionGroup -> GroupedDataFormat
    return (props.selectableEntries as OptGroup[]).flatMap((g) => ([
      {
        value: g.optGroup, label: g.optGroup, disabled: true, text: '',
      },
      ...g.options.map((o) => transformOption(o, g)),
    ]));
  }
  return (props.selectableEntries as Option[]).map((o) => transformOption(o));
});

function transformOption(option: Option, group?: OptGroup): DataSetCompositeOption {
  return (typeof option === 'string' || typeof option === 'number')
    ? { value: option, text: option.toString(), group: group?.optGroup }
    : {
      value: option.value,
      text: option.text,
      group: group?.optGroup,
      photo: option.photo,
      state: option.state,
      disabled: option.disabled,
    };
}

const modalConfig = inject('dataSetModalConfig', undefined) as DataSetModalConfig|undefined;
const modalWidth = modalConfig?.leftContentComponent ? 860 : 800;
const modalTitle = () => modalConfig?.modalTitle || props.title;
const modalSearchInputPlaceHolder = () => modalConfig?.modalSearchInputPlaceholder || props.searchInputPlaceholder;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    title: modalTitle(),
    footer: false,
    width: _unref(modalWidth),
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    header: _withCtx(() => [
      _createVNode(BaseButton, {
        class: "v-save-dataset",
        onClicked: updatedSelectedEntries,
        size: "large"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.buttonText), 1)
        ]),
        _: 1
      })
    ]),
    content: _withCtx(() => [
      _createVNode(DatasetModalContent, {
        searchInputPlaceholder: modalSearchInputPlaceHolder(),
        selectableEntries: transformedDataSetOptions.value,
        primaryTextKey: __props.primaryTextKey,
        selectedEntries: _unref(selectedEntriesValues),
        selectorType: __props.selectorType,
        isSingle: __props.isSingle,
        onSelectedEntries: updateSelectedEntries
      }, null, 8, ["searchInputPlaceholder", "selectableEntries", "primaryTextKey", "selectedEntries", "selectorType", "isSingle"])
    ]),
    _: 1
  }, 8, ["title", "width"]))
}
}

})