// construct and export default singleton client
// because of circular references (?) this must be in a separate file

import { InteractionAction } from '@/_shared/types/interaction_action';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import apiClient from '@/_shared/services/apiClient';

interface InteractionActionsApiResponse {
  actions: InteractionAction[]
}
export default async function fetchInteractionActions(codename : string) {
  const { currentOrgUnitId } = storeToRefs(useUserStore());
  const url = `/api/v2/organisation_units/${currentOrgUnitId.value}/interaction_actions/${codename}`;
  try {
    const actionsResponse = await apiClient.get<InteractionActionsApiResponse>(url, {});
    return actionsResponse.actions || [] as InteractionAction[];
  } catch (error) {
    return [] as InteractionAction[];
  }
}
