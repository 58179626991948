import { InteractionLogTableDataEntry } from '@/timeline/types/InteractionLogData';
import BaseTableHeader from '@/_shared/types/baseTableHeader';

const filterOutEmpties = (entries: InteractionLogTableDataEntry[], headers: BaseTableHeader[]): [InteractionLogTableDataEntry[], BaseTableHeader[]] => {
  const permanentKeys = [
    'finishAt',
    'savedAt',
    'responsiblePeople',
    'originatorId',
    'state',
    'dailyNote',
    'handover',
  ];
  const keys = headers
    .filter((header) => !permanentKeys.includes(header.keyName))
    .map((header) => header.keyName);

  keys.forEach((key) => {
    const emptyColumn = entries.every((entry) => (entry[key] == null || entry[key] === ''));
    if (emptyColumn) {
      entries.forEach((entry) => {
        delete entry[key];
      });
      headers = headers.filter((header) => header.keyName.toString() !== key.toString());
    }
  });

  return [entries, headers];
};

export default filterOutEmpties;
