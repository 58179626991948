import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-document-wrapper" }
const _hoisted_2 = { class: "v-document-type-icon" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "v-document-line" }
const _hoisted_5 = { class: "v-document-details" }
const _hoisted_6 = { class: "v-document-name" }
const _hoisted_7 = { class: "v-document-uploaded-at" }
const _hoisted_8 = { class: "v-document-actions" }
const _hoisted_9 = { class: "v-document-actions-icon preview" }
const _hoisted_10 = { class: "v-document-actions-icon download" }

import {
  computed, PropType, ref,
} from 'vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { GenericFile } from '@/_shared/types/genericFile';
import use from '@/_shared/compositionApi';
import { formatDateTime } from '@/_shared/helpers/useDateTimeHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDocument',
  props: {
  document: {
    type: Object as PropType<GenericFile>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const descriptionCollapsed = ref(true);
const { translate } = use.helpers();
const documentDescriptionTextElement = ref();
const documentURL = (inline: boolean) => {
  const deviceUuid = window.localStorage.getItem('deviceUUID');
  const params = `?device_uuid=${deviceUuid}`;
  if (inline) {
    window.open(`${props.document.relativeUrl}${params.concat('&disposition=inline')}`, '_blank');
  } else {
    window.open(`${props.document.relativeUrl}${params}`);
  }
};

const contentType = ref((props.document.contentType as string) || '');

const previewDocument = () => {
  documentURL(true);
};

const downloadDocument = () => {
  documentURL(false);
};

const previewableDocument = computed(() => {
  const previewableExtensions = /pdf|png|svg|bmp|gif|jpg|jpeg|html|htm/;
  return previewableExtensions.test(contentType.value.toLocaleLowerCase());
});

const descriptionClass = computed(() => (descriptionCollapsed.value ? 'collapsed' : 'expanded'));

const hasDescription = computed(() => props.document.fileDescription && props.document.fileDescription.length);

const hasExpandableDescription = computed(() => {
  if (!hasDescription.value) {
    return false;
  }
  const elem = documentDescriptionTextElement;
  if (elem.value === undefined || elem.value === null) {
    return false;
  }
  return (elem.value.scrollHeight - elem.value.clientHeight > 2) || !descriptionCollapsed.value;
});

const expandDescriptionText = computed(() => (descriptionCollapsed.value ? translate('timeline.interaction.seeMore') : translate('timeline.interaction.collapseText')));

const toggleExpandDescription = () => {
  descriptionCollapsed.value = !descriptionCollapsed.value;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        alt: __props.document.fileLabel,
        src: __props.document.iconPath
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(__props.document.fileLabel || __props.document.fileName), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(translate)('document.uploadedAt')) + " " + _toDisplayString(__props.document.createdAt ? _unref(formatDateTime)(new Date(__props.document.createdAt)) : ''), 1),
        _createElementVNode("p", {
          class: _normalizeClass(["v-document-description", descriptionClass.value]),
          ref_key: "documentDescriptionTextElement",
          ref: documentDescriptionTextElement
        }, _toDisplayString(__props.document.fileDescription), 3),
        (hasExpandableDescription.value)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "v-document-description-expand-text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleExpandDescription()))
            }, _toDisplayString(expandDescriptionText.value), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(BaseTooltip, {
            position: "bottom",
            tooltipText: _unref(translate)('common.preview')
          }, {
            default: _withCtx(() => [
              (previewableDocument.value)
                ? (_openBlock(), _createBlock(BaseIcon, {
                    key: 0,
                    name: "eye",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (previewDocument())),
                    "foreground-color": "grey",
                    compact: "",
                    class: "sp-preview-file",
                    align: "right",
                    size: "lg"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["tooltipText"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(BaseTooltip, {
            position: "bottom",
            tooltipText: _unref(translate)('common.download')
          }, {
            default: _withCtx(() => [
              _createVNode(BaseIcon, {
                name: "download",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (downloadDocument())),
                "foreground-color": "grey",
                compact: "",
                class: "sp-download-file",
                align: "right",
                size: "lg"
              })
            ]),
            _: 1
          }, 8, ["tooltipText"])
        ])
      ])
    ])
  ]))
}
}

})