import BaseTableHeader from '@/_shared/types/baseTableHeader';
import { clientStore } from '@/_shared/store/clients';
import use from '@/_shared/compositionApi';
import { DataRetentionReportRow, DataRetentionTableData } from '../types/DataRetentionTableData';

const { translate } = use.helpers();

const clientTerm = clientStore.clientTerm(false);

const dataRetentionTable = (entries: DataRetentionReportRow[]): DataRetentionTableData => {
  const headers = buildHeaders();

  return { headers, entries };
};

const buildHeaders = () => {
  const headers: BaseTableHeader[] = [
    {
      keyName: 'fullName',
      displayName: clientTerm,
      type: 'string',
    },
    {
      keyName: 'clientId',
      displayName: translate('data_retention.client_id'),
      type: 'number',
    },
    {
      keyName: 'birthDate',
      displayName: translate('data_retention.date_of_birth'),
      type: 'string',
    },
    {
      keyName: 'healthNumber',
      displayName: translate('data_retention.health_number'),
      type: 'string',
    },
    {
      keyName: 'orgUnitName',
      displayName: translate('data_retention.service'),
      type: 'string',
    },
    {
      keyName: 'state',
      displayName: translate('data_retention.status'),
      type: 'string',
    },
    {
      keyName: 'lastStatusUpdate',
      displayName: translate('data_retention.date_status_last_updated'),
      type: 'string',
    },
    {
      keyName: 'durationOfLastStatus',
      displayName: translate('data_retention.duration_latest_status'),
      type: 'number',
    },
    {
      keyName: 'lastInteractionDate',
      displayName: translate('data_retention.last_interaction_date'),
      type: 'string',
    },
    {
      keyName: 'elapsedTime',
      displayName: translate('data_retention.elapsed_time'),
      type: 'number',
      defaultSort: 'desc',
    },
    {
      keyName: 'selectForDeletion',
      displayName: translate('data_retention.select_for_deletion'),
      type: 'checkbox',
    },
  ];
  return headers;
};

export default dataRetentionTable;
