import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BaseListEntry from '@/_shared/components/display/BaseListEntry.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericListEntry',
  props: {
  icon: {
    type: String,
    default: '',
  },
  title: {
    type: String,
  },
},
  setup(__props) {

// to be used in ServiceCounts/Client/Carer etc.




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseListEntry, {
    "custom-icon": "",
    extended: "",
    title: __props.title
  }, {
    personBadges: _withCtx(() => [
      _renderSlot(_ctx.$slots, "personBadges")
    ]),
    customIcon: _withCtx(() => [
      _createVNode(BaseIcon, {
        name: __props.icon,
        width: "90px",
        height: "90px",
        border: "",
        compact: "",
        "no-hover": ""
      }, null, 8, ["name"])
    ]),
    headerMainCol: _withCtx(() => [
      _renderSlot(_ctx.$slots, "additionalInformation")
    ]),
    _: 3
  }, 8, ["title"]))
}
}

})