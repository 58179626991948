
import { computed, defineComponent, PropType } from 'vue';
import { Photo } from '@/_shared/store/people';

export default defineComponent({
  props: {
    photo: {
      type: Object as PropType<Photo>,
    },
    open: {
      type: Boolean,
      default: false,
    },
    withBGImg: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const photoSrc = computed(() => {
      const { photo } = props;
      return (photo && photo.large) ? photo.large!.url : 'default-avatar.png';
    });

    return {
      photoSrc,
    };
  },
});
