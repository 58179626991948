import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "v-pupil-size-parameter-container" }
const _hoisted_2 = { class: "v-param-pupil-size-col" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "v-params-entry-param-label" }

import { PropType, toRef, Ref } from 'vue';
import BaseParameter from '@/timeline/interaction_parameters/BaseParameter.vue';
import { CoercedChoice, Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'PupilSizeParameter',
  props: {
  parameter: {
    type: Object as PropType<Parameter>,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<CoercedChoice | null>;

function selectOptionAndCalculate(choice: CoercedChoice | null) {
  if (props.readOnly === false) {
    coercedValue.value = choice;
  }
}

function choiceMatchesCoercedValue(choice: CoercedChoice) {
  if (coercedValue.value && choice) {
    return choice.value === coercedValue.value?.value;
  }
  return false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: true
    }, {
      "second-line": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.parameter.coercedChoices, (choice, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["v-params-entry-pupil-size-input", {checked: choiceMatchesCoercedValue(choice), disabled: __props.readOnly}]),
              key: index,
              onClick: ($event: any) => (selectOptionAndCalculate(choice))
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "v-params-entry-pupil-size-graph" }, null, -1)),
              _createElementVNode("div", _hoisted_4, _toDisplayString(choice.label) + " " + _toDisplayString(__props.parameter.unit), 1)
            ], 10, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["parameter"])
  ]))
}
}

})