import { Report } from '@/_shared/types/report';
import * as apiReports from '@/_shared/services/reportsApi';
import useUserStore from '@/_shared/store/user';
import { ref } from 'vue';
import { defineStore } from 'pinia';

const useReportStore = defineStore('report', () => {
  const newReport = ref<Partial<Report>>({});
  const reports = ref<Report[]>([]);

  const buildNewReport = (organisationUnitId: number): void => {
    newReport.value = {
      id: 0,
      organisationUnitId,
      title: '',
      format: 'html',
      state: 'draft',
      stateDetail: '',
      params: {
        pageBreak: false,
        pageNumbering: false,
        redactionOption: 'none',
        fields: [],
      },
      file: {
        url: '',
      },
      createdAt: new Date(),
      updatedAt: new Date(),
      reportClass: 'InteractionReport',
      uuid: null,
    };
  };

  const fetchAll = async (orgUnitId = useUserStore().currentOrgUnitId): Promise<void> => {
    reports.value = await apiReports.fetchAllReports(orgUnitId);
  };

  const fetchReport = async (ids: number[]): Promise<Report> => apiReports.fetchReport(ids[0]);

  const deleteReport = (id: number): void => {
    apiReports.deleteReport(id).then(() => {
      reports.value = reports.value.filter((report) => report.id !== id);
    });
  };

  const createReport = (report: Report, clientIds: number[], fields: string[]): void => {
    apiReports.createReport(report, clientIds, fields);
  };

  return {
    newReport,
    buildNewReport,
    fetchAll,
    fetchReport,
    deleteReport,
    createReport,
  };
});

export default useReportStore;
