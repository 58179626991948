import { Ref } from 'vue';
import { DateWindow, GpConnectRequest, GpConnectResponse } from '../types/gp_connect';

export const createRequestParams = (startDate: Date, endDate: Date, applyDates: boolean, clientId: number, section: string) => {
  let dateWindow: (DateWindow | null) = null;
  if (applyDates) {
    dateWindow = {
      start: startDate,
      end: endDate,
    };
  }
  const requestParams: GpConnectRequest = {
    clientId,
    dataCategory: section,
    dateWindow,
  };
  return requestParams;
};

export const updateValues = (gpcResponse: GpConnectResponse, gpConnectRecord: Ref<GpConnectResponse|undefined>, displayLoading: Ref<boolean>, successfulRequest: Ref<boolean>, errorType: Ref<string>) => {
  gpConnectRecord.value = gpcResponse;
  displayLoading.value = false;

  if (gpConnectRecord.value !== undefined) {
    if (gpConnectRecord.value.divResponse) {
      successfulRequest.value = true;
    } else {
      successfulRequest.value = false;
      updateErrorType(gpConnectRecord.value, errorType);
    }
  }
};

export const updateErrorType = (gpConnectRecord: GpConnectResponse, errorType: Ref<string>) => {
  if (gpConnectRecord.demographicsError) {
    errorType.value = 'demographics';
  } else if (gpConnectRecord.errorMessage) {
    errorType.value = 'error_message';
  } else {
    errorType.value = 'error';
  }
};
