import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-searchable-list" }

import DOMPurify from 'dompurify';
import { computed, PropType, ref } from 'vue';
import { SelectorSection } from '@/_shared/types/baseSearchableList';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseSearchableListSection from '@/_shared/components/general/BaseSearchableListSection.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import { CloseRightPanelIcon } from '@/_shared/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSearchableList',
  props: {
  title: {
    type: String,
    default: '',
  },
  sections: {
    type: Array as PropType<SelectorSection[]>,
    required: true,
  },
  closeCallback: {
    type: Function as PropType<() => void>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const searchString = ref('');

const filteredSections = computed(() => props.sections.reduce((filtered, section) => {
  if (!searchString.value) filtered.push(section);
  else if (section.searchable) {
    const filteredSection = { ...section };
    filteredSection.items = filteredSection.items.filter((item) => matchMultipleWordsInAnyOrder(item.name, searchString.value));
    filtered.push(filteredSection);
  }
  return filtered;
}, [] as SelectorSection[]));

function matchMultipleWordsInAnyOrder(item: string, search: string): boolean {
  const searchWords = DOMPurify.sanitize(search).toLowerCase().split(' ');
  const targetLower = item.toLowerCase();
  return searchWords.every((word: string) => targetLower.includes(word));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(CloseRightPanelIcon), {
      onCloseRightPanel: props.closeCallback
    }, null, 8, ["onCloseRightPanel"]),
    _createVNode(BaseCard, {
      padding: false,
      "header-background": ""
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.title), 1)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(BaseInput, {
            modelValue: searchString.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchString).value = $event)),
            border: "",
            icon: "search"
          }, null, 8, ["modelValue"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSections.value, (section) => {
            return (_openBlock(), _createBlock(BaseSearchableListSection, {
              key: section.header,
              section: section
            }, null, 8, ["section"]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ], 64))
}
}

})