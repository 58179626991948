import { defineStore } from 'pinia';
import { generateCarePlanReport } from '@/_shared/services/careplanApi';
import { ref } from 'vue';

const useCarePlanStore = defineStore('carePlan', () => {
  const consentCarePlanReportId = ref<number | null>(null);
  const consentCarePlanServiceId = ref<number | null>(null);

  const getNewCarePlanReport = async (clientId: number) => {
    const response = await generateCarePlanReport(clientId);
    consentCarePlanReportId.value = response?.report?.id;
    return response;
  };

  const resetConsentCarePlanData = () => {
    consentCarePlanReportId.value = null;
    consentCarePlanServiceId.value = null;
  };

  return {
    consentCarePlanReportId,
    consentCarePlanServiceId,
    getNewCarePlanReport,
    resetConsentCarePlanData,
  };
});

export default useCarePlanStore;
