import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import InteractionPlusButton from '@/timeline/components/InteractionPlusButton.vue';
import useFilterNewEventServices from '@/events/composables/eventServices';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsPlusButtonWrapper',
  setup(__props) {

const filteredNewEventServices = useFilterNewEventServices();

const showEventsPlusButton = computed(() => filteredNewEventServices.value && filteredNewEventServices.value.length);


return (_ctx: any,_cache: any) => {
  return (showEventsPlusButton.value)
    ? (_openBlock(), _createBlock(InteractionPlusButton, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})