// this is router used while migrating the site from Angular to Vue
// but most likely routerAngular will continue to be used
// and renamed to 'router'

import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
} from 'vue-router';
import allRoutes, { allAllowedRoutes } from '@/_shared/router/routes';
import useAuditTrailStore from '@/_shared/store/auditTrail';

const legacyRouter = createRouter({
  history: createWebHashHistory('/'),
  routes: allRoutes,
});

let allowedRoutes = [] as (string | symbol | null | undefined)[];

const calculateAllowedRoutes = () => {
  const userStore = useUserStore();
  const {
    currentUser,
  } = storeToRefs(userStore);
  const {
    userCan,
    userHasPermission,
    getFeatureToggle,
    isSuperuser,
    isImpersonated,
  } = useUserStore();
  const routeAuthContext = {
    currentUser: currentUser.value,
    userCan,
    userHasPermission,
    getFeatureToggle,
    isSuperuser,
    isImpersonated,
  };
  allowedRoutes = allAllowedRoutes(routeAuthContext);
};

// eslint-disable-next-line consistent-return, @typescript-eslint/no-unused-vars
legacyRouter.beforeEach(async (to, from) => {
  // HACK this needs to work with Vue authentication store and detect whether user is logged in or not
  if (window.location.pathname.startsWith('/login')) { return false; }

  if (to.fullPath === from.fullPath && to.meta.syncAngular) { return false; }
  if (to.meta.skipAuth) {
    console.log(`vue route skipAuth '${to.name?.toString()}' ... ${to.fullPath}`);
    return true;
  }

  const needsRefresh = await useUserStore().ensureLoaded();
  if (needsRefresh || allowedRoutes.length === 0) { calculateAllowedRoutes(); }

  if (allowedRoutes.includes(to.name) || isAllowedGpConnectRoutes(to)) {
    console.log(`vue route '${to.name?.toString()}' ... ${to.fullPath}`);
    return true;
  }

  console.log(`vue route not allowed '${to.name?.toString()}' ... ${to.fullPath}`);
  // { name: 'NotFound', meta: { skipAuth: true } };
  return false;
});

function isAllowedGpConnectRoutes(to : RouteLocationNormalized) {
  if (to.name !== 'gpConnect') return false;
  const fullName = ['client.profile.gpConnect', `${to.params.section || ''}`].filter(Boolean).join('.');
  return allowedRoutes.includes(fullName);
}

legacyRouter.afterEach((to, from, failure) => {
  // failure includes cancelled navigation
  if (failure) return;
  // debugger;
  // Angular sometimes changes state/route multiple times
  if (to.fullPath === from.fullPath) return;

  const { getFeatureToggle } = useUserStore();
  if (getFeatureToggle('useUserAudit')) useAuditTrailStore().logRouteAccess(to, from);

  // eslint-disable-next-line no-restricted-globals
  if (from?.name && from.name !== 'NotFound' && to.meta.refresh && !from.meta.isAngular && history.state.needsRefresh) {
    console.log('vue router - force refresh');
    window.location.reload();
  }

  if (from?.name && to.meta.isAngular) {
    console.log('vue router - back to angular');
    window.location.replace(window.location.href);
  } else if (to.meta.needSyncAngular && from.meta.needSyncAngular) {
    to.meta.syncAngular = true;
    window.location.replace(window.location.href);
  }
});

export default legacyRouter;
