import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multiselect-single-label" }

import {
  computed, inject, PropType, ref, watch,
} from 'vue';
import Multiselect from '@vueform/multiselect';
import {
  Options, Option, OptGroup, DataSetCompositeOption, TransformedOption,
} from '@/_shared/types/baseSelect';
import { useInputMethods } from '@/_shared/helpers/useInputMethods';
import CustomTag from '@/_shared/components/display/SelectCustomTag.vue';
import use from '@/_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSelect',
  props: {
  modelValue: {
    type: [Number, String, Array],
  },
  placeholder: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  options: {
    type: Object as PropType<Options>,
    default: () => [] as Options,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  canClear: {
    type: Boolean,
    default: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  settings: {
    type: Object,
    default: () => ({}),
  },
  border: {
    type: Boolean,
    default: false,
  },
  labelWidth: {
    type: String,
    default: undefined,
  },
  boldLabel: {
    type: Boolean,
    default: undefined,
  },
  inputWidth: {
    type: String,
    default: '100%',
  },
  inputHeight: {
    type: String,
    default: 'auto',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  showOptions: {
    type: Boolean,
    default: true,
  },
  removeIconDisplay: {
    type: String,
    default: 'flex',
  },
  closeOnSelect: {
    type: String,
  },
  customTags: {
    type: Boolean,
    default: false,
  },
  sort: {
    type: Boolean,
    default: false,
  },
  afterLabel: {
    type: String,
    default: '',
  },
  ellipsis: {
    type: Number,
    default: 0,
  },
},
  emits: ['update:modelValue', 'openModal'],
  setup(__props, { emit: __emit }) {

_useCssVars(_ctx => ({
  "95bdae3e": (__props.removeIconDisplay)
}))

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

const {
  inputClass, inputStyle, labelClass, labelStyle,
} = useInputMethods(props);
const selected = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

function sortOptions(a: { label: string }, b: { label: string }) {
  return a.label.localeCompare(b.label);
}

const transformedOptions = computed(() => {
  if (props.options.length === 0) {
    return [];
  }
  const firstOption = props.options[0];
  if (typeof firstOption === 'object' && 'optGroup' in firstOption) {
    // this is an optionGroup -> GroupedDataFormat
    let opts = (props.options as OptGroup[]).flatMap((g) => [
      { value: g.optGroup, label: g.optGroup, disabled: true },
      ...g.options.map(transformOption),
    ]);

    // TODO: Is this needed below? It was to make sure the removed value was shown.
    if (Array.isArray(selected.value)) {
      const removed = selected.value.filter((v) => !opts.some((o) => o.value === v));
      opts = [...opts, ...(removed as string[]).map(transformOption)];
    }
    return opts;
  }
  const options = (props.options as Option[]).map(transformOption);
  if (props.sort) options.sort(sortOptions);
  return options;
});

function maybeEllipsisString(s: string) {
  if (props.ellipsis && s.length > props.ellipsis) return `${s.slice(0, props.ellipsis)}...`;
  return s;
}

function transformOption(option: Option) : TransformedOption {
  if (typeof option === 'string' || typeof option === 'number') {
    return { value: option, label: maybeEllipsisString(option.toString()) };
  }
  if (option?.disabled || option?.state === 'archived') {
    return { value: option.value, label: `${maybeEllipsisString(option?.text)} (${translate('common.archived')})`, disabled: true };
  }
  return { value: option?.value, label: maybeEllipsisString(option?.text) };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: https://github.com/vueform/multiselect/issues/260
const multiselect = ref<InstanceType<typeof Multiselect>>(null);
const singleOption = ref('');
watch(transformedOptions, () => {
  if (props.required && transformedOptions.value?.length === 1 && multiselect.value) {
    emit('update:modelValue', transformedOptions.value[0].value);
    singleOption.value = transformedOptions.value[0].label;
  }
});
const clearable = computed(() => props.canClear && inject('canClearBaseSelect', true));
function openModal() {
  if (props.disabled) {
    return;
  }
  emit('openModal');
}

function removeOption(option: DataSetCompositeOption) {
  const modelValues = props.modelValue as string[];
  const index = modelValues.indexOf(option.value as string);
  if (index > -1) {
    modelValues.splice(index, 1);
  }
  emit('update:modelValue', modelValues);
}
const findCorrespondedOption = (option: TransformedOption) => computed(() => transformedOptions.value.find((o) => o.value === option.value) || option);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-select-wrapper", __props.label ? 'v-outset-select' : ''])
  }, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: _normalizeClass(_unref(labelClass)())
        }, _toDisplayString(__props.label), 7))
      : _createCommentVNode("", true),
    (singleOption.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_unref(inputClass)(), "single-multiselect multiselect v-multiselect"]),
          style: _normalizeStyle(_unref(inputStyle)())
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(singleOption.value), 1)
        ], 6))
      : (_openBlock(), _createBlock(_unref(Multiselect), {
          key: 2,
          ref_key: "multiselect",
          ref: multiselect,
          modelValue: selected.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
          options: transformedOptions.value,
          disabled: __props.disabled,
          required: __props.required,
          mode: __props.multiple ? 'tags' : 'single',
          placeholder: __props.placeholder,
          style: _normalizeStyle(_unref(inputStyle)()),
          class: _normalizeClass([_unref(inputClass)(), "v-multiselect"]),
          searchable: __props.searchable,
          canDeselect: __props.searchable,
          showOptions: __props.showOptions,
          canClear: __props.multiple && clearable.value,
          internalSearch: false,
          onClick: openModal
        }, _createSlots({ _: 2 }, [
          (__props.customTags)
            ? {
                name: "tag",
                fn: _withCtx((option) => [
                  _createVNode(CustomTag, {
                    option: findCorrespondedOption(option.option).value,
                    onRemoveOption: removeOption
                  }, null, 8, ["option"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["modelValue", "options", "disabled", "required", "mode", "placeholder", "style", "class", "searchable", "canDeselect", "showOptions", "canClear"])),
    (__props.afterLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 3,
          style: _normalizeStyle(_unref(labelStyle)()),
          class: "v-label after-label"
        }, _toDisplayString(__props.afterLabel), 5))
      : _createCommentVNode("", true)
  ], 2))
}
}

})