import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "centered-content" }
const _hoisted_2 = { class: "v-delete-clients-content" }
const _hoisted_3 = { class: "v-delete-clients-content-text" }
const _hoisted_4 = {
  key: 0,
  class: "v-delete-clients-content-text"
}
const _hoisted_5 = { class: "v-delete-clients-content-text" }

import {
  PropType, Ref, ref, computed,
} from 'vue';
import use from '@/_shared/compositionApi';
import BaseTable from '@/_shared/components/display/BaseTable.vue';
import {
  DataRetentionTableData, DataRetentionReportRow, DataRetentionDeletedClientsData, PendingDeletionsTableData, PendingDeletionRow,
} from '@/data_retention/types/DataRetentionTableData';
import { useInfiniteScroll } from '@vueuse/core';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import trashIcon from '@/_shared/assets/icons/trash.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataRetentionReportTable',
  props: {
  data: {
    type: Object as PropType<DataRetentionTableData|DataRetentionDeletedClientsData|PendingDeletionsTableData>,
    required: true,
  },
  containsArray: {
    type: Boolean,
    default: true,
  },
  emptyMessage: {
    type: String,
  },
  activeStates: {
    type: Array as PropType<string[]>,
    required: true,
  },
},
  emits: ['enableDeleteButton', 'loadMore', 'reverseOrder', 'deleteClients', 'restoreClient'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const { translate } = use.helpers();
const toBeDeletedClients = ref<DataRetentionReportRow[]>([]);
const showActiveModal = ref(false);
const selectedClient = ref<DataRetentionReportRow | null>(null);
const selectedCheckbox = ref<HTMLInputElement>();
const emit = __emit;

const modalTitle = computed(() => translate('data_retention.confirmation_modal.active_client_title', { client_term: window.currentOrganisationUnit.client_term }));

const updateItem = (item: DataRetentionReportRow|PendingDeletionRow, shouldAdd: boolean) => {
  if (item.deletionDate) {
    emit('restoreClient', item);
    return;
  }
  const index = toBeDeletedClients.value.findIndex((client) => client.clientId === item.clientId);
  if (index !== -1 && !shouldAdd) {
    toBeDeletedClients.value.splice(index, 1); // 2nd parameter means remove one item only
  } else if (index === -1 && shouldAdd) {
    toBeDeletedClients.value.push(item as DataRetentionReportRow);
  }
  emit('enableDeleteButton', toBeDeletedClients.value.length);
  emit('deleteClients', toBeDeletedClients.value);
};
const handleCheckboxChange = ({ item, checkbox }: { item: DataRetentionReportRow; checkbox: HTMLInputElement }) => {
  const shouldAdd = checkbox.checked;
  if (shouldAdd && item.state.some((state) => props.activeStates.includes(state))) {
    selectedCheckbox.value = checkbox;
    selectedClient.value = item;
    showActiveModal.value = true;
  } else {
    updateItem(item, shouldAdd);
  }
};

const confirmCheckbox = () => {
  if (selectedClient.value) {
    updateItem(selectedClient.value, true);
    selectedClient.value = null;
  }
  showActiveModal.value = false;
};

const cancelAction = () => {
  if (selectedClient.value && selectedCheckbox.value) {
    selectedCheckbox.value.checked = false;
    updateItem(selectedClient.value, false);
    selectedClient.value = null;
  }
  showActiveModal.value = false;
};

const el = ref<HTMLElement | null>(null);
useInfiniteScroll(
  el as Ref<HTMLElement | null>,
  () => {
    emit('loadMore');
  },
  {
    distance: 20,
  },
);
__expose({
  el,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "el",
    ref: el,
    class: "v-data-retention-table"
  }, [
    _createVNode(BaseTable, {
      headers: props.data.headers,
      items: props.data?.entries,
      cellCentered: true,
      stickyHeader: true,
      darkTheme: true,
      containsArray: __props.containsArray,
      onUpdateItem: updateItem,
      onCreateItem: updateItem,
      hasSorting: true,
      onSortColumn: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reverseOrder'))),
      emptyMessage: __props.emptyMessage,
      onCheckboxChanged: handleCheckboxChange
    }, null, 8, ["headers", "items", "containsArray", "emptyMessage"]),
    (showActiveModal.value)
      ? (_openBlock(), _createBlock(BaseModal, {
          key: 0,
          title: modalTitle.value,
          "icon-svg": _unref(trashIcon),
          onConfirm: confirmCheckbox,
          "confirm-text": _unref(translate)('alert.confirm'),
          "cancel-hidden": false,
          "cancel-text": _unref(translate)('alert.cancel'),
          onCloseModal: cancelAction,
          width: 700,
          fromRestoreModal: ""
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(translate)('data_retention.confirmation_modal.active_client_text_p1', {name: selectedClient.value?.fullName, id: selectedClient.value?.clientId})), 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                (selectedClient.value && selectedClient.value.orgUnitName && selectedClient.value.orgUnitName.length === 1)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(translate)('data_retention.confirmation_modal.active_client_text_p2', {status: selectedClient.value?.state[0] , service_name: selectedClient.value?.orgUnitName[0]})), 1))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(translate)('data_retention.confirmation_modal.active_client_text_p3')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedClient.value?.orgUnitName, (unit, index) => {
                        return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(_unref(translate)('data_retention.confirmation_modal.client_status_list', {status: selectedClient.value?.state[index], service_name: unit})), 1))
                      }), 128))
                    ], 64))
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "icon-svg", "confirm-text", "cancel-text"]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})