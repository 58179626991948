import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock, withModifiers as _withModifiers, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "v-table" }
const _hoisted_2 = {
  key: 0,
  class: "col-xs-1"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["item", "rowspan"]
const _hoisted_5 = ["rowspan"]
const _hoisted_6 = {
  key: 0,
  class: "v-editable-div"
}
const _hoisted_7 = ["item"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["colspan"]
const _hoisted_11 = { class: "centered-content" }

import { onUpdated, PropType } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseDatePickerInput from '@/_shared/components/inputs/BaseDatePickerInput.vue';
import use from '@/_shared/compositionApi';
import BaseTableHeader from '@/_shared/types/baseTableHeader';
import BaseTableRow from '@/_shared/types/baseTableRow';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import { TableLink } from '@/_shared/types/tableLink';
import BaseButton from '../buttons/BaseButton.vue';

interface ClickEvent extends Event {
    target: HTMLElement;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTable',
  props: {
  headers: {
    type: Array as PropType<BaseTableHeader[]>,
    required: true,
  },
  items: {
    type: Array as PropType<BaseTableRow[]>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  hasSlot: {
    type: Boolean,
    default: false,
  },
  hasRowSlot: {
    type: Boolean,
    default: false,
  },
  cellCentered: {
    type: Boolean,
    default: false,
  },
  stickyHeader: {
    type: Boolean,
    default: false,
  },
  darkTheme: {
    type: Boolean,
    default: false,
  },
  containsArray: {
    type: Boolean,
    default: false,
  },
  sortByColumn: {
    type: String,
    default: '',
  },
  emptyMessage: {
    type: String,
    required: false,
  },
  customRows: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:items', 'updateItem', 'createItem', 'sortColumn', 'checkboxChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

const onChange = (event: Event, item: BaseTableRow) => {
  if (Object.prototype.hasOwnProperty.call(item, 'id')) {
    emit('updateItem', item);
  } else {
    emit('createItem', item);
  }
};

const archive = (item: BaseTableRow, index: number) => {
  if (item && item.id) {
    item.archived = true;
    emit('updateItem', item);
  } else {
    const { items } = props;
    items.splice(index, 1);
    emit('update:items', items);
  }
};

const addRow = () => {
  const { items } = props;
  const newRow = {};
  items.push(newRow);
  emit('update:items', items);
};

onUpdated(() => {
  const textareas = document.getElementsByTagName('textarea');

  for (let i = 0; i < textareas.length; i += 1) {
    textareas[i].style.height = `${textareas[i].scrollHeight}px`;
  }
});

const toggleSortDirection = (header: string, event: ClickEvent) => {
  emit('sortColumn', header);
  let icon = event.target;
  if (icon.tagName !== 'I') {
    icon = icon.firstElementChild as HTMLElement;
  }
  icon.classList.toggle('fa-sort-amount-desc');
  icon.classList.toggle('fa-sort-amount-asc');
};

const sortIcon = (defaultSort: string) => (defaultSort === 'asc' ? 'fa fa-sort-amount-asc' : 'fa fa-sort-amount-desc');

function arrayLength(item: BaseTableRow) {
  if (!item) { return 0; }
  const arrayElement = Object.values(item).find((value) => Array.isArray(value)) as string[];
  return arrayElement.length;
}

const onCheckboxChange = (event: Event, item: BaseTableRow) => {
  const checkbox = event.target as HTMLInputElement;
  emit('checkboxChanged', { item, checkbox });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.headers, (header) => {
          return (_openBlock(), _createElementBlock("th", _mergeProps({
            key: header.keyName,
            class: [ {'sortable':  header.defaultSort }, {'v-sticky-header': __props.stickyHeader }, {'v-dark-header': __props.darkTheme}]
          }, _toHandlers(header.defaultSort ? { click: (event) => toggleSortDirection(header.keyName, event) } : {}, true)), [
            _createTextVNode(_toDisplayString(header.displayName) + " ", 1),
            (header.defaultSort)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(['sort-icon', sortIcon(header.defaultSort)])
                }, null, 2))
              : _createCommentVNode("", true)
          ], 16))
        }), 128)),
        (__props.editable)
          ? (_openBlock(), _createElementBlock("th", _hoisted_2, [
              _createVNode(BaseTooltip, {
                position: "top",
                tooltipText: _unref(translate)('table.add_entry')
              }, {
                default: _withCtx(() => [
                  _createVNode(BaseIcon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (addRow())),
                    size: "lg",
                    name: "plus no-margin"
                  })
                ]),
                _: 1
              }, 8, ["tooltipText"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (__props.items.length)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_3, [
          (__props.containsArray)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.items, (item, outerIndex) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.id
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(arrayLength(item), (index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: `${item.id}-${index}`,
                      class: _normalizeClass([[ outerIndex % 2 == 0 ? 'v-even-row' : 'v-odd-row', {'v-last-row' : index == arrayLength(item)}], "v-array-row"])
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.headers, (header) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: `${header.keyName}-${item.id}`
                        }, [
                          (Array.isArray(item[header.keyName]))
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 0,
                                class: _normalizeClass(["v-td v-add-left-border", __props.cellCentered ? 'v-td-centered' : 'v-td-not-centered'])
                              }, _toDisplayString((item[header.keyName] as any[])[index - 1]), 3))
                            : (header.type == 'checkbox' && index == 1)
                              ? (_openBlock(), _createElementBlock("td", {
                                  class: "v-td v-add-bottom-border",
                                  item: item[header.keyName],
                                  key: header.keyName + '-checkbox',
                                  rowspan: arrayLength(item)
                                }, [
                                  _createVNode(BaseCheckbox, {
                                    "black-tick": "",
                                    center: "",
                                    onChange: ($event: any) => (onCheckboxChange($event, item))
                                  }, null, 8, ["onChange"])
                                ], 8, _hoisted_4))
                              : (index == 1)
                                ? (_openBlock(), _createElementBlock("td", {
                                    key: 2,
                                    class: _normalizeClass(["v-td v-add-bottom-border", __props.cellCentered ? 'v-td-centered' : 'v-td-not-centered']),
                                    rowspan: arrayLength(item)
                                  }, _toDisplayString(item[header.keyName]), 11, _hoisted_5))
                                : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ], 2))
                  }), 128))
                ], 64))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.items, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item.id
                }, [
                  (__props.customRows)
                    ? _renderSlot(_ctx.$slots, "default", {
                        item: item,
                        key: item.id
                      })
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.headers, (header) => {
                        return (_openBlock(), _createElementBlock("td", {
                          class: _normalizeClass(["v-td", __props.cellCentered ? 'v-td-centered' : 'v-td-not-centered']),
                          key: `${header.keyName}-${item.id}`
                        }, [
                          (__props.editable)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (header.type === 'date')
                                  ? (_openBlock(), _createBlock(BaseDatePickerInput, {
                                      key: 0,
                                      onChangeDate: ($event: any) => (onChange($event, item)),
                                      modelValue: item[header.keyName] as string,
                                      "onUpdate:modelValue": (newValue) => (item[header.keyName] = newValue),
                                      type: "date"
                                    }, null, 8, ["onChangeDate", "modelValue", "onUpdate:modelValue"]))
                                  : (_openBlock(), _createBlock(BaseInput, {
                                      key: 1,
                                      onChange: _withModifiers(($event: any) => (onChange($event, item)), ["stop"]),
                                      textarea: "",
                                      modelValue: item[header.keyName] as string,
                                      "onUpdate:modelValue": (newValue) => (item[header.keyName] = newValue)
                                    }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])),
                                _createVNode(BaseIcon, {
                                  name: "pencil no-margin no-hover edit-icon",
                                  size: "lg"
                                })
                              ]))
                            : (__props.hasSlot)
                              ? _renderSlot(_ctx.$slots, "default", {
                                  item: item[header.keyName],
                                  key: header.keyName
                                })
                              : (header.type === 'button')
                                ? (_openBlock(), _createElementBlock("div", {
                                    item: item[header.keyName],
                                    key: header.keyName + '-button'
                                  }, [
                                    (_openBlock(), _createBlock(BaseButton, {
                                      onClicked: ($event: any) => (onChange($event, item)),
                                      key: header.keyName,
                                      class: "v-restore-button"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(header.displayName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClicked"]))
                                  ], 8, _hoisted_7))
                                : (header.type === 'link')
                                  ? (_openBlock(), _createBlock(_component_router_link, {
                                      key: 3,
                                      to: (item[header.keyName] as TableLink)['to'],
                                      class: _normalizeClass({
              bold: (item[header.keyName] as TableLink).bold,
              italic: (item[header.keyName] as TableLink).italic
            })
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString((item[header.keyName] as TableLink)['text']), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to", "class"]))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 4,
                                      class: "v-non-editable-text",
                                      innerHTML: item[header.keyName]
                                    }, null, 8, _hoisted_8))
                        ], 2))
                      }), 128)),
                  (__props.editable)
                    ? (_openBlock(), _createElementBlock("td", {
                        key: 2,
                        class: _normalizeClass(["v-td", __props.cellCentered ? 'v-td-centered' : 'v-td-not-centered'])
                      }, [
                        _createVNode(BaseTooltip, {
                          position: "top",
                          tooltipText: _unref(translate)('common.archive')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(BaseIcon, {
                              onClick: ($event: any) => (archive(item, index)),
                              name: "archive no-margin",
                              size: "lg"
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1032, ["tooltipText"])
                      ], 2))
                    : _createCommentVNode("", true),
                  (__props.hasRowSlot)
                    ? _renderSlot(_ctx.$slots, "default", {
                        item: item.id as any,
                        key: item.id
                      })
                    : _createCommentVNode("", true)
                ]))
              }), 128))
        ]))
      : (props.emptyMessage)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                class: "v-td",
                colspan: __props.headers.length + (__props.editable ? 1 : 0)
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(__props.emptyMessage), 1)
              ], 8, _hoisted_10)
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})