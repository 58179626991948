import { RouteLocationNormalizedLoaded } from 'vue-router';
import { computed } from 'vue';

const useBodyMapRoutes = (route: RouteLocationNormalizedLoaded) => {
  const isWithInBodyMapNestedRoutes = computed(() => route.path?.toString().includes('instances'));

  const isBodyMapViewerNestedRoute = computed(() => isWithInBodyMapNestedRoutes.value && !route.params.instanceId);

  const isInstanceViewerNestedRoute = computed(() => !!route.params?.instanceId);

  const timelineRouteName = computed(() => {
    switch (true) {
      case isBodyMapViewerNestedRoute.value:
        return 'client.timeline.body_map';
      case isInstanceViewerNestedRoute.value:
        return 'client.timeline.body_map.instance';
      default:
        return 'client.timeline';
    }
  });

  const InstanceViewerRoutePattern = /\/instances\/\d+/;
  const BodyMapViewerRoutePattern = /\/instances/;

  const currentRouteInstanceViewerPath = (instanceId: number) => computed(() => {
    let path = '';
    if (InstanceViewerRoutePattern.exec(route.path)) {
      path = route.path;
      // there is no why to go form instance to instance no need to check id
    } else {
      const rst = BodyMapViewerRoutePattern.exec(route.path);
      if (rst) {
        path = route.path.replace(rst[0], `/instances/${instanceId}`);
      } else {
        const index = (`/clients/${route.params.clientId}`).length;
        path = `${route.path.slice(0, index)}/instances/${instanceId}${route.path.slice(index)}`;
      }
    }
    return path.endsWith('/') ? path.slice(0, -1) : path;
  });

  const currentRouteBodyMapViewerPath = computed(() => {
    let path: string;
    const rst = InstanceViewerRoutePattern.exec(route.path);
    if (rst) {
      path = route.path.replace(rst[0], '/instances');
    } else if (BodyMapViewerRoutePattern.exec(route.path)) {
      path = route.path;
    } else {
      const index = (`/clients/${route.params.clientId}`).length;
      path = `${route.path.slice(0, index)}/instances${route.path.slice(index)}`;
    }
    return path.endsWith('/') ? path.slice(0, -1) : path;
  });

  // This is to build path for client.timeline.interactions
  const currentTimelineViewStartPath = computed(() => {
    let path = `/clients/${route.params.clientId}`;
    if (isWithInBodyMapNestedRoutes.value) {
      path += '/instances';
      return isInstanceViewerNestedRoute.value ? path.concat(`/${route.params.instanceId}`) : path;
    }
    return path;
  });
  const timelineDefaultViewRouteName = computed(() => ((route.name as string).includes('client.timeline.body_map') ? 'client.timeline' : route.name));
  return {
    isBodyMapViewerNestedRoute,
    isInstanceViewerNestedRoute,
    timelineRouteName,
    isWithInBodyMapNestedRoutes,
    currentRouteInstanceViewerPath,
    currentTimelineViewStartPath,
    currentRouteBodyMapViewerPath,
    timelineDefaultViewRouteName,
  };
};
export default useBodyMapRoutes;
