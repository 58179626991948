import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-ou-integration-card-container"
}
const _hoisted_2 = { class: "v-ou-information-block" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "v-buttons-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LondonCareRecordProviderDetail = _resolveComponent("LondonCareRecordProviderDetail")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseToggle = _resolveComponent("BaseToggle")!
  const _component_IntegrationDisableModal = _resolveComponent("IntegrationDisableModal")!
  const _component_IntegrationInformationModal = _resolveComponent("IntegrationInformationModal")!
  const _component_IntegrationEnableModal = _resolveComponent("IntegrationEnableModal")!
  const _component_IntegrationNhsAsidModal = _resolveComponent("IntegrationNhsAsidModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shouldShowOu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString(_ctx.ou.name), 1),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            (_ctx.isOuToggleEnabled() && !_ctx.isAdvancedCollection)
              ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.translate('integrations.enabled_by')) + " " + _toDisplayString(_ctx.updatedBy) + " " + _toDisplayString(_ctx.translate('common.on')) + " " + _toDisplayString(_ctx.updatedAt), 1))
              : _createCommentVNode("", true),
            (_ctx.integration.integrationCodename == 'london_care_records')
              ? (_openBlock(), _createBlock(_component_LondonCareRecordProviderDetail, {
                  key: 1,
                  customerIntegration: _ctx.getCustomerIntegrationForOu(_ctx.integration.integrationCodename, _ctx.ou.id)
                }, null, 8, ["customerIntegration"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.showInfoButton && !_ctx.isAdvancedCollection)
              ? (_openBlock(), _createBlock(_component_BaseIcon, {
                  key: 0,
                  name: "bell",
                  class: "v-info-modal-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInfoModal = true)),
                  foregroundColor: "grey",
                  foregroundHoverColor: "black",
                  noHoverBackground: ""
                }))
              : _createCommentVNode("", true),
            (!_ctx.isAdvancedCollection)
              ? (_openBlock(), _createBlock(_component_BaseIcon, {
                  key: 1,
                  name: "pencil",
                  class: "v-edit-integration-button",
                  onClick: _ctx.showTokenOrToggleIntegration,
                  foregroundColor: "grey",
                  foregroundHoverColor: "black",
                  noHoverBackground: ""
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_BaseToggle, {
              checked: _ctx.isOuToggleEnabled(),
              blockToggle: true,
              onToggleChange: _ctx.ouIntegrationToggled,
              disabled: false,
              tooltipText: "Toggle Extension"
            }, null, 8, ["checked", "onToggleChange"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDisableAlert)
      ? (_openBlock(), _createBlock(_component_IntegrationDisableModal, {
          key: 1,
          integration: _ctx.integration,
          onConfirmDisableIntegration: _ctx.disableOuIntegration,
          onCloseIntegrationDisableModal: _ctx.closeDisableAlert,
          type: "organisation_unit",
          ou: _ctx.ou
        }, null, 8, ["integration", "onConfirmDisableIntegration", "onCloseIntegrationDisableModal", "ou"]))
      : _createCommentVNode("", true),
    (_ctx.showInfoModal === true)
      ? (_openBlock(), _createBlock(_component_IntegrationInformationModal, {
          key: 2,
          ou: _ctx.ou,
          integration: _ctx.integration,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showInfoModal = false))
        }, null, 8, ["ou", "integration"]))
      : _createCommentVNode("", true),
    (_ctx.showEnableIntegrationModal === true)
      ? (_openBlock(), _createBlock(_component_IntegrationEnableModal, {
          key: 3,
          onCloseEnableModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showEnableIntegrationModal = false)),
          ou: _ctx.ou,
          integration: _ctx.integration,
          tokenModalOnly: _ctx.tokenModalOnly,
          customerIntegration: _ctx.getCustomerIntegrationForOu(_ctx.integration.integrationCodename, _ctx.ou.id),
          onToggleOnExtension: _ctx.toggleOnExtension,
          onToggleOnNhsExtension: _ctx.toggleOnNhsExtension
        }, null, 8, ["ou", "integration", "tokenModalOnly", "customerIntegration", "onToggleOnExtension", "onToggleOnNhsExtension"]))
      : _createCommentVNode("", true),
    (_ctx.showAsidModal === true)
      ? (_openBlock(), _createBlock(_component_IntegrationNhsAsidModal, {
          key: 4,
          ou: _ctx.ou,
          onCancelAsidModal: _cache[3] || (_cache[3] = ($event: any) => {_ctx.showAsidModal = false;}),
          onConfirmAsidModal: _cache[4] || (_cache[4] = ($event: any) => {_ctx.showAsidModal = false;}),
          token: _ctx.token,
          integration: _ctx.integration
        }, null, 8, ["ou", "token", "integration"]))
      : _createCommentVNode("", true)
  ], 64))
}