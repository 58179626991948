import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-integration-card-container"
}
const _hoisted_2 = { class: "v-integration-card-contents" }
const _hoisted_3 = { class: "v-info-holder" }
const _hoisted_4 = {
  key: 0,
  class: "v-org-unit-card-container"
}

import {
  onBeforeMount, PropType, ref,
} from 'vue';
import use from '@/_shared/compositionApi';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import { BaseIcon, BaseToggle } from '@/_shared/components/';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import useIntegrationStore from '@/_shared/store/integrations';
import IntegrationDisableModal from './modals/IntegrationDisableModal.vue';
import Integration from '../types/integration';
import IntegrationOrgUnitCard from './IntegrationOrgUnitCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationCard',
  props: {
  integration: {
    type: Object as PropType<Integration>,
    required: true,
  },
  organisationUnits: {
    type: Array as PropType<OrganisationUnit[]>,
    required: true,
  },
  categoryIcon: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();
const showDisableAlert = ref<boolean>(false);
const showOuIntegrations = ref<boolean>(false);
const integrationsStore = useIntegrationStore();
const tempValue = ref<number>(0);

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('integrations'); // TODO WHEN PURE VUE remove
});

const getIconSize = () => {
  if (props.categoryIcon?.includes('.svg')) {
    return '2x';
  }
  return 'sm';
};

const orgIntegrationToggled = () => {
  if ((isAdvancedCollection() && props.integration.enabled)
    || (props.integration.integrationCodename && integrationsStore.isOrgToggleEnabled(props.integration.integrationCodename))) {
    showDisableAlert.value = true;
    return;
  }
  toast(translate('integrations.org_toggle_enable'), ToastType.Danger);
};

const disableIntegration = async () => {
  showDisableAlert.value = false;
  if (isAdvancedCollection()) {
    await integrationsStore.unsubscribeOrgFromAdvancedCollection(props.integration, props.organisationUnits);
  }
  await integrationsStore.deleteOrgIntegration(props.integration as Integration);
};

const closeDisableAlert = () => {
  showDisableAlert.value = false;
};

const toggleOuIntegrationsDisplay = () => {
  showOuIntegrations.value = !showOuIntegrations.value;
};

const openIntegrationInfoPage = (integrationInfoUrl: string) => {
  window.open(integrationInfoUrl, '_blank');
};

const isAdvancedCollection = () => !!props.integration.advancedCollectionCodename;

const allAdvancedCollections: Integration[] = integrationsStore.integrations.filter((integration) => !!integration.advancedCollectionCodename);

const reloadValues = () => {
  tempValue.value += 1;
};

const showOrgIntegration = () => {
  if (isAdvancedCollection()) {
    const currentIndex = integrationsStore.integrations.indexOf(props.integration);
    const firstOccurrenceIndex = integrationsStore.integrations.indexOf(integrationsStore.integrations.filter((integration) => integration.advancedCollectionCodename === props.integration.advancedCollectionCodename)[0]);
    return currentIndex === firstOccurrenceIndex;
  }
  return true;
};

const isOrgToggleEnabled = () => {
  if (isAdvancedCollection()) {
    return allAdvancedCollections.some((collection) => collection.enabled
      && collection.advancedCollectionCodename === props.integration.advancedCollectionCodename);
  }
  return integrationsStore.isOrgToggleEnabled(props.integration.integrationCodename!);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showOrgIntegration())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(BaseIcon), {
              class: "v-header-icon v-no-margin",
              name: __props.categoryIcon,
              foregroundColor: "black",
              backgroundColor: "transparent",
              noHover: "",
              size: getIconSize()
            }, null, 8, ["name", "size"]),
            _createElementVNode("div", {
              class: "v-integration-card-title",
              onClick: toggleOuIntegrationsDisplay
            }, _toDisplayString(__props.integration.name), 1),
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createVNode(_unref(BaseIcon), {
                class: "v-info-icon",
                name: "nsx-font-icon--help",
                foregroundColor: "grey",
                foregroundHoverColor: "black",
                noHoverBackground: "",
                size: "2x",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (openIntegrationInfoPage(__props.integration.infoUrl)))
              }, null, 512), [
                [_vShow, __props.integration.infoUrl]
              ])
            ]),
            _createVNode(_unref(BaseToggle), {
              checked: isOrgToggleEnabled(),
              blockToggle: true,
              onToggleChange: orgIntegrationToggled,
              disabled: false,
              tooltipText: "Toggle Extension",
              tempValue: tempValue.value
            }, null, 8, ["checked", "tempValue"]),
            (!showOuIntegrations.value)
              ? (_openBlock(), _createBlock(_unref(BaseIcon), {
                  key: 0,
                  name: "sort-desc",
                  foregroundColor: "black",
                  backgroundColor: "transparent",
                  class: _normalizeClass(["v-dropdown-toggle-desc", isAdvancedCollection() ? 'v-collection-dropdown-toggle-desc' : 'v-integration-dropdown-toggle-desc']),
                  onClick: toggleOuIntegrationsDisplay
                }, null, 8, ["class"]))
              : (_openBlock(), _createBlock(_unref(BaseIcon), {
                  key: 1,
                  name: "sort-asc",
                  foregroundColor: "black",
                  backgroundColor: "transparent",
                  class: "v-dropdown-toggle-asc",
                  onClick: toggleOuIntegrationsDisplay
                }))
          ]),
          (showOuIntegrations.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.organisationUnits, (ou) => {
                  return (_openBlock(), _createBlock(IntegrationOrgUnitCard, {
                    key: ou['id'],
                    ou: ou,
                    integration: __props.integration,
                    onOuToggled: reloadValues
                  }, null, 8, ["ou", "integration"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (showDisableAlert.value)
      ? (_openBlock(), _createBlock(IntegrationDisableModal, {
          key: 1,
          integration: __props.integration,
          onConfirmDisableIntegration: disableIntegration,
          onCloseIntegrationDisableModal: closeDisableAlert,
          type: "organisation"
        }, null, 8, ["integration"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})