import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "connected status"
}
const _hoisted_3 = {
  key: 1,
  class: "not-connected status"
}

import {
  onBeforeMount, PropType, ref, Ref,
} from 'vue';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import { lcrOdsCodeLookup } from '../../../integrations/services/integrationsApi';
import CustomerIntegration from '../../../integrations/types/customerIntegration';


export default /*@__PURE__*/_defineComponent({
  __name: 'LondonCareRecordProviderDetail',
  props: {
  customerIntegration: {
    type: Object as PropType<CustomerIntegration>,
    required: false,
  },
},
  setup(__props) {

const { currentUser } = storeToRefs(useUserStore());

const props = __props;

const show = currentUser.value.isImpersonated && props.customerIntegration;

const regionCode: Ref<string | undefined | null> = ref(null);

onBeforeMount(async () => {
  if (show) {
    await callLondonCareOdsCheck(props.customerIntegration.integrationToken);
  }
});

const callLondonCareOdsCheck = async (tokenValue: string | undefined) => {
  if (!tokenValue) {
    return;
  }
  const response = await lcrOdsCodeLookup(tokenValue);
  if (response.success) {
    regionCode.value = response.data?.shareCareRegionCode;
  }
};


return (_ctx: any,_cache: any) => {
  return (_unref(show))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (regionCode.value)
          ? (_openBlock(), _createElementBlock("small", _hoisted_2, [
              _cache[0] || (_cache[0] = _createTextVNode("Connected to ")),
              _createElementVNode("span", null, _toDisplayString(regionCode.value), 1)
            ]))
          : (_openBlock(), _createElementBlock("small", _hoisted_3, "Not connected to London Care Record"))
      ]))
    : _createCommentVNode("", true)
}
}

})