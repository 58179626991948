import {
  h, PropType, SetupContext, VNode,
  Component, VNodeProps,
} from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import InstanceViewTimeline from '@/timeline/components/InstanceViewTimeline.vue';
import BodyMapTimeline from '@/timeline/components/BodyMap/BodyMapTimeline.vue';

export type InstancesTimelineProps = VNodeProps &{
  route: RouteLocationNormalized,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InstancesTimelineFComponent(props: InstancesTimelineProps, _: SetupContext): VNode|null {
  if (props.route.path.includes('instances')) {
    if (props.route.params.instanceId) return h(InstanceViewTimeline as Component, props.route.params);
    // return h(BodyMapTimeline, mergeProps(ctx, props.route.params));
    return h(BodyMapTimeline as Component, {
      ...props.route.params,
    });
  }
  return null;
}

InstancesTimelineFComponent.props = {
  route: {
    type: Object as PropType<RouteLocationNormalized>,
    required: true,
  },
};
export default InstancesTimelineFComponent;
