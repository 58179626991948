import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import BaseButton from '@/_shared/components/buttons/BaseButton.vue';
import { formatQuestionOptions, isValueSelected } from '@/timeline/helper/parametersHelper';
import { Parameter } from '../types/Parameter';


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleChoiceQuestion',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  currentAnswer: {
    type: String,
  },
},
  emits: ['answerChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const selectAnswer = (option: string) => {
  const newOption = isValueSelected(option, props.currentAnswer) ? '' : option;
  emit('answerChanged', newOption);
};

const questionOptions = formatQuestionOptions(props.parameter);


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(questionOptions), (option) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "v-param-multi-question-container",
      key: option
    }, [
      _createVNode(BaseButton, {
        class: _normalizeClass(["v-param-multi-answer", {selected: _unref(isValueSelected)(option, __props.currentAnswer)}]),
        disabled: __props.readOnly,
        onClicked: ($event: any) => (selectAnswer(option)),
        colour: "transparent"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.trim()), 1)
        ]),
        _: 2
      }, 1032, ["disabled", "class", "onClicked"])
    ]))
  }), 128))
}
}

})