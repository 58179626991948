import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-supporting-documents-wrapper"
}
const _hoisted_2 = { class: "v-supporting-documents-header-wrapper" }
const _hoisted_3 = { class: "v-supporting-documents-open-icon-wrapper" }

import { computed } from 'vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { storeToRefs } from 'pinia';
import currentInteractionStore from '@/timeline/store/currentInteractionStore';
import use from '../../_shared/compositionApi';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionDocumentsSection',
  emits: ['toggleShowDocuments'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { translate } = use.helpers();
const { currentInteraction } = storeToRefs(currentInteractionStore());
const documentsCount = computed(() => {
  if (!currentInteraction.value) return 0;
  return currentInteraction.value.carePlanDocuments.length + currentInteraction.value.supportingDocuments.length;
});

const hasDocuments = computed(() => {
  if (!currentInteraction.value) return false;
  return currentInteraction.value.carePlanDocuments.length || currentInteraction.value?.supportingDocuments.length;
});


return (_ctx: any,_cache: any) => {
  return (hasDocuments.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-supporting-documents-icon-wrapper" }, [
          _createElementVNode("i", { class: "nsx-font-icon--attached-document v-supporting-documents-icon" })
        ], -1)),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(translate)('timeline.interaction.supportingDocuments')) + " (" + _toDisplayString(documentsCount.value) + ") ", 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BaseIcon, {
            name: "nsx-font-icon--arrow-right",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('toggleShowDocuments'))),
            align: "right",
            size: "2x",
            "no-hover-background": "",
            class: "sp-open-supporting-documents",
            "foreground-color": "blue",
            "foreground-hover-color": "grey"
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})