import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import use from '@/_shared/compositionApi';
import { computed } from 'vue';
import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverFlag',
  props: {
  checked: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
  hideCheckbox: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  handoverDisabledReason: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const { translate } = use.helpers();

const { featureToggles } = storeToRefs(useUserStore());

const flagCssClasses = computed(() => {
  const classes = ['v-note-handover-flag', 'sp-handover', props.size];
  if (props.disabled) {
    classes.push('v-handover-disabled', 'sp-handover-disabled');
  }
  if (props.size === 'sm') {
    classes.push('v-note-handover-flag-small');
  }
  return classes;
});

const wrapperCssClasses = computed(() => {
  const classes = [];
  if (props.disabled) {
    classes.push('v-handover-disabled');
  }
  if (props.size !== 'sm') {
    classes.push('v-note-handover');
  }
  return classes;
});

const checkboxCssClasses = computed(() => {
  const classes = ['v-note-handover-checkbox'];
  if (props.disabled) {
    classes.push('v-note-handover-checkbox-disabled');
  }
  return classes;
});

const maybeShowError = () => {
  if (props.disabled && props.handoverDisabledReason) {
    toast(translate(props.handoverDisabledReason), ToastType.Danger);
  }
};

return (_ctx: any,_cache: any) => {
  return (_unref(featureToggles).handover)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(wrapperCssClasses.value),
        onClick: maybeShowError
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(flagCssClasses.value)
        }, _toDisplayString(_unref(translate)("timeline.interaction.handover")), 3),
        (!__props.hideCheckbox)
          ? (_openBlock(), _createBlock(BaseCheckbox, {
              key: 0,
              checked: __props.checked,
              class: _normalizeClass(checkboxCssClasses.value),
              disabled: __props.disabled
            }, null, 8, ["checked", "class", "disabled"]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})