
import {
  computed, defineComponent, ref, PropType,
} from 'vue';
import use from '@/_shared/compositionApi';
import ClientType from '@/client_types/types/client_type';
import { parseReactiveTextToStaticText } from '@/_shared/helpers/useClientTypesHelpers';
import { BaseInput, BaseButton, BaseIcon } from '@/_shared/components/';

export default defineComponent({
  components: { BaseIcon, BaseInput, BaseButton },
  props: {
    clientType: {
      type: Object as PropType<ClientType>,
      required: true,
    },
    warningMessage: {
      type: String,
    },
  },
  emits: ['confirmSavingClientType', 'cancelEditing', 'validateClientTypeName'],
  setup(props, context) {
    const { translate } = use.helpers();
    const clientTypeName = ref(props.clientType.name!);
    const clientType = ref(props.clientType);
    const clientTypeOriginalName = parseReactiveTextToStaticText(props.clientType!.name!);
    const saveClientType = () => {
      clientType.value.name = clientTypeName.value;
      context.emit('confirmSavingClientType');
    };

    const validateName = () => {
      context.emit('validateClientTypeName', clientTypeName.value);
    };

    const saveDisabled = computed(() => (props.warningMessage && props.warningMessage.length > 0) || clientTypeOriginalName === clientTypeName.value);

    return {
      translate,
      clientTypeName,
      saveClientType,
      validateName,
      saveDisabled,
    };
  },
});
