import {
  memorizedOrganisationRoles, OrganisationRole, OrganisationRolesApiResponse,
} from '@/_shared/services/organisationRolesApi';
import { onBeforeMount, ref } from 'vue';

export default function useFetchOrganisationRoles() {
  const organisationRolesApiResponse = ref<OrganisationRolesApiResponse>();
  const organisationRoles = ref<OrganisationRole[]>([]);

  onBeforeMount(async () => {
    organisationRolesApiResponse.value = await memorizedOrganisationRoles();
    organisationRoles.value = organisationRolesApiResponse.value?.organisationRoles || [];
  });

  return organisationRoles;
}
