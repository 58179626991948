// construct and export default singleton client
// because of circular references (?) this must be in a separate file

import { AssessmentState } from '@/assessments/types/assessmentState';
import apiClient from '@/_shared/services/apiClient';
import { ProvidedService } from '@/_shared/types/providedService';
import { promiseMemorise } from '@/_shared/services/UseUtils';

export interface ServiceFiltersApiResponse {
  nonAdHocServices: number[],
  editableServices: number[],
  clientTypeServices: Record<string, number[]>,
  stateFilters: AssessmentState[],
  newSkinInstanceServices: SkinInstanceServicesFilter[],
  managedSkinInstanceServices: SkinInstanceServicesFilter[],
}

export interface SkinInstanceServicesFilter {
  id?: number;
  subType?: string[] | null;
  state?: string;
}

export interface NewEventsApiResponse {
  services: ProvidedService[];
}

export default async function fetchServiceFilters() {
  const url = '/api/v2/services/filters';
  try {
    const response = await apiClient.get<ServiceFiltersApiResponse>(url, { params: { } });
    return response as ServiceFiltersApiResponse;
  } catch (error) {
    return {} as ServiceFiltersApiResponse;
  }
}

export async function fetchNewEventServices() {
  try {
    const response = await apiClient.get<NewEventsApiResponse>('/api/v2/services/events');
    return response;
  } catch (errorsResponse) {
    return {} as NewEventsApiResponse;
  }
}

export const memorizedNewEventServices = promiseMemorise(fetchNewEventServices);
