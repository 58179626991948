import { computed, onBeforeMount, ref } from 'vue';
import {
  memorizedNewEventServices,
  NewEventsApiResponse,
} from '@/_shared/services/serviceFiltersApi';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';
import { ProvidedService } from '@/_shared/types/providedService';

export default function useFilterNewEventServices() {
  const { providedServices } = storeToRefs(useServiceFilteringStore());
  const newEventsApiResponse = ref<NewEventsApiResponse>();
  const newEventServices = ref<ProvidedService[]>([]);

  onBeforeMount(async () => {
    newEventsApiResponse.value = await memorizedNewEventServices();
    newEventServices.value = newEventsApiResponse.value?.services || [];
  });

  return computed(() => (
    newEventServices.value ? providedServices.value
      .filter((ps) => newEventServices.value
        .map((filter) => filter.id)
        .includes(ps.serviceId)) : []));
}
