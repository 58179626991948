import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "v-file-parameter-container" }
const _hoisted_2 = ["accept"]
const _hoisted_3 = {
  key: 0,
  class: "v-temp-file-name"
}
const _hoisted_4 = {
  key: 1,
  class: "v-existing-file"
}

import {
  computed, Ref, toRef, ref,
} from 'vue';
import use from '@/_shared/compositionApi';
import validateFile from '@/_shared/services/fileValidator';
import GenericFileMain from '@/generic_file/GenericFileMain.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { getAllowedContentTypes } from '@/generic_file/services/GenericFileApi';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { FileWrapper, Parameter } from '../types/Parameter';
import BaseParameter from './BaseParameter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FileParameter',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { setCoercedValueByParameterId } = useCurrentInteractionStore();

const { translate } = use.helpers();
const tempFile = toRef(props.parameter, 'tempFile') as Ref<File>;
const coercedValue = toRef(props.parameter, 'coercedValue') as Ref<FileWrapper>;
const tempFileExists = computed(() => !!tempFile.value?.name);
const highlightIcon = ref(false);
const allowedContentTypes = computed(() => {
  const types = getAllowedContentTypes();
  return types?.join(', ') || '';
});

const isGenericFile = computed(() => (!!genericFile.value
    && !genericFile.value.destroyed
    && !tempFileExists.value
    && !genericFile.value.archived
    && !genericFile.value.deleted));

const genericFile = computed(() => coercedValue.value?.file || props.parameter.genericFile);
function onChange(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const { files } = inputElement;

  if (files && files.length === 1) {
    const file: File = files[0];
    if (validateFile(file)) {
      setCoercedValueByParameterId(props.parameter.id as number, { file: genericFile.value } as FileWrapper);
      tempFile.value = file;
    } else {
      inputElement.value = '';
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BaseParameter, {
      parameter: __props.parameter,
      secondLine: tempFileExists.value || isGenericFile.value,
      hideHeading: __props.hideHeading
    }, _createSlots({
      "second-line": _withCtx(() => [
        (tempFile.value && _unref(validateFile)(tempFile.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(tempFile.value.name), 1))
          : _createCommentVNode("", true),
        (isGenericFile.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(GenericFileMain, {
                genericFile: genericFile.value,
                readOnly: __props.readOnly
              }, null, 8, ["genericFile", "readOnly"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (!__props.readOnly)
        ? {
            name: "value-slot",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                class: "v-file-parameter",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$refs.file as HTMLInputElement).click())),
                onMouseover: _cache[2] || (_cache[2] = ($event: any) => (highlightIcon.value = true)),
                onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (highlightIcon.value = false)),
                onFocus: _cache[4] || (_cache[4] = ($event: any) => (highlightIcon.value = true)),
                onBlur: _cache[5] || (_cache[5] = ($event: any) => (highlightIcon.value = false)),
                tabindex: "0"
              }, [
                (!isGenericFile.value)
                  ? (_openBlock(), _createBlock(BaseTooltip, {
                      key: 0,
                      position: "bottom",
                      tooltipText: _unref(translate)('common.upload')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("label", null, [
                          _createElementVNode("input", {
                            type: "file",
                            ref: "file",
                            style: {"display":"none"},
                            accept: allowedContentTypes.value,
                            class: "sp-file-input",
                            onChange: _cache[0] || (_cache[0] = ($event: any) => (onChange($event as unknown as Event)))
                          }, null, 40, _hoisted_2)
                        ]),
                        _createVNode(BaseIcon, {
                          class: "v-upload",
                          name: "upload",
                          size: "lg",
                          foregroundColor: "white",
                          backgroundColor: "black",
                          backgroundShape: "circle",
                          active: highlightIcon.value
                        }, null, 8, ["active"])
                      ]),
                      _: 1
                    }, 8, ["tooltipText"]))
                  : _createCommentVNode("", true)
              ], 32)
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["parameter", "secondLine", "hideHeading"])
  ]))
}
}

})