import { createApp } from 'vue/dist/vue.esm-bundler';
import { App, Component } from 'vue';
import i18n, { setGlobalTermsAngular } from '@/_shared/translations/i18n';
import { createPinia } from 'pinia';
import RequireContext = __WebpackModuleApi.RequireContext;
import legacyRouter from './router/indexAngular';

export const createVueAppInternal = (appComponent: Component | unknown, forAngular: boolean) => {
  if (!appComponent) {
    appComponent = {
      data() {
        return {};
      },
      mounted: () => {
        console.log('App is mounted!');
      },
      unmounted: () => {
        console.log('App is unmounted!');
      },
    };
  }
  const vueApp: App = createApp(appComponent);
  configureVueApp(vueApp, forAngular);
  return vueApp;
};

export const createVueAppForAngular = () => createVueAppInternal(undefined, true);

export const createVueApp = (appComponent: Component) => createVueAppInternal(appComponent, false);

const configureVueApp = (vueApp: App, forAngular = false) => {
  const registerWithApp = (context: RequireContext, registrationFunction: (...args: [string, Record<string, unknown>])=>void) => {
    context.keys().forEach((fileName: string) => {
      const config = context(fileName);
      const name = fileName.replace(/^.*\/(.*)\.\w+$/, '$1');
      registrationFunction(name, config.default || config);
    });
  };

  // we now manually import all Base components
  // const sharedComponents = require.context(
  //   './components/',
  //   true,
  //   /Base[A-Z]\w+\.(vue)$/,
  // );
  // registerWithApp(sharedComponents, vueApp.component);

  if (forAngular) {
    const forAngularComponents = require.context(
      './components/routing/',
      true,
      /Base[A-Z]\w+\.(vue)$/,
    );
    registerWithApp(forAngularComponents, vueApp.component);
  }

  const mainComponents = require.context(
    '../',
    true,
    /\w+Main\.(vue)$/,
  );
  registerWithApp(mainComponents, vueApp.component);

  // we now manually import all directives
  // const sharedDirectives = require.context(
  //   './directives/',
  //   true,
  //   /\w+\.ts$/,
  // );
  // registerWithApp(sharedDirectives, vueApp.directive);

  registerTranslations(vueApp);
  registerPinia(vueApp);
  vueApp.use(legacyRouter);
};

export const registerTranslations = (vueApp: App) => {
  vueApp.use(i18n);
  setGlobalTermsAngular(window.currentOrganisationUnit);
};

export const registerPinia = (vueApp: App) => {
  const pinia = createPinia();
  vueApp.use(pinia);
};
